import { validateMonthlyAllocation } from "@src/features/resource_plan/utils/resource_planner_utils";

const updateDiff = (month, year, value, params, isValid) => {
  const key = year + "_" + month;
  const hasPreviousError = params.node.error.has(key);

  if (!isValid) {
    params.node.error.add(key);
  } else if (hasPreviousError) {
    params.node.error.delete(key);
  }

  if (!params.node.initialData.yearlyAllocations[year] && value === 0) {
    delete params.node.diff[key];
  } else if (
    params?.node?.initialData?.yearlyAllocations[year] &&
    params?.node?.initialData?.yearlyAllocations[year][month] === value
  ) {
    delete params.node.diff[key];
  } else {
    params.node.diff[key] = {
      isAllocationRequest: true,
      requestID: params.node.data.childId,
      allocation: value,
      requestedMonth: month,
      requestedYear: year,
      uniqueId: key,
    };
  }
};

export function onCellValueChanged(params) {
  initNode(params);
  setChangeStatus(params);

  let inputValue = params.data[params.colDef.field];

  if (params.colDef.field.toString().indexOf("allocations") !== -1) {
    inputValue =
      params.data["allocations"][
        params.colDef.year + "_" + params.colDef.month
      ];
  }

  params.value = inputValue || "0";

  if (
    params.colDef?.colId?.match(/^\d{4}_\d{1,2}$/) // matches "yyyy_mm" or "yyyy_m"
  ) {
    const splitColId = params.colDef.colId.split("_");
    const year = +splitColId[0];
    const month = +splitColId[1];

    const selectedYearAllocations = params.node.data.yearlyAllocations[year];

    const currentValue = selectedYearAllocations
      ? selectedYearAllocations[month]
      : 0;

    if (currentValue?.toString() !== params.value) {
      const validatedResult = validateMonthlyAllocation(params, year, month);
      const updatedValue = validatedResult.updatedValue;

      updateDiff(month, year, updatedValue, params, validatedResult.isValid);

      params.node.data.yearlyAllocations[year] = selectedYearAllocations || {};

      params.node.data.yearlyAllocations[year][month] = updatedValue;
      params.node.yearlyAllocations = params.data.yearlyAllocations;
      params.api.refreshCells(params);
    }
  }
}

export const setChangeStatus = (params) => {
  const initChangeStatus = (colId, colUpdateName) => {
    if (params.column.colId !== colId) return;

    if (
      params.node.initialData[colId] === params.newValue ||
      (!params.node.initialData[colId] && !params.newValue)
    ) {
      params.node[colUpdateName] = false;
    } else if (params.node.initialData[colId] !== params.newValue) {
      params.node[colUpdateName] = true;
    }
  };

  initChangeStatus("projectIdentifier", "isProjectIdentifierUpdated");
  initChangeStatus("scope", "isScopeUpdated");
  initChangeStatus("specificRole", "isSpecificRoleUpdated");
  initChangeStatus("genericRoleName", "isGenericRoleUpdated");
  initChangeStatus("jointVenture", "isJointVentureUpdated");
};

export const initNode = (params) => {
  if (params.node.initialData) return;

  params.node.suggestWorkload = true;
  params.node.initialData = JSON.parse(JSON.stringify(params.node.data));

  if (params.column.colId === "projectIdentifier") {
    params.node.initialData.projectIdentifier = params.oldValue;
  }

  if (params.column.colId === "scope") {
    params.node.initialData.scope = params.oldValue;
  }

  if (params.column.colId === "specificRole") {
    params.node.initialData.specificRole = params.oldValue;
  }

  if (params.column.colId === "genericRoleName") {
    params.node.initialData.genericRoleName = params.oldValue;
  }

  if (params.column.colId === "jointVenture") {
    params.node.initialData.jointVenture = params.oldValue;
  }

  params.node.diff = {};
  params.node.error = new Set();
  params.api.refreshCells(params);
};
