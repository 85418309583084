// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-rows-overlay {
  background-color: #fafafa;
  color: #3b3d42;
  border: 1px solid #d2dae4;
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/overlays/no_rows_overlay/no_rows_overlay.less"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,cAAA;EACA,yBAAA;EACA,YAAA;AADF","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.no-rows-overlay {\n  background-color: @sand-20;\n  color: @font-00;\n  border: 1px solid @brand-20;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
