import { DatePicker } from "antd";

import React from "react";

import "./date_range_filter.less";
import Calendar from "@src/assets/icons/calendar.svg";
import FilterCollapseWrapper from "@src/features/table_filtering/components/filter_collapse_wrapper/filter_collapse_wrapper";

export interface DateRangeFilterProps {
  title: string;
  dateFilter: any;
  disabledFrom: boolean;
  disabledTo: boolean;
  onRangePickerChange: (dateRange: any) => void;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  title,
  dateFilter,
  disabledFrom,
  disabledTo,
  onRangePickerChange,
}) => {
  function getSuffixIcon() {
    return (
      <div className="date-range-picker__suffix-icon">
        <img src={Calendar} alt="calendar" />
      </div>
    );
  }

  return (
    <FilterCollapseWrapper title={title}>
      <DatePicker.RangePicker
        className="date-range-picker"
        suffixIcon={getSuffixIcon()}
        data-testid="filter-range-picker"
        format="DD.MM.YYYY"
        onChange={onRangePickerChange}
        value={dateFilter}
        disabled={[disabledFrom, disabledTo]}
      />
    </FilterCollapseWrapper>
  );
};

export default DateRangeFilter;
