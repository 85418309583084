import React, { useState, useEffect } from "react";
import "./note_history.less";
import { Input } from "antd/lib/index";
import Cookies from "universal-cookie";
import moment from "moment";
import {
  useGetNoteQuery,
  useSaveNoteStatusMutation,
} from "@src/services/slices/notesApiSlice";
import { Note } from "@src/types";

const { TextArea } = Input;
const cookies = new Cookies();

interface NoteHistoryProps {
  requestId: number;
}

const NoteHistory: React.FC<NoteHistoryProps> = ({ requestId }) => {
  const [completeNote, setCompleteNote] = useState<string>("");

  const { data: notes, isSuccess: isGetNotesSuccess } =
    useGetNoteQuery(requestId);

  const [updateNote] = useSaveNoteStatusMutation();

  useEffect(() => {
    if (!isGetNotesSuccess) return;

    let complete = completeNote;

    for (const note of notes) {
      complete = complete
        ? complete + "\r\n" + createNoteString(note)
        : createNoteString(note);
    }

    setCompleteNote(complete);

    if (notes.length !== 0) {
      const lastCommentedEmployeeId = notes[0].employeeId;
      const noteId = notes[0].id;
      let loggedInEmployeeId = null;

      if (cookies.get("loggedInuser") != null) {
        loggedInEmployeeId = cookies.get("loggedInuser").employeeId;
      }

      if (lastCommentedEmployeeId !== loggedInEmployeeId) {
        const noteStatus: any = {
          noteId: noteId,
          requestId: requestId,
          readByEmployeeId: loggedInEmployeeId,
        };

        updateNote(noteStatus);
      }
    }
  }, [isGetNotesSuccess]);

  function createNoteString(note: Note): string {
    const datePart = "[" + moment(note.createdDate).format("DD/MM/YYYY") + "]";
    return `${datePart} ${note.employeeFullName}: ${note.text}`;
  }

  return (
    <TextArea
      data-testid="comments-area"
      rows={4}
      placeholder="No comments available"
      readOnly={true}
      value={completeNote}
      style={{ resize: "none" }}
    />
  );
};

export default NoteHistory;
