import React from "react";

import { Tree, TreeDataNode } from "antd";

import "./split_requests_tree.less";

/**
 * actually I wanted this to be the split_role_transfer_right_side but I need further investigation
 * this might change when outsourcing some more code from the split_request_assignment component
 */
interface SplitRequestsTreeProps {
  treeRequestData: TreeDataNode[];
  onItemSelect: (key: React.Key, check: boolean) => void;
}

const SplitRequestsTree: React.FC<SplitRequestsTreeProps> = ({
  treeRequestData,
  onItemSelect,
}) => {
  return (
    <div className="split-role-transfer-tree-left">
      {treeRequestData.length > 0 ? (
        <Tree
          blockNode
          checkable
          treeData={treeRequestData}
          onCheck={(_, { checked, node: { key } }) => {
            onItemSelect(key, checked);
          }}
        />
      ) : (
        <div className="split-role-transfer-tree-left__no-requests">
          No requests assigned yet
        </div>
      )}
    </div>
  );
};

export default SplitRequestsTree;
