// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headline {
  direction: rtl;
  width: 100%;
  margin: 20px 0 20px 0;
}
.headline * {
  direction: ltr;
}
.headline .title {
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/headline/headline.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,qBAAA;AACF;AAEA;EACE,cAAA;AAAF;AAGA;EACE,eAAA;AADF","sourcesContent":[".headline {\n  direction: rtl;\n  width: 100%;\n  margin: 20px 0 20px 0;\n}\n\n.headline * {\n  direction: ltr;\n}\n\n.headline .title {\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
