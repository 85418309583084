// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout-content:has(.management-dashboard) {
  padding: 0;
}
.management-dashboard {
  padding: 0;
}
.management-dashboard__content {
  width: 100%;
  padding: 20px;
}
.management-dashboard__charts .ant-card {
  box-shadow: 0 1px 4px 0 #00000025;
  border-radius: 5px;
  width: 100%;
}
.management-dashboard__charts .ant-card:not(.simple-custom-card) .ant-card-body {
  height: 400px;
}
.management-dashboard__charts .ant-card-head {
  height: 50px;
}
.management-dashboard .ant-row {
  margin-bottom: 32px;
}
.management-dashboard .ant-col {
  min-width: 400px;
}
.management-dashboard .ant-col--request-replies {
  min-width: 200px;
}
.management-dashboard .ant-col--simple-custom-cards {
  min-width: 150px;
  display: flex;
  flex-direction: column;
}
.management-dashboard .ant-collapse,
.management-dashboard .ant-collapse-content,
.management-dashboard .ant-tree {
  background-color: #fafafa;
}
.management-dashboard .filter-selection {
  padding: 16px;
}
.management-dashboard .filter-selection label {
  font-size: 14px;
}
.management-dashboard__month-range-select {
  margin-top: 10px;
  display: flex;
  width: 150px;
}
.management-dashboard__filter {
  margin-left: 0;
}
.management-dashboard__declined-requests-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/dashboard_page.less"],"names":[],"mappings":"AAEA;EACE,UAAA;AADF;AAIA;EACE,UAAA;AAFF;AAIE;EACE,WAAA;EACA,aAAA;AAFJ;AAKE;EAEI,iCAAA;EACA,kBAAA;EACA,WAAA;AAJN;AAMM;EAEI,aAAA;AALV;AASM;EACE,YAAA;AAPR;AAaI;EACE,mBAAA;AAXN;AAcI;EACE,gBAAA;AAZN;AAcM;EACE,gBAAA;AAZR;AAeM;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;AAbR;AAiBI;;;EAGE,yBAAA;AAfN;AAjCA;EAqDI,aAAA;AAjBJ;AApCA;EAwDM,eAAA;AAjBN;AAqBE;EACE,gBAAA;EACA,aAAA;EACA,YAAA;AAnBJ;AAsBE;EACE,cAAA;AApBJ;AAuBE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;AArBJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.ant-layout-content:has(.management-dashboard) {\n  padding: 0;\n}\n\n.management-dashboard {\n  padding: 0;\n\n  &__content {\n    width: 100%;\n    padding: 20px;\n  }\n\n  &__charts {\n    .ant-card {\n      box-shadow: 0 1px 4px 0 #00000025;\n      border-radius: 5px;\n      width: 100%;\n\n      &:not(.simple-custom-card) {\n        .ant-card-body {\n          height: 400px;\n        }\n      }\n\n      &-head {\n        height: 50px;\n      }\n    }\n  }\n\n  .ant {\n    &-row {\n      margin-bottom: 32px;\n    }\n\n    &-col {\n      min-width: 400px;\n\n      &--request-replies {\n        min-width: 200px;\n      }\n\n      &--simple-custom-cards {\n        min-width: 150px;\n        display: flex;\n        flex-direction: column;\n      }\n    }\n\n    &-collapse,\n    &-collapse-content,\n    &-tree {\n      background-color: @sand-20;\n    }\n  }\n\n  .filter-selection {\n    padding: 16px;\n\n    label {\n      font-size: 14px;\n    }\n  }\n\n  &__month-range-select {\n    margin-top: 10px;\n    display: flex;\n    width: 150px;\n  }\n\n  &__filter {\n    margin-left: 0;\n  }\n\n  &__declined-requests-label {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
