import React from "react";

import WarningFilled from "@ant-design/icons/lib/icons/WarningFilled";
import "./edit_project_confirmation_modal.less";
import { Button, Col, Modal, Row, Typography } from "antd";
const { Paragraph } = Typography;

function EditProjectConfirmationModal({
  formValues,
  action,
  setModalVisible,
  modalVisible,
}) {
  const handleOk = () => {
    setModalVisible(false);
    action(formValues);
  };

  const getUniqueEditors = (editors) => {
    const editorsName = new Set(editors);
    return [...editorsName];
  };

  return (
    <>
      <Modal
        width={"30%"}
        open={modalVisible}
        centered
        closable={false}
        bodyStyle={{ width: "100%" }}
        onOk={handleOk}
        onCancel={() => setModalVisible(false)}
        footer={
          <>
            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
            <Button
              onClick={handleOk}
              type="primary"
              data-testid={"acceptEditorBtn"}
            >
              {" "}
              Save{" "}
            </Button>
          </>
        }
      >
        <Row className={"warning-row"}>
          <Col>
            <WarningFilled className="warningIcon" />
          </Col>
          <Col>
            <div className={"warning-title"} data-testid="changesAcceptWarning">
              Please check the changes you made again, they affect permission
              rights for this project
            </div>
          </Col>
        </Row>

        <div style={{ marginLeft: "2.8%" }}>
          <Paragraph>
            Project manager:{" "}
            <b>
              <i>
                {Object.keys(formValues).length > 0 &&
                  formValues.projectManager}{" "}
              </i>
            </b>
          </Paragraph>
          <Paragraph data-testid={"editorAccessRightChangeWarning"}>
            Project editors:{" "}
            <b>
              <i>
                {Object.keys(formValues).length > 0 &&
                  getUniqueEditors([
                    formValues.projectManager,
                    ...formValues.editors,
                  ])
                    .map((editor) => {
                      return `${editor}`;
                    })
                    .join("  /  ")}{" "}
              </i>
            </b>
          </Paragraph>
        </div>
      </Modal>
    </>
  );
}

export default EditProjectConfirmationModal;
