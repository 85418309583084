import React from "react";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { Button, Tooltip } from "antd";
import "./add_time_blocker.less";

function AddTimeBlocker({ timeBlockerInEdit, addTimeBlocker, value }) {
  const project = {
    isInEdit: true,
    isNewTimeBlocker: true,
    projectRoleRequestId: -1,
    allocations: [],
    teamMemberId: value?.employeeId,
  };

  const checkButtonDisableStatus = () => {
    let isButtonDisabled =
      !value?.genericRoleName || value?.vacancy || timeBlockerInEdit;

    return isButtonDisabled;
  };

  function getTooltipContent() {
    if (timeBlockerInEdit === -1) {
      return "Please save/cancel the currently editing time blocker to add new one for any employee";
    }
    if (!value?.genericRoleName) {
      return "Employee should not be a vacancy and must have a standard role to add any timeBlocker";
    }
    return "Add time blocker";
  }

  return (
    <div className="time-blocker-add">
      <Tooltip title={getTooltipContent}>
        <Button
          data-testid="add-time-blocker-button"
          disabled={checkButtonDisableStatus()}
          onClick={() => addTimeBlocker(project)}
          icon={<PlusOutlined />}
        >
          Add time blocker
        </Button>
      </Tooltip>
    </div>
  );
}

export default AddTimeBlocker;
