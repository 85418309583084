// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-project-overview .actions-cell {
  white-space: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.admin-project-overview .actions-cell .ag-cell-wrapper {
  height: 100%;
  width: 100%;
}
.admin-project-overview .actions-cell .ag-cell-value {
  height: 100%;
}
.admin-project-overview .actions-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: space-around;
  height: 100%;
}
.admin-project-overview .text-cell {
  white-space: normal;
}
.admin-project-overview .project-editor-cell .ag-cell-wrapper {
  width: 90%;
}
.admin-project-overview .filter-buttons-section .filter-download-section {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.admin-project-overview .filter-buttons-section .filter-download-section .filter-group {
  width: 850px;
  margin-bottom: 10px;
}
.admin-project-overview .filter-buttons-section .filter-download-section .ant-form-item {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/features/admin_project_assignments/components/admin_project_overview/admin_project_overview.less"],"names":[],"mappings":"AAEI;EACE,mBAAA;EAEA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;AAFN;AAJI;EASI,YAAA;EACA,WAAA;AAFR;AARI;EAcI,YAAA;AAHR;AAMI;EACE,aAAA;EACA,8BAAA;EACA,eAAA;EACA,2BAAA;EACA,YAAA;AAJN;AApBA;EA6BI,mBAAA;AANJ;AAvBA;EAkCM,UAAA;AARN;AA1BA;EAwCM,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;AAXN;AAhCA;EA8CQ,YAAA;EACA,mBAAA;AAXR;AApCA;EAmDQ,gBAAA;AAZR","sourcesContent":[".admin-project-overview {\n  .actions {\n    &-cell {\n      white-space: normal;\n\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n      height: 100%;\n\n      .ag-cell-wrapper {\n        height: 100%;\n        width: 100%;\n      }\n\n      .ag-cell-value {\n        height: 100%;\n      }\n    }\n    &-buttons {\n      display: flex;\n      justify-content: space-between;\n      flex-wrap: wrap;\n      align-content: space-around;\n      height: 100%;\n    }\n  }\n\n  .text-cell {\n    white-space: normal;\n  }\n\n  .project-editor-cell {\n    .ag-cell-wrapper {\n      width: 90%;\n    }\n  }\n\n  .filter-buttons-section {\n    .filter-download-section {\n      margin-top: 24px;\n      display: flex;\n      justify-content: space-between;\n      flex-wrap: wrap;\n\n      .filter-group {\n        width: 850px;\n        margin-bottom: 10px;\n      }\n\n      .ant-form-item {\n        margin-bottom: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
