import { ROLES } from "@src/constants";
import React from "react";
import { AssignRolesPayload, StandardRole } from "@src/types";

export function getAccountRolePopOverContent(): React.ReactElement {
  return (
    <div>
      The roles that you select here define the access and permissions of the
      user
    </div>
  );
}

export function getTeamLeadPopOverContent(): React.ReactElement {
  return (
    <>
      <div>
        If you select a standard role here, the user is responsible for this
        standard role. This means all requests for that specific role are going
        to be sent to this person first.
      </div>
      <br />
      <div className="standard-role-warning">
        If you don't select a responsible team lead for a standard role, nobody
        will receive requests for this role.
      </div>
    </>
  );
}

export function createPayload(
  fieldValue,
  employeeId: number,
  standardRoles: StandardRole[]
): AssignRolesPayload {
  const updatedUserDetails = {
    employeeId: employeeId,
    applicationRoles: getApplicationRole(fieldValue),
    tlPrimaryGenericRoles: fieldValue.teamLead
      ? findStandardRoleObjectByName(
          fieldValue.teamLeadStandardRoles,
          standardRoles
        ).map((role) => role.standardRoleId)
      : null,
    deputyStandardRoleIds: fieldValue.deputy
      ? findStandardRoleObjectByName(
          fieldValue.deputyStandardRoles,
          standardRoles
        ).map((role) => role.standardRoleId)
      : [],
    tmGenericRole: fieldValue.teamMember
      ? findStandardRoleObjectByName(
          [fieldValue.teamMemberStandardRole],
          standardRoles
        )[0]?.standardRoleId
      : null,
  };

  return updatedUserDetails;
}

export function getApplicationRole(fieldValue): string[] {
  const ROLES_MAP = {
    manager: ROLES.MANAGER,
    admin: ROLES.ADMIN,
    deputy: ROLES.DEPUTY,
    teamLead: ROLES.TEAM_LEAD,
    teamMember: ROLES.TEAM_MEMBER,
    projectLead: ROLES.PROJECT_LEAD,
  };

  return Object.keys(ROLES_MAP)
    .filter((key) => fieldValue[key])
    .map((key) => ROLES_MAP[key]);
}

function findStandardRoleObjectByName(
  standardRoleNames: string[],
  standardRoles: StandardRole[]
): StandardRole[] {
  const rolesToBeFiltered: StandardRole[] = [];

  standardRoles.forEach((standardRole: StandardRole) => {
    if (standardRoleNames.includes(standardRole.standardRoleName))
      rolesToBeFiltered.push(standardRole);
  });

  return rolesToBeFiltered;
}

export enum INPUT_FIELDS {
  MANAGER = "manager",
  ADMIN = "admin",
  DEPUTY = "deputy",
  DEPUTY_STANDARD_ROLES = "deputyStandardRoles",
  TEAM_LEAD = "teamLead",
  TEAM_LEAD_STANDARD_ROLES = "teamLeadStandardRoles",
  TEAM_MEMBER = "teamMember",
  PROJECT_LEAD = "projectLead",
}
