// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tree-selection-with-tooltip {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/features/table_filtering/components/tree_selection_with_tooltip/tree_selection_with_tooltip.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,QAAA;AACF","sourcesContent":[".tree-selection-with-tooltip {\n  display: flex;\n  justify-content: space-between;\n  gap: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
