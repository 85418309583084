import React from "react";

import { OpenRequests } from "@src/features/resource_plan/components/open_requests";

const ImportResourcePlanTable = ({ project, requests }) => {
  return (
    <OpenRequests
      projectId={project.id}
      startDate={project.startDate}
      endDate={project.endDate}
      requests={requests}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      callBack={() => {}}
      isJointVenture={!!project.jointVenturePartner}
      isAdminUpload
      project={project}
    />
  );
};

export default ImportResourcePlanTable;
