import React from "react";

import { SelectOption } from "@src/types/antd_types";
import { Select } from "antd";

interface FilterSelectionProps {
  headline: string;
  options: SelectOption[];
  onFilter: (timeRange: number) => void;
  disabled?: boolean;
  className?: string;
  defaultValue?: number;
}

const FilterSelection: React.FC<FilterSelectionProps> = ({
  headline,
  options,
  className,
  disabled,
  onFilter,
  defaultValue,
}) => {
  return (
    <div className="filter-selection">
      <label>{headline}</label>
      <Select
        className={className}
        disabled={disabled}
        data-testid="month-range-selection"
        options={options}
        onChange={onFilter}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default FilterSelection;
