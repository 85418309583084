import { useEffect, useState } from "react";

import { TECHNOLOGY } from "@src/constants";
import { useLazyGetEmployeeByIdQuery } from "@src/services/slices/employeesSlice";
import { useGetDistinctStandardRolesQuery } from "@src/services/slices/standardRolesApi";
import {
  BasicRoleWithRoleAssignments,
  Employee,
  Project,
  RoleAssignment,
} from "@src/types";
import { Col, Form, Input, Modal, Row, Select, Tooltip } from "antd";
import "./select_standard_role_modal.less";

type setModalVisibleFunction = (isVisible: boolean) => void;
type onFinishFormFunction = (fieldValues: any[]) => void;
type onCancelModalFunction = () => void;

interface ISelectStandardRoleModal {
  setModalVisible: setModalVisibleFunction;
  modalVisible: boolean;
  onFinishForm: onFinishFormFunction;
  onCancelModal: onCancelModalFunction;
  project?: Project;
}

export function SelectStandardRoleModal({
  setModalVisible,
  modalVisible,
  onFinishForm,
  onCancelModal,
  project,
}: ISelectStandardRoleModal) {
  const [roleAssignments, setRoleAssignments] = useState<RoleAssignment[]>([]);
  const [selectedRoleAssignment, setSelectedRoleAssignment] =
    useState(undefined);
  const [selectedTeamLeadName, setSelectedTeamLeadName] = useState("");

  const [form] = Form.useForm();

  const isNoRoleSpecificationAvailable =
    roleAssignments.length === 1 &&
    roleAssignments[0].roleSpecificationId === 0;

  const {
    data: basicRolesWithRoleAssignments,
    isSuccess: isSuccessBasicRolesWithRoleAssignments,
  } = useGetDistinctStandardRolesQuery();
  const [fetchEmployeeById] = useLazyGetEmployeeByIdQuery();
  const ROLE_SPECIFICATION_NONE = "--none--";

  useEffect(() => {
    if (modalVisible && form) {
      form.resetFields(["basicRole", "roleSpecification"]);
      setSelectedRoleAssignment(undefined);
      setSelectedTeamLeadName("");
      setRoleAssignments([]);
    }
  }, [modalVisible, form]);

  const handleSave = () => {
    form.setFieldsValue({
      genericRoleId: selectedRoleAssignment.standardRoleId,
    });
    form.submit();
    setModalVisible(false);
  };

  function onSelectRoleSpecification(
    roleSpecificationName: string,
    roleSpecifications: RoleAssignment[]
  ) {
    const foundRoleAssignment: RoleAssignment = roleSpecifications.find(
      (roleAssignment: RoleAssignment) =>
        roleAssignment.roleSpecificationName === roleSpecificationName
    );

    setSelectedRoleAssignment(foundRoleAssignment);
    updateSelectedTeamLead(foundRoleAssignment);
  }

  function onSelectBasicRole() {
    form.resetFields(["roleSpecification"]);

    const selectedBasicRoleName: string = form.getFieldValue("basicRole");
    const actualBasicRole: BasicRoleWithRoleAssignments =
      basicRolesWithRoleAssignments.find(
        (role: BasicRoleWithRoleAssignments) =>
          role.basicRoleName === selectedBasicRoleName
      );
    const roleAssignments: RoleAssignment[] = actualBasicRole.roleAssignments;
    setRoleAssignments(roleAssignments);

    if (roleAssignments.length > 1) {
      setSelectedRoleAssignment(undefined);
      setSelectedTeamLeadName("");
      // should check if auto selected is possible for role specification
      roleSpecificationAutoSelect(actualBasicRole);
    } else {
      const role: RoleAssignment = roleAssignments[0];
      roleSpecificationAutoSelect(actualBasicRole);
      setSelectedRoleAssignment(role);
      updateSelectedTeamLead(role);
    }
  }

  function getAutoSelectedRoleSpecificationNameByTechnology(
    roleAssignments: RoleAssignment[]
  ): string {
    let roleSpecificationName = "";

    // should preselect role specification based on technology in the following order.
    // Hydrogen should be first, then Fixed bottom, then Floating
    const technologyOrder = [
      TECHNOLOGY.HYDROGEN,
      TECHNOLOGY.FIXED_BOTTOM,
      TECHNOLOGY.FLOATING,
    ];

    for (const technology of technologyOrder) {
      roleSpecificationName = roleAssignments.find(
        (roleAssignment: RoleAssignment) =>
          roleAssignment.roleSpecificationName === technology
      )?.roleSpecificationName;

      if (roleSpecificationName) break;
    }

    return roleSpecificationName;
  }

  function roleSpecificationAutoSelect(
    actualBasicRole: BasicRoleWithRoleAssignments
  ) {
    if (!project) return;

    const roleAssignments: RoleAssignment[] = actualBasicRole.roleAssignments;

    // need to check if auto selected is possible for role specification in the following order:
    const autoSelectedTypes = [
      "country",
      "regionName",
      "technologies",
      "projectPhase",
    ];

    for (const projectAttribute of autoSelectedTypes) {
      const value: string = project[projectAttribute];

      const foundRoleAssignment: RoleAssignment = roleAssignments.find(
        (roleSpecification: RoleAssignment) =>
          roleSpecification.roleSpecificationName === value
      );

      let autoSelectedRoleSpecificationName: string =
        foundRoleAssignment?.teamLeadId &&
        foundRoleAssignment.roleSpecificationName;

      // try to auto select based on technology
      if (
        project.technologies.length > 0 &&
        projectAttribute === "technologies"
      ) {
        autoSelectedRoleSpecificationName =
          getAutoSelectedRoleSpecificationNameByTechnology(roleAssignments);
      }

      // if auto selected is possible, then auto select the role specification
      if (autoSelectedRoleSpecificationName) {
        form.setFieldsValue({
          roleSpecification: autoSelectedRoleSpecificationName,
        });
        onSelectRoleSpecification(
          autoSelectedRoleSpecificationName,
          roleAssignments
        );

        break;
      }
    }
  }

  function updateSelectedTeamLead(selectedRole: RoleAssignment) {
    fetchEmployeeById(selectedRole.teamLeadId)
      .unwrap()
      .then((employee: Employee) => {
        setSelectedTeamLeadName(`${employee.firstName} ${employee.lastName}`);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  function getDescription(): string {
    if (!selectedRoleAssignment) return "";
    return selectedRoleAssignment.description;
  }

  return (
    <Modal
      width="50%"
      open={modalVisible}
      title="Standard role selection"
      maskClosable={false}
      closable={false}
      centered
      bodyStyle={{ width: "100%" }}
      style={{ right: "20%" }}
      onCancel={onCancelModal}
      cancelButtonProps={{ size: "large" }}
      okButtonProps={{ disabled: !selectedRoleAssignment, size: "large" }}
      okText="Save Changes"
      onOk={handleSave}
    >
      <Form
        form={form}
        name="selectStandardRoleForm"
        onFinish={onFinishForm}
        layout="vertical"
        size="large"
        className="select-standard-role-form"
      >
        <Form.Item
          label="Please select a standard role for this request"
          name="basicRole"
          wrapperCol={{
            span: 14,
          }}
        >
          <Select
            showSearch
            data-testid="basic-role-selection"
            placeholder="Select standard role"
            onSelect={() => onSelectBasicRole()}
          >
            {isSuccessBasicRolesWithRoleAssignments &&
              basicRolesWithRoleAssignments.map((role) => {
                // role should be disabled when no specification has a primary team lead id
                const disabled = !role.roleAssignments.some(
                  (roleAssignment: RoleAssignment) => roleAssignment.teamLeadId
                );

                return (
                  <Select.Option
                    key={role.basicRoleName}
                    value={role.basicRoleName}
                    data-testid={`${role.basicRoleName} Option`}
                    disabled={disabled}
                  >
                    {disabled ? (
                      <Tooltip
                        placement="topLeft"
                        title="No team lead assigned to the role, contact administrator for further details"
                      >
                        {role.basicRoleName}
                      </Tooltip>
                    ) : (
                      role.basicRoleName
                    )}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Please select the role's specification (if applicable)"
          name="roleSpecification"
          wrapperCol={{
            span: 14,
          }}
        >
          <Select
            showSearch
            data-testid="role-specification-selection"
            placeholder={
              isNoRoleSpecificationAvailable
                ? ROLE_SPECIFICATION_NONE
                : "Select role specification"
            }
            disabled={isNoRoleSpecificationAvailable}
            onSelect={(selectedRoleSpecificationName: string) =>
              onSelectRoleSpecification(
                selectedRoleSpecificationName,
                roleAssignments
              )
            }
          >
            {roleAssignments.map((role) => {
              return (
                <Select.Option
                  key={role.standardRoleId}
                  value={role.roleSpecificationName}
                  data-testid={`${role.roleSpecificationName} Option`}
                  disabled={!role.teamLeadId}
                >
                  {!role.teamLeadId ? (
                    <Tooltip
                      placement="topLeft"
                      title="No team lead assigned to the role, contact administrator for further details"
                    >
                      {role.roleSpecificationName
                        ? role.roleSpecificationName
                        : ROLE_SPECIFICATION_NONE}
                    </Tooltip>
                  ) : role.roleSpecificationName ? (
                    role.roleSpecificationName
                  ) : (
                    ROLE_SPECIFICATION_NONE
                  )}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="genericRoleId" hidden data-testid="genericRoleIdForm">
          <Input />
        </Form.Item>
      </Form>

      <Row gutter={[48, 16]} className="role-information">
        <Col span={14} className="col-description">
          <span>Role description</span>
          <Input.TextArea
            className="text-area-description"
            data-testid="role-description"
            disabled
            value={getDescription()}
            style={{ resize: "none" }}
          />
        </Col>

        <Col span={10}>
          <span>Responsible Team Lead</span>
          <Input
            data-testid="responsible-team-lead"
            disabled
            value={selectedTeamLeadName}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default SelectStandardRoleModal;
