import React from "react";

import { OutdatedBadge } from "@src/components/outdated_badge";
import { ICellRendererParams } from "ag-grid-community";

interface StatusRendererProps {
  params: ICellRendererParams;
}

const StatusRenderer: React.FC<StatusRendererProps> = ({ params }) => {
  return (
    <>
      {params?.data?.outdated ? (
        <OutdatedBadge daysUntilDeletion={params.data.daysUntilDeletion} />
      ) : (
        <p>{params?.value}</p>
      )}
    </>
  );
};

export default StatusRenderer;
