import React, { useState } from "react";

import "./note_form.less";
import { useAddNoteMutation } from "@src/services/slices/notesApiSlice";
import { Employee, ModalMsg, Note } from "@src/types";
import { Button, Input, Form, message } from "antd";
import moment from "moment";
import Cookies from "universal-cookie";

const { TextArea } = Input;

interface NoteForm {
  newComment: string;
}

interface NoteFormProps {
  requestId: number;
  action: () => void;
  setModalVisible: (visible: boolean) => void;
  modalMsg: ModalMsg;
  isActionButtonDisabled: boolean;
  isNoteMandatory: boolean;
}

const NoteForm: React.FC<NoteFormProps> = ({
  requestId,
  action,
  setModalVisible,
  modalMsg,
  isActionButtonDisabled,
  isNoteMandatory,
}) => {
  const [newNote, setNewNote] = useState<string>("");
  const [noteForm] = Form.useForm<NoteForm>();
  const [addNote] = useAddNoteMutation();

  async function saveNote(): Promise<void> {
    if (newNote && newNote !== "") {
      const date: string = moment().format("YYYY-MM-DDThh:mm");

      const cookies: Cookies = new Cookies();
      const user: Employee = cookies.get("loggedInuser");

      const note: Note = {
        employeeId: user.employeeId,
        createdDate: date,
        roleRequestId: requestId,
        text: newNote,
        employeeFullName: user.fullName,
        createdBy: user.employeeId,
      };

      setNewNote("");

      addNote(note)
        .unwrap()
        .then(() => {
          message.success("Successfully saved note", 2);
        })
        .catch((err) => console.log("error", err));
    }

    action();
  }

  return (
    <>
      {(!modalMsg.size || modalMsg.size === "large") && (
        <>
          <div>
            <Form
              form={noteForm}
              autoComplete="off"
              layout="vertical"
              size="large"
              onValuesChange={(noteForm: NoteForm) =>
                setNewNote(noteForm.newComment)
              }
              role="form"
            >
              <Form.Item name="newComment">
                <div className="leave-comment-area">
                  <TextArea
                    data-testid="leave-comment-form"
                    placeholder="Leave a comment"
                    value={newNote}
                    rows={5}
                    style={{ resize: "none" }}
                  />
                </div>
              </Form.Item>
            </Form>
          </div>

          <div className="modal-btn-area">
            <Button
              onClick={() => setModalVisible(false)}
              data-testid="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={saveNote}
              data-testid="action-btn"
              disabled={isActionButtonDisabled || (isNoteMandatory && !newNote)}
            >
              {modalMsg.action}
            </Button>
          </div>
        </>
      )}

      {modalMsg.size === "small" && (
        <div className="comment-area-small">
          <Form
            autoComplete="off"
            layout="vertical"
            onValuesChange={(noteForm: NoteForm) =>
              setNewNote(noteForm.newComment)
            }
            role="form"
          >
            <Form.Item name="newComment">
              <div className="leave-comment-area">
                <Input
                  data-testid="leaveCommentInputForm"
                  placeholder="Leave a comment"
                  value={newNote}
                />
              </div>
            </Form.Item>
          </Form>

          <div className="modal-btn-area">
            <Button
              onClick={saveNote}
              data-testid="action-btn"
              disabled={isActionButtonDisabled || (isNoteMandatory && !newNote)}
            >
              {modalMsg.action}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default NoteForm;
