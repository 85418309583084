// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-margin {
  width: 100%;
}
.button-margin .ant-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px !important;
}
.button-margin .rightButtons {
  padding: 0 !important;
}
.button-margin .rightButtons button {
  margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/import_resource_plan_page/import_resource_plan_page.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,2BAAA;AACJ;AAEE;EACE,qBAAA;AAAJ;AACI;EACE,iBAAA;AACN","sourcesContent":[".button-margin {\n  width: 100%;\n\n  & .ant-row {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    margin: 0 0 16px !important;\n  }\n\n  & .rightButtons {\n    padding: 0 !important;\n    & button {\n      margin-left: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
