// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-btn-area {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.modal-btn-area button {
  margin-left: 10px;
}
.leave-comment-area {
  display: flex;
  margin-top: 20px;
}
.leave-comment-area button {
  height: 50px;
}
.comment-area-small {
  display: flex;
}
.comment-area-small form {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/note/note_form/note_form.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,yBAAA;AACF;AAJA;EAMI,iBAAA;AACJ;AAGA;EACE,aAAA;EACA,gBAAA;AADF;AADA;EAKI,YAAA;AADJ;AAKA;EACE,aAAA;AAHF;AAEA;EAGI,WAAA;AAFJ","sourcesContent":[".modal-btn-area {\n  padding-top: 20px;\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    margin-left: 10px;\n  }\n}\n\n.leave-comment-area {\n  display: flex;\n  margin-top: 20px;\n\n  button {\n    height: 50px;\n  }\n}\n\n.comment-area-small {\n  display: flex;\n  form {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
