// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag-proposed {
  font-family: "RWESansWeb-Medium", sans-serif;
  font-size: 12px !important;
  background-color: #ffa500 !important;
  padding: 0 10px !important;
  line-height: 20px !important;
}
.badge {
  font-size: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/features/teams/components/name_circle_renderer/name_circle_renderer.less"],"names":[],"mappings":"AAEA;EACE,4CAAA;EACA,0BAAA;EACA,oCAAA;EACA,0BAAA;EACA,4BAAA;AADF;AAIA;EACE,eAAA;AAFF","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.tag-proposed {\n  font-family: \"RWESansWeb-Medium\", sans-serif;\n  font-size: 12px !important;\n  background-color: @light-orange !important;\n  padding: 0 10px !important;\n  line-height: 20px !important;\n}\n\n.badge {\n  font-size: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
