import React from "react";
import { Tooltip } from "antd";

const TooltipRenderer = (props) => {
  return (
    <Tooltip placement="topLeft" title={props}>
      {props}
    </Tooltip>
  );
};

export default TooltipRenderer;
