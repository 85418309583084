// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-spin-dot {
  position: fixed !important;
}
.rpp-admin .ant-table-body {
  max-height: calc(100vh - 290px) !important;
}
.content {
  padding-right: 5% !important;
  padding-left: 5% !important;
  padding-bottom: 25px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/admin_role_assignment_page/admin_role_assignment_page.less"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;AAEA;EACE,0CAAA;AAAF;AAGA;EACE,4BAAA;EACA,2BAAA;EACA,+BAAA;AADF","sourcesContent":[".ant-spin-dot {\n  position: fixed !important;\n}\n\n.rpp-admin .ant-table-body {\n  max-height: calc(100vh - 290px) !important;\n}\n\n.content {\n  padding-right: 5% !important;\n  padding-left: 5% !important;\n  padding-bottom: 25px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
