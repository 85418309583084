// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-project-lead-modal .ant-modal-footer {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/features/sap_upload/components/update_project_lead_modal/update_project_lead_modal.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;AAAJ","sourcesContent":[".update-project-lead-modal {\n  .ant-modal-footer {\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
