import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SplitRoleState } from "@src/types";
import { TransferItem } from "antd/es/transfer";
import { TransferProps } from "antd";

const initialState: SplitRoleState = {
  currentAssignedRequestsToSplit: {},
  unassignedRequests: [],
  allRequestsToReassign: [],
  targetKeys: [],
  isSaveDisabled: true,
  isLastStepStateInitialized: false,
};

const splitRoleSlice = createSlice({
  name: "splitRole",
  initialState: initialState,
  reducers: {
    /**
     * Current assigned requests are the requests when the user assigns them. These are the requests which are
     * connected to the new specification.
     *
     * @param {SplitRoleState}  state the split role request state with all values.
     * @param {PayloadAction}   currentAssignedRequestsToSplitAction The requests the user assigned with the selected specification id.
     *                                                              (e.G.: {12: [1, 2, 3]}
     */
    setCurrentAssignedRequestsToSplit: (
      state: SplitRoleState,
      currentAssignedRequestsToSplitAction: PayloadAction<
        Record<string, string[]>
      >
    ) => {
      state.currentAssignedRequestsToSplit =
        currentAssignedRequestsToSplitAction.payload;
    },

    /**
     * Initialising all unassigned requests which are shown in the left transfer column.
     *
     * @param {SplitRoleState}  state the split role request state with all values.
     * @param {PayloadAction<TransferItem[]>} unassignedRequestsAction all requests which should be assigned to a new specification.
     */
    setUnassignedRequests: (
      state: SplitRoleState,
      unassignedRequestsAction: PayloadAction<TransferItem[]>
    ) => {
      state.unassignedRequests = unassignedRequestsAction.payload;

      if (state.allRequestsToReassign.length === 0) {
        state.allRequestsToReassign = unassignedRequestsAction.payload;
      }
    },

    /**
     * Tracks when the save button in the parent step progress component should be enabled.
     *
     * @param {SplitRoleState}  state the split role request state with all values.
     * @param {PayloadAction<boolean>} isSaveDisabledAction state for the save status. It should track if the save could be enabled or not.
     *                                                      The save button should be only enabled after all requests are assigned to a new split.
     */
    setIsSaveDisabled: (
      state: SplitRoleState,
      isSaveDisabledAction: PayloadAction<boolean>
    ) => {
      state.isSaveDisabled = isSaveDisabledAction.payload;
    },

    /**
     * If the user visits the request assignment step then the flag will be set to true. This is necessary,
     * to be sure, that when there are requests in the unassigned array the array will not be initialized with all requests again.
     * This will happen when the user assigns all requests to a split and then goes back and again to the next page.
     * This leads usually to reinitialization of the unassignedRequests so that`s why it was important to track the state.
     *
     * @param {SplitRoleState}  state the split role request state with all values.
     * @param {PayloadAction<boolean>} lastStepInitializedAction       True or false when the last step was initialized.
     */
    setLastStepStateInitialized: (
      state: SplitRoleState,
      lastStepInitializedAction: PayloadAction<boolean>
    ) => {
      state.isLastStepStateInitialized = lastStepInitializedAction.payload;
    },

    /**
     * The target keys are important for the antd transfer component to track which elements have been transfered.
     *
     * @param {SplitRoleState}  state the split role request state with all values.
     * @param {PayloadAction<TransferProps["targetKeys"]>} targetKeysAction  the keys of the requests needed for the transform list.
     */
    setTargetKeys: (
      state: SplitRoleState,
      targetKeysAction: PayloadAction<TransferProps["targetKeys"]>
    ) => {
      state.targetKeys = targetKeysAction.payload;
    },

    /**
     * When the user assigns requests to a split and then goes back to the split section and removes the split
     * with the assigned requests, then the state should also be updated and the split removed from the state
     * and the requests should be assigned back to the unassigned requests list.
     *
     * @param {SplitRoleState}  state the split role request state with all values.
     * @param {PayloadAction<string[]>} deletedSpecificationIds   The ids of the requests to remove from the split
     */
    removeAssignmentFromSplit: (
      state: SplitRoleState,
      deletedSpecificationIds: PayloadAction<string[]>
    ) => {
      if (deletedSpecificationIds.payload.length === 0) {
        return;
      }

      for (const roleSpecificationId of deletedSpecificationIds.payload) {
        const assignedRequests: string[] =
          state.currentAssignedRequestsToSplit[roleSpecificationId];

        if (!assignedRequests) continue;

        // Filter and collect all requests that need to be reassigned.
        const requestsToReassign: TransferItem[] =
          state.allRequestsToReassign.filter((request: TransferItem) => {
            // check which request should be taken back from the deleted split to the pool of selectable requests
            const isRequestIdToReassign = assignedRequests.includes(
              request.key.toString()
            );

            if (isRequestIdToReassign) {
              // should also update target keys after assignment removed
              state.targetKeys.splice(
                state.targetKeys.indexOf(request.key.toString()),
                1
              );
            }

            return isRequestIdToReassign;
          });

        // Append found requests back to the unassigned requests array.
        state.unassignedRequests.push(...requestsToReassign);

        // Remove the processed roleSpecificationId from current assignments.
        delete state.currentAssignedRequestsToSplit[roleSpecificationId];
      }

      state.isSaveDisabled = true;
    },

    /**
     * Reset the search to initial state after the user leaves the assignment view and clicks back.
     *
     * @param {SplitRoleState}  state the split role request state with all values.
     */
    resetSearch: (state: SplitRoleState) => {
      state.unassignedRequests = state.allRequestsToReassign.filter(
        (transferItemRequest: TransferItem) =>
          !state.targetKeys.includes(transferItemRequest.key.toString())
      );
    },

    /**
     * Rest the complete store. For example when the user clicks cancel then the store will be reset.
     */
    resetSplitStore: () => initialState,
  },
});

export const {
  // initializer
  setCurrentAssignedRequestsToSplit,
  setUnassignedRequests,
  setIsSaveDisabled,
  setTargetKeys,
  setLastStepStateInitialized,

  // reset functions
  resetSplitStore,
  resetSearch,
  removeAssignmentFromSplit,
} = splitRoleSlice.actions;

export default splitRoleSlice;
