import React, { ReactNode, useState } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";

import { ResetFilterButton } from "../reset_filter_button";
import "./filter_group.less";

interface FilterGroupProps {
  onFilterQuickSearch: (value: string) => void;
  onReset: () => void;
  disabled: boolean;
  children?: ReactNode;
}

const FilterGroup: React.FC<FilterGroupProps> = ({
  onFilterQuickSearch,
  onReset,
  disabled,
  children,
}) => {
  const [searchText, setSearchText] = useState<string>("");

  const onQuickFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSearchText(event.target.value);
    onFilterQuickSearch(event.target.value);
  };

  const onClickReset = (): void => {
    setSearchText("");
    onReset();
  };

  return (
    <div className="filter-group" data-testid="filter-group">
      <Input
        prefix={<SearchOutlined />}
        placeholder="Search"
        size="large"
        onChange={onQuickFilterChange}
        value={searchText}
        disabled={disabled}
        data-testid="filter-group-input"
        className="filter-group__input search-input"
      />

      {children}
      <ResetFilterButton onClick={onClickReset} disabled={disabled} />
    </div>
  );
};

export default FilterGroup;
