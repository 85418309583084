// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-required {
  display: flex;
  width: 25px;
}
.action-required-yes::before {
  background-color: #e7343f !important;
}
.action-required-no::before {
  background-color: #3ed8c3 !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_requests/custom_renderer/required_action_renderer/required_action_renderer.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;AACF;AACE;EACE,oCAAA;AACJ;AAEE;EACE,oCAAA;AAAJ","sourcesContent":[".action-required {\n  display: flex;\n  width: 25px;\n\n  &-yes::before {\n    background-color: #e7343f !important;\n  }\n\n  &-no::before {\n    background-color: #3ed8c3 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
