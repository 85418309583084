// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-section {
  display: flex;
  justify-content: space-between;
}
.button-section .duplicate-project-button {
  margin-left: 0;
}
.last-edit {
  margin-top: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/project_page/project_page.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;AAHA;EAKI,cAAA;AACJ;AAGA;EACE,gBAAA;AADF","sourcesContent":[".button-section {\n  display: flex;\n  justify-content: space-between;\n\n  .duplicate-project-button {\n    margin-left: 0;\n  }\n}\n\n.last-edit {\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
