import React from "react";

import { FilterDropdown } from "@src/components/filter_dropdown";
import { FilterGroup } from "@src/components/filter_group";
import { TreeNode } from "@src/types/antd_types";
import { GridApi } from "ag-grid-community";

interface RegionAssignmentFilterProps {
  gridApi: GridApi;
  disabled: boolean;
  regions: TreeNode[];
  countries?: TreeNode[];
}

const RegionAssignmentFilter: React.FC<RegionAssignmentFilterProps> = ({
  gridApi,
  disabled,
  regions,
  countries,
}) => {
  let clearFilterCountries: () => void;
  let clearFilterRegions: () => void;

  function onChangeQuickFilter(searchText: string): void {
    gridApi.setGridOption("quickFilterText", searchText);
  }
  function onClickResetFilter(): void {
    gridApi.setFilterModel(null);
    gridApi.setGridOption("quickFilterText", "");
    clearFilterCountries?.();
    clearFilterRegions?.();
  }

  function assignResetFunctionCountries(resetFilterFunction: () => void): void {
    clearFilterCountries = resetFilterFunction;
  }

  function assignResetFunctionRegions(resetFilterFunction: () => void): void {
    clearFilterRegions = resetFilterFunction;
  }

  const onFilterChange = (fieldId: string, values: string[]): void => {
    gridApi.setColumnFilterModel(fieldId, { values: values });
    gridApi.onFilterChanged();
  };

  return (
    <div className="essential-filter-container">
      <div className="filter-dropdowns">
        {countries?.length > 0 && (
          <FilterDropdown
            placeholderDropdown="Country Filter"
            placeholderSearch="Enter Country"
            treeData={countries}
            onFilterChange={(values: string[]) =>
              onFilterChange("countryName", values)
            }
            assignResetFunction={assignResetFunctionCountries}
            disabled={disabled}
          />
        )}
        <FilterDropdown
          placeholderDropdown="Region Filter"
          placeholderSearch="Enter Region"
          treeData={regions}
          onFilterChange={(values: string[]) =>
            onFilterChange("regionName", values)
          }
          assignResetFunction={assignResetFunctionRegions}
          disabled={disabled}
        />
      </div>
      <FilterGroup
        onFilterQuickSearch={onChangeQuickFilter}
        onReset={onClickResetFilter}
        data-testid="filterGroup"
        disabled={disabled}
      />
    </div>
  );
};

export default RegionAssignmentFilter;
