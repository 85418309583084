import { LockOutlined } from "@ant-design/icons";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { RoleSpecification } from "@src/types";
import {
  ColDef,
  GridOptions,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { Tooltip } from "antd";

import { getCommonGridOptions } from "./common_grid_options";
import { RoleTypes } from "./role_assignment_constants";

export type RoleSpecificationOption = RoleSpecification & {
  disabled?: boolean;
};

const columnDefs: ColDef[] = [
  {
    headerName: "Basic Role",
    field: "basicRole.basicRoleName",
    colId: "basicRole", // needed for getFilterInstance in role_assignment_filter
    cellRenderer: (params: ICellRendererParams) => (
      <>
        {params.data.onlyVisibleForAdmin && <LockOutlined />}
        {params.value}
      </>
    ),
    filter: "agSetColumnFilter",
    minWidth: 200,
  },
  {
    headerName: "Specification",
    field: "roleSpecification.roleSpecificationName",
    colId: "roleSpecification", // needed for getFilterInstance in role_assignment_filter
    filter: "agSetColumnFilter",
    minWidth: 150,
  },
  {
    headerName: "Role Description",
    field: "description",
    minWidth: 350,
    cellRenderer: (params: ICellRendererParams) => (
      <Tooltip
        placement="bottomLeft"
        title={params.value}
        overlayClassName="description__tooltip"
      >
        {params.value}
      </Tooltip>
    ),
    cellClass: "description",
  },
  {
    headerName: "Team Lead",
    field: "teamLead.fullName",
    colId: "teamLead", // needed for getFilterInstance in role_assignment_filter
    filter: "agSetColumnFilter",
    minWidth: 180,
  },
  {
    headerName: "Area",
    field: "basicRole.area.areaName",
    minWidth: 150,
  },
  {
    headerName: "N-1 Department",
    field: "basicRole.department",
    minWidth: 150,
    valueFormatter: (params: ValueFormatterParams) =>
      params.value ? `${params.value.orgName} (${params.value.orgUnit})` : "",
  },
  {
    headerName: "Only Visible for Admin",
    field: "onlyVisibleForAdmin",
    minWidth: 180,
    hide: true,
  },
  {
    headerName: "Team Lead Department",
    colId: "teamLeadDepartment",
    field: "teamLead.departmentCode",
    minWidth: 180,
    hide: true,
  },
];

/**
 * sets the grid options for the StandardRoleManagement.
 * combining basic role specific grid options with common ones
 *
 * @param updateRoleRtkFunction function to reach the update endpoint
 * @param deleteRoleRtkFunction function to reach the delete endpoint
 * @returns the grid options
 */
export function createGridOptions(
  updateRoleRtkFunction: MutationTrigger<any>,
  deleteRoleRtkFunction: MutationTrigger<any>,
  onSplit: (params: ICellRendererParams) => void
): GridOptions {
  const gridOptions = getCommonGridOptions(
    RoleTypes.STANDARD_ROLE,
    columnDefs,
    updateRoleRtkFunction,
    deleteRoleRtkFunction,
    onSplit
  );
  gridOptions.rowHeight = 100;
  return gridOptions;
}
