// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-drawer .ant-drawer-header {
  background-color: #1d4477;
  height: 64px;
}
.filter-drawer .ant-drawer-header .ant-drawer-title {
  text-align: right;
  padding-right: 10px;
}
.filter-drawer .ant-drawer-header * {
  color: #ffffff;
}
.filter-drawer .filter-group {
  padding-top: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/features/table_filtering/components/filters_drawer/filters_drawer.less"],"names":[],"mappings":"AAEA;EAEI,yBAAA;EACA,YAAA;AAFJ;AADA;EAMM,iBAAA;EACA,mBAAA;AAFN;AALA;EAWM,cAAA;AAHN;AARA;EAgBI,iBAAA;EACA,aAAA;EACA,2BAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;AALJ","sourcesContent":["@import \"@src/stylings/variables\";\n\n.filter-drawer {\n  .ant-drawer-header {\n    background-color: @brand;\n    height: 64px;\n\n    .ant-drawer-title {\n      text-align: right;\n      padding-right: 10px;\n    }\n\n    * {\n      color: @white;\n    }\n  }\n\n  .filter-group {\n    padding-top: 20px;\n    display: flex;\n    justify-content: flex-start;\n    gap: 15px;\n    margin-bottom: 10px;\n    flex-wrap: wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
