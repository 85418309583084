import React, { useState } from "react";

import { Col, Form, Input, Modal, Row } from "antd";
import _ from "lodash";

export function ShowMoreEmployeeDataModal({
  modalVisible,
  setModalVisible,
  employeeData,
  onUpdate,
}) {
  const [form] = Form.useForm();
  const [formErrors, setFormErrors] = useState(new Set());
  const [validationErrors, setValidationError] = useState(0);

  const validateEmail = (rule, value) => {
    const column = rule.field;

    let regex = new RegExp(
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|" +
        "(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
    );

    if (regex.test(value) && value.includes("rwe.com")) {
      resetFormErrors(column);
      return Promise.resolve();
    } else {
      return errorHandling(`Wrong email format.`, column);
    }
  };

  const errorHandling = (message, column) => {
    setFormErrors((formErrors) => new Set(formErrors.add(column)));
    setValidationError(formErrors.size);
    return Promise.reject(message);
  };

  const resetFormErrors = (column) => {
    if (formErrors.has(column)) {
      let updatedErrorNodes = new Set(formErrors);
      updatedErrorNodes.delete(column);
      setFormErrors(new Set(updatedErrorNodes));
      setValidationError(updatedErrorNodes.size);
    }
  };

  const updateEmployee = () => {
    const currentFormValues = form.getFieldsValue();
    const keys = _.keys(currentFormValues);

    for (const key of keys) {
      employeeData[key] = currentFormValues[key];
    }
    setModalVisible(false);
    onUpdate({ selectedEmployee: employeeData, setRequestsToRequested: false });
  };

  return (
    <Modal
      width="600px"
      open={modalVisible}
      data-testid="modal"
      centered
      maskClosable={false}
      closable={false}
      onCancel={() => setModalVisible(false)}
      bodyStyle={{ width: "100%", height: "320px" }}
      onOk={updateEmployee}
      okButtonProps={{
        disabled: formErrors.size > 0 || validationErrors > 0,
        "data-testid": "ok_btn",
      }}
      className="show-more-employee-data-modal"
      title={"Update " + employeeData.fullName}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        size="large"
        data-testid="employee_form"
        initialValues={employeeData}
      >
        <Row gutter={[48, 16]}>
          <Col span={12}>
            <Form.Item
              label="Contract type"
              name="contractType"
              validateTrigger="onBlur"
            >
              <Input
                data-testid="contract_type"
                placeholder="Type contract type"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Availability"
              name="totalAvailablePercentage"
              validateTrigger="onBlur"
            >
              <Input
                data-testid="total_available_percentage"
                placeholder="Type availability"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[48, 16]}>
          <Col span={12}>
            <Form.Item
              label="E-Mail"
              name="email"
              validateTrigger="onBlur"
              rules={[{ validator: validateEmail }]}
            >
              <Input data-testid="email" placeholder="Type email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Cost center (optional)"
              name="costCenter"
              validateTrigger="onBlur"
            >
              <Input data-testid="cost_center" placeholder="Type cost center" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[48, 16]}>
          <Col span={12}>
            <Form.Item
              label="RCD Number (optional)"
              name="rcdNumber"
              validateTrigger="onBlur"
            >
              <Input data-testid="rcd_number" placeholder="Type RCD Number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Located in"
              name="companyName"
              validateTrigger="onBlur"
            >
              <Input
                data-testid="company_name"
                placeholder="Type company name"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default ShowMoreEmployeeDataModal;
