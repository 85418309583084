import React from "react";

import { Headline } from "@src/components/headline";
import { RequestOverview } from "@src/features/staffing_requests/components/request_overview";
import { useGetStaffingRequestsQuery } from "@src/services/slices/teamLeadsApi";
import "./staffing_request_overview_page.less";

const StaffingRequestOverviewPage = () => {
  const { data: requests, isLoading } = useGetStaffingRequestsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <Headline title="Request overview" />
      <RequestOverview requests={requests} isLoading={isLoading} />
    </>
  );
};

export default StaffingRequestOverviewPage;
