import React, { useEffect, useRef, useState } from "react";

import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import RedoOutlined from "@ant-design/icons/lib/icons/RedoOutlined";
import { DeleteResourceModal } from "@src/components/modals/delete_resource_modal";
import { LoadingModal } from "@src/components/modals/loading_modal";
import "@src/features/resource_plan/components/sent_requests/sent_requests.less";
import { NoRowsOverlay } from "@src/components/overlays/no_rows_overlay";
import { FilterSentRequests } from "@src/features/resource_plan/components/filter_sent_requests/filter_sent_requests";
import { useSentRequestStateChangeHandler } from "@src/features/resource_plan/hooks/use_sent_requests_state_change_handler";
import { getCommonColumnDefs } from "@src/features/resource_plan/utils/common_column_defs";
import { updateFTEData } from "@src/features/resource_plan/utils/resource_planner_utils";
import {
  compareNodesByProjectRoleRequestId,
  frameworkComponents,
  getPayloadData,
  getStandardRoleUpdatedModalMessage,
  getGridOptions,
  updatableColumnProps,
} from "@src/features/resource_plan/utils/sent_requests_utils";
import ActiveFilterBar from "@src/features/table_filtering/components/active_filters_bar/active_filters_bar";
import FiltersBar from "@src/features/table_filtering/components/filters_bar/filters_bar";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import {
  setSentRequestsColumns,
  setSentRequestsQuickFilter,
} from "@src/services/resourcePlanSlice";
import { useCloneRequestMutation } from "@src/services/slices/projectRoleRequestsApi";
import { useEditRequestMutation } from "@src/services/slices/projectsSlice";
import { useLazyGetTeamLeadOfStandardRoleQuery } from "@src/services/slices/standardRolesApi";
import { useAppDispatch, useAppSelector } from "@src/setupStore";
import {
  EditRequestPayload,
  Project,
  ProjectRoleRequest,
  SentRequest,
  SentRequestRowNode,
  TableSettingsConfig,
  UpdateRequest,
} from "@src/types";
import { TreeNode } from "@src/types/antd_types";
import { handleParallelScrollEvent } from "@src/utils/handle_scroll_utils";
import { calculatePercentage } from "@src/utils/helper";
import { onCellValueChanged } from "@src/utils/update_monthly_allocation_utils";
import {
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  GridOptions,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { App, Button, Col, Row, message } from "antd";
import _ from "lodash";

interface SentRequestProps {
  id: string;
  sentRequests: SentRequest[];
  startDate: Date;
  endDate: Date;
  project: Project;
  callBack: (a: boolean, b: boolean) => void;
  selectedTable: boolean;
  gridApiRefCallback: (a: GridApi) => void;
}

export const SentRequests: React.FC<SentRequestProps> = ({
  id,
  sentRequests,
  startDate,
  endDate,
  project,
  callBack,
  selectedTable,
  gridApiRefCallback,
}) => {
  const gridApi = useRef<GridApi>(null);

  const { modal } = App.useApp();

  const [deleteBtnDisabled, setDeleteBtnDisabled] = useState<boolean>(true);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<SentRequest[]>([]);
  const [sentRequestBeforeEdit, setSentRequestBeforeEdit] = useState<
    ProjectRoleRequest[]
  >([]);
  const [disableSaveBtn, setDisableSaveBtn] = useState<boolean>(true);
  const [updatedNodes, setUpdatedNodes] = useState<SentRequestRowNode[]>(null);
  const [disableUndoBtn, setDisableUndoBtn] = useState<boolean>(true);
  const [gridLoaded, setGridLoaded] = useState<boolean>(false);
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
  const [context, setContext] = useState({});

  const [totalUpdatedSelectedRequests, setTotalUpdatedSelectedRequests] =
    useState(0);
  const [
    processedUpdatedSelectedRequests,
    setProcessedUpdatedSelectedRequests,
  ] = useState(0);

  const [genericRoleNameFilterList, setGenericRoleNameFilterList] = useState<
    TreeNode[]
  >([]);
  const [loadingModalMessage, setLoadingModalMessage] = useState({
    text: "Saving data ...",
    totalRows: selectedRows.length,
    currentRowStatus: selectedRows.length,
    percent: 0,
    type: "progress",
  });

  const columnDefs = getCommonColumnDefs(
    "sent",
    startDate,
    endDate,
    0
  ) as ColDef[];

  //state
  const dispatch = useAppDispatch();
  const {
    sentRequestsTableState: { quickFilter, columns },
  } = useAppSelector((state) => state.resourcePlanSlice);

  //rtk hooks
  const [fetchTeamLeadOfStandardRole] = useLazyGetTeamLeadOfStandardRoleQuery();

  const [cloneRequest] = useCloneRequestMutation();

  const [editDefinedRequest] = useEditRequestMutation();

  const { activeFilters, resetActiveFilters, setTableRendered } =
    useSentRequestStateChangeHandler({
      gridApi,
      columnDefs,
    });

  useEffect(() => {
    if (sentRequests) {
      setSentRequestBeforeEdit(_.cloneDeep(sentRequests));

      const filterOptions = mapToTreeData(sentRequests, "genericRoleName");
      setGenericRoleNameFilterList(filterOptions);
    }
  }, [sentRequests]);

  useEffect(() => {
    if (gridApi.current && selectedTable != null && selectedTable === false) {
      const selectedNodes = gridApi.current.getCellRanges();
      if (selectedNodes.length > 0) {
        gridApi.current.clearRangeSelection();
      }
    }
  }, [selectedTable]);

  const updateProgress = () => {
    const leftSelectedRow = processedUpdatedSelectedRequests;
    const percent = calculatePercentage(
      leftSelectedRow,
      totalUpdatedSelectedRequests
    );

    setLoadingModalMessage({
      ...loadingModalMessage,
      percent: percent,
      currentRowStatus: leftSelectedRow,
      totalRows: totalUpdatedSelectedRequests,
    });

    if (percent === 100) {
      setShowLoadingModal(false);
      message.success("Successfully updated request(s)");
      setProcessedUpdatedSelectedRequests(0);
    }
  };

  const refreshCells = (node) => {
    gridApi.current.refreshCells({ rowNodes: [node], force: true });
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
    gridApiRefCallback(gridApi.current);

    setGridLoaded(true);
    setTimeout(() => {
      handleParallelScrollEvent(
        ".open-requests .ag-body-horizontal-scroll-viewport",
        ".sent-requests .ag-body-horizontal-scroll-viewport"
      );

      handleParallelScrollEvent(
        ".sent-requests .ag-horizontal-left-spacer",
        ".sent-requests .ag-pinned-left-header"
      );
      handleParallelScrollEvent(
        ".sent-requests .ag-horizontal-left-spacer",
        ".sent-requests .ag-pinned-left-cols-container"
      );

      const scrollContainer = document.querySelector(
        ".sent-requests .ag-horizontal-left-spacer"
      );
      if (scrollContainer) {
        const node = document.createElement("div");
        node.className = "scrollContent";
        scrollContainer.appendChild(node);
      }
    }, 1000);
  };

  const deleteRequest = () => {
    gridApi.current.applyTransaction({ remove: selectedRows });

    const updatedSentRequests = sentRequests.filter(
      (request) =>
        !selectedRows.find((selectedRow) =>
          compareNodesByProjectRoleRequestId(selectedRow, request)
        )
    );

    const newGenericRoleNameFilterList = mapToTreeData(
      updatedSentRequests,
      "genericRoleName"
    );
    setGenericRoleNameFilterList(newGenericRoleNameFilterList);
  };

  const checkIfNonAllocationValuesChanged = (
    selectedRows: SentRequest[],
    updatedNodes: SentRequestRowNode[]
  ) => {
    let isNonAllocationValueChanged = false;

    if (!updatedNodes || updatedNodes.length === 0)
      return isNonAllocationValueChanged;

    let selectedUpdatedRequests = updatedNodes;

    if (selectedRows?.length !== 0) {
      selectedUpdatedRequests = updatedNodes.filter((updatedNode) =>
        selectedRows.some((selectedRow) =>
          compareNodesByProjectRoleRequestId(selectedRow, updatedNode.data)
        )
      );
    }

    for (const node of selectedUpdatedRequests) {
      if (node.isProjectIdentifierUpdated) {
        isNonAllocationValueChanged = true;
        break;
      }

      if (node.isSpecificRoleUpdated) {
        isNonAllocationValueChanged = true;
        break;
      }

      if (node.isScopeUpdated) {
        isNonAllocationValueChanged = true;
        break;
      }

      if (node.isGenericRoleUpdated) {
        isNonAllocationValueChanged = true;
        break;
      }

      if (node.isJointVentureUpdated) {
        isNonAllocationValueChanged = true;
        break;
      }
    }

    return isNonAllocationValueChanged;
  };

  const canAllocationBeSaved = (
    selectedRows: SentRequest[],
    updatedNodes: SentRequestRowNode[]
  ) => {
    let allocationCanBeSaved = true;

    //if there are no rows selected, the allocation can not be saved
    if (!selectedRows || selectedRows.length === 0) return false;

    //if there are no updated nodes, the allocation cannot be saved.
    if (!updatedNodes || updatedNodes.length === 0) return false;

    //if there are updated nodes and selected rows, then verify if the selected rows belong to the updated nodes
    const allSelectedNodesWithUpdates = updatedNodes.filter((updatedNote) =>
      selectedRows.some((selectedRows) =>
        compareNodesByProjectRoleRequestId(selectedRows, updatedNote.data)
      )
    );

    for (const node of allSelectedNodesWithUpdates) {
      const allocationDates = Object.keys(node.diff);
      const yearAllocations = node.data.yearlyAllocations
        ? Object.values(node.data.yearlyAllocations)
        : [];

      //no allocations for the current year found, so it should continue with the next year
      if (yearAllocations.length === 0) {
        allocationCanBeSaved = false;
        continue;
      }

      //check if there are any values greater than 0 available.
      const areValuesHigherThanZeroAvailable = yearAllocations.some(
        (yearAllocation) => {
          return Object.values(yearAllocation).some(
            (allocation) => allocation > 0
          );
        }
      );

      //when there are only 0 values available, the allocation cannot be saved.
      if (!areValuesHigherThanZeroAvailable) {
        allocationCanBeSaved = false;
        break;
      }

      //when there are not only 0 values available, check if the allocation is between 0 and 100 an if it is an integer
      for (const allocationDate of allocationDates) {
        const isNotInt = isNaN(node.diff[allocationDate].allocation);

        if (
          node.diff[allocationDate].allocation < 0 ||
          node.diff[allocationDate].allocation > 100 ||
          isNotInt
        ) {
          allocationCanBeSaved = false;
          break;
        }
      }
    }

    return allocationCanBeSaved;
  };

  const checkIfAllocationChanged = (
    selectedRows: SentRequest[],
    updatedNodes: SentRequestRowNode[]
  ) => {
    let allocationChanged = false;

    if (updatedNodes != null && updatedNodes?.length !== 0) {
      let filteredUpdatedNodes = updatedNodes;

      if (selectedRows?.length !== 0) {
        filteredUpdatedNodes = updatedNodes.filter(
          (updatedNode) =>
            !!selectedRows.find((selectedRow) =>
              compareNodesByProjectRoleRequestId(selectedRow, updatedNode.data)
            )
        );
      }

      for (const node of filteredUpdatedNodes) {
        const allocationDates = Object.keys(node.diff);

        if (allocationDates.length > 0) {
          allocationChanged = true;
          break;
        }
      }
    }

    return allocationChanged;
  };

  const undoSelectedRequests = () => {
    const model = gridApi.current.getFilterModel();
    const clonedSentRequestsBeforeEdit: ProjectRoleRequest[] = _.cloneDeep(
      sentRequestBeforeEdit
    );

    gridApi.current.deselectAll();

    const updatedSelectedNodes = updatedNodes.filter((updatedNode) => {
      return selectedRows.some((selectedRow) => {
        return compareNodesByProjectRoleRequestId(
          selectedRow,
          updatedNode.data
        );
      });
    });

    for (const updatedSelectedNode of updatedSelectedNodes) {
      const oldData = clonedSentRequestsBeforeEdit.find((sendRequests) =>
        compareNodesByProjectRoleRequestId(
          sendRequests,
          updatedSelectedNode.data
        )
      );

      updatedSelectedNode.setData(
        _.merge(oldData, updatedSelectedNode.initialData)
      );
      updatedSelectedNode.diff = {};
      resetIsUpdatedFlags(updatedSelectedNode);
      removeUpdatedNode(updatedNodes, updatedSelectedNode);
      refreshCells(updatedSelectedNode);
    }

    gridApi.current.setFilterModel(model);
    undoRequest();
  };

  const undoRequest = () => {
    setDeleteBtnDisabled(true);
    setDisableSaveBtn(true);
    setSelectedRows([]);
  };

  const onSendRequests = async () => {
    setShowLoadingModal(true);
    const updatedStandardRoleRequests: SentRequestRowNode[] = [];

    // get all modified rows that are selected
    const updatedSelectedRequests = updatedNodes.filter((updatedNodes) => {
      return selectedRows.find((selectedRow) =>
        compareNodesByProjectRoleRequestId(selectedRow, updatedNodes.data)
      );
    });

    // loop through rows that will be updated
    for (const [index, node] of updatedSelectedRequests.entries()) {
      if (node.isGenericRoleUpdated) {
        // if standard role is updated, add it to the array
        updatedStandardRoleRequests.push(node);
      } else {
        // send it directly
        await sendRequest(node, index, updatedSelectedRequests.length);
      }
    }

    // after all updates are sent the standard role updates will be handled
    setShowLoadingModal(false);
    handleStandardRoleUpdates(updatedStandardRoleRequests);
    gridApi.current.applyTransaction({ update: [updatedSelectedRequests] });
  };

  const sendRequest = async (
    node: SentRequestRowNode,
    index: number,
    totalUpdatedSelectedRequests: number
  ) => {
    if (!node.data.childId) {
      await createChildRequest(node);
    }

    const payload: UpdateRequest[] = createPayload(node);
    if (payload.length < 1) return;

    // api call
    const request: EditRequestPayload = {
      projectId: parseInt(id),
      editedRequests: payload,
    };
    await editDefinedRequest(request)
      .unwrap()
      .then(() => {
        finishEdit(node);
      })
      .catch(console.error);

    setTotalUpdatedSelectedRequests(totalUpdatedSelectedRequests);
    setProcessedUpdatedSelectedRequests(index + 1);
  };

  const handleStandardRoleUpdates = (
    updatedStandardRoleRequests: SentRequestRowNode[]
  ) => {
    // opens a modal for every request where a standard role was updated
    // the user can confirm to update the standard role or cancel to skip the request
    updatedStandardRoleRequests.forEach((node) => {
      // fetch team lead full name to show it in the modal
      fetchTeamLeadOfStandardRole(node.data.genericRoleId)
        .unwrap()
        .then((teamLead) => {
          modal.confirm({
            autoFocusButton: null,
            content: getStandardRoleUpdatedModalMessage(
              node.initialData.genericRoleName,
              node.data.genericRoleName,
              node.data.parentRequestId,
              teamLead.fullName
            ),
            async onOk() {
              await sendRequest(node, undefined, undefined);
            },
          });
        })
        .catch(console.error);
    });
  };

  const createChildRequest = async (node: SentRequestRowNode) => {
    // childId is needed, so we set it first to the parentId
    node.data.childId = node.data.projectRoleRequestId;

    //clone request only if joint venture is removed from request or not added
    if (!node.data.jointVenture && !node.isJointVentureUpdated) {
      await cloneRequest(node.data.projectRoleRequestId)
        .unwrap()
        .then((res) => {
          node.data.childId = res;
        })
        .catch(console.error);
    }
  };

  const createPayload = (node: SentRequestRowNode) => {
    let payload = [];

    // check for every column if it was updated
    updatableColumnProps.forEach((column) => {
      const isColumnUpdated = column[0];
      const columnName = column[1];
      const attributeName = column[2];
      if (node[isColumnUpdated]) {
        // if the column was updated add the data to the payload
        const payloadData = getPayloadData(node, columnName, attributeName);
        payload.push(payloadData);
      }
    });

    // add allocations updates to the payload
    const allocations = Object.values(node.diff);
    for (const allocation of allocations) {
      allocation.requestID = node.data.childId;
      allocation.originRequestId = node.data.parentRequestId;
    }
    payload = allocations.concat(payload);

    return payload;
  };

  const finishEdit = (node: SentRequestRowNode) => {
    undoRequest();
    updateProgress();
    gridApi.current.deselectAll();
    setDisableUndoBtn(true);

    removeUpdatedNode(updatedNodes, node);
    updateYearlyAllocations(node);
    resetIsUpdatedFlags(node);
    updateSentRequestBeforeEdit(node);

    node.diff = {};
    refreshCells(node);

    gridApi.current.deselectAll();
    setDisableUndoBtn(true);
  };

  const removeUpdatedNode = (updatedNodes, node) => {
    const updatedNodeIndex = updatedNodes.findIndex((updatedNode) =>
      compareNodesByProjectRoleRequestId(updatedNode.data, node.data)
    );
    updatedNodes.splice(updatedNodeIndex, 1);
  };

  const updateYearlyAllocations = (node) => {
    const yearMonthAllocations = Object.keys(node.diff);

    for (const yearMonthAllocation of yearMonthAllocations) {
      const year = yearMonthAllocation.substring(0, 4);
      const month = yearMonthAllocation.substring(
        5,
        yearMonthAllocation.length
      );

      const yearAllocation = node.initialData.yearlyAllocations;

      if (
        yearAllocation &&
        Object.keys(yearAllocation).length === 0 &&
        Object.getPrototypeOf(yearAllocation) === Object.prototype
      ) {
        node.initialData.yearlyAllocations = {};
        node.initialData.yearlyAllocations[year] = {};
      }

      if (!node.initialData.yearlyAllocations[year]) {
        node.initialData.yearlyAllocations[year] = {};
      }
      node.initialData.yearlyAllocations[year][month] =
        node.diff[yearMonthAllocation].allocation;
    }
  };

  const resetIsUpdatedFlags = (node: SentRequestRowNode) => {
    updatableColumnProps.forEach((column) => {
      const isColumnUpdated = column[0];
      const attributeName = column[2];
      if (isColumnUpdated === "isGenericRoleUpdated") {
        node.isGenericRoleUpdated = false;
        node.initialData.genericRoleId = node.data.genericRoleId;
        node.initialData.genericRoleName = node.data.genericRoleName;
      } else if (node[isColumnUpdated]) {
        node[isColumnUpdated] = false;
        node.initialData[attributeName] = node.data[attributeName];
      }
    });
  };

  const updateSentRequestBeforeEdit = (node) => {
    const requestArrIndex = sentRequestBeforeEdit.findIndex((req) =>
      compareNodesByProjectRoleRequestId(req, node.data)
    );
    sentRequestBeforeEdit[requestArrIndex].yearlyAllocations =
      node.data.yearlyAllocations;

    setSentRequestBeforeEdit(_.cloneDeep(sentRequestBeforeEdit));
  };

  const onRangeSelectionChanged = () => {
    const selectedNodes = gridApi.current.getCellRanges();

    if (selectedNodes.length > 0) {
      callBack(null, true);
    }
  };

  const getUpdatedNodes = () => {
    const updatedNodes = [];

    gridApi.current.forEachNode((node: SentRequestRowNode) => {
      if (
        (node.diff && Object.keys(node.diff).length !== 0) ||
        node.isProjectIdentifierUpdated ||
        node.isScopeUpdated ||
        node.isSpecificRoleUpdated ||
        node.isGenericRoleUpdated ||
        node.isJointVentureUpdated
      ) {
        updatedNodes.push(node);
      }
    });

    return updatedNodes;
  };

  const onCellValueChangedSentRequest = (params: CellValueChangedEvent) => {
    onCellValueChanged(params);

    const updatedNodes = getUpdatedNodes();
    setUpdatedNodes(updatedNodes);

    let disableSave = true;

    const isAllocationChanged = checkIfAllocationChanged(
      selectedRows,
      updatedNodes
    );

    const isNonAllocationValueChanged = checkIfNonAllocationValuesChanged(
      selectedRows,
      updatedNodes
    );

    const allocationCanBeSaved = canAllocationBeSaved(
      selectedRows,
      updatedNodes
    );

    if (updatedNodes?.length !== 0) {
      // even allocation was not changed it should be tested if allocation is possible to save because of outdated requests
      // without the check it is possible to save outdated requests when only non allocation values changed
      if (
        allocationCanBeSaved &&
        ((isNonAllocationValueChanged && !isAllocationChanged) ||
          isAllocationChanged)
      ) {
        disableSave = false;
      }
    } else {
      disableSave = false;
    }

    const filteredUpdatedNodes = getSelectedUpdatedNodes(
      selectedRows,
      updatedNodes
    );

    setDisableUndoBtn(filteredUpdatedNodes.length === 0);

    if (!disableSave && selectedRows.length !== 0) {
      setDisableSaveBtn(false);
    } else {
      setDisableSaveBtn(true);
    }
    refreshCells(params.node);
  };

  const getSelectedUpdatedNodes = (selectedRows, updatedNodes) => {
    let filteredUpdatedNodes = [];

    if (updatedNodes != null && updatedNodes?.length !== 0) {
      filteredUpdatedNodes = updatedNodes.filter((updatedNode) =>
        selectedRows.some((selectedRow) =>
          compareNodesByProjectRoleRequestId(selectedRow, updatedNode.data)
        )
      );
    }

    return filteredUpdatedNodes;
  };

  const onSelectionChanged = () => {
    const selectedRows: SentRequest[] = gridApi.current.getSelectedRows();
    setSelectedRows(selectedRows);

    const filteredUpdatedNodes = getSelectedUpdatedNodes(
      selectedRows,
      updatedNodes
    );

    const disableDeleteBtn = selectedRows.length <= 0;
    const disableUndoBtn = filteredUpdatedNodes.length === 0;
    let disableSaveBtn = true;

    if (filteredUpdatedNodes.length !== 0) {
      const isNonAllocationValueChanged = checkIfNonAllocationValuesChanged(
        selectedRows,
        updatedNodes
      );
      const isAllocationChanged = checkIfAllocationChanged(
        selectedRows,
        updatedNodes
      );

      const allocationCanBeSaved = canAllocationBeSaved(
        selectedRows,
        filteredUpdatedNodes
      );

      if (
        (isAllocationChanged || isNonAllocationValueChanged) &&
        allocationCanBeSaved
      ) {
        disableSaveBtn = false;
        // even allocation was not changed it should be tested if allocation is savable because of outdated requests
        // without the check it is possible to save outdated requests when only non allocation values changed
      }
    }
    setDeleteBtnDisabled(disableDeleteBtn);
    setDisableUndoBtn(disableUndoBtn);
    setDisableSaveBtn(disableSaveBtn);
  };

  function onQuickFilterChange(value: string) {
    dispatch(setSentRequestsQuickFilter(value));
  }

  function getExtraButtons() {
    return (
      <>
        <Button
          type="primary"
          size="large"
          danger
          onClick={() => setModalDeleteVisible(true)}
          disabled={deleteBtnDisabled}
          data-testid="deleteBtn"
          icon={<DeleteOutlined />}
        >
          Delete selected requests
        </Button>
        <Button
          data-testid="undoBtn"
          type="primary"
          htmlType="submit"
          size="large"
          onClick={() => undoSelectedRequests()}
          disabled={disableUndoBtn}
          icon={<RedoOutlined />}
        >
          Undo selected requests
        </Button>
        <Button
          data-testid="sentRequestBtn"
          type="primary"
          htmlType="submit"
          size="large"
          onClick={() => onSendRequests()}
          disabled={disableSaveBtn}
          icon={<EditOutlined />}
        >
          Send selected requests
        </Button>
      </>
    );
  }

  function getTableSettings(): TableSettingsConfig {
    const baseHiddenColumns = [
      "Contract availability",
      "Contract type",
      "Cost center",
      "RCD Team lead",
      "RCD Team member",
      "Team lead",
      "Team lead Org-Code",
      "Team member",
      "Team member Org-Code",
      "UI Team lead",
      "UI Team member",
      "Technology",
    ];
    return {
      columns: columnDefs,
      onChange: (columns: string[]) => {
        dispatch(setSentRequestsColumns(columns));
      },
      selectedColumns: columns,
      columnsToHide: project.jointVenturePartner
        ? [...baseHiddenColumns]
        : [...baseHiddenColumns, "JV"],
    };
  }

  function getFilters() {
    return (
      <FilterSentRequests
        disabled={showLoadingModal}
        roles={genericRoleNameFilterList}
      />
    );
  }

  return (
    <>
      {showLoadingModal && (
        <LoadingModal
          modalVisible={showLoadingModal}
          loadingMessage={loadingModalMessage}
        />
      )}

      <div style={{ marginTop: "20px" }}>
        <FiltersBar
          drawerTitle="Filter Already requested roles"
          extraButtons={getExtraButtons()}
          tableSettings={getTableSettings()}
          quickFilterDefaultValue={quickFilter}
          onQuickFilterChange={onQuickFilterChange}
          filters={getFilters()}
        />
        <ActiveFilterBar
          activeFilters={activeFilters}
          onRemove={(key: string, value: string) =>
            resetActiveFilters(key, value)
          }
          onRemoveAll={() => resetActiveFilters()}
        />
        <Row>
          <Col className="edit-status-msg">
            {updatedNodes?.length > 0 && (
              <span>
                Requests changed: <b>{updatedNodes.length}</b>
              </span>
            )}

            {selectedRows?.length > 0 && (
              <span>
                &nbsp; Requests selected: <b>{selectedRows.length}</b>
              </span>
            )}
          </Col>
        </Row>
      </div>

      <div
        id="sentRequests"
        className={`sent-requests ag-theme-alpine ${
          sentRequests && sentRequests.length === 0
            ? "zero-rows"
            : "scroll-default"
        }`}
      >
        <p hidden={true} id="api" data-testid="api">
          {gridLoaded ? "the grid API has been loaded" : null}
        </p>

        <div className="ag-theme-alpine header-white">
          <AgGridReact
            gridOptions={getGridOptions(columnDefs) as GridOptions}
            rowData={sentRequests}
            onGridReady={onGridReady}
            components={frameworkComponents}
            onSelectionChanged={onSelectionChanged}
            onCellValueChanged={onCellValueChangedSentRequest}
            onRangeSelectionChanged={onRangeSelectionChanged}
            pinnedTopRowData={sentRequests?.length === 0 ? null : [{}]}
            noRowsOverlayComponent={NoRowsOverlay}
            noRowsOverlayComponentParams={{
              text: "No requests found",
              customStyle: { marginTop: "97px" },
            }}
            onRowDataUpdated={(params) => updateFTEData(params.api, setContext)}
            onFilterChanged={(params) => updateFTEData(params.api, setContext)}
            onFirstDataRendered={setTableRendered}
            context={context}
          />
        </div>
      </div>

      {selectedRows.length > 0 && (
        <DeleteResourceModal
          setModalDeleteVisible={setModalDeleteVisible}
          modalDeleteVisible={modalDeleteVisible}
          requests={selectedRows}
          action={deleteRequest}
          projectId={project.id}
        />
      )}
    </>
  );
};

export default SentRequests;
