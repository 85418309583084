// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.start-screen-page .loginContainer {
  width: 350px;
  margin: auto;
  margin-top: 50px;
}
.start-screen-page .loginContainer > * {
  display: block;
}
.start-screen-page .loginContainer button {
  float: right;
  margin-top: 10px;
}
.start-screen-page .ant-spin-text {
  margin-top: 40px;
  margin-left: 20px;
  color: #1d4477;
}
`, "",{"version":3,"sources":["webpack://./src/pages/start_screen_page/start_screen_page.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,YAAA;EACA,gBAAA;AAAJ;AAJA;EAQI,cAAA;AADJ;AAPA;EAYI,YAAA;EACA,gBAAA;AAFJ;AAXA;EAiBI,gBAAA;EACA,iBAAA;EACA,cAAA;AAHJ","sourcesContent":[".start-screen-page {\n  .loginContainer {\n    width: 350px;\n    margin: auto;\n    margin-top: 50px;\n  }\n\n  .loginContainer > * {\n    display: block;\n  }\n\n  .loginContainer button {\n    float: right;\n    margin-top: 10px;\n  }\n\n  .ant-spin-text {\n    margin-top: 40px;\n    margin-left: 20px;\n    color: #1d4477;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
