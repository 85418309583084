import React from "react";

import { Button } from "antd";
import { navigate } from "@reach/router";

interface DetailsRendererParams {
  projectRoleRequestId: number;
}

const DetailsRenderer: React.FC<DetailsRendererParams> = ({
  projectRoleRequestId,
}) => {
  return (
    <Button
      type="link"
      onClick={() =>
        navigate(`staffingRequests/suggestTeamMember/${projectRoleRequestId}`)
      }
    >
      View Details
    </Button>
  );
};

export default DetailsRenderer;
