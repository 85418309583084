// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suggest-workload .fixed-cell {
  position: sticky !important;
  left: 10px;
}
.suggest-workload th {
  padding-left: 17px !important;
}
.suggest-workload .ant-table-small .suggest-workload .ant-table-thead > tr > th {
  background-color: #f7f9fc;
  font-family: "RWESansWeb-Bold", sans-serif !important;
  font-weight: 700 !important;
}
.suggest-workload .ant-table-container {
  border: 1px solid #babfc7 !important;
  border-radius: 3px;
  margin-top: 80px !important;
}
.suggest-workload .ant-table-body {
  overflow-y: hidden !important;
}
.suggest-workload .ant-table-cell {
  text-align: center;
}
.suggest-workload .ant-table-thead .ant-table-cell {
  text-align: left !important;
}
.suggest-workload .ant-table-row-level-0:nth-child(2n) {
  background-color: #f7f9fc;
  font-family: "RWESansWeb-Bold", sans-serif !important;
  font-weight: 700 !important;
}
.suggest-workload .ant-table-row-level-0:nth-child(2n) .ant-table-cell {
  text-align: left !important;
}
.suggest-workload textarea {
  width: 100%;
  resize: none;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_requests/components/suggest_workload/suggest_workload.less"],"names":[],"mappings":"AAAA;EAEI,2BAAA;EACA,UAAA;AAAJ;AAHA;EAOI,6BAAA;AADJ;AAKI;EACE,yBAAA;EACA,qDAAA;EACA,2BAAA;AAHN;AAMI;EACE,oCAAA;EACA,kBAAA;EACA,2BAAA;AAJN;AAOI;EACE,6BAAA;AALN;AAQI;EACE,kBAAA;AANN;AASI;EAEI,2BAAA;AARR;AAYI;EACE,yBAAA;EACA,qDAAA;EACA,2BAAA;AAVN;AAOI;EAMI,2BAAA;AAVR;AAjCA;EAiDI,WAAA;EACA,YAAA;AAbJ","sourcesContent":[".suggest-workload {\n  .fixed-cell {\n    position: sticky !important;\n    left: 10px;\n  }\n\n  th {\n    padding-left: 17px !important;\n  }\n\n  .ant-table {\n    &-small &-thead > tr > th {\n      background-color: #f7f9fc;\n      font-family: \"RWESansWeb-Bold\", sans-serif !important;\n      font-weight: 700 !important;\n    }\n\n    &-container {\n      border: 1px solid #babfc7 !important;\n      border-radius: 3px;\n      margin-top: 80px !important;\n    }\n\n    &-body {\n      overflow-y: hidden !important;\n    }\n\n    &-cell {\n      text-align: center;\n    }\n\n    &-thead {\n      .ant-table-cell {\n        text-align: left !important;\n      }\n    }\n\n    &-row-level-0:nth-child(2n) {\n      background-color: #f7f9fc;\n      font-family: \"RWESansWeb-Bold\", sans-serif !important;\n      font-weight: 700 !important;\n\n      .ant-table-cell {\n        text-align: left !important;\n      }\n    }\n  }\n\n  textarea {\n    width: 100%;\n    resize: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
