// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.requested-role-col {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.requested-role-col-icon-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.requested-role-col-icon {
  height: 14px;
  margin: 0;
}
.requested-role-col-icon.empty {
  width: 14px;
  visibility: hidden;
}
.requested-role-col-button {
  white-space: unset;
  text-align: left;
  padding: 0;
  height: 100%;
}
.requested-role-col-button-text {
  font-size: 14px;
}
.requested-role-col-subtext {
  font-size: 10px;
  padding-left: 25px;
  color: #3b3d42;
  position: relative;
  top: -4px;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_requests/custom_renderer/requested_role_renderer/requested_role_renderer.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAGE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AADJ;AAIE;EACE,YAAA;EACA,SAAA;AAFJ;AAII;EACE,WAAA;EACA,kBAAA;AAFN;AAME;EACE,kBAAA;EACA,gBAAA;EACA,UAAA;EACA,YAAA;AAJJ;AAMI;EACE,eAAA;AAJN;AAQE;EACE,eAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;AANJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.requested-role-col {\n  display: flex;\n  gap: 10px;\n  justify-content: space-between;\n  align-items: center;\n\n  &-icon-wrapper {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n  }\n\n  &-icon {\n    height: 14px;\n    margin: 0;\n\n    &.empty {\n      width: 14px;\n      visibility: hidden;\n    }\n  }\n\n  &-button {\n    white-space: unset;\n    text-align: left;\n    padding: 0;\n    height: 100%;\n\n    &-text {\n      font-size: 14px;\n    }\n  }\n\n  &-subtext {\n    font-size: 10px;\n    padding-left: 25px;\n    color: @font-00;\n    position: relative;\n    top: -4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
