import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  getFilteredTreeData,
  getPropertyValuesOfData,
} from "@src/features/table_filtering/utils/filter_utils";
import { TreeNode } from "@src/types/antd_types";
import { toEqualStringLists } from "@src/utils/helper";
import { Button, Checkbox, Input, InputRef, Select, Tree } from "antd";
import _ from "lodash";
import "./filter_dropdown.less";

interface FilterDropdownProps {
  placeholderDropdown: string;
  placeholderSearch: string;
  treeData: TreeNode[];
  onFilterChange: (values: string[]) => void;
  assignResetFunction: (resetFunction: () => void) => void;
  disabled: boolean;
  className?: string;
  defaultKeys?: string[];
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  placeholderDropdown,
  placeholderSearch,
  treeData,
  onFilterChange,
  assignResetFunction,
  disabled,
  className = "",
  defaultKeys = [],
}) => {
  const inputRef = useRef<InputRef>();

  const [searchText, setSearchText] = useState<string>("");
  const [isFilteredWithSearch, setIsFilteredWithSearch] =
    useState<boolean>(false);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const filteredTreeData = useMemo<TreeNode[]>(() => {
    return getFilteredTreeData(treeData, searchText);
  }, [treeData, searchText]);

  // mapping to only the keys (necessary for attribute checkedKeys in Tree)
  const filteredKeys = useMemo<string[]>(() => {
    return getPropertyValuesOfData(filteredTreeData, "key");
  }, [filteredTreeData]);

  const [checkedKeys, setCheckedKeys] = useState<string[]>(
    defaultKeys.length > 0 ? defaultKeys : filteredKeys
  );

  /**
   * resets all filters
   */
  const resetAll = (): void => {
    setSearchText("");
    setIsFilteredWithSearch(false);
    if (inputRef.current) {
      inputRef.current.input.value = "";
    }
    setCheckedKeys(
      defaultKeys.length > 0
        ? defaultKeys
        : getPropertyValuesOfData(treeData, "key")
    );
    setIsAllChecked(
      defaultKeys.length < 1 || treeData.length === defaultKeys.length
    );
    invisibleCheckedKeys.current = [];
  };

  // this will give the resetAll function to the parent
  // the reset button in parent can call resetAll function to reset states of this component
  assignResetFunction?.(resetAll);

  // the checked but not filtered options must be stored somewhere, in case search filter is used without saving
  const invisibleCheckedKeys = useRef<string[]>([]);

  useEffect(() => {
    // some data may be loaded later
    setCheckedKeys(defaultKeys.length > 0 ? defaultKeys : filteredKeys);
    setIsAllChecked(
      defaultKeys.length < 1 || treeData.length === defaultKeys.length
    );
  }, [treeData]);

  useEffect(() => {
    // calculation if the "check all" button needs to be checked
    if (isFilteredWithSearch) {
      const filteredCheckedKeys: string[] = checkedKeys.filter((key: string) =>
        filteredKeys.includes(key)
      );
      invisibleCheckedKeys.current = checkedKeys.filter(
        (key: string) => !filteredKeys.includes(key)
      );
      setIsAllChecked(toEqualStringLists(filteredCheckedKeys, filteredKeys));
    } else {
      invisibleCheckedKeys.current = [];
      setIsAllChecked(toEqualStringLists(checkedKeys, filteredKeys));
    }
  }, [searchText]);

  /**
   * onChange function of search field
   * @param event of the input field
   */
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
    setIsFilteredWithSearch(!!event.target.value);
  };

  /**
   * onClick function of the save button
   * executes the onFilter function
   */
  const onClickSave = (): void => {
    const filteredCheckedKeys: string[] = checkedKeys.filter((key: string) =>
      filteredKeys.includes(key)
    );
    setCheckedKeys(filteredCheckedKeys);
    onFilterChange(filteredCheckedKeys);
    invisibleCheckedKeys.current = [];
  };

  /**
   * onChange function of the check all checkbox
   * checks or unchecks all visible (filtered) checkboxes
   * @param checked boolean if checked or not
   */
  const onChangeCheckAll = (checked: boolean) => {
    setIsAllChecked(checked);
    if (checked) {
      setCheckedKeys(filteredKeys);
    } else {
      setCheckedKeys([]);
    }
  };

  /**
   * onCheck function of the tree
   * @param values all checked values on the tree
   */
  const onCheck = (values: string[]) => {
    setCheckedKeys(values);
    setIsAllChecked(toEqualStringLists(values, filteredKeys));
  };

  const getSelectionRenderer = (): React.ReactElement => (
    <div
      className="filter-dropdown-renderer"
      data-testid="filter-dropdown-renderer"
    >
      <Input
        placeholder={placeholderSearch}
        value={searchText}
        onChange={onChangeInput}
        ref={inputRef}
      />

      <div className="checkbox-content">
        <Checkbox
          onChange={(e) => onChangeCheckAll(e.target.checked)}
          checked={isAllChecked}
          data-testid="checkbox-check-all"
        >
          Check all
        </Checkbox>

        <Tree
          checkable
          virtual={false}
          motion={false}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          treeData={_.cloneDeep(filteredTreeData)}
          selectable={false}
          data-testid="filter-tree"
        />
      </div>

      <div className="button-content">
        <Button type="primary" size="small" onClick={onClickSave}>
          Save
        </Button>
      </div>
    </div>
  );

  return (
    <Select
      className={`filter-select ${className}`}
      placeholder={placeholderDropdown}
      disabled={disabled}
      data-testid="filter-select"
      dropdownRender={() => getSelectionRenderer()}
    />
  );
};

export default FilterDropdown;
