import {
  paginationGridOptions,
  preventPinnedColumnJumpingGridOption,
} from "@src/utils/aggrid_utils";

import { TooltipRenderer } from "../../../custom_renderer/tooltip_renderer";

export const girdOptionInformations = {
  ...paginationGridOptions,
  ...preventPinnedColumnJumpingGridOption,
  paginationPageSize: 3,
  suppressContextMenu: true,
  rowSelection: "multiple",
  rowHeight: 60,
  suppressRowClickSelection: true,
  defaultColDef: {
    suppressHeaderMenuButton: true,
  },
};

export const columnDefs = (currentUpdatedUser) => [
  {
    field: "assignedTeamMember",
    headerName: "Team member",
    width: 275,
    cellRenderer: (props) => {
      return TooltipRenderer(props.data.assignedTeamMember);
    },
  },
  {
    field: "genericRoleName",
    headerName: "Standard role",
    width: 275,
    cellRenderer: (props) => {
      return TooltipRenderer(props.data.genericRoleName);
    },
  },
  {
    field: "assignedTeamMember",
    headerName: "Old team lead",
    width: 274,
    cellRenderer: (props) => {
      return TooltipRenderer(props.data.teamLeadEmployeeDTO.fullName);
    },
  },
  {
    field: "newTeamLead",
    headerName: "New team lead",
    width: 274,
    cellRenderer: () => {
      return TooltipRenderer(currentUpdatedUser.fullName);
    },
  },
  {
    width: 50,
    colKey: "checkbox",
    checkboxSelection: true,
    cellClass: "ag-grid-cell no-border",
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    pinned: "right",
  },
];
