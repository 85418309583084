import { message } from "antd";
import { HTTP_METHODS } from "@src/constants";
import { baseApiSlice } from "./baseApiSlice";

export const deputiesApi = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    /* GET endpoints */
    ...getEndpoints(builder),

    /* POST endpoints */
    ...postEndpoints(builder),
  }),
});

function getEndpoints(builder) {
  return {
    /**
     * Get the standard roles of a deputy he is allowed to assign to team members
     */
    getDeputyStandardRoles: builder.query({
      query: (deputyId) => `deputies/${deputyId}/standard-roles`,
      transformErrorResponse: async () => {
        message.error("Unable to get standard roles", 5);
      },
      providesTags: ["DeputyStandardRoles"],
    }),

    /**
     * Get deputies
     */
    getDeputies: builder.query({
      query: () => "deputies",
      transformErrorResponse: async () => {
        message.error("Unable to get deputies", 5);
      },
      providesTags: ["Deputies"],
    }),
  };
}

function postEndpoints(builder) {
  const baseMethod = HTTP_METHODS.POST;

  return {
    /**
     * Create a new deputy role assignment
     */
    addDeputy: builder.mutation({
      query: (deputy) => ({
        url: "deputies",
        method: baseMethod,
        body: deputy,
      }),
      transformErrorResponse: async () => {
        message.error("Error creating new deputy assignment", 5);
      },
      invalidatesTags: ["Deputies", "AdminStandardRoles"],
    }),
  };
}

export const {
  /** Get Endpoints */
  useGetDeputyStandardRolesQuery,
  useLazyGetDeputyStandardRolesQuery,
  useLazyGetDeputiesQuery,

  /** Post Endpoints */
  useAddDeputyMutation,
} = deputiesApi;
