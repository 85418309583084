import React from "react";

import { COLORS } from "@src/constants";
import { Badge, Tooltip } from "antd";
import "./outdated_badge.less";

interface OutdatedBadgeProps {
  daysUntilDeletion: number;
}

const OutdatedBadge: React.FC<OutdatedBadgeProps> = ({ daysUntilDeletion }) => {
  return (
    <Tooltip
      placement="topLeft"
      overlayClassName="outdated-badge__tooltip"
      title={
        <>
          This request is outdated and will be{" "}
          <span className="outdated-badge__tooltip--highlighted">
            deleted in {daysUntilDeletion} days
          </span>
        </>
      }
    >
      <div className="outdated-badge">
        <Badge
          count={daysUntilDeletion}
          color={COLORS.ORANGE_50}
          style={{ width: "28px" }}
          size="small"
        />
        Outdated
      </div>
    </Tooltip>
  );
};

export default OutdatedBadge;
