/**
 * checks the provided data to match and basic role
 * @param {*} basicRoles all basicRoles
 * @param {*} providedRoleName provided standard role name
 * @returns true if it finds any match
 */
export const getInitialBasicRoleName = (basicRoles, providedRoleName) => {
  //first check for exact matching and then for partial matching
  let providedRole =
    basicRoles.find((role) => providedRoleName === role.basicRoleName) ||
    basicRoles.find((role) => providedRoleName?.includes(role.basicRoleName));
  return providedRole?.basicRoleName;
};

/**
 * checks if the basic role has any specifications
 * @param {*} basicRoles all basicRoles including their specifications
 * @param {*} basicRoleName basicRoleName that will be checked
 * @returns all specification names for the given basic role name
 */
export const getOptionsForSpecificationDropdown = (
  basicRoles,
  basicRoleName
) => {
  const selectedBasicRole = basicRoles.find(
    (role) => role.basicRoleName === basicRoleName
  );
  const roleSpecificationNames =
    selectedBasicRole?.roleAssignments?.length > 0
      ? selectedBasicRole.roleAssignments
          .filter(
            (roleSpecification) =>
              roleSpecification.roleSpecificationName !== null
          )
          .map((roleSpecification) => roleSpecification.roleSpecificationName)
      : [];

  return roleSpecificationNames;
};
