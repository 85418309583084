import React, { useCallback, useEffect, useRef, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";

import { NoRowsOverlay } from "@src/components/overlays/no_rows_overlay";
import { TooltipRenderer } from "@src/custom_renderer";
import "@src/features/resource_plan/components/request_replies/request_replies.less";
import { FilterRequestReplies } from "@src/features/resource_plan/components/filter_request_replies/filter_request_replies";
import {
  CommentRenderer,
  FullWidthCellRenderer,
  GenericRoleRenderer,
  RequestRenderer,
  RequestRepliesStatusRenderer,
  SimpleEditor,
} from "@src/features/resource_plan/custom_renderer";
import { useRequestRepliesStateChangeHandler } from "@src/features/resource_plan/hooks/use_request_replies_state_change_handler";
import { getCommonColumnDefs } from "@src/features/resource_plan/utils/common_column_defs";
import { requestRepliesOptions } from "@src/features/resource_plan/utils/requests_replies_utils";
import { updateFTEData } from "@src/features/resource_plan/utils/resource_planner_utils";
import ActiveFilterBar from "@src/features/table_filtering/components/active_filters_bar/active_filters_bar";
import FiltersBar from "@src/features/table_filtering/components/filters_bar/filters_bar";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import { setFilterResult } from "@src/services/requestOverviewSlice";
import {
  setRequestRepliesColumns,
  setRequestRepliesQuickFilter,
} from "@src/services/resourcePlanSlice";
import { useAppDispatch, useAppSelector } from "@src/setupStore";
import { handleParallelScrollEvent } from "@src/utils/handle_scroll_utils";
import { Card } from "antd";

const RequestReplies = ({ startDate, endDate, requestReplies, project }) => {
  const gridApi = useRef(null);

  const [genericRoleNameFilterList, setGenericRoleNameFilterList] = useState(
    []
  );
  const [context, setContext] = useState({});

  const columnDefs = getCommonColumnDefs("replies", startDate, endDate);

  const dispatch = useAppDispatch();
  const {
    requestRepliesTableState: { quickFilter },
  } = useAppSelector((state) => state.resourcePlanSlice);

  const filterCallBack = useCallback(() => {
    if (gridApi.current) {
      const rows = [];
      gridApi.current.forEachNodeAfterFilter((node) => {
        if (node.data) {
          rows.push(node.data);
        }
      });
      dispatch(setFilterResult([...rows]));
    }
  }, [dispatch]);

  const { activeFilters, resetActiveFilters, setTableRendered } =
    useRequestRepliesStateChangeHandler({
      gridApi,
      columnDefs,
      filterCallBack,
    });

  useEffect(() => {
    if (requestReplies) {
      let filterOptions = mapToTreeData(requestReplies, "genericRoleName");
      setGenericRoleNameFilterList(filterOptions);
    }
  }, [requestReplies]);

  const frameworkComponents = {
    tooltipRenderer: (props) =>
      TooltipRenderer(
        props.data.isJointVenture
          ? props.data?.projectDTO?.jointVenturePartner
          : props.data?.assignedTeamMember
      ),
    genericRoleRenderer: GenericRoleRenderer,
    simpleEditorRenderer: SimpleEditor,
    statusRenderer: RequestRepliesStatusRenderer,
    commentRenderer: CommentRenderer,
    requestIdRenderer: RequestRenderer,
    fullWidthCellRenderer: (params) =>
      FullWidthCellRenderer(params, gridApi.current),
  };

  const onGridReady = (params) => {
    gridApi.current = params.api;

    handleParallelScrollEvent(
      ".requests-replies .ag-horizontal-left-spacer",
      ".requests-replies .ag-pinned-left-header"
    );
    handleParallelScrollEvent(
      ".requests-replies .ag-horizontal-left-spacer",
      ".requests-replies .ag-pinned-left-cols-container"
    );

    handleParallelScrollEvent(
      ".requests-replies .ag-pinned-left-cols-container",
      ".requests-replies .ag-pinned-left-header"
    );

    const scrollContainer = document.querySelector(
      ".requests-replies .ag-horizontal-left-spacer"
    );
    if (scrollContainer) {
      const node = document.createElement("div");
      node.className = "scrollContent2";
      scrollContainer.appendChild(node);
    }
  };

  requestReplies.sort((a, b) => b.isOutdated - a.isOutdated);

  function getTableSettings() {
    return {
      columns: columnDefs,
      onChange: (columns) => {
        dispatch(setRequestRepliesColumns(columns));
      },
      columnsToHide: project.jointVenturePartner ? [] : ["JV"],
    };
  }

  function onQuickFilterChange(value) {
    dispatch(setRequestRepliesQuickFilter(value));
  }

  function getFilters() {
    return (
      <FilterRequestReplies
        disabled={false}
        roles={genericRoleNameFilterList}
      />
    );
  }

  return (
    <Card className="pageSection">
      <div
        data-testid="requests-replies"
        className={`requests-replies ag-theme-alpine ${
          requestReplies && requestReplies.length === 0 ? "" : "scroll-default"
        }`}
      >
        <FiltersBar
          drawerTitle="Filter request replies"
          tableSettings={getTableSettings()}
          quickFilterDefaultValue={quickFilter}
          onQuickFilterChange={onQuickFilterChange}
          filters={getFilters()}
        />
        <ActiveFilterBar
          activeFilters={activeFilters}
          onRemove={(key, value) => resetActiveFilters(key, value)}
          onRemoveAll={() => resetActiveFilters()}
        />
        <div style={{ width: "100%", height: "100%" }}>
          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
            }}
            className="ag-theme-alpine header-white"
          >
            <AgGridReact
              masterDetail={true}
              detailCellRenderer="fullWidthCellRenderer"
              components={frameworkComponents}
              onGridReady={onGridReady}
              rowData={requestReplies}
              columnDefs={columnDefs}
              gridOptions={requestRepliesOptions}
              pinnedTopRowData={requestReplies?.length === 0 ? null : [{}]}
              noRowsOverlayComponent={NoRowsOverlay}
              noRowsOverlayComponentParams={{
                text: "No requests found",
                customStyle: { marginTop: "97px" },
              }}
              onRowDataUpdated={(params) =>
                updateFTEData(params.api, setContext)
              }
              onFilterChanged={(params) =>
                updateFTEData(params.api, setContext)
              }
              context={context}
              onFirstDataRendered={setTableRendered}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default RequestReplies;
