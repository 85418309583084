// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.split-section {
  display: flex;
  margin-top: 20px;
}
.split-section__number-input {
  margin: 0 5px;
  height: 33px;
}
.split-section__number-section {
  display: flex;
  justify-content: center;
  align-items: end;
  margin-right: 20px;
}
.split-section__form-item {
  margin-bottom: 0 !important;
}
.split-section__form-item .ant-form-item-label {
  margin-left: 5px !important;
}
.split-section__form-item .ant-row {
  margin-bottom: 0;
}
.split-section__specification-selection {
  padding-left: 30px;
  width: 100%;
  border-left: 1px solid #d2dae4;
  min-height: 275px;
  max-height: 275px;
  overflow-y: auto;
  overflow-x: hidden;
}
.split-section .ant-input-number-disabled .ant-input-number-input {
  cursor: default;
}
.split-section .ant-input-number {
  cursor: default;
}
.split-section .ant-input-number-input-wrap {
  color: #3b3d42;
}
`, "",{"version":3,"sources":["webpack://./src/features/role_split/components/split_selection/split_selection.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,gBAAA;AADF;AAGE;EACE,aAAA;EACA,YAAA;AADJ;AAIE;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,kBAAA;AAFJ;AAKE;EACE,2BAAA;AAHJ;AAEE;EAGI,2BAAA;AAFN;AADE;EAOI,gBAAA;AAHN;AAOE;EACE,kBAAA;EACA,WAAA;EACA,8BAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AALJ;AA7BA;EAsCI,eAAA;AANJ;AAhCA;EA0CI,eAAA;AAPJ;AASI;EACE,cAAA;AAPN","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.split-section {\n  display: flex;\n  margin-top: 20px;\n\n  &__number-input {\n    margin: 0 5px;\n    height: 33px;\n  }\n\n  &__number-section {\n    display: flex;\n    justify-content: center;\n    align-items: end;\n    margin-right: 20px;\n  }\n\n  &__form-item {\n    margin-bottom: 0 !important;\n    .ant-form-item-label {\n      margin-left: 5px !important;\n    }\n\n    .ant-row {\n      margin-bottom: 0;\n    }\n  }\n\n  &__specification-selection {\n    padding-left: 30px;\n    width: 100%;\n    border-left: 1px solid @brand-20;\n    min-height: 275px;\n    max-height: 275px;\n    overflow-y: auto;\n    overflow-x: hidden;\n  }\n\n  .ant-input-number-disabled .ant-input-number-input {\n    cursor: default;\n  }\n\n  .ant-input-number {\n    cursor: default;\n\n    &-input-wrap {\n      color: @innogy-text-color;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
