import React from "react";

import { CheckOutlined } from "@ant-design/icons";
import { Tree, TreeDataNode } from "antd";
import "./requests_to_select_tree.less";

/**
 * actually I wanted this to be the split_role_transfer_left_side.
 * For the left side this might be it (maybe you can add the mapping of the split_role_node here)
 * but for the right side, I need further investigation and therefore I wanted to name and implement it equivalent to the right side
 * this might change when outsourcing some more code from the split_request_assignment component
 */
interface RequestsToSelectTreeProps {
  treeRequestData: TreeDataNode[];
  onItemSelect: (key: React.Key, check: boolean) => void;
  checkedKeys: React.Key[];
  isUserSearching?: boolean;
}

const RequestsToSelectTree: React.FC<RequestsToSelectTreeProps> = ({
  treeRequestData,
  onItemSelect,
  checkedKeys,
  isUserSearching,
}) => {
  const isChecked = (selectedKeys: React.Key[], eventKey: React.Key) =>
    selectedKeys.includes(eventKey);

  return (
    <div className="split-role-transfer-tree-left">
      {treeRequestData.length > 0 ? (
        <Tree
          blockNode
          checkable
          treeData={treeRequestData}
          checkedKeys={checkedKeys}
          onCheck={(_, { node: { key } }) => {
            onItemSelect(key, !isChecked(checkedKeys, key));
          }}
          onSelect={(_, { node: { key } }) => {
            onItemSelect(key, !isChecked(checkedKeys, key));
          }}
        />
      ) : (
        <div className="split-role-transfer-tree-left__no-requests">
          {isUserSearching ? (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              No requests found
            </span>
          ) : (
            <span style={{ display: "flex" }}>
              <CheckOutlined />
              All request are assigned to new splits. You can save the new
              splits now.
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default RequestsToSelectTree;
