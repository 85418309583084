import React, { useEffect, useState } from "react";

import WarningFilled from "@ant-design/icons/lib/icons/WarningFilled";
import "./delete_resource_modal.less";
import { Button, Modal, Radio } from "antd";

import {
  useDeleteRequestCascadingMutation,
  useLazyGetRequestsQuery,
} from "../../../services/slices/projectsSlice";
import { calculatePercentage } from "../../../utils/helper";
import { LoadingModal } from "../loading_modal";

function DeleteResourceModal({
  setModalDeleteVisible,
  modalDeleteVisible,
  requests,
  action,
  projectId,
}) {
  const [modalDeleteMessage, setModalDeleteMessage] = useState("");
  const [leftSelectedRow, setLeftSelectedRow] = useState(requests.length);
  const [acceptCallHR, setAcceptCallHR] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingModalMessage, setLoadingModalMessage] = useState({
    text: "Saving data ...",
    totalRows: requests.length,
    currentRowStatus: requests.length,
    percent: 0,
    type: "progress",
  });

  const [
    deleteRequestsCascading,
    { isSuccess: deleteRequestsCascadingSuccess },
  ] = useDeleteRequestCascadingMutation();
  const [getRequests] = useLazyGetRequestsQuery();

  useEffect(() => {
    if (requests?.find((r) => r.status.toLowerCase() === "in recruitment")) {
      setAcceptCallHR(false);
      setModalDeleteMessage(
        "Attention! There is at least on request in recruitment you are about to delete. " +
          "This needs internal communication with the responsible Team lead. Are you sure you want to delete this request(s)?"
      );
    } else {
      setAcceptCallHR(true);
      setModalDeleteMessage(
        "You are about to delete this request(s). Are you sure?"
      );
    }
  }, [modalDeleteVisible]);

  useEffect(() => {
    if (deleteRequestsCascadingSuccess) {
      const percent = calculatePercentage(leftSelectedRow, requests.length);
      setLoadingModalMessage({
        ...loadingModalMessage,
        percent: percent,
        currentRowStatus: leftSelectedRow,
      });
    }
  }, [deleteRequestsCascadingSuccess, leftSelectedRow]);

  function hideDeleteModal() {
    setModalDeleteVisible(false);
    setAcceptCallHR(false);
  }

  const deleteRequest = async () => {
    setModalDeleteVisible(false);
    setShowLoadingModal(true);

    for (const [index, request] of requests.entries()) {
      await deleteRequestsCascading(request.projectRoleRequestId);
      setLeftSelectedRow(index + 1);
    }

    action();
    getRequests(projectId);
  };

  function getRecruitmentCheckBtn() {
    return (
      <>
        <span>
          <Button onClick={hideDeleteModal} data-testid="modalDeleteBtn">
            Cancel
          </Button>
        </span>
        <Radio onClick={() => setAcceptCallHR(true)}>
          Yes. I am in contact.
        </Radio>
      </>
    );
  }

  function getNormalConfirmationBtn() {
    return (
      <>
        <Button onClick={hideDeleteModal}>Cancel</Button>
        <Button
          danger
          type="primary"
          data-testid="modalDeleteBtn"
          onClick={() => {
            deleteRequest();
          }}
        >
          Delete
        </Button>
      </>
    );
  }

  return (
    <>
      {showLoadingModal && (
        <LoadingModal
          modalVisible={showLoadingModal}
          loadingMessage={loadingModalMessage}
        />
      )}
      <Modal
        width="30%"
        open={modalDeleteVisible}
        title={
          <div className="warning-title">
            <p>
              <WarningFilled className="warning-icon" />
              &nbsp; Delete Request
            </p>
          </div>
        }
        centered
        closable={false}
        bodyStyle={{ width: "100%" }}
        footer={
          acceptCallHR
            ? [getNormalConfirmationBtn()]
            : [getRecruitmentCheckBtn()]
        }
      >
        <p className="warning-desc">{modalDeleteMessage}</p>
      </Modal>
    </>
  );
}

export default DeleteResourceModal;
