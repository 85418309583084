import React from "react";
import { filter } from "@src/features/sap_upload/utils/csv_parse_table_column";
import { sapDTOStatusEnum } from "@src/utils/helper";
import "./upload_result.less";
import {
  FileAddOutlined,
  FileExclamationOutlined,
  FileOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import { UploadStatistics } from "@src/types";

export interface IUploadResultProps {
  gridApi: any;
  uploadStatistics: UploadStatistics;
}
export default function UploadResult({
  gridApi,
  uploadStatistics,
}: IUploadResultProps) {
  return (
    <div className="status-section">
      <div>
        <Button
          shape="round"
          type="primary"
          data-testid="added-btn"
          onClick={() => filter(sapDTOStatusEnum.ADDED, "status", gridApi)}
          icon={<FileAddOutlined />}
        >
          added:&nbsp; <b>{uploadStatistics.totalAdded}</b>
        </Button>
        <Button
          shape="round"
          className="orange"
          data-testid="updated-btn"
          onClick={() => filter(sapDTOStatusEnum.UPDATED, "status", gridApi)}
          icon={<FileSyncOutlined />}
        >
          updated:&nbsp; <b>{uploadStatistics.totalUpdated}</b>
        </Button>
        <Button
          shape="round"
          className="grey"
          data-testid="nochange-btn"
          onClick={() => filter(sapDTOStatusEnum.NOCHANGE, "status", gridApi)}
          icon={<FileOutlined />}
        >
          {" "}
          no change:&nbsp; <b>{uploadStatistics.totalUnchanged}</b>
        </Button>
        <Button
          shape="round"
          className="black"
          data-testid="deleted-btn"
          onClick={() => filter(sapDTOStatusEnum.DELETED, "status", gridApi)}
          icon={<DeleteOutlined />}
        >
          deleted:&nbsp; <b>{uploadStatistics.totalDeleted}</b>
        </Button>
      </div>
      <div className="error-section">
        <Button
          shape="round"
          type="primary"
          data-testid="error-btn"
          danger
          onClick={() => filter(sapDTOStatusEnum.ERROR, "status", gridApi)}
          icon={<FileExclamationOutlined />}
        >
          errors:&nbsp; <b>{uploadStatistics.totalErrors}</b>
        </Button>
      </div>
    </div>
  );
}
