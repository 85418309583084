import React, { useEffect, useState } from "react";

import FilterCollapseWrapper from "@src/features/table_filtering/components/filter_collapse_wrapper/filter_collapse_wrapper";
import { TreeNode } from "@src/types/antd_types";
import { Tree } from "antd";
import _ from "lodash";

interface SelectionFilterProps {
  title: string;
  disabled?: boolean;
  defaultKeys?: string[];
  treeData: TreeNode[];
  onFilter: (values: string[]) => void;
  customRenderer?: (node: TreeNode) => React.ReactNode;
}

const SelectionFilter: React.FC<SelectionFilterProps> = ({
  title,
  disabled = false,
  defaultKeys,
  treeData,
  onFilter,
  customRenderer,
}) => {
  const [checkedKeys, setCheckedKeys] = useState<string[]>(defaultKeys);

  useEffect(() => {
    if (defaultKeys?.length > 0) {
      setCheckedKeys(defaultKeys);
    } else {
      setCheckedKeys([]);
    }
  }, [defaultKeys]);

  function handleSelection(values: string[]) {
    setCheckedKeys(values);
    onFilter(values);
  }

  function enhanceTreeData(treeData: TreeNode[]): TreeNode[] {
    return treeData.map((node) => ({
      ...node,
      title: customRenderer ? customRenderer(node) : node.title,
      children: node.children ? enhanceTreeData(node.children) : [],
    }));
  }

  const enhancedTreeData = enhanceTreeData(_.cloneDeep(treeData));

  return (
    <FilterCollapseWrapper title={title}>
      {treeData.length > 0 ? (
        <Tree
          checkable
          defaultExpandAll
          disabled={disabled}
          onCheck={handleSelection}
          treeData={enhancedTreeData}
          checkedKeys={checkedKeys || []}
          selectable={false}
          data-testid="selection-filter-tree"
        />
      ) : (
        <label>No data to filter</label>
      )}
    </FilterCollapseWrapper>
  );
};

export default SelectionFilter;
