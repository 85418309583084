// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assigned-roles-msg {
  margin-bottom: 20px;
}
.label {
  font-family: "RWESansWeb-Medium", sans-serif;
}
.resource-selection {
  min-width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/features/sap_upload/components/reassign_team_member_with_requests/reassign_team_member_with_requests.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEA;EACE,4CAAA;AAAF;AAGA;EACE,gBAAA;AADF","sourcesContent":[".assigned-roles-msg {\n  margin-bottom: 20px;\n}\n\n.label {\n  font-family: \"RWESansWeb-Medium\", sans-serif;\n}\n\n.resource-selection {\n  min-width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
