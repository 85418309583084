import { ActiveFilter, DashboardRequest, FilterPanel } from "@src/types";
import { SelectOption } from "@src/types/antd_types";
import { getDistinctList } from "@src/utils/helper";

import {
  FILTER_TYPES_DISPLAY_ORDER,
  DashboardFilterCollapseHeader,
  DashboardRequestProperty,
} from "./dashboard_constants";

/**
 * Get the time range options for time range selection
 * @returns {SelectOption[]} - The values and labels used in the Time Range Filter
 */
export function getTimeRangeOptions(): SelectOption[] {
  return [
    { value: 0, label: "none" },
    { value: 3, label: "3 Months" },
    { value: 6, label: "6 Months" },
    { value: 12, label: "12 Months" },
    { value: 24, label: "24 Months" },
  ];
}

/**
 * Maps the data of the dashboard requests to a data format that fits to the filter collapse
 * @param {DashboardRequest[]} dashboardRequest - All requests from the dashboard
 * @param {Map<string, string[]>} currentFilter - Current applied filter
 * @returns {FilterPanel[]} The data to be rendered in the filter collapse
 */
export function mapToFilterPanel(
  dashboardRequest: DashboardRequest[],
  currentFilter: Map<string, string[]>
): FilterPanel[] {
  const filterData: FilterPanel[] = [];

  if (!dashboardRequest) return [];

  for (let i = 0; i < FILTER_TYPES_DISPLAY_ORDER.length; i++) {
    const filterType = FILTER_TYPES_DISPLAY_ORDER[i];
    const property = DashboardRequestProperty[filterType];
    const distinctLabels = getDistinctList(
      dashboardRequest.map((request: DashboardRequest) => request[property])
    );
    const dashboardHeader = DashboardFilterCollapseHeader[filterType];

    const filterPanel: FilterPanel = {
      labels: distinctLabels,
      header: dashboardHeader,
      key: i,
      filterType: filterType,
      checkedLabels: currentFilter?.get(filterType) || [], // necessary when unchecking the active filter
    };

    filterData.push(filterPanel);
  }

  return filterData;
}

/**
 * Maps the filter to a data format that fits to the active filter
 * @param {Map<string, string[]>} currentFilter - The filtered data from the dashboard
 * @returns {ActiveFilter[]} The data to be rendered in the active filter
 */
export function mapToActiveFilter(
  currentFilter: Map<string, string[]>
): ActiveFilter[] {
  const activeFilter: ActiveFilter[] = [];

  currentFilter?.forEach((values, key) => {
    values.forEach((value) => {
      activeFilter.push({ filterType: key, filterValue: value });
    });
  });

  return activeFilter;
}
