import { months } from "../../../constants";

const transformYearMonth = (allocationDetails = [], type) => {
  const record = {};

  if (allocationDetails) {
    allocationDetails.forEach((eachAllocation) => {
      const monthName = months[eachAllocation.month - 1];
      record[`${eachAllocation.year}_${monthName}`] =
        eachAllocation[type] + "%";
    });
  }
  return [record];
};

export default transformYearMonth;
