// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.projects .ant-empty {
  margin: auto;
  margin-top: 30vh;
  background-color: white;
  padding: 20px;
  color: #1d4477;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/projects_overview_page/projects_overview_page.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,WAAA;EACA,8BAAA;AACF;AACE;EACE,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,aAAA;EACA,cAAA;EACA,kBAAA;AACJ","sourcesContent":[".projects {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  justify-content: space-between;\n\n  & .ant-empty {\n    margin: auto;\n    margin-top: 30vh;\n    background-color: white;\n    padding: 20px;\n    color: #1d4477;\n    border-radius: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
