import React from "react";

import fixedbottom from "@src/assets/icons/fixedbottom-tech.svg";
import floating from "@src/assets/icons/floating-tech.svg";
import hydrogen from "@src/assets/icons/hydrogen.svg";
import profile from "@src/assets/icons/profile.svg";
import "./project_information_renderer.less";
import ProjectPhaseChip from "@src/components/project_phase_chip/project_phase_chip";
import { ICellRendererParams } from "ag-grid-community";
import { Tooltip } from "antd";

const technologyIcons = {
  Floating: floating,
  "Fixed bottom": fixedbottom,
};

interface ProjectInformationRendererParams {
  params: ICellRendererParams;
}

const ProjectInformationRenderer: React.FC<
  ProjectInformationRendererParams
> = ({ params }) => {
  const projectName = params.data.projectName;
  const projectPhase = params.data.projectPhase;
  const technologies = params.data.technologies;
  const projectLeadEmployeeName = params.data.projectLeadEmployeeName;

  function getTechnologyName(technologies: string[]): string {
    if (!technologies) return "";
    return technologies.filter((tech) => tech !== "Hydrogen").join(", ");
  }

  function isHydrogenTechnology(technologies: string[]): boolean {
    if (!technologies) return false;
    return technologies.includes("Hydrogen");
  }

  function getTechnologyIcon(technologies: string[]): string {
    const technologyName = getTechnologyName(technologies);
    return technologyIcons[technologyName] || "";
  }

  function getTooltipText() {
    return (
      <div className="project-info-tooltip">
        <p>{`Project Name: ${projectName}`}</p>
        <p>{`Project Phase: ${projectPhase}`}</p>
        {technologies && <p>{`Technologies: ${technologies.join(", ")}`}</p>}
        <p>{`Project Lead: ${projectLeadEmployeeName}`}</p>
      </div>
    );
  }

  return (
    <Tooltip
      overlayClassName="project-info-tooltip"
      placement="bottomLeft"
      mouseEnterDelay={0.5}
      title={getTooltipText()}
    >
      <div className="project-info">
        <div className="project-info-wrapper">
          <ProjectPhaseChip projectPhase={projectPhase} />
          {getTechnologyIcon(technologies) && (
            <img
              src={getTechnologyIcon(technologies)}
              alt="project phase"
              className="project-info-technology-icon"
            />
          )}
          <div>
            <span className="project-info-name-label">{projectName}</span>
            <div className="project-info-subtext">
              <span>
                {technologies && `${getTechnologyName(technologies)} ·`}
              </span>
              <img
                src={profile}
                alt="project lead"
                className="project-info-profile-icon"
              />
              <span>{projectLeadEmployeeName}</span>
            </div>
          </div>
        </div>
        <div>
          {isHydrogenTechnology(technologies) && (
            <img
              src={hydrogen}
              alt="hydrogen"
              className="project-info-hydrogen-icon"
            />
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default ProjectInformationRenderer;
