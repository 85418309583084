import React from "react";

import { message } from "antd";
import _ from "lodash/index";
import moment from "moment";

import api from "../api";

export const sapDTOStatusEnum = {
  ADDED: "Added",
  UPDATED: "Updated",
  DELETED: "Deleted",
  ERROR: "Error",
  NOCHANGE: "no change",
};

export const getErrorMessage = (errorMessage) => {
  message.error({
    content: errorMessage,
  });
};

export const showErrorMessage = (messageApi, message) => {
  messageApi.open({
    type: "error",
    content: message,
  });
};

export const mergeMaps = (map1, map2) => {
  const result = new Map(map1); // Start with a clone of map1

  map2.forEach((value, key) => {
    if (result.has(key)) {
      // If the key exists in both maps, merge the arrays and remove duplicates
      const mergedArray = _.union(result.get(key), value);
      result.set(key, mergedArray);
    } else {
      // If the key only exists in map2, add it to the result
      result.set(key, value);
    }
  });

  return result;
};

export const calculatePercentage = (leftElements, totalElements) => {
  const percent = Math.trunc((leftElements * 100) / totalElements);
  return percent;
};

export const RequestIdTransformation = (requestId, projectName) => {
  if (requestId) {
    if (projectName) {
      const formatedProjectName = projectName
        .toString()
        .replace(" - ", " ")
        .replace(/ /g, "_");
      return `${formatedProjectName}_${requestId.toString().padStart(4, "0")}`;
    } else {
      return `${requestId.toString().padStart(4, "0")}`;
    }
  } else {
    return null;
  }
};

export const CalculateTableHeader = (childRequest, allocationDetailsLast) => {
  const currentDate = new Date();
  const startYear = currentDate.getFullYear();
  let startMonth = currentDate.getMonth() + 1;

  const endYear = childRequest
    ? Math.max(
        allocationDetailsLast.year,
        childRequest[childRequest.length - 1].year
      )
    : allocationDetailsLast.year;
  let endMonth = childRequest
    ? Math.max(
        allocationDetailsLast.month / 100 + allocationDetailsLast.year,
        childRequest[childRequest.length - 1].month / 100 +
          childRequest[childRequest.length - 1].year
      )
    : allocationDetailsLast.month;

  /* istanbul ignore else*/
  if (childRequest) {
    startMonth = Math.round((startMonth - startYear) * 100);
    endMonth = Math.round((endMonth - endYear) * 100);
  }

  const noOfYears = endYear - startYear;
  const totalMonths = moment([startYear, startMonth, 1]).diff(
    moment([endYear, endMonth, 1]),
    "months",
    true
  );

  return {
    startYear: startYear,
    startMonth: startMonth,
    endYear: endYear,
    endMonth: endMonth,
    noOfYears: noOfYears,
    totalMonths: totalMonths,
  };
};

export const CreateStartMonthAndEndMonth = (dateRanges, year) => {
  let currentEndMonth = 12;
  let currentStartMonth = 1;

  if (new Date().getFullYear() === year) {
    currentStartMonth = new Date().getMonth() + 1;
  } else if (dateRanges.startYear === dateRanges.endYear) {
    currentEndMonth = 12;
  } else if (year === dateRanges.startYear) {
    currentStartMonth = dateRanges.startMonth;
  } else if (year === dateRanges.endYear) {
    currentEndMonth = dateRanges.endMonth;
  }

  return {
    currentStartMonth: currentStartMonth,
    currentEndMonth: currentEndMonth,
  };
};

export const SortAlphabetically = (listToSort, attributeName) => {
  if (listToSort && listToSort.length !== 0) {
    listToSort.sort((a, b) => {
      if (a[attributeName]?.toUpperCase() < b[attributeName]?.toUpperCase()) {
        return -1;
      }
      if (a[attributeName]?.toUpperCase() > b[attributeName]?.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  }
};

export const RoleReassignModalMessage = async (user, standardRoles) => {
  const modalMessage = {
    title: (
      <p>
        <b>
          The changes you made effect responsibilities for standard roles, old
          and new requests would be assigned to this team lead.
          <br />
          Proposals will turn in requested again. Forwarded requests will be
          transferred to <u>{user.fullName}</u>. <br />
          Please check them again to confirm your changes
        </b>
      </p>
    ),
    button: "Confirm",
    warning: "",
  };

  let li = [];

  for (const standardRole of standardRoles) {
    if (
      standardRole.primaryTeamLeadEmployeeId !== null &&
      standardRole.primaryTeamLeadEmployeeId !== user.employeeId
    ) {
      const primaryTeamlead = await api.get(
        `/standard-roles/${standardRole.standardRoleId}/team-leads`,
        {},
        "Failed to get standard roles"
      );

      if (
        primaryTeamlead &&
        primaryTeamlead.status === 200 &&
        primaryTeamlead.data !== ""
      ) {
        li.push(
          <li>
            <b> {standardRole.standardRoleName}</b> : from{" "}
            <u>{primaryTeamlead.data.fullName}</u> to <u>{user.fullName}</u>
          </li>
        );
      }
    }
  }

  let warning = (
    <>
      {li.length !== 0 && "Handover responsibilities of roles"}
      <ul>{li}</ul>
    </>
  );

  modalMessage.warning = warning;

  return modalMessage;
};

export const toEqualStringLists = (list1, list2) => {
  if (!list1 || !list2) {
    return false;
  }
  if (list1.length !== list2.length) {
    return false;
  }
  const listHelper = list1.filter((item) => list2.includes(item));
  return listHelper.length === list1.length;
};

export const removeValuesFromStringList = (list, listToBeExcluded) => {
  if (!list) {
    return [];
  }
  if (!listToBeExcluded) {
    return list;
  }
  return list.filter((item) => !listToBeExcluded.includes(item));
};

export const getDistinctList = (list) => {
  return [...new Set(list)];
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getSortedDistinctList = (list, attributeName) => {
  SortAlphabetically(list, attributeName);
  return getDistinctList(list?.map((item) => item[attributeName]));
};

export const switchNameAndSurname = (fullNameToSwitchNameAndSurname) => {
  const name = fullNameToSwitchNameAndSurname.substring(
    fullNameToSwitchNameAndSurname.indexOf(",") + 1,
    fullNameToSwitchNameAndSurname.length
  );

  const surname = fullNameToSwitchNameAndSurname.substring(
    0,
    fullNameToSwitchNameAndSurname.indexOf(",")
  );

  return `${name} ${surname}`;
};

export function areArraysIdentical(arr1, arr2) {
  return _.isEqual(_.sortBy(arr1), _.sortBy(arr2));
}
