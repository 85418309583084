import React, { useState } from "react";
import "./doughnut_chart_legend_item.less";

export interface DoughnutChartLegendItemProps {
  className?: string;
  text: string;
  count: number;
  backgroundColor?: string;
  action?: () => void;
}

const DoughnutChartLegendItem: React.FC<DoughnutChartLegendItemProps> = ({
  className = "",
  text,
  count,
  backgroundColor = "",
  action,
}) => {
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const onClick = () => {
    if (!action) return;
    setIsClicked(!isClicked);
    action();
  };

  return (
    <div
      className={`legend__item ${className}`}
      onClick={onClick}
      data-testid="legend-item"
    >
      <div className={`legend__item__text ${isClicked ? "line-through" : ""}`}>
        <div
          className="legend__item__circle"
          style={{ backgroundColor: backgroundColor }}
          data-testid="circle"
        />
        {text}
      </div>{" "}
      <span className="legend__item__count">{count}</span>
    </div>
  );
};

export default DoughnutChartLegendItem;
