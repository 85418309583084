// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-header {
  height: 63px;
}
.main-header .ant-tabs-tab {
  height: 64px !important;
}
.main-header .header-navigation {
  display: flex;
  justify-content: space-between;
  background-color: #1d4477;
}
.main-header .help-icon-user-section {
  display: flex;
  height: 60px;
}
.sub-navigation {
  background: #e8e8e8;
  height: 60px;
  color: black;
  display: flex;
  justify-content: end;
  align-items: center;
}
.sub-navigation button {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/app_layout/app_layout.less"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAAA;EAII,uBAAA;AADJ;AAHA;EAQI,aAAA;EACA,8BAAA;EACA,yBAAA;AAFJ;AARA;EAcI,aAAA;EACA,YAAA;AAHJ;AAOA;EACE,mBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;EACA,mBAAA;AALF;AADA;EASI,kBAAA;AALJ","sourcesContent":["@import \"../../stylings/variables\";\n\n.main-header {\n  height: 63px;\n\n  .ant-tabs-tab {\n    height: 64px !important;\n  }\n\n  .header-navigation {\n    display: flex;\n    justify-content: space-between;\n    background-color: @brand;\n  }\n\n  .help-icon-user-section {\n    display: flex;\n    height: 60px;\n  }\n}\n\n.sub-navigation {\n  background: @light-dark-grey;\n  height: 60px;\n  color: black;\n  display: flex;\n  justify-content: end;\n  align-items: center;\n\n  button {\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
