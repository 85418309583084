import React from "react";

import { Radio, RadioChangeEvent } from "antd";
import "./filter_tabs.less";
import { TabConfig } from "@src/types";

export interface FilterTabProps {
  defaultActiveConfig: TabConfig;
  items: TabConfig[];
  onChange: (key: string) => void;
  disabled?: boolean;
}

const FilterTabs: React.FC<FilterTabProps> = ({
  defaultActiveConfig,
  items,
  onChange,
  disabled = false,
}) => {
  const [activeOption, setActiveOption] = React.useState(
    items.find((item) => item.key === defaultActiveConfig?.key)
  );

  function handleChange(e: RadioChangeEvent) {
    setActiveOption(items.find((item) => item.key === e.target.value));
    onChange(e.target.value);
  }

  return (
    <Radio.Group
      disabled={disabled}
      size={"large"}
      defaultValue={activeOption.key}
      onChange={handleChange}
      data-testid="tabs-group"
      className="tabs"
    >
      {items.map((item) => (
        <Radio.Button key={item.key} value={item.key}>
          <div className="tab-label">
            {item?.icon}
            {item.label}
          </div>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default FilterTabs;
