// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-collapse.ant-collapse {
  border: 0;
}
.filter-collapse .ant-collapse-item {
  border-bottom: 0;
}
.filter-collapse .ant-collapse-content {
  height: 190px;
  overflow: scroll;
  border: 0;
  padding: 10px;
}
.filter-collapse .ant-collapse-content .filter-panel__border {
  margin: 0 10px;
  border-top: 1px solid #e8ecf1;
}
.filter-collapse .ant-tree {
  padding: 10px;
}
.filter-collapse .ant-tree-treenode {
  margin-bottom: 4px;
}
.filter-collapse .ant-tree-switcher {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/filter_collapse/filter_collapse.less"],"names":[],"mappings":"AAGE;EACE,SAAA;AAFJ;AAOM;EACE,gBAAA;AALR;AAQM;EACE,aAAA;EACA,gBAAA;EACA,SAAA;EACA,aAAA;AANR;AASU;EACE,cAAA;EACA,6BAAA;AAPZ;AAaI;EACE,aAAA;AAXN;AAYM;EACE,kBAAA;AAVR;AAaM;EACE,aAAA;AAXR","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.filter-collapse {\n  &.ant-collapse {\n    border: 0;\n  }\n\n  .ant {\n    &-collapse {\n      &-item {\n        border-bottom: 0;\n      }\n\n      &-content {\n        height: 190px;\n        overflow: scroll;\n        border: 0;\n        padding: 10px;\n\n        .filter-panel {\n          &__border {\n            margin: 0 10px;\n            border-top: 1px solid @brand-10;\n          }\n        }\n      }\n    }\n\n    &-tree {\n      padding: 10px;\n      &-treenode {\n        margin-bottom: 4px;\n      }\n\n      &-switcher {\n        display: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
