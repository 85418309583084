import React, { useState, useRef, useMemo, useCallback } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { navigate } from "@reach/router";
import { DownloadResourcePlanByRegion } from "@src/components/download_resource_plan_by_region";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import { useGetProjectLeadsQuery } from "@src/services/slices/employeesSlice";
import { useGetAllProjectsQuery } from "@src/services/slices/projectsSlice";
import { Employee, Project } from "@src/types";
import {
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button } from "antd";
import _ from "lodash";

import "./admin_project_overview.less";
import { getGridOptions } from "../../utils/admin_project_overview_grid_options";
import { onCellValueChanged } from "../../utils/admin_project_overview_utils";
import { FilterProjects } from "../filter_projects";

export interface AdminProjectOverviewContext {
  projectLeads: Employee[];
}

const AdminProjectOverview = () => {
  const gridApi = useRef<GridApi>(null);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const { data: projects, isFetching: isFetchingProjects } =
    useGetAllProjectsQuery();
  const { data: projectLeads } = useGetProjectLeadsQuery();

  const context = useMemo<AdminProjectOverviewContext>(() => {
    return {
      projectLeads: projectLeads,
    };
  }, [projectLeads]);

  /**
   * onClick method for the add button
   * navigates to the create project page
   */
  function onAddProject(): void {
    navigate("/projects/create/-1");
  }

  /**
   * onGridReady event from AG Grid.
   * assignes the gridApi, resizes the column width and set loading to false
   *
   * @param {GridReadyEvent} event the event property if AG grid
   */
  const onGridReady = useCallback((event: GridReadyEvent) => {
    gridApi.current = event.api;
    event.api.sizeColumnsToFit();
    setIsTableLoading(false);
  }, []);

  return (
    <div className="admin-project-overview">
      <div className="filter-buttons-section">
        <Button
          className="add-project-button"
          size="large"
          type="primary"
          onClick={onAddProject}
          disabled={isFetchingProjects || isTableLoading}
          icon={<PlusOutlined />}
          data-testid="add-project-button"
        >
          Add Project
        </Button>
        <div className="filter-download-section">
          <FilterProjects
            gridApi={gridApi.current}
            projects={mapToTreeData(projects, "name")}
            countries={mapToTreeData(projects, "country")}
            disabled={isFetchingProjects || isTableLoading}
          />
          <DownloadResourcePlanByRegion
            disabled={isFetchingProjects || isTableLoading}
          />
        </div>
      </div>

      <div className="ag-theme-alpine header-white">
        <AgGridReact
          rowData={_.cloneDeep(
            projects?.filter(
              (project: Project) => project.name !== "Time Blocker"
            )
          )}
          gridOptions={getGridOptions()}
          onGridReady={onGridReady}
          context={context}
          onGridSizeChanged={(event: GridSizeChangedEvent) =>
            event.api.sizeColumnsToFit()
          }
          onCellValueChanged={onCellValueChanged}
        />
      </div>
    </div>
  );
};

export default AdminProjectOverview;
