import { useState } from "react";

import { RouteComponentProps } from "@reach/router";
import "./import_resource_plan_page.less";
import { CSVParser } from "@src/components/csv_parser";
import { LoadingModal } from "@src/components/modals/loading_modal";
import {
  UpdateStandardRoleModal,
  ImportResourcePlanTable,
} from "@src/features/import_resource_plan/components";
import {
  mapRequestsForTable,
  readResourcePlanHeader,
} from "@src/features/import_resource_plan/utils/import_resource_plan_utils";
import { useGetProjectQuery } from "@src/services/slices/projectsSlice";
import { useCheckResourcePlanDataMutation } from "@src/services/slices/adminApi";
import { message } from "antd";
import { AdminUploadRequest, LoadingMessage } from "@src/types";
import _ from "lodash";

export interface IImportResourcePlanPage extends RouteComponentProps {
  id: string;
}

const ImportResourcePlanPage = ({ id }: IImportResourcePlanPage) => {
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<LoadingMessage>(null);
  const [standardRoleModalVisible, setStandardRoleModalVisible] =
    useState<boolean>(false);
  const [requests, setRequests] = useState<AdminUploadRequest[]>([]);
  const [standardRoleErrorRequests, setStandardRoleErrorRequests] = useState<
    AdminUploadRequest[]
  >([]);
  const [showOpenRequests, setShowOpenRequests] = useState<boolean>(false);

  const { data: project } = useGetProjectQuery(id);

  const [checkResourcePlanData] = useCheckResourcePlanDataMutation();

  const textArray: string[] = [
    "Click or drag new resource plan to this area.",
    "The file will only be displayed to check if the format complies with the prerequisites.",
  ];

  /**
   * sends data to the backend to check if every request has valid values
   * @param {*} payload data to be sent
   */
  const sendData = async (payload: AdminUploadRequest[]) => {
    setIsFileUploading(false);
    checkResourcePlanData(payload)
      .unwrap()
      .then((adminUploadRequest: AdminUploadRequest[]) => {
        if (adminUploadRequest.length > 0) {
          const requests = _.cloneDeep(adminUploadRequest);
          setRequests(requests);
          validateAndProcessData(requests);
        } else {
          message.error(
            `Something is wrong with the csv. Please contact the it`
          );
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  /**
   * checks and processes the results of the backend validation
   * if any requests is not valid, the standard role modal will be opened
   * if all requests are valid, the open request table will be opened
   * @param {*} data data coming from backend
   */
  const validateAndProcessData = (data: AdminUploadRequest[]) => {
    // checks if any request has an invalid status
    const standardRoleErrors: AdminUploadRequest[] = data.filter(
      (request) => request.validateStatus === "Standard Role Error"
    );
    if (standardRoleErrors.length > 0) {
      setStandardRoleModalVisible(true);
      setStandardRoleErrorRequests(standardRoleErrors);
    } else {
      setShowOpenRequests(true);
    }
  };

  /**
   * onSave Method of the standard role modal
   * updates the requests with errors coming with new data from modal
   * and send them again to backend to check again for errors
   * @param {*} requestsFromModal data coming from the modal
   */
  const onStandardRoleModalSave = (requestsFromModal: AdminUploadRequest[]) => {
    const updatedRequests: AdminUploadRequest[] = requests.map(
      (request: AdminUploadRequest) => {
        return updateExistingRequest(request, requestsFromModal);
      }
    );
    sendData(updatedRequests);
    setRequests(updatedRequests);
    setStandardRoleModalVisible(false);
  };

  /**
   * finds the original request and updates it with the data coming from modal
   * @param {*} request original request that will be updated
   * @param {*} requestsFromModal all requests that were modified in the modal
   * @returns the updated request
   */
  const updateExistingRequest = (
    request: AdminUploadRequest,
    requestsFromModal: AdminUploadRequest[]
  ) => {
    const updatedRequest: AdminUploadRequest = requestsFromModal.find(
      (r) => r.rowNumber === request.rowNumber
    );
    if (updatedRequest) {
      request.basicRole = updatedRequest.basicRole;
      request.specification = updatedRequest.specification;
      request.standardRole = updatedRequest.specification
        ? updatedRequest.basicRole + " " + updatedRequest.specification
        : updatedRequest.basicRole;
    }
    delete request.validateStatus;
    return request;
  };

  /**
   * resets all data
   */
  const onCancel = () => {
    setRequests([]);
    setStandardRoleErrorRequests([]);
    setIsFileUploading(false);
    setShowOpenRequests(false);
  };

  return (
    <>
      {isFileUploading && (
        <LoadingModal
          modalVisible={isFileUploading}
          loadingMessage={loadingMessage}
        />
      )}
      <CSVParser
        setIsFileUploading={setIsFileUploading}
        isFileUploading={isFileUploading}
        setLoadingMessage={setLoadingMessage}
        sendData={sendData}
        onCancel={onCancel}
        title="Upload new resource plan"
        textArray={textArray}
        readData={readResourcePlanHeader}
      />
      <UpdateStandardRoleModal
        modalVisible={standardRoleModalVisible}
        setModalVisible={setStandardRoleModalVisible}
        requests={standardRoleErrorRequests}
        handleSave={onStandardRoleModalSave}
        handleCancel={onCancel}
      />

      {showOpenRequests && (
        <ImportResourcePlanTable
          project={project}
          requests={mapRequestsForTable(requests)}
        />
      )}
    </>
  );
};

export default ImportResourcePlanPage;
