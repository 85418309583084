import React, { useEffect, useState } from "react";

import {
  gatherActiveFilters,
  onChangeQuickFilter,
  onColumnFilterChange,
  onFilterChange,
  resetAllActiveFilters,
} from "@src/features/table_filtering/utils/base_state_handler_utils";
import {
  clearSentRequestsCustomTableFilterProperty,
  clearSentRequestsFilter,
} from "@src/services/resourcePlanSlice";
import { useAppDispatch, useAppSelector } from "@src/setupStore";
import { ActiveFilter } from "@src/types";
import { ColDef, GridApi } from "ag-grid-community";

interface UseSentRequestStateChangeHandlerProps {
  gridApi: React.MutableRefObject<GridApi>;
  columnDefs: ColDef[];
}

interface UseSentRequestStateChangeHandlerResponse {
  activeFilters: ActiveFilter[];
  resetActiveFilters: (key?: string, value?: string) => void;
  setTableRendered: () => void;
}

export const useSentRequestStateChangeHandler = ({
  gridApi,
  columnDefs,
}: UseSentRequestStateChangeHandlerProps): UseSentRequestStateChangeHandlerResponse => {
  const [isTableRendered, setIsTableRendered] = useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>([]);
  const dispatch = useAppDispatch();

  const {
    sentRequestsTableState: { quickFilter, customTableFilters, columns },
  } = useAppSelector((state) => state.resourcePlanSlice);

  /**
   * Load filter state from store to grid
   */
  useEffect(() => {
    if (!gridApi.current && !isTableRendered) return;

    onChangeQuickFilter(quickFilter, gridApi.current, null);
    onColumnFilterChange(columns, gridApi.current, columnDefs);

    for (const key of Object.keys(customTableFilters)) {
      const values: string[] = customTableFilters[key];
      onFilterChange(values, key, gridApi.current, null);
    }

    const activeFilters = gatherActiveFilters(customTableFilters);
    setActiveFilters(activeFilters);
  }, [
    columns,
    quickFilter,
    customTableFilters,
    gridApi.current,
    isTableRendered,
  ]);

  function resetActiveFilterByKey(key: string, value: string): void {
    const valueToBeCleared = { key, value };
    dispatch(clearSentRequestsCustomTableFilterProperty(valueToBeCleared));
  }

  function resetActiveFilters(key?: string, value?: string): void {
    if (key !== undefined && value !== undefined) {
      resetActiveFilterByKey(key, value);
    } else {
      resetAllActiveFilters(gridApi.current);
      dispatch(clearSentRequestsFilter());
    }
  }

  return {
    activeFilters,
    resetActiveFilters,
    setTableRendered: () => setIsTableRendered(true),
  };
};
