import React, { useEffect, useState } from "react";

import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import "./request_country_statistics_chart.less";
import {
  getMapPosition,
  getMapsOptions,
} from "@src/features/dashboard/utils/request_country_statistics_utils";
import { RequestCountryStatistics } from "@src/types";
const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;
import { GoogleMapsMarker } from "@src/features/dashboard/components/google_maps_marker.tsx";
import { Card } from "antd";
import { GoogleMapsMarkerProps } from "@src/features/dashboard/components/google_maps_marker.tsx/google_maps_marker";

export interface RequestCountryStatisticsChartProps {
  requestCountryStatisticsData: RequestCountryStatistics[];
  isLoading: boolean;
}

type GoogleMapsMarkers = Pick<
  GoogleMapsMarkerProps,
  "position" | "totalRequests"
>;

const RequestCountryStatisticsChart: React.FC<
  RequestCountryStatisticsChartProps
> = ({ requestCountryStatisticsData, isLoading }) => {
  const [googleMapsMarker, setGoogleMapsMarker] = useState<
    Map<string, GoogleMapsMarkers>
  >(new Map());

  useJsApiLoader({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    if (!isLoading && requestCountryStatisticsData) {
      setGoogleMapsMarker(new Map());
      calculateMarker();
    }
  }, [requestCountryStatisticsData, isLoading]);

  function calculateMarker() {
    for (const requestCountryStatisticsDataElements of requestCountryStatisticsData) {
      const geocoder: google.maps.Geocoder = new window.google.maps.Geocoder();

      geocoder
        .geocode({ address: requestCountryStatisticsDataElements.countryName })
        ?.then((results: google.maps.GeocoderResponse) => {
          const googleMapsMarker: GoogleMapsMarkers = {
            position: results.results[0].geometry.location,
            totalRequests: requestCountryStatisticsDataElements.totalRequests,
          };

          setGoogleMapsMarker((prevMap: Map<string, GoogleMapsMarkers>) =>
            new Map(prevMap).set(
              requestCountryStatisticsDataElements.countryName,
              googleMapsMarker
            )
          );
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <Card
      title="Requests"
      className="request-country-statistics-chart"
      loading={isLoading}
    >
      <GoogleMap
        mapContainerClassName="request-country-statistics-chart__google-maps-chart"
        center={getMapPosition()}
        zoom={1}
        options={getMapsOptions()}
        data-testid="google-maps"
        onLoad={calculateMarker}
      >
        {Array.from(googleMapsMarker).map(([key, value]) => (
          <GoogleMapsMarker
            key={key}
            countryName={key}
            totalRequests={value.totalRequests}
            position={value.position}
          />
        ))}
      </GoogleMap>
    </Card>
  );
};

export default RequestCountryStatisticsChart;
