import { Form, Input } from "antd";
import React from "react";
/*
This components is the old global input renderer that changed with refactorings in the role assignment.
It needs to be removed in the future in order to use the global input renderer.
Since the whole region implementation was inspired by the role assignment page, the region assignment components should be refactored as well.
 */
const InputRenderer = ({
  dataObject,
  onChangeCallback,
  initialValues,
  showInput,
  formItemName,
  placeholder,
  inputClass,
  fallBackMessage,
  isTextArea,
}) => {
  const InputComponent = isTextArea ? Input.TextArea : Input;
  return (
    <>
      {showInput ? (
        <Form
          layout="vertical"
          size="large"
          onValuesChange={(val) => onChangeCallback(val, dataObject)}
          initialValues={initialValues}
          data-testid="form-element"
          style={{ width: "100%" }}
        >
          <Form.Item name={formItemName}>
            <InputComponent
              className={inputClass}
              placeholder={placeholder}
              data-testid="input-element"
              style={{ resize: "none" }}
            />
          </Form.Item>
        </Form>
      ) : (
        fallBackMessage
      )}
    </>
  );
};

export default InputRenderer;
