import React from "react";
import "./required_action_renderer.less";

interface ActionRequiredRendererProps {
  actionRequired: boolean;
}

const ActionRequiredRenderer: React.FC<ActionRequiredRendererProps> = ({
  actionRequired,
}) => {
  return (
    <div className="action-required">
      <div
        data-testid="action-required-renderer"
        className={
          "status " +
          (actionRequired ? "action-required-yes" : "action-required-no")
        }
      />
      <div>{actionRequired ? "Yes" : "No"}</div>
    </div>
  );
};

export default ActionRequiredRenderer;
