import React, { useEffect, useState } from "react";

import "./add_employee_modal.less";
import { ConfirmationModal } from "@src/components/modals/confirmation_modal";
import { ROLES } from "@src/constants";
import { RoleReassignModalMessage } from "@src/utils/helper";
import { Button, Modal, Form, Select } from "antd";

import { ReassignTeamMemberWithRequests } from "../reassign_team_member_with_requests";

export default function AddEmployeeModal({
  showAddEmployeeModal,
  setShowAddEmployeeModal,
  standardroles,
  openedDetailsSapDTO,
  setOpenedDetailsSapDTO,
  gridapi,
  teamleads,
  setStandardRoles,
}) {
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [showApplicationRoleSelection, setShowApplicationRoleSelection] =
    useState(true);
  const [modalMessage, setModalMessage] = useState({});
  const [showRoleAssignmentWarningModal, setShowRoleAssignmentWarningModal] =
    useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setIsSaveBtnDisabled(true);
  }, [showAddEmployeeModal]);

  useEffect(() => {
    setShowApplicationRoleSelection(true);

    initializeRoles();
    const lead = initializeTeamLead();

    const showApplicationRoleSelection =
      openedDetailsSapDTO?.data?.applicationRoles.find(
        (ar) => ar.applicationRoleName === ROLES.TEAM_LEAD
      );

    setShowApplicationRoleSelection(showApplicationRoleSelection);

    if (
      lead &&
      showApplicationRoleSelection &&
      openedDetailsSapDTO.data.standardRoles?.length > 0
    ) {
      setIsSaveBtnDisabled(false);
    }
  }, [openedDetailsSapDTO]);

  const initializeRoles = () => {
    if (openedDetailsSapDTO?.data?.standardRoles?.length > 0) {
      const appliedStandardRoles = openedDetailsSapDTO.data.standardRoles.map(
        (role) => role.standardRoleId
      );

      form.setFieldsValue({ standardRole: appliedStandardRoles });

      return appliedStandardRoles;
    } else {
      form.resetFields(["standardRole"]);
    }

    return null;
  };

  const initializeTeamLead = () => {
    const lead = teamleads.find(
      (l) => l.uiNumber === openedDetailsSapDTO.data.uiNumberBoss
    );

    if (lead) {
      form.setFieldsValue({ teamLead: lead.employeeId });
    } else {
      form.resetFields(["teamLead"]);
    }

    return lead;
  };

  const onChange = () => {
    const selectedTeamLead = form.getFieldValue("teamLead");

    if (showApplicationRoleSelection) {
      const selectedStandardRole = form.getFieldValue("standardRole");
      let isSaveBtnDisabled = !(
        selectedStandardRole?.length !== 0 && selectedTeamLead
      );
      setIsSaveBtnDisabled(isSaveBtnDisabled);
    } else {
      let isSaveBtnDisabled = !selectedTeamLead;
      setIsSaveBtnDisabled(isSaveBtnDisabled);
    }
  };

  const getStandardRoles = () => {
    const selectedStandardRole = form.getFieldValue("standardRole");

    if (selectedStandardRole && selectedStandardRole.length > 0) {
      const selectedStandardRolesObjects = standardroles.filter((role) => {
        if (selectedStandardRole.includes(role.standardRoleId)) {
          role.disabled = true;
          return true;
        }
        return false;
      });

      return selectedStandardRolesObjects;
    } else {
      return [];
    }
  };

  const onSave = async () => {
    updateTeamLeadInformation();

    if (showApplicationRoleSelection) {
      const selectedStandardRolesObjects = updateStandardRoles();

      const modalMessage = await RoleReassignModalMessage(
        openedDetailsSapDTO.data,
        selectedStandardRolesObjects
      );
      setModalMessage(modalMessage);
      setShowRoleAssignmentWarningModal(true);
    } else {
      updateRow();
      setShowAddEmployeeModal(false);
    }
  };

  const updateStandardRoles = () => {
    const selectedStandardRole = form.getFieldValue("standardRole");

    const selectedStandardRolesObjects = standardroles.filter((role) => {
      if (selectedStandardRole?.includes(role.standardRoleId)) {
        role.disabled = true;
        return true;
      }
      return false;
    });

    openedDetailsSapDTO.data.standardRoles = selectedStandardRolesObjects;
    setOpenedDetailsSapDTO(openedDetailsSapDTO);

    return selectedStandardRolesObjects;
  };

  const updateTeamLeadInformation = () => {
    const selectedTeamLead = form.getFieldValue("teamLead");

    if (selectedTeamLead) {
      const teamLeadObject = teamleads.find(
        (lead) => lead.employeeId === selectedTeamLead
      );

      openedDetailsSapDTO.data.uiNumberBoss = teamLeadObject.uiNumber;
      openedDetailsSapDTO.data.rcdNumberBoss = teamLeadObject.rcdNumber;
      openedDetailsSapDTO.data.teamLeadFullname = teamLeadObject.fullName;
      openedDetailsSapDTO.data.teamLeadOrgCode = teamLeadObject.departmentCode;
    }
  };

  const processSave = () => {
    const selectedStandardRole = form.getFieldValue("standardRole");
    standardroles.filter((role) => {
      if (selectedStandardRole?.includes(role.standardRoleId)) {
        role.temporaryAssignedEmployee =
          openedDetailsSapDTO.data.uiNumber +
          "_" +
          openedDetailsSapDTO.data.positionId;
        return true;
      } else {
        delete role.temporaryAssignedEmployee;
      }
      return false;
    });

    setStandardRoles(standardroles);

    form.resetFields();
    updateRow();

    setShowAddEmployeeModal(false);
    setShowApplicationRoleSelection(true);
    setIsSaveBtnDisabled(false);
  };

  const updateRow = () => {
    openedDetailsSapDTO.node.setRowSelectable(true);
    gridapi.redrawRows(openedDetailsSapDTO);
    gridapi.flashCells({ rowNodes: [openedDetailsSapDTO.node] });
  };

  const onCancel = () => {
    if (!openedDetailsSapDTO.data.standardRoles) {
      form.resetFields(["standardRole"]);
    } else {
      form.setFieldsValue({
        standardRole: openedDetailsSapDTO.data.standardRoles.map(
          (role) => role.standardRoleId
        ),
      });
    }

    const teamLeadObject = teamleads.find(
      (lead) => lead.uiNumber === openedDetailsSapDTO.data.uiNumberBoss
    );

    if (teamLeadObject) {
      form.setFieldsValue({ teamLead: teamLeadObject.employeeId });
    } else {
      form.resetFields(["teamLead"]);
    }

    setShowAddEmployeeModal(false);
  };

  const checkDisableStatus = (param) => {
    if (!param.temporaryAssignedEmployee) {
      return false;
    } else if (
      openedDetailsSapDTO.data.uiNumber +
        "_" +
        openedDetailsSapDTO.data.positionId !==
      param.temporaryAssignedEmployee
    ) {
      return true;
    }

    return false;
  };

  const isReassignedEmployeeSectionAvailable = () => {
    return (
      openedDetailsSapDTO?.data?.teammemberWithAssignedRequests?.length > 0 &&
      !isNextClicked
    );
  };

  return (
    <>
      {showRoleAssignmentWarningModal && (
        <ConfirmationModal
          action={processSave}
          modalVisible={showRoleAssignmentWarningModal}
          setModalVisible={setShowRoleAssignmentWarningModal}
          modalMessage={modalMessage}
        />
      )}
      <Modal
        width="60%"
        open={showAddEmployeeModal}
        centered
        data-testid="add-employee-modal"
        title={
          isReassignedEmployeeSectionAvailable()
            ? "Add employee additional data"
            : "Reassign employees with requests"
        }
        closable={false}
        bodyStyle={{ width: "100%" }}
        maskClosable={false}
        onCancel={() => setShowAddEmployeeModal(false)}
        footer={
          <>
            <Button
              data-testid="back-cancel-btn"
              onClick={() =>
                isNextClicked ? setIsNextClicked(false) : onCancel()
              }
            >
              {isNextClicked ? "Back" : "Cancel"}
            </Button>

            <Button
              disabled={isSaveBtnDisabled}
              type="primary"
              data-testid="next-save-btn"
              onClick={() =>
                isReassignedEmployeeSectionAvailable()
                  ? setIsNextClicked(true)
                  : onSave()
              }
            >
              {isReassignedEmployeeSectionAvailable() ? (
                <span>Next</span>
              ) : (
                <span>Save</span>
              )}
            </Button>
          </>
        }
      >
        {!isNextClicked && (
          <Form form={form} layout="vertical" size="large">
            {showApplicationRoleSelection && (
              <>
                <Form.Item
                  name="standardRole"
                  data-testid="standard-role-form-item"
                  className="form-item"
                  label="Add standard role as primary responsibilities"
                >
                  <Select
                    mode="multiple"
                    showSearch
                    data-testid="standardRoleSelection"
                    placeholder="Select Standard Role(s) as primary responsibilities"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={onChange}
                  >
                    {standardroles.map((standardRole) => {
                      return (
                        <Select.Option
                          key={standardRole.standardRoleId}
                          value={standardRole.standardRoleId}
                          disabled={checkDisableStatus(standardRole)}
                        >
                          {standardRole.standardRoleName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <br />
              </>
            )}

            <Form.Item name="teamLead" label="Team lead">
              <Select
                showSearch
                data-testid="teamlead-selection"
                placeholder="Please select a team lead"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={onChange}
              >
                {teamleads.map((lead) => {
                  return (
                    <Select.Option
                      key={lead.employeeId}
                      value={lead.employeeId}
                    >
                      {lead.fullName}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        )}
        {isNextClicked && (
          <ReassignTeamMemberWithRequests
            selectedStandardRoles={getStandardRoles()}
            openedDetailsSapDTO={openedDetailsSapDTO}
          />
        )}
      </Modal>
    </>
  );
}
