export const ROLE_ASSIGNMENT_ERROR_MESSAGES = {
  EMPTY: "should not be empty",
  LESS_THAN_2_CHARS: "should contain at least 2 chars",
  SPECIAL_CHARS: `Only special chars "& ( ) / -" are allowed`,
  ONLY_NUMERIC: "Only numeric values are not allowed",
  DUPLICATE: "entry already exists",
};

export enum RoleTypes {
  BASIC_ROLE = "basicRole",
  ROLE_SPECIFICATION = "roleSpecification",
  STANDARD_ROLE = "standardRole",
}

export enum StandardRoleAssignments {
  REQUESTS = "Requests",
  TIME_BLOCKER = "Time Blocker",
  POSITIONS = "Employees",
  DEPUTIES = "Deputies",
}
