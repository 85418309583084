import { REQUEST_STATUS, BADGE_VARIANTS } from "@src/constants";
import { userUtils } from "@src/utils/login_utils";
import dayjs from "dayjs";

export const showBadge = (primaryTeamLeadEmployeeId, status) => {
  return (
    !userUtils.getEmployeeData().isPrimary(primaryTeamLeadEmployeeId) ||
    status !== REQUEST_STATUS.IN_PROGRESS
  );
};

export const getBadgeVariant = (props) => {
  const shouldShowBadge = showBadge(
    props.data.primaryTeamLeadEmployeeId,
    props.data.status
  );
  if (!shouldShowBadge) return;
  if (props.data.outdated) return BADGE_VARIANTS.OUTDATED;
  if (props.data.isDeclined) return BADGE_VARIANTS.DECLINED;
  if (props.data.commentAvailable && !props.data.commentReadByEmployee)
    return BADGE_VARIANTS.COMMENT;
  if (props.data.isUpdated) return BADGE_VARIANTS.UPDATED;
};

export const getBadgeClass = (variant) => {
  switch (variant) {
    case BADGE_VARIANTS.OUTDATED:
      return "outdatedBadge";
    case BADGE_VARIANTS.COMMENT:
      return "commentBadge";
    case BADGE_VARIANTS.DECLINED:
    case BADGE_VARIANTS.UPDATED:
      return "notificationCount";
    default:
      return "";
  }
};

export const isDateInRange = (date, rangeStartDate, rangeEndDate) => {
  return dayjs(date).isBetween(rangeStartDate, rangeEndDate, "days", "[]");
};
