// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-area {
  display: flex;
  flex-direction: column;
}
.input-area textarea {
  width: 100%;
  resize: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/note/note_modal/note_modal.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAHA;EAKI,WAAA;EACA,YAAA;AACJ","sourcesContent":[".input-area {\n  display: flex;\n  flex-direction: column;\n\n  textarea {\n    width: 100%;\n    resize: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
