import React, { useCallback, useRef, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { createGridOptions } from "@src/features/role_assignment/utils/basic_roles_management_grid_options";
import {
  useAddBasicRoleMutation,
  useDeleteBasicRoleMutation,
  useGetBasicRolesQuery,
  useUpdateBasicRoleMutation,
} from "@src/services/slices/basicRolesApi";
import { BasicRole } from "@src/types";
import { columnTypes } from "@src/utils/aggrid_utils";
import {
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { App, Button } from "antd";
import _ from "lodash";

import { RoleTypes } from "../../utils/role_assignment_constants";
import { showSuccessMessage } from "../../utils/role_assignment_helper";
import { BasicRoleModal } from "../basic_role_modal";
import { RoleAssignmentFilter } from "../role_assignment_filter";

const BasicRoleManagement: React.FC = () => {
  const gridApi = useRef<GridApi>(null);

  const { message } = App.useApp();

  /* use states */
  const [showUpdateRoleModal, setShowUpdateRoleModal] =
    useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  /* rtk queries and mutations */
  const { data: basicRoles, isFetching } = useGetBasicRolesQuery();
  const [addBasicRole] = useAddBasicRoleMutation();
  const [deleteBasicRole] = useDeleteBasicRoleMutation();
  const [updateBasicRole] = useUpdateBasicRoleMutation();

  /* -------- AG Grid functions --------  */

  /**
   * AG Grid onGridReady function
   * assigns the GridApi to a ref and resizes the columns so that the table is displayed correctly
   * also enables the filter bar and add button when table is ready
   *
   * @param {GridReadyEvent} event AG Grid event
   */
  const onGridReady = useCallback((event: GridReadyEvent) => {
    gridApi.current = event.api;
    event.api.sizeColumnsToFit();
    setIsTableLoading(false);
  }, []);

  /* -------- click events --------  */

  /**
   * opens a modal where the user can add a new role
   */
  function onAddNewRow(): void {
    setShowUpdateRoleModal(true);
  }

  /**
   * makes a post request to create a new basic role
   *
   * @param {BasicRole} newBasicRole the basic role to create
   */
  function onSaveRoleModal(newBasicRole: BasicRole) {
    addBasicRole(newBasicRole)
      .unwrap()
      .then(() => {
        showSuccessMessage("created", message, RoleTypes.STANDARD_ROLE);
        setShowUpdateRoleModal(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  return (
    <>
      {showUpdateRoleModal && (
        <BasicRoleModal
          showModal={showUpdateRoleModal}
          setShowModal={setShowUpdateRoleModal}
          onSaveCallback={onSaveRoleModal}
        />
      )}
      <div className="top-section" data-testid="top-section">
        <RoleAssignmentFilter
          gridApi={gridApi.current}
          filterData={{
            basicRoles: basicRoles,
          }}
          disabled={isFetching || isTableLoading}
        />
        <Button
          onClick={onAddNewRow}
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          disabled={isFetching}
          data-testid="add-button"
        >
          Add Role
        </Button>
      </div>
      <div
        className="ag-theme-alpine header-white"
        data-testid="roles-management-table"
      >
        <AgGridReact
          rowData={_.cloneDeep(basicRoles)} //without clone deep props.data are not extensible with isEditActive or isNewRow
          gridOptions={createGridOptions(updateBasicRole, deleteBasicRole)}
          onGridReady={onGridReady}
          columnTypes={columnTypes}
          onGridSizeChanged={(event: GridSizeChangedEvent) =>
            event.api.sizeColumnsToFit()
          }
        />
      </div>
    </>
  );
};

export default BasicRoleManagement;
