import React from "react";

import { getBadgeClass } from "../../utils/request_overview_utils";

interface TagRendererProps {
  variant: string;
}

const TagRenderer: React.FC<TagRendererProps> = ({ variant = "" }) => {
  const className = getBadgeClass(variant);

  return (
    variant && (
      <span className={className} data-testid="tag-renderer">
        {variant}
      </span>
    )
  );
};

export default TagRenderer;
