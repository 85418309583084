import dayjs from "dayjs";

const dateFormat = "DD/MM/YYYY";

/**
 * get the initial values out of the given project for prefilling the form
 * @param {*} project data to prefill the form
 * @param {*} userFullName to prefill project manager if no project is given
 * @returns initial form values
 */
export const getInitialProjectValues = (project, userFullName) => {
  if (project) {
    return {
      projectManager: project.projectManager.fullName,
      editors: getProjectEditorNames(project.projectEditors),
      name: project.name,
      projectType: project.projectType,
      countryId: project.countryId,
      region: project?.regionName ? project.regionName : "no region selected",
      phase: project.projectPhase,
      projectDate: [dayjs(project.startDate), dayjs(project.endDate)],
      stageGateDate: dayjs(project.stageGateDate),
      jointVenture: !!project.jointVenturePartner,
      jointVenturePartner: project.jointVenturePartner,
      technologies: project.technologies,
    };
  }

  return {
    projectManager: userFullName,
    editors: [],
  };
};

/**
 * maps the form values to the payload data that are sent to the backend
 * @param {*} fieldsValue values of the form
 * @param {*} projectLeads all project leads (to calculate the ids of manager and editors)
 * @param {*} projectId id of the project
 * @returns
 */
export const getPayload = (fieldsValue, projectLeads, projectId) => {
  const startDate = fieldsValue["projectDate"]?.[0].format(dateFormat);
  const endDate = fieldsValue["projectDate"]?.[1].format(dateFormat);

  return {
    countryId: fieldsValue.countryId,
    name: fieldsValue.name,
    phase: fieldsValue.phase,
    projectType: fieldsValue.projectType,
    region: fieldsValue.region,
    projectManager: getProjectEditorsIds(
      [fieldsValue.projectManager],
      projectLeads
    )[0],
    editors: getProjectEditorsIds(fieldsValue.editors, projectLeads),
    startDate: startDate,
    endDate: endDate,
    stageGateDate: fieldsValue.stageGateDate.format(dateFormat),
    jointVenturePartner: fieldsValue.jointVenture
      ? fieldsValue.jointVenturePartner
      : null,
    technologies: fieldsValue.technologies || [],
    projectId: projectId,
  };
};

/**
 * maps the editor to their names
 * @param {*} editors list of employees
 * @returns list of names
 */
export const getProjectEditorNames = (editors) => {
  return editors.map((editor) => editor.fullName);
};

/**
 * checks if editors were updated
 * @param {*} changedValue new value
 * @param {*} initialValue old value
 * @returns true if values differ, false otherwise
 */
export const areEditorsUpdated = (changedValue, initialValue) => {
  if (
    changedValue &&
    initialValue &&
    changedValue.length === initialValue.length
  ) {
    return !changedValue.every((editor) => {
      return initialValue.includes(editor);
    });
  }
  return true;
};

/**
 * gets the first employeeId find in the list of project leads that has the given name
 * @param {*} fullNames list of full names
 * @param {*} projectLeads all project leads
 * @returns a set of employee ids
 */
// TODO: we need to look further into this because it could get the wrong id when
// having two employees with the same full name
export const getProjectEditorsIds = (fullNames, projectLeads) => {
  const employeeIds = new Set();
  fullNames.forEach((name) => {
    projectLeads?.forEach((projectLead) => {
      if (projectLead.fullName === name) {
        employeeIds.add(projectLead.employeeId);
      }
    });
  });
  return [...employeeIds];
};
