import { camelize } from "@src/utils/csv_parser_helper";
import moment from "moment";
import { sapDTOStatusEnum } from "@src/utils/helper";
import { ROLES } from "@src/constants";

/**
 * This function is used to map the SAP header to the DTO
 *
 * @param header
 * @returns {*|string}
 */
export const mapSAPHeader = (header) => {
  const formattedHeader = header.replace(/;/g, "");

  switch (formattedHeader) {
    case "Personalteilbereich (Beschreibung)":
      return "located";
    case "UI":
      return "uiNumber";
    case "UI No Boss":
      return "uiNumberBoss";
    case "RCD-No-Boss":
      return "rcdNumberBoss";
    case "PosID":
      return "positionId";
    case "OrgUnit":
      return "departmentCode";
    case "OrgUnit Text":
      return "departmentName";
    case "Firstname":
      return "firstName";
    case "Lastname":
      return "lastName";
    case "Fullname":
      return "fullName";
    case "PosText":
      return "positionText";
    case "Position end date":
      return "positionEndDate";
    case "Position start date":
      return "positionStartDate";
    case "Company":
      return "companyName";
    case "Availability":
      return "totalAvailablePercentage";
    case "UniqueID":
      return "rcdNumber";
    case "Mail":
      return "email";
    default:
      return camelize(header);
  }
};

/**
 * This method is used to read the data from the SAP CSV file
 *
 * @param data
 * @returns {*[]}
 */
export const readSAPData = (data) => {
  const headers = data[1].map(mapSAPHeader);
  const list = [];

  // start from the second row because the first row is the header
  for (
    let currentRowIndex = 2;
    currentRowIndex < data.length;
    currentRowIndex++
  ) {
    const currentRow = data[currentRowIndex];

    if (!headers || currentRow.length !== headers.length) continue;

    const obj = {};

    for (let j = 0; j < headers.length; j++) {
      const currentHeaderName = headers[j];
      let currentRowValue = currentRow[j];

      if (currentHeaderName) {
        obj[currentHeaderName] = currentRowValue.trim().replace(/;/g, "");

        switch (currentHeaderName) {
          case "positionEndDate":
          case "positionStartDate":
            currentRowValue = moment(
              moment(currentRowValue, "DD/MM/YYYY").format("YYYY-MM-DD")
            ).toDate();
            break;
          case "posId":
          case "totalAvailablePercentage":
            currentRowValue = parseInt(currentRowValue.trim());
            break;
          case "uiNumber":
          case "uiNumberBoss":
            currentRowValue = generateUINumber(currentRowValue);
            break;
        }
      }

      obj[currentHeaderName] = currentRowValue;

      //parse email and make it lower case because sometimes there are lower case emails and upper case
      //this might lead to confusing update results
      generateEmail(currentRowValue, currentHeaderName, obj);
    }

    // remove the blank rows
    if (Object.values(obj).filter((x) => x).length > 0) {
      list.push(obj);
    }
  }

  return list;
};

/**
 * This method will generate UI number. It will check if the current row value contains ENERGY and split it to get the UI number
 *
 * @param currentRowValue
 * @returns {*|string}
 */
const generateUINumber = (currentRowValue) => {
  //generate UI number
  if (currentRowValue.includes("ENERGY")) {
    return currentRowValue.substring(7, currentRowValue.length);
  }

  return currentRowValue;
};

/**
 * This method will parse the email and make it lower case because sometimes there are lower case emails and upper case
 *
 * @param currentRowValue
 * @param currentHeaderName
 * @param obj
 */
const generateEmail = (currentRowValue, currentHeaderName, obj) => {
  //parse email and make it lower case because sometimes there are lower case emails and upper case
  //this might lead to confusing update results
  if (currentHeaderName && currentHeaderName === "email") {
    obj[currentHeaderName] = currentRowValue
      .trim()
      .toLowerCase()
      .replace(/;/g, "");
  }
};

/**
 * This method will check if the employee selection is enabled to prevent from selecting
 * employees which are not allowed to be selected because of invalid data
 *
 * @param params
 * @returns {boolean}
 */
export const isRowSelectable = (params) => {
  //should disable row when the employee has no manager
  if (
    params.data.uiNumberBoss === "NO_MANAGER" &&
    params.data.rcdNumberBoss === "NO_MANAGER"
  ) {
    return false;
  }

  //disable row selection when a row has the status no change because there is nothing to update
  if (
    params.data.status === sapDTOStatusEnum.NOCHANGE ||
    params.data.status === sapDTOStatusEnum.ERROR
  )
    return false;

  if (params.data.status === sapDTOStatusEnum.DELETED) {
    //disable row selection for project leads with projects which are flagged for deletion
    //the admin will be forced to select a substitute for all project leads projects
    if (params.data.projectDtoList && params.data.projectDtoList.length !== 0)
      return false;

    //disable row selection for deletion when a team lead has a team or a role assigned
    if (params.data.hasEmployeeRolesAssigned || params.data.hasTeamleadTeam)
      return false;
  }

  //disable row selection when the team lead of the employee is not in dualis.
  //this will force the admin to add first the team lead and then the role selection will be enabled
  if (params.data.teamLeadNotInDualisDB || params.data.teamLeadNotInSAPFile)
    return false;

  //will disable the selection when an employee is team lead and is new.
  //The admin will be forced to assign him new role before adding the new employee
  return !(
    params.data.isTeamLead && params.data.status === sapDTOStatusEnum.ADDED
  );
};

/**
 * This method will update all necessary fields for vacancies
 *
 * @param sapEmployee
 * @returns sapEmployee
 */
export const updateVacancies = (sapEmployee) => {
  const isEmployeeAVacancy =
    sapEmployee.firstName === "" && sapEmployee.lastName === "";

  if (isEmployeeAVacancy) {
    const vacancyGeneratedId = "Vac_" + sapEmployee.positionId;

    sapEmployee.firstName = sapEmployee.positionId;
    sapEmployee.lastName = "Vacancy";
    sapEmployee.fullName = "Vacancy, " + sapEmployee.positionId;
    sapEmployee.uiNumber = vacancyGeneratedId;
    sapEmployee.rcdNumber = vacancyGeneratedId;
  }

  return sapEmployee;
};

/**
 * This method will update the team lead information for the given sap employee
 *
 * @param sapEmployees
 * @param sapEmployee
 * @returns sapEmployee
 */
export const updateTeamLeadInformation = (sapEmployees, sapEmployee) => {
  const bosEntry = sapEmployees.find(
    (employee) => employee.uiNumber === sapEmployee.uiNumberBoss
  );

  if (bosEntry) {
    sapEmployee.teamLeadFullname = bosEntry.fullName;
    sapEmployee.teamLeadOrgCode = bosEntry.departmentCode;
  } else {
    sapEmployee.teamLeadNotInSAPFile = true;
  }

  return sapEmployee;
};

/**
 * This method should flag all employees of a team lead as error if the team lead was not found in the SAP file
 *
 * @param sapEmployees
 * @param sapEmployee
 */
export const flagEmployeesOfTeamLeadAsErorror = (sapEmployees, sapEmployee) => {
  //  if the team lead of a team lead was not found in the SAP file and the team lead has a team than
  // the team should also be flagged as error
  sapEmployees
    .filter((employee) => employee.uiNumberBoss === sapEmployee.uiNumber)
    .map((employee) => {
      const teamLeadFlaggedAsError = "Team lead was flagged as error";

      if (!employee.errorMessage) {
        employee.errorMessage = [];
      }

      if (!employee.errorMessage.includes(teamLeadFlaggedAsError)) {
        employee.errorMessage.push(teamLeadFlaggedAsError);
      }

      employee.status = sapDTOStatusEnum.ERROR;
    });
};

/**
 * If team lead has team assigned than the selection for deletion should be disabled as long as the team was removed
 *
 * @param sapEmployees
 * @param sapEmployee
 */
export const checkIfTeamLeadHasTeam = async (sapEmployees) => {
  const teamLeads = sapEmployees.filter((employee) =>
    employee?.applicationRoles.some(
      (role) => role.applicationRoleName === ROLES.TEAM_LEAD
    )
  );

  if (teamLeads.length === 0) return;

  teamLeads.map((teamLead) => {
    if (
      sapEmployees.some(
        (employee) => employee.uiNumberBoss === teamLead.uiNumber
      )
    ) {
      teamLead.hasTeamleadTeam = true;
    }
  });
};

/**
 * This method will update the application roles for the given sap employee
 *
 * @param sapEmployee
 * @param sapEmployeeList
 * @param projectLeads
 * @returns {*}
 */
export const updateApplicationRoles = (
  sapEmployee,
  sapEmployeeList,
  projectLeads
) => {
  // every employee has the role team member
  sapEmployee.applicationRoles = [
    {
      applicationRoleId: 4,
      applicationRoleName: ROLES.TEAM_MEMBER,
    },
  ];

  // check if the employee is a project lead and add the role
  const isEmployeeProjectManager = projectLeads.find(
    (projectLead) => projectLead.uiNumber === sapEmployee.uiNumber
  );

  if (isEmployeeProjectManager) {
    sapEmployee.applicationRoles.push({
      applicationRoleId: 2,
      applicationRoleName: ROLES.PROJECT_LEAD,
    });
  }

  // check if the employee is a team lead and add the role
  const isEmployeeTeamLead = sapEmployeeList.some(
    (employee) => sapEmployee.uiNumber === employee.uiNumberBoss
  );

  if (isEmployeeTeamLead) {
    sapEmployee.isTeamLead = true;
    sapEmployee.applicationRoles.push({
      applicationRoleId: 3,
      applicationRoleName: ROLES.TEAM_LEAD,
    });
  } else {
    sapEmployee.isTeamLead = false;
  }

  return sapEmployee;
};

/**
 * This method will flag the given sap employee as error if the employee entry is duplicated in the sap file
 *
 * @param count
 * @param sapCsvDataEntry
 * @param sapEmployees
 * @returns {{isTeamLead}|*}
 */
export const flagDuplicated = (count, sapCsvDataEntry, sapEmployees) => {
  if (count[sapCsvDataEntry.uiNumber] > 1) {
    sapCsvDataEntry.status = sapDTOStatusEnum.ERROR;
    sapCsvDataEntry.errorMessage.push("Duplicate entry");

    if (sapCsvDataEntry.isTeamLead) {
      flagEmployeesOfTeamLeadAsErorror(sapEmployees, sapCsvDataEntry);
    }
  }

  return sapCsvDataEntry;
};

/**
 * This method will update the total for updated, added, deleted, unchanged and errors
 *
 * @param parseResultToShowInTable
 * @param setUploadStatics
 * @param initialStatistics
 */
export const updateStatistic = (
  parseResultToShowInTable,
  setUploadStatics,
  initialStatistics
) => {
  const aggregatedStats = parseResultToShowInTable.reduce((acc, result) => {
    switch (result.status) {
      case sapDTOStatusEnum.ADDED:
        acc.totalAdded += 1;
        break;
      case sapDTOStatusEnum.UPDATED:
        acc.totalUpdated += 1;
        break;
      case sapDTOStatusEnum.ERROR:
        acc.totalErrors += 1;
        break;
      case sapDTOStatusEnum.DELETED:
        acc.totalDeleted += 1;
        break;
      case sapDTOStatusEnum.NOCHANGE:
        acc.totalUnchanged += 1;
        break;
      default:
        break;
    }
    return acc;
  }, initialStatistics);

  setUploadStatics(() => ({
    totalAdded: aggregatedStats.totalAdded,
    totalUpdated: aggregatedStats.totalUpdated,
    totalErrors: aggregatedStats.totalErrors,
    totalDeleted: aggregatedStats.totalDeleted,
    totalUnchanged: aggregatedStats.totalUnchanged,
  }));
};
