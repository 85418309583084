import React from "react";
import { Input, Tooltip } from "antd";
import "./simple_editor.css";
import * as constants from "../../../../constants";

const SimpleEditor = (params) => {
  const rowInEdit = params.colDef.cellRendererParams?.requestInEdit;
  const projectRoleRequestId = params.data.projectRoleRequestId;

  const handleUpdate = (event, params) => {
    if (!rowInEdit) {
      const field = params.colDef.field;
      params.node.setDataValue(field, event.target.value);
    } else {
      const field = params.colDef.field;
      params.node.data[field] = event.target.value;
    }
  };

  let placeholder = "";
  switch (params.colDef.field) {
    case "specificRole":
      placeholder = "Type specific role";
      break;
    case "projectIdentifier":
      placeholder = "Type project identifier";
      break;
    case "scope":
      placeholder = "Describe scope";
      break;
    default:
  }

  const inputBox = (
    <Input
      className={`ag-input-field-input ag-text-field-input ${
        params.node.error && params.node.error[params.colDef.field]
          ? "ag-input-error"
          : ""
      }`}
      data-testid={params.colDef.field}
      placeholder={placeholder}
      disabled={
        rowInEdit
          ? rowInEdit !== projectRoleRequestId
          : params.node.data.status !== constants.REQUEST_STATUS.DRAFT ||
            !params.node.data.genericRoleId
      }
      onBlur={(event) => {
        handleUpdate(event, params);
      }}
      defaultValue={params.value}
    />
  );

  const textPlaceholder = <div>{params.value}</div>;

  return (
    <Tooltip
      title={
        params.node.data.genericRoleId
          ? params.value
          : "Please select standard role first"
      }
    >
      <div>
        {rowInEdit === undefined || rowInEdit === projectRoleRequestId
          ? inputBox
          : textPlaceholder}
      </div>
    </Tooltip>
  );
};

export default SimpleEditor;
