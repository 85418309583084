import React from "react";
import { DownOutlined } from "@ant-design/icons";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";

function ActionButtonRenderer({
  rowData,
  onTableRowExpand,
  expandedKeys,
  showTimeBlockerNotInEditButtons,
  showTimeBlockerInEditButtons,
}) {
  const expandIcons = (
    <a onClick={() => onTableRowExpand(rowData)} data-testid="expand-icon">
      <span className="icons">
        {expandedKeys.includes(rowData.employeeId) ? (
          <DownOutlined data-testid="down-arrow-icon" />
        ) : (
          <RightOutlined data-testid="right-arrow-icon" />
        )}
      </span>
    </a>
  );

  if (rowData?.employeeId) {
    return expandIcons;
  }

  if (rowData?.projectName === "Time Blocker" && !rowData?.isInEdit) {
    return showTimeBlockerNotInEditButtons(rowData);
  }

  if (rowData?.isInEdit) {
    return showTimeBlockerInEditButtons(rowData);
  }

  return null;
}

export default ActionButtonRenderer;
