import { ReactNode, useEffect } from "react";

import {
  InteractionStatus,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { msalInstance } from "@src/auth";
import { Loader } from "@src/loader";

type Props = {
  children: ReactNode;
};

// (Refactor) Should be replaced with your loading indicator of choice
const LoadingComponent = () => <Loader />;
const ErrorComponent = () => <p>Failed to authenticate...</p>;

export const ValidateAuth = ({ children }: Props) => {
  const { accounts, inProgress } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((e: EventMessage) => {
      if (e.eventType === EventType.LOGIN_SUCCESS && e.payload) {
        const payload = e.payload as AuthenticationResult;
        localStorage.setItem("token", payload.accessToken);
        const account = payload.account;
        msalInstance.setActiveAccount(account);
      }
    });
  }, [accounts]);

  const inProgressStates = [
    InteractionStatus.Startup,
    InteractionStatus.Login,
    InteractionStatus.HandleRedirect,
  ];

  if (inProgressStates.includes(inProgress)) {
    return <Loader />;
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      loadingComponent={LoadingComponent}
      errorComponent={ErrorComponent}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};
