import React from "react";
import { Tooltip } from "antd";

export const freeTextRenderer = (params) => {
  return (
    <Tooltip placement="topLeft" title={params.value}>
      <p>{params.value}</p>
    </Tooltip>
  );
};

export default freeTextRenderer;
