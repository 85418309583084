import React from "react";

import "./logo.less";
import logo from "@src/assets/dualis_logo.svg";

const Logo: React.FC = () => {
  return (
    <img
      src={logo}
      alt={"dualis-logo"}
      className={"logo"}
      data-testid={"logo-img"}
    />
  );
};

export default Logo;
