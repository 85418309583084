import React from "react";
import { Button } from "antd";
import "./actions_renderer.less";
import _ from "lodash";
import { ICellRendererParams } from "ag-grid-community";

interface ActionsRendererProps {
  props: ICellRendererParams;
  onDelete?: (props: ICellRendererParams) => void;
  onSave: (props: ICellRendererParams) => void;
  onEdit: (props: ICellRendererParams) => void;
  onCancel: (props: ICellRendererParams) => void;
}

const ActionsRenderer: React.FC<ActionsRendererProps> = ({
  props,
  onDelete,
  onSave,
  onEdit,
  onCancel,
}) => {
  return (
    <>
      {props?.data?.isEditActive ? (
        <Button
          type="primary"
          onClick={() => onSave(props)}
          className="action-renderer-button"
          data-testid="save-button"
          disabled={_.isEmpty(props.data.updatedData)}
        >
          Save
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={() => onEdit(props)}
          className="action-renderer-button"
          data-testid="edit-button"
        >
          Edit
        </Button>
      )}

      <Button
        onClick={() => onCancel(props)}
        disabled={!props.data.isEditActive}
        className={`action-renderer-button ${
          props.data.newRow ? "hidden" : ""
        }`}
        data-testid="cancel-button"
      >
        Cancel
      </Button>

      {onDelete && (
        <Button
          type="primary"
          danger
          onClick={() => onDelete(props)}
          className="action-renderer-button"
          disabled={!props.data.isEditActive}
          data-testid="delete-button"
        >
          Delete
        </Button>
      )}
    </>
  );
};

export default ActionsRenderer;
