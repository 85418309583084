import React, { useState, useEffect } from "react";
import { months } from "../../constants";
import * as constants from "../../constants";
import "./allocation_details_table.less";
import { generateMonthColumns } from "../../utils/generate_month_columns_utils";
import { Table } from "antd";
import _ from "lodash";

const AllocationDetailsTable = ({ requestData }) => {
  const [allocations, setAllocations] = useState({
    allocations: [],
    proposedAllocation: {},
  });

  const [yearColumns, setYearColumns] = useState({
    requestedYearlyColumns: [],
  });

  useEffect(() => {
    if (requestData.status) {
      let startYear, startMonth, firstMonth;
      const currentDate = new Date();

      startYear = currentDate.getFullYear();
      startMonth = currentDate.getMonth() + 1;
      firstMonth = startYear + "_" + months[startMonth - 1];

      let allocationsRowData = [
        { [firstMonth]: "Requested  workload" },
        transformAllocations()[0],
      ];

      let isOtherAllocationProposed = false;

      if (!_.isEmpty(requestData.proposedYearlyAllocations)) {
        isOtherAllocationProposed = !_.isEqual(
          requestData.yearlyAllocations,
          requestData.proposedYearlyAllocations
        );
      }

      if (
        (requestData.status === constants.REQUEST_STATUS.PROPOSED ||
          requestData.status === constants.REQUEST_STATUS.DECLINED) &&
        isOtherAllocationProposed
      ) {
        allocationsRowData.push({ [firstMonth]: "Proposed  workload" });
        allocationsRowData.push(transformProposedAllocations()[0]);
      }

      setAllocations({ ...allocations, allocations: allocationsRowData });

      setYearColumns({
        ...yearColumns,
        requestedYearlyColumns: generateMonthColumns(requestData),
      });
    }
  }, [requestData]);

  const transformAllocations = () => {
    const record = {};

    Object.keys(requestData.yearlyAllocations).forEach((year) => {
      Object.keys(requestData.yearlyAllocations[year]).forEach((month) => {
        const monthName = months[month - 1];
        record[`${year}_${monthName}`] =
          requestData.yearlyAllocations[year][month];
      });
    });
    return [record];
  };

  const transformProposedAllocations = () => {
    const record = {};

    Object.keys(requestData.yearlyAllocations).forEach((year) => {
      Object.keys(requestData.yearlyAllocations[year]).forEach((month) => {
        const monthName = months[month - 1];
        if (
          requestData["proposedYearlyAllocations"][year] &&
          requestData["proposedYearlyAllocations"][year][month] >= 0
        ) {
          record[`${year}_${monthName}`] =
            requestData["proposedYearlyAllocations"][year][month];
        } else {
          record[`${year}_${monthName}`] =
            requestData.yearlyAllocations[year][month];
        }
      });
    });

    return [record];
  };

  return (
    <div className="allocationTable">
      <Table
        bordered
        dataSource={allocations.allocations}
        columns={yearColumns.requestedYearlyColumns}
        pagination={false}
        scroll={{ x: "max-content", y: "100%" }}
        size="small"
      />
    </div>
  );
};

export default AllocationDetailsTable;
