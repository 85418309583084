import { message } from "antd";
import { createFetchBaseQuery } from "./baseQuery";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Department } from "@src/types";

const getEndpoints = (builder: EndpointBuilder<any, any, any>) => {
  return {
    /**
     * Get departments
     */
    getN1Departments: builder.query<Department[], void>({
      query: () => "?departmentLevel=N1",
      transformErrorResponse: async () => {
        message.error("Unable to load N-1 departments", 5);
      },
    }),

    getDepartments: builder.query<Department[], void>({
      query: () => "",
      transformErrorResponse: async () => {
        message.error("Failed to get department list", 5);
      },
    }),
  };
};

export const departmentsSlice = createApi({
  reducerPath: "departmentsSlice",
  baseQuery: createFetchBaseQuery("/departments"),
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),
  }),
});

export const {
  /* GET endpoints */
  useGetN1DepartmentsQuery,
  useGetDepartmentsQuery,
  useLazyGetDepartmentsQuery,
} = departmentsSlice;
