import {
  columnTypes,
  paginationGridOptions,
  preventPinnedColumnJumpingGridOption,
} from "@src/utils/aggrid_utils";
import {
  ColDef,
  FillOperationParams,
  GetRowIdParams,
  GridOptions,
} from "ag-grid-community";

import { defaultFTEColDefs } from "./resource_planner_utils";

export function getGridOptions(columnsDefs: ColDef[]): GridOptions {
  return {
    ...paginationGridOptions,
    ...preventPinnedColumnJumpingGridOption,
    enableRangeSelection: true,
    enableFillHandle: true,
    rowSelection: "multiple",
    rowHeight: 60,
    columnDefs: [...columnsDefs],
    suppressRowClickSelection: true,
    suppressCellFocus: false,
    suppressContextMenu: true,
    domLayout: "autoHeight",
    alwaysAggregateAtRootLevel: true,
    defaultColDef: {
      ...columnTypes.default,
      ...defaultFTEColDefs,
      flex: 1,
      autoHeaderHeight: true,
      resizable: false,
    },

    getRowId: (params: GetRowIdParams) => {
      return params.rowPinned ? 0 : params.data.projectRoleRequestId;
    },
    fillOperation: (params: FillOperationParams) => {
      const isNonAllocationCell = isNaN(
        // @ts-ignore
        parseInt(params.column.colId.substring(0, 4))
      );
      if (isNonAllocationCell && params.direction === "left") {
        return params.currentCellValue;
      }
      return params.values[params.values.length - 1];
    },
  };
}
