import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Area } from "@src/types";
import { message } from "antd";

import { baseApiSlice } from "./baseApiSlice";

export const areasApi = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    /**
     * Get all areas
     */
    getAreas: builder.query<Area[], void>({
      query: () => "areas",
      transformErrorResponse: async (): Promise<void> => {
        message.error("Unable to load areas", 5);
      },
    }),
  };
}

export const {
  /* GET endpoints */
  useGetAreasQuery,
} = areasApi;
