import React from "react";

import "./note_modal.less";
import { ModalMsg } from "@src/types";
import { Modal } from "antd";

import { NoteForm } from "../note_form";

interface NoteModalProps {
  action: () => void;
  setModalVisible: (value: boolean) => void;
  modalVisible: boolean;
  requestId: number;
  modalMsg: ModalMsg;
  isProjectLead: boolean;
  isNoteMandatory: boolean;
}

export const NoteModal: React.FC<NoteModalProps> = ({
  action,
  setModalVisible,
  modalVisible,
  requestId,
  modalMsg,
  isProjectLead,
  isNoteMandatory,
}) => {
  async function handleOk(): Promise<void> {
    setModalVisible(false);
    action();
  }

  return (
    <Modal
      width="30%"
      open={modalVisible}
      centered
      maskClosable={false}
      closable={false}
      bodyStyle={{ width: "100%" }}
      onOk={handleOk}
      onCancel={() => setModalVisible(false)}
      title={modalMsg?.title}
      footer=""
    >
      <div className="input-area">
        {modalMsg?.msg && <p data-testid="modal-msg">{modalMsg?.msg}</p>}
        <p data-testid="leave-comment-for-msg">
          {/*if project lead declines request than the string 'Leave a comment for the team lead' should be shown.
          The project lead is leaving a comment for the team lead and vice versa*/}
          Leave a comment for the {isProjectLead ? "team lead" : "project lead"}{" "}
          {modalMsg?.leaveCommentFor && <b>{modalMsg.leaveCommentFor}</b>}.
        </p>
        <NoteForm
          requestId={requestId}
          action={handleOk}
          setModalVisible={setModalVisible}
          modalMsg={modalMsg}
          isActionButtonDisabled={false}
          isNoteMandatory={isNoteMandatory}
        />
      </div>
    </Modal>
  );
};

export default NoteModal;
