import React from "react";
import { REQUEST_STATUS as RequestStatus } from "@src/constants";
import { OutdatedBadge } from "@src/components/outdated_badge";

const requestRepliesStatusRenderer = (params) => {
  const statusColors = {
    Proposed: "red",
    Confirmed: "green",
  };

  if (!params?.data.seen && params?.data["isOutdated"]) {
    return <OutdatedBadge daysUntilDeletion={params.data.daysUntilDeletion} />;
  }

  if (
    !params?.data.seen &&
    (params?.data["isProposalWithdrawn"] || params?.data["isEmployeeDeleted"])
  ) {
    return <span className={"notificationCount request-update"}>Updated</span>;
  }

  if (params?.data?.status === RequestStatus.DECLINED) {
    return <span className={"redBadge request-update"}>Declined</span>;
  }

  return (
    <span className={`status status-${statusColors[params.getValue()] || ""}`}>
      <b>{params.getValue()}</b>
    </span>
  );
};

export default requestRepliesStatusRenderer;
