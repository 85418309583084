import { create as axiosCreate } from "axios";
import { EventTarget } from "event-target-shim";
import { message } from "antd";
import Cookies from "universal-cookie";

const {
  NODE_ENV,
  REACT_APP_SERVER_URL,
  REACT_APP_SSO_ENABLE,
  REACT_APP_BEARER,
} = process.env;

const cookies = new Cookies();

const axiosApi = axiosCreate({
  baseURL:
    NODE_ENV === "development"
      ? REACT_APP_SERVER_URL + "api"
      : window.location.protocol + "//" + window.location.host + "/api",
});

const axiosApiRef = {
  get: axiosApi.get,
  post: axiosApi.post,
  put: axiosApi.put,
  delete: axiosApi.delete,
};

export const ApiErrorEventTarget = new EventTarget();

export const apiErrorHandler = (e, errorMessage, message) => {
  console.error(e, errorMessage);
  if (
    e &&
    e.response &&
    e.response.data &&
    e.response.data.reasons &&
    Object.keys(e.response.data.reasons).includes("EMPLOYEE_NOT_FOUND")
  ) {
    errorMessage = errorMessage + " (Employee not found in Dualis)";
  }
  ApiErrorEventTarget.dispatchEvent(
    new CustomEvent("apiError", {
      detail: {
        error: e,
        errorMessage: errorMessage,
      },
    })
  );
};

for (const method of Object.keys(axiosApiRef)) {
  axiosApi[method] = (url, config, errorMessage) => {
    const run = () => axiosApiRef[method](url, config, errorMessage);
    const runRequest = run();
    return runRequest.catch((e) => apiErrorHandler(e, errorMessage));
  };
}

axiosApi.interceptors.request.use(
  (config) => {
    return new Promise((resolve, reject) => {
      if (REACT_APP_SSO_ENABLE === "true") {
        const token = localStorage.getItem("token");

        if (token) {
          config.headers.Authorization = "Bearer " + token;
          config.headers.HTTP2_HEADER_ACCESS_CONTROL_ALLOW_ORIGIN = "*";

          const impersonationUser = cookies.get("ImpersonationUser", {
            path: "/",
          });

          if (impersonationUser) {
            config.headers.ImpersonationUser = impersonationUser;
          } else {
            const empObj = cookies.get("loggedInuser");
            if (empObj) {
              cookies.set("ImpersonationUser", empObj.employeeId, {
                path: "/",
              });
              config.headers.ImpersonationUser = impersonationUser;
            }
          }

          resolve(config);
        } else {
          message.info("Session has Expired!! Recovering the session.");
        }
      } else {
        const impersonationUser = cookies.get("ImpersonationUser", {
          path: "/",
        });
        /* istanbul ignore else*/
        if (impersonationUser) {
          config.headers.HTTP2_HEADER_ACCESS_CONTROL_ALLOW_ORIGIN = "*";
          config.headers.ImpersonationUser = impersonationUser;
        }

        config.headers.authorization = REACT_APP_BEARER;
        resolve(config);
      }
    });
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      (error.response && 401 === error.response.status) ||
      (error.response && 403 === error.response.status) ||
      (error.response && 500 === error.response.status)
    ) {
      return error.response;
    } else {
      return Promise.reject(error);
    }
  }
);

ApiErrorEventTarget.addEventListener("apiError", (errorDetail) => {
  message.error(errorDetail.detail.errorMessage);
});

export default axiosApi;
