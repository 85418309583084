// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-rows-info {
  visibility: visible;
}
.selected-rows-info-hidden {
  visibility: hidden;
}
.result-label {
  margin-top: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.upload-result-section {
  margin-top: 3em;
}
.upload-result-section .info-section-with-btn {
  display: flex;
  margin-bottom: 2em;
  justify-content: space-between;
  align-items: flex-end;
}
.upload-result-section .info-section-with-btn .btn-section button {
  margin-left: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/features/sap_upload/utils/csv_parse_table_column.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAGA;EACE,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AADF;AAIA;EACE,eAAA;AAFF;AACA;EAII,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,qBAAA;AAFJ;AALA;EAWQ,gBAAA;AAHR","sourcesContent":[".selected-rows-info {\n  visibility: visible;\n\n  &-hidden {\n    visibility: hidden;\n  }\n}\n\n.result-label {\n  margin-top: 40px;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 28px;\n}\n\n.upload-result-section {\n  margin-top: 3em;\n\n  .info-section-with-btn {\n    display: flex;\n    margin-bottom: 2em;\n    justify-content: space-between;\n    align-items: flex-end;\n\n    .btn-section {\n      button {\n        margin-left: 1em;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
