import { PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: "6641510d-13d8-428f-a8a1-43087d272b58",
    authority: `https://login.microsoftonline.com/f481d56f-c63d-4d74-8406-d64e74f44395`,
    postLogoutRedirectUri: null,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
    secureCookies: true,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
};
