// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-custom-card {
  border-radius: 8px;
}
.simple-custom-card.ant-card-bordered {
  border: 0;
}
.simple-custom-card .ant-card-head {
  padding: 0;
  border-bottom: 0;
  border-radius: 8px;
  height: 60px;
}
.simple-custom-card .ant-card-head-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0 0 0;
}
.simple-custom-card .ant-card-body {
  padding: 10px;
  display: flex;
  justify-content: center;
  height: 110px;
}
.simple-custom-card__label {
  font-size: 54px;
}
.simple-custom-card--require-action {
  margin-bottom: 32px;
  background: #e8ecf1;
  color: #3b3d42;
}
.simple-custom-card--require-action .ant-card-head {
  background: #e8ecf1;
}
.simple-custom-card--decline {
  background: #f7f7f7;
  color: #c54c5d;
}
.simple-custom-card--decline .ant-card-head {
  background: #f7f7f7;
}
`, "",{"version":3,"sources":["webpack://./src/components/simple_custom_card/simple_custom_card.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAGE;EACE,SAAA;AADJ;AAKI;EACE,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AAHN;AAKM;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AAHR;AAOI;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;AALN;AASE;EACE,eAAA;AAPJ;AAUE;EACE,mBAAA;EAUF,mBAAA;EACA,cAAA;AAjBF;AAKE;EAcE,mBAAA;AAhBJ;AAOE;EAMA,mBAAA;EACA,cAAA;AAVF;AAGE;EASE,mBAAA;AATJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.simple-custom-card {\n  border-radius: 8px;\n\n  &.ant-card-bordered {\n    border: 0;\n  }\n\n  .ant-card {\n    &-head {\n      padding: 0;\n      border-bottom: 0;\n      border-radius: 8px;\n      height: 60px;\n\n      &-title {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        padding: 24px 0 0 0;\n      }\n    }\n\n    &-body {\n      padding: 10px;\n      display: flex;\n      justify-content: center;\n      height: 110px;\n    }\n  }\n\n  &__label {\n    font-size: 54px;\n  }\n\n  &--require-action {\n    margin-bottom: 32px;\n    .card-style-mixin(@brand-10, @font-00);\n  }\n\n  &--decline {\n    .card-style-mixin(@light-grey-10, @dark-red-80);\n  }\n}\n\n.card-style-mixin(@bgColor, @textColor) {\n  background: @bgColor;\n  color: @textColor;\n  .ant-card-head {\n    background: @bgColor;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
