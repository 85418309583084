import React from "react";

import "./help_info.less";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";

const HelpInfo = () => {
  const text = <span>Where can I get help?</span>;
  const content = (
    <div>
      If you need help, support or have any questions, please send an email to:{" "}
      <b>dualis@rwe.com</b>
    </div>
  );
  return (
    <div className="question-icon" data-testid="question-icon-div">
      <Popover
        data-testid="popover"
        placement="bottomRight"
        title={text}
        content={content}
        trigger="click"
        overlayClassName="question-icon__popover"
      >
        <QuestionCircleOutlined />
      </Popover>
    </div>
  );
};

export default HelpInfo;
