import type { Faro } from "@grafana/faro-react";
import {
  initializeFaro as coreInit,
  getWebInstrumentations,
} from "@grafana/faro-react";
import {
  TracingInstrumentation,
  getDefaultOTELInstrumentations,
} from "@grafana/faro-web-tracing";

export const initializeFaro = (): Faro | undefined => {
  const { REACT_APP_ENVIRONMENT, REACT_APP_GRAFANA_AGENT_API_KEY } =
    process.env;
  if (REACT_APP_ENVIRONMENT === "local") {
    return;
  }

  const faro = coreInit({
    url: "/collect",
    apiKey: REACT_APP_GRAFANA_AGENT_API_KEY,
    batching: {
      enabled: true,
      sendTimeout: 30000, // 30 seconds
    },
    instrumentations: [
      ...getWebInstrumentations({
        captureConsole: true,
      }),
      new TracingInstrumentation({
        instrumentations: [...getDefaultOTELInstrumentations()],
      }),
    ],
    sessionTracking: {
      enabled: true,
      session: (window as any).__PRELOADED_STATE__?.faro?.session,
    },
    app: {
      name: "dualis-frontend-" + REACT_APP_ENVIRONMENT,
      version: "0.0.1",
      environment: REACT_APP_ENVIRONMENT,
    },
  });

  faro.api.pushLog(["Faro was initialized"]);

  return faro;
};
