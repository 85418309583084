const addToggleClassToTable = ({ target }) => {
  const cell = target.parentNode.parentNode.parentNode.parentNode;
  cell.classList.toggle("ant-table-selection-column-custom-bg");
};

export const connectTableSelectors = () => {
  setTimeout(() => {
    const tableSelections = document.querySelectorAll(
      "th.ant-table-selection-column .ant-checkbox-input"
    );
    tableSelections.forEach((tS) =>
      tS.addEventListener("click", addToggleClassToTable)
    );
  }, 2000);
};
