import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { EndpointBuilder } from "@reduxjs/toolkit/src/query/endpointDefinitions";
import { DashboardFilterPayload, DashboardStatistics } from "@src/types";
import { message } from "antd";

import { createFetchBaseQuery } from "./baseQuery";
import { DashboardFilterQueryParam } from "@src/features/dashboard/utils/dashboard_constants";

const getEndpoints = (builder: EndpointBuilder<any, any, any>) => {
  return {
    /**
     * Get managers dashboard statistics
     */
    getManagersDashboardStatistics: builder.query<
      DashboardStatistics,
      DashboardFilterPayload
    >({
      query: ({ managerId, monthRange, ...filterArgs }) => {
        const filterTypes = Object.keys(filterArgs);
        const queryParams = { monthRange: monthRange };

        for (const filterType of filterTypes) {
          let filterValues = filterArgs[filterType];

          if (filterValues?.length > 0) {
            // it is necessary to search in the filter for commas and replace them by another sign because
            // the backend will interpret it as a separator and the value will be cut into 2 values
            filterValues = filterValues
              .map((value: string) => value.replace(/,/g, ";|"))
              .toString();
          }

          const queryParamKey = DashboardFilterQueryParam[filterType];
          queryParams[queryParamKey] = filterValues;
        }
        return {
          url: `/managers/${managerId}/dashboard`,
          params: queryParams,
        };
      },
      transformErrorResponse: async () => {
        message.error("Error loading the dashboard statistics", 5);
      },
    }),
  };
};

export const managersSlice = createApi({
  reducerPath: "managerSlice",
  baseQuery: createFetchBaseQuery(""),
  refetchOnMountOrArgChange: 1,
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),
  }),
});

export const {
  /* GET endpoints */
  useGetManagersDashboardStatisticsQuery,
} = managersSlice;
