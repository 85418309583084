// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-info {
  margin-bottom: 10px;
}
.info-text {
  font-family: "RWESansWeb-Medium", sans-serif;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/change_team_lead_after_role_change_modal/change_team_lead_after_role_change_modal.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEA;EACE,4CAAA;EACA,mBAAA;AAAF","sourcesContent":[".selected-info {\n  margin-bottom: 10px;\n}\n\n.info-text {\n  font-family: \"RWESansWeb-Medium\", sans-serif;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
