import React, { useEffect, useState } from "react";

import "./user_area.less";
import { useGetPhotoLoggedInUserQuery } from "@src/services/slices/usersSlice";
import { Employee } from "@src/types";

interface UserAreaProps {
  loggedInUser: Employee;
}

const UserArea: React.FC<UserAreaProps> = ({ loggedInUser }) => {
  const { data: photoUrl } = useGetPhotoLoggedInUserQuery();

  const createApplicationRoleLabel = (): string => {
    if (loggedInUser === null) return;

    //Todo: need to remove primary team lead because there is already a team lead role and primary team lead is not necessary
    //that`s why it is not shown in the application roles list and should be remove from the database
    return loggedInUser.applicationRoles
      .filter(
        (applicationRole: string) => applicationRole !== "Primary team lead"
      )
      .toString()
      .replace(/,/g, ", ");
  };

  const createAvatarPlaceholder = (): string => {
    if (loggedInUser === null) return;
    return `${loggedInUser.firstName.charAt(0)} ${loggedInUser.lastName.charAt(
      0
    )}`;
  };

  return (
    <div className={"main-box"} data-testid={"main-box"}>
      <div className={"user-info"}>
        <label className={"name-label"} data-testid={"name-label"}>
          {loggedInUser?.firstName} {loggedInUser?.lastName}
        </label>
        <label className={"role-label"} data-testid={"application-roles-label"}>
          {createApplicationRoleLabel()}
        </label>
      </div>

      <div className={"photo"}>
        {photoUrl ? (
          <img src={photoUrl} alt={"user-avatar"} />
        ) : (
          createAvatarPlaceholder()
        )}
      </div>
    </div>
  );
};

export default UserArea;
