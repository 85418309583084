import React, { useState, useRef, useEffect } from "react";

import { InfoCircleOutlined } from "@ant-design/icons";
import { SelectionRenderer } from "@src/custom_renderer";
import { gridOptions } from "@src/features/employee_data/utils/delete_employee_modal_utils";
import {
  useDeleteResponseMutation,
  useUpdateTeamLeadsResponseMutation,
} from "@src/services/slices/employeesSlice";
import { useUpdateProjectLeadsResponseMutation } from "@src/services/slices/projectsSlice";
import {
  Employee,
  EmployeeDeleteStatus,
  ReassignProjectLeadPayload,
  ReassignTeamLeadPayload,
  SelectedLead,
} from "@src/types";
import {
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, Modal, Tooltip } from "antd";
import "./delete_employee_modal.less";

interface DeleteEmployeeModalProps {
  modalVisible: boolean;
  setModalVisible: (modalVisible: boolean) => void;
  employeeDeleteStatus: EmployeeDeleteStatus[];
  employeeGridApi: GridApi;
  projectLeads: Employee[];
  teamLeads: Employee[];
}

export const DeleteEmployeeModal: React.FC<DeleteEmployeeModalProps> = ({
  modalVisible,
  setModalVisible,
  employeeDeleteStatus,
  employeeGridApi,
  projectLeads,
  teamLeads,
}) => {
  const gridApi = useRef<GridApi>(null);

  const shouldTlOrPlBeSelected: boolean = employeeDeleteStatus.some(
    (status: EmployeeDeleteStatus) =>
      status.teamleadAndNotDeleteable || status.projectLeadAndNotDeleteable
  );

  /* use states */
  const [newSelectedLead, setNewSelectedLead] = useState<SelectedLead[]>([]);
  const [
    finalSelectedEmployeesForDeletion,
    setFinalSelectedEmployeesForDeletion,
  ] = useState<EmployeeDeleteStatus[]>([]);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState<boolean>(
    shouldTlOrPlBeSelected
  );
  const [isCancelBtnDisabled, setIsCancelBtnDisabled] =
    useState<boolean>(false);

  /* rtk queries and mutations */
  const [deleteResponse] = useDeleteResponseMutation();
  const [updateTeamLeadsResponse] = useUpdateTeamLeadsResponseMutation();
  const [updateProjectLeadsResponse] = useUpdateProjectLeadsResponseMutation();

  useEffect(() => {
    if (projectLeads && gridApi) {
      gridApi.current?.refreshCells({ force: true });
      gridOptions.context.projectLeads = projectLeads;
    }
  }, [projectLeads, gridApi]);

  useEffect(() => {
    if (teamLeads && gridApi) {
      gridApi.current?.refreshCells({ force: true });
      gridOptions.context.teamleads = teamLeads;
    }
  }, [teamLeads, gridApi]);

  function onGridReady(event: GridReadyEvent): void {
    gridApi.current = event.api;
    gridApi.current.sizeColumnsToFit();
    gridApi.current.selectAll();
    setFinalSelectedEmployeesForDeletion(gridApi.current.getSelectedRows());
  }

  function errorIconRenderer(props: ICellRendererParams): React.ReactElement {
    let errorMessage = "";
    if (props.node.data.errorMessage) {
      props.node.data.errorMessage.forEach((message: string): void => {
        errorMessage += message;
      });
    }

    return (
      errorMessage !== "" && (
        <Tooltip placement="topLeft" title={errorMessage}>
          <Button type={"text"} icon={<InfoCircleOutlined />} />
        </Tooltip>
      )
    );
  }

  function updateNewLead(
    val: string,
    idToUpdate: string,
    props: ICellRendererParams
  ): void {
    setNewSelectedLead((oldValues: SelectedLead[]) => {
      const foundLead: SelectedLead = oldValues.find(
        (selectedLead: SelectedLead) =>
          selectedLead.oldLeadId === props.data.employeeId
      );

      // TODO: verify if this is correct
      if (foundLead) {
        foundLead[idToUpdate] = val;
        const newValue: SelectedLead[] = [...oldValues];
        setIsSaveBtnDisabled(checkButtonDisableStatus(newValue));
        return newValue;
      } else {
        const newLead = { oldLeadId: props.data.employeeId, [idToUpdate]: val };
        const newValue: SelectedLead[] = [...oldValues, newLead];
        setIsSaveBtnDisabled(checkButtonDisableStatus(newValue));
        return newValue;
      }
    });
  }

  function updateTeamlead(value: string, props: ICellRendererParams): void {
    updateNewLead(value, "newTeamLeadId", props);
  }

  function updateProjectLead(value: string, props: ICellRendererParams): void {
    updateNewLead(value, "newProjectLeadId", props);
  }

  const frameworkComponents: any = {
    errorIconRenderer: errorIconRenderer,
    showTeamLeadRenderer: (props: ICellRendererParams) => {
      return (
        <SelectionRenderer
          props={props}
          dataToExtractOptions={
            props.data.teamleadAndNotDeleteable &&
            gridOptions.context.teamleads.filter(
              (teamlead: Employee) =>
                teamlead.employeeId !== props.data.employeeId
            )
          }
          //should only show other employees than the employee flagged for deletion. So that a new substitute can be selected.
          propertyForOptionValues="employeeId"
          propertyForOptionLabels="fullName"
          defaultValue={null}
          style={{ minWidth: "190px" }}
          placeholder="Select new Team lead"
          onChangeCallback={updateTeamlead}
          hideSelection={!props.data.teamleadAndNotDeleteable} //hide selection and shows fallback message when the team lead can be deleted
          fallBackMessage="no need to select team lead"
          disabled={props.context.teamleads?.length === 0}
          isLoading={props.context.teamleads?.length === 0}
        />
      );
    },
    showProjectLeadRenderer: (props: ICellRendererParams) => {
      return (
        <SelectionRenderer
          props={props}
          dataToExtractOptions={
            props.data.projectLeadAndNotDeleteable &&
            gridOptions.context.projectLeads.filter(
              (projectLead: Employee): boolean =>
                projectLead.employeeId !== props.data.employeeId
            )
          }
          //should only show other employees than the employee flagged for deletion. So that a new substitute can be selected.
          propertyForOptionValues="employeeId"
          propertyForOptionLabels="fullName"
          defaultValue={null}
          style={{ minWidth: "190px" }}
          placeholder="Select new Project lead"
          onChangeCallback={updateProjectLead}
          hideSelection={!props.data.projectLeadAndNotDeleteable} //hide selection and shows fallback message when the project lead can be deleted
          fallBackMessage="no need to select project lead"
          disabled={props.context.projectLeads?.length === 0}
          isLoading={props.context.projectLeads?.length === 0}
        />
      );
    },
  };

  function checkButtonDisableStatus(leads: SelectedLead[]): boolean {
    let isOkBtnDisabled = false;

    //early return when no new tl and pl should be selected
    if (!shouldTlOrPlBeSelected) return isOkBtnDisabled;

    function checkLead(idAttributeName: string, employeeId: number): boolean {
      const isNewLeadSelected = !!leads.find(
        (lead: SelectedLead): boolean => lead.oldLeadId === employeeId
      )?.[idAttributeName];
      return !isNewLeadSelected;
    }

    for (const deleteStatus of employeeDeleteStatus) {
      if (deleteStatus.projectLeadAndNotDeleteable) {
        isOkBtnDisabled = checkLead(
          "newProjectLeadId",
          deleteStatus.employeeId
        );
        if (isOkBtnDisabled) break;
      }

      if (deleteStatus.teamleadAndNotDeleteable) {
        isOkBtnDisabled = checkLead("newTeamLeadId", deleteStatus.employeeId);
        if (isOkBtnDisabled) break;
      }
    }

    return isOkBtnDisabled;
  }

  async function onOk(): Promise<void> {
    setIsSaveBtnDisabled(true);
    setIsCancelBtnDisabled(true);

    for (const statusOfDeletion of finalSelectedEmployeesForDeletion) {
      //employee has no teams assigned and no projects assigned and can be deleted
      if (
        !statusOfDeletion.projectLeadAndNotDeleteable &&
        !statusOfDeletion.teamleadAndNotDeleteable
      ) {
        await deleteEmployee(statusOfDeletion.employeeId);
      } else {
        //employee has teams assigned or is assigned to projects and can not be deleted

        const isTeamLeadAndProjectLead =
          statusOfDeletion.projectLeadAndNotDeleteable &&
          statusOfDeletion.teamleadAndNotDeleteable;

        let newTeamleadReassigned = false;
        let newProjectLeadReassigned = false;

        const selectedLead: SelectedLead = newSelectedLead.find(
          (lead: SelectedLead): boolean =>
            lead.oldLeadId === statusOfDeletion.employeeId
        );

        //reassign projects to a new project lead
        if (statusOfDeletion.projectLeadAndNotDeleteable) {
          newProjectLeadReassigned = reassignProjectLeads(
            selectedLead.oldLeadId,
            selectedLead.newProjectLeadId
          );
        }

        //reassign employees, requests and roles to new team lead
        if (statusOfDeletion.teamleadAndNotDeleteable) {
          newTeamleadReassigned = reassignTeamLeads(
            selectedLead.oldLeadId,
            selectedLead.newTeamLeadId
          );
        }

        if (isTeamLeadAndProjectLead) {
          if (newTeamleadReassigned && newProjectLeadReassigned) {
            await deleteEmployee(statusOfDeletion.employeeId);
          }
        } else if (
          statusOfDeletion.teamleadAndNotDeleteable &&
          newTeamleadReassigned
        ) {
          await deleteEmployee(statusOfDeletion.employeeId);
        } else if (
          statusOfDeletion.projectLeadAndNotDeleteable &&
          newProjectLeadReassigned
        ) {
          await deleteEmployee(statusOfDeletion.employeeId);
        }
      }
    }

    setIsSaveBtnDisabled(false);
    setIsCancelBtnDisabled(false);
    onSelectionChanged();
    gridApi.current.hideOverlay();
    employeeGridApi.deselectAll();
    employeeGridApi.refreshServerSide({});

    if (gridApi.current.paginationGetRowCount() === 0) {
      setModalVisible(false);
    }
  }

  async function deleteEmployee(employeeId: number): Promise<void> {
    gridApi.current.showLoadingOverlay();
    deleteResponse(employeeId)
      .unwrap()
      .then(() => {
        const finalForDeletionSelectedEmployee =
          finalSelectedEmployeesForDeletion.find(
            (employee: EmployeeDeleteStatus): boolean =>
              employee.employeeId === employeeId
          );

        gridApi.current.applyTransaction({
          remove: [finalForDeletionSelectedEmployee],
        });
      })
      .catch((error) => {
        addErrorMessageToRowNode(error, employeeId);
      });
  }

  function reassignTeamLeads(oldLeadId: number, newLeadId: number): boolean {
    const reassignTeamLeadPayload: ReassignTeamLeadPayload = {
      oldLeadId: oldLeadId,
      newLeadId: newLeadId,
    };

    try {
      updateTeamLeadsResponse(reassignTeamLeadPayload);
      return true;
    } catch (err) {
      addErrorMessageToRowNode(err, oldLeadId);
      return false;
    }
  }

  function reassignProjectLeads(
    oldLeadId: number,
    newProjectLeadId: number
  ): boolean {
    const reassignProjectLeadPayload: ReassignProjectLeadPayload = {
      oldLeadId: oldLeadId,
      newProjectLeadId: newProjectLeadId,
    };

    try {
      updateProjectLeadsResponse(reassignProjectLeadPayload);
      return true;
    } catch (err) {
      addErrorMessageToRowNode(err, oldLeadId);
      return false;
    }
  }

  function addErrorMessageToRowNode(error: string, employeeId: number): void {
    const node: IRowNode = gridApi.current.getRowNode(employeeId.toString());

    if (node.data.errorMessage) {
      node.data.errorMessage = [...node.data.errorMessage, error];
    } else {
      node.data.errorMessage = [error];
    }

    gridApi.current.applyTransaction({ update: [node] });
    gridApi.current.redrawRows();
    node.setSelected(false);
  }

  function onSelectionChanged(): void {
    setFinalSelectedEmployeesForDeletion(gridApi.current.getSelectedRows());
  }

  return (
    <Modal
      width={"1050px"}
      open={modalVisible}
      data-testid={"modal"}
      centered
      closable={false}
      maskClosable={false}
      onCancel={() => setModalVisible(false)}
      onOk={onOk}
      okButtonProps={{
        disabled: isSaveBtnDisabled,
      }}
      cancelButtonProps={{
        disabled: isCancelBtnDisabled,
      }}
      title={"Delete employee overview"}
    >
      <div data-testid={"delete_info"}>
        If you click ok then all selected employees will be deleted
      </div>
      <br />
      <div className="ag-theme-alpine delete-overview-table header-white">
        <AgGridReact
          rowData={employeeDeleteStatus}
          onGridReady={onGridReady}
          gridOptions={gridOptions}
          components={frameworkComponents}
          onSelectionChanged={onSelectionChanged}
          overlayNoRowsTemplate={
            '<span class="ag-overlay-loading-center">No Employees to delete</span>'
          }
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Deleting employees. Please wait...</span>'
          }
        />
      </div>
    </Modal>
  );
};

export default DeleteEmployeeModal;
