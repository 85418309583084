// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-inputs {
  display: flex;
}
.form-inputs .filter-input {
  margin-left: 1em;
  width: 310px;
}
.form-inputs .clear-filter {
  align-self: flex-end;
  margin-left: 1em;
}
.form-inputs .ant-form-item {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/features/sap_upload/components/sap_filter/sap_filter.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAFA;EAII,gBAAA;EACA,YAAA;AACJ;AANA;EASI,oBAAA;EACA,gBAAA;AAAJ;AAII;EACE,gBAAA;AAFN","sourcesContent":[".form-inputs {\n  display: flex;\n\n  .filter-input {\n    margin-left: 1em;\n    width: 310px;\n  }\n\n  .clear-filter {\n    align-self: flex-end;\n    margin-left: 1em;\n  }\n\n  .ant {\n    &-form-item {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
