import React, { useMemo } from "react";

import {
  getChartData,
  getChartOptions,
} from "@src/features/dashboard/utils/request_replied_chart_utils";
import { Card } from "antd";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./request_replied_chart.less";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

export interface RequestRepliedChartProps {
  requested: number;
  replied: number;
  isLoading?: boolean;
}
const RequestRepliedChart: React.FC<RequestRepliedChartProps> = ({
  requested,
  replied,
  isLoading,
}) => {
  const chartOptions: ChartOptions<"bar"> = getChartOptions();
  const chartData: ChartData<"bar"> = getChartData(requested, replied);

  return (
    <Card
      title="Request / Replied"
      className="request-replied-chart"
      loading={isLoading}
    >
      <Bar
        options={chartOptions}
        data={chartData}
        width="150px"
        height="350px"
      />
    </Card>
  );
};

export default RequestRepliedChart;
