import React from "react";
import "./headline.less";

const Headline = ({
  className = "headline",
  title,
}: {
  className?: string;
  title: string;
}) => {
  return (
    <div className={className} data-testid="headline">
      <h2 className="title">{title}</h2>
    </div>
  );
};

export default Headline;
