import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const AuthorizedContent = ({ children, roles }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  const userHasAccess = (userRoles, authRoles) => {
    return userRoles?.some((ur) => {
      return authRoles.some((p) => p.toUpperCase() === ur.toUpperCase());
    });
  };

  useEffect(() => {
    async function init() {
      const user = cookies.get("loggedInuser");
      setIsAuthorized(
        user ? userHasAccess(user.applicationRoles, roles) : false
      );
    }
    init();
  }, [roles]);

  return isAuthorized ? children : <></>;
};

export default AuthorizedContent;
