// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.split-role-transfer-tree-left__no-requests {
  padding: 12px 24px 12px 24px;
}
`, "",{"version":3,"sources":["webpack://./src/features/role_split/components/split_requests_tree/split_requests_tree.less"],"names":[],"mappings":"AACE;EACE,4BAAA;AAAJ","sourcesContent":[".split-role-transfer-tree-left {\n  &__no-requests {\n    padding: 12px 24px 12px 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
