// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-modal {
  min-width: 200px !important;
}
.loading-modal__spinn-tip {
  padding: 30px;
}
.loading-modal__progress {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/loading_modal/loading_modal.less"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".loading-modal {\n  min-width: 200px !important;\n\n  &__spinn-tip {\n    padding: 30px;\n  }\n\n  &__progress {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
