import React from "react";

import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import moment from "moment/moment";

import {
  MemberRenderer,
  NameCircleRenderer,
} from "../../../features/teams/components";
import { ActionButtonRenderer } from "../../../features/teams/components/action_button_renderer";

export const modalMessage = {
  button: "Delete",
  title: "Are you sure you want to delete this time blocker?",
  btnType: "danger",
  warning:
    "This time blocker will be deleted completely, " +
    "and will not be considered in this team member availability",
};

export const colorsMembers = {
  0: "#1D4477",
  1: "#3ED8C3",
  2: "#EF7D00",
  3: "#00B1EB",
  4: "#00A19F",
};

export const columnsSettings = (
  columnProps,
  onTableRowExpand,
  showTimeBlockerInEditButtons,
  showTimeBlockerNotInEditButtons,
  teamLeadStandardRoles,
  teamMembers,
  setTeamMembers
) => [
  nameCircle,
  nameColumn(columnProps, teamLeadStandardRoles, teamMembers, setTeamMembers),
  showMoreColumn(
    onTableRowExpand,
    columnProps.expandKeys,
    showTimeBlockerInEditButtons,
    showTimeBlockerNotInEditButtons
  ),
  prevArrowColumn(columnProps),
  ...columnProps.yearColumns,
  nextArrowColumn(columnProps),
];

export const nameCircle = {
  key: "nameCircle",
  fixed: "left",
  width: 80,
  align: "center",
  render: (value, record, index) => (
    <NameCircleRenderer
      record={record}
      colorsMembers={colorsMembers}
      value={value}
      index={index}
    />
  ),
};

export const nameColumn = (
  columProps,
  teamLeadStandardRoles,
  teamMembers,
  setTeamMembers
) => ({
  title: "Team member",
  key: "fullName",
  width: 400,
  fixed: "left",
  editable: true,
  render: (value) => (
    <MemberRenderer
      value={value}
      timeBlockerInEdit={columProps.timeBlockerInEdit}
      addTimeBlocker={columProps.addTimeBlocker}
      teamLeadStandardRoles={teamLeadStandardRoles}
      teamMembers={teamMembers}
      setTeamMembers={setTeamMembers}
    />
  ),
});

export const showMoreColumn = (
  onTableRowExpand,
  expandedKeys,
  showTimeBlockerInEditButtons,
  showTimeBlockerNotInEditButtons
) => ({
  key: "operation",
  width: 70,
  className: "icons-container",
  render: (rowData) => (
    <ActionButtonRenderer
      rowData={rowData}
      onTableRowExpand={onTableRowExpand}
      expandedKeys={expandedKeys}
      showTimeBlockerInEditButtons={showTimeBlockerInEditButtons}
      showTimeBlockerNotInEditButtons={showTimeBlockerNotInEditButtons}
    />
  ),
});

export const prevArrowColumn = (columProps) => ({
  title: (
    <LeftOutlined
      style={{ fontSize: "20px" }}
      data-testid="prev-arrow"
      onClick={() => {
        if (columProps.startMonthIndex > 0) {
          columProps.navigateMonthBack();
        }
      }}
    />
  ),
  key: "prevArrow",
  fixed: "left",
  width: 30,
});

export const nextArrowColumn = (columProps) => {
  const totalMonths = 12 - moment().month() + 36;

  return {
    title: (
      <RightOutlined
        style={{ fontSize: "20px" }}
        type="text"
        data-testid="next-arrow"
        onClick={() => {
          if (
            columProps.startMonthIndex + columProps.displayColumnCount <
            totalMonths
          ) {
            columProps.navigateMonthForward();
          }
        }}
      />
    ),
    key: "nextArrow",
    dataIndex: "2",
    fixed: "right",
    width: 30,
  };
};
