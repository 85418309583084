import Cookies from "universal-cookie";

import { ROLES } from "../constants";

const cookies = new Cookies();

let getEmployeeData = () => {
  const user = cookies.get("loggedInuser");
  return user
    ? {
        ...user,
        id: user.employeeId,
        isPrimary: (primaryTeamLeadId) => {
          return (
            user.applicationRoles.includes(ROLES.TEAM_LEAD) &&
            primaryTeamLeadId === user.employeeId
          );
        },
      }
    : { id: null, isPrimary: () => false, applicationRoles: [] };
};

export const userUtils = {
  getEmployeeData,
};

userUtils.isUserProjectLead = () => {
  return getEmployeeData().applicationRoles.includes(ROLES.PROJECT_LEAD);
};

userUtils.isUserAdmin = () => {
  return getEmployeeData().applicationRoles.includes(ROLES.ADMIN);
};

userUtils.isManager = () => {
  return getEmployeeData().applicationRoles.includes(ROLES.MANAGER);
};

userUtils.isDeputy = () => {
  return getEmployeeData().applicationRoles.includes(ROLES.DEPUTY);
};
