// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageSection .project-name-label {
  margin-top: 0 !important;
  font-size: 21px;
}
.pageSection .button-section {
  display: flex;
  justify-content: space-between;
}
.pageSection .button-section .duplicate-project-button {
  margin-left: 0;
}
.pageSection .last-edit {
  margin-top: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/features/projects/components/project_details_card/project_details_card.less"],"names":[],"mappings":"AAAA;EAEI,wBAAA;EACA,eAAA;AAAJ;AAHA;EAOI,aAAA;EACA,8BAAA;AADJ;AAPA;EAWM,cAAA;AADN;AAVA;EAgBI,gBAAA;AAHJ","sourcesContent":[".pageSection {\n  .project-name-label {\n    margin-top: 0 !important;\n    font-size: 21px;\n  }\n\n  .button-section {\n    display: flex;\n    justify-content: space-between;\n\n    .duplicate-project-button {\n      margin-left: 0;\n    }\n  }\n\n  .last-edit {\n    margin-top: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
