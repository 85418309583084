import React from "react";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { CategoryEnum } from "@src/constants";
import { useGetRoleSpecificationsQuery } from "@src/services/slices/roleSpecificationsApi";
import { RoleSpecification } from "@src/types";
import { Form, Input, Modal, Select } from "antd";

import { getValidatorRulesForInput } from "../../utils/role_assignment_helper";
export interface RoleSpecificationModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onSaveCallback?: (roleSpecification: RoleSpecification) => void;
  defaultRoleSpecification?: RoleSpecification;
}

const RoleSpecificationModal: React.FC<RoleSpecificationModalProps> = ({
  showModal,
  setShowModal,
  onSaveCallback,
  defaultRoleSpecification,
}) => {
  const [form] = Form.useForm();

  /* rtk queries and mutations */
  const { data: roleSpecifications } = useGetRoleSpecificationsQuery();

  const initialValues = defaultRoleSpecification && {
    roleSpecificationName: defaultRoleSpecification.roleSpecificationName,
    category: defaultRoleSpecification.roleSpecificationCategory,
  };

  /* -------- click events --------  */

  /**
   * click function of the save button. submits the form
   */
  function onSave() {
    form.submit();
  }

  /**
   * click function of the cancel button. make the modal disappear
   */
  function onCancel() {
    setShowModal(false);
  }

  /* -------- form events -------- */

  /**
   * onFinish function of the form.
   * will map the payload and call the onSaveCallback function
   */
  function onFinish() {
    const payload: Partial<RoleSpecification> = getPayload();
    onSaveCallback(payload as RoleSpecification);
  }

  /* -------- helper functions -------- */

  /**
   * maps the FormItem values to the payload
   * @returns the payload
   */
  function getPayload(): Partial<RoleSpecification> {
    return {
      roleSpecificationId: defaultRoleSpecification?.roleSpecificationId,
      roleSpecificationName: form.getFieldValue("roleSpecificationName"),
      roleSpecificationCategory: form.getFieldValue("category"),
    };
  }

  /**
   * needed for the duplicate validation.
   * checks if a role specification with the entered name already exists
   *
   * @param {string} newRoleSpecificationName entered role specification name
   * @returns true if it already exists, false if not
   */
  function checkIfRoleExists(newRoleSpecificationName: string): boolean {
    const newNameTrimmed = newRoleSpecificationName.trim();
    const initialValue = defaultRoleSpecification?.roleSpecificationName;

    if (newNameTrimmed === initialValue) {
      return false;
    }

    return roleSpecifications.some(
      (roleSpecification) =>
        roleSpecification.roleSpecificationName === newNameTrimmed
    );
  }

  return (
    <Modal
      open={showModal}
      closable={false}
      title={
        defaultRoleSpecification
          ? "Edit Role Specification"
          : "Add Role Specification"
      }
      okText={defaultRoleSpecification ? "Save" : "Add Role"}
      okButtonProps={{ icon: <CheckOutlined />, size: "large" }}
      onOk={onSave}
      cancelButtonProps={{ icon: <CloseOutlined />, size: "large" }}
      onCancel={onCancel}
      width="470px"
      maskClosable={false}
    >
      <Form
        layout="vertical"
        initialValues={initialValues}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          name="roleSpecificationName"
          label="Role Specification"
          rules={getValidatorRulesForInput(checkIfRoleExists)}
        >
          <Input placeholder="Type role specification" />
        </Form.Item>

        <Form.Item name="category" label="Category">
          <Select
            placeholder="Select category"
            allowClear
            showSearch
            options={Object.values(CategoryEnum)?.map((category) => ({
              value: category,
              label: category,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RoleSpecificationModal;
