import { Tooltip } from "antd";
import { TransferItem } from "antd/es/transfer";
import "./split_role_transfer_tree_node.less";

interface SplitRoleTransferTreeNodeProps {
  transferItem: TransferItem;
}

const SplitRoleTransferTreeNode: React.FC<SplitRoleTransferTreeNodeProps> = ({
  transferItem,
}) => {
  let projectName: React.ReactElement = <>{transferItem.data.projectName}</>;

  if (transferItem.data.projectName.length > 50) {
    projectName = (
      <Tooltip title={projectName}>
        {`${transferItem.data.projectName.substring(0, 50)}...`}
      </Tooltip>
    );
  }

  return (
    <div className="split-role-transfer-tree-node">
      <div
        className="split-role-transfer-tree-node__title"
        data-testid="tree-node-title"
      >
        {transferItem.key.toString()} - {projectName}
      </div>
      <div
        className="split-role-transfer-tree-node__text"
        data-testid="tree-node-text"
      >
        {transferItem.data.country} - {transferItem.data.projectPhase} -{" "}
        {transferItem.data.status}
      </div>
    </div>
  );
};

export default SplitRoleTransferTreeNode;
