// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phase-renderer {
  display: flex;
  gap: 4px;
  text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_requests/custom_renderer/phase_renderer/phase_renderer.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,QAAA;EACA,0BAAA;AADF","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.phase-renderer {\n  display: flex;\n  gap: 4px;\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
