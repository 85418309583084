import React from "react";
import "./no_rows_overlay.less";

export interface NoRowsOverlayComponentProps {
  text: string;
  customStyle?: React.CSSProperties;
}

const NoRowsOverlay: React.FC<NoRowsOverlayComponentProps> = ({
  text,
  customStyle,
}) => {
  return (
    <div className="no-rows-overlay" style={customStyle}>
      {text}
    </div>
  );
};

export default NoRowsOverlay;
