// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.region-assignment-page .top-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.region-assignment-page .top-section .essential-filter-container {
  width: 65%;
  display: flex;
  justify-content: space-between;
}
.region-assignment-page .top-section .essential-filter-container .filter-dropdowns {
  width: 420px;
  display: flex;
  justify-content: space-between;
}
.region-assignment-page .top-section .essential-filter-container .filter-group {
  justify-content: center;
  width: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/region_assignment_page/region_assignment_page.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;AAJA;EAOM,UAAA;EACA,aAAA;EACA,8BAAA;AAAN;AATA;EAYQ,YAAA;EACA,aAAA;EACA,8BAAA;AAAR;AAdA;EAkBQ,uBAAA;EACA,UAAA;AADR","sourcesContent":[".region-assignment-page {\n  .top-section {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 10px;\n\n    .essential-filter-container {\n      width: 65%;\n      display: flex;\n      justify-content: space-between;\n\n      .filter-dropdowns {\n        width: 420px;\n        display: flex;\n        justify-content: space-between;\n      }\n\n      .filter-group {\n        justify-content: center;\n        width: 50%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
