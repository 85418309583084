import { Employee, Project, ProjectPayload } from "@src/types";
import { CellValueChangedEvent, ICellRendererParams } from "ag-grid-community";
import dayjs from "dayjs";
import _ from "lodash";

const dateFormat = "DD/MM/YYYY";

/**
 * builds the project payload for the backend
 *
 * @param data the project data object that will be mapped to the payload structure
 * @returns the project payload
 */
export function getProjectPayload(data: Project): ProjectPayload {
  return {
    projectId: data.id,
    projectManager: data.projectManager.employeeId,
    editors: _.cloneDeep(
      data.projectEditors.map((employee: Employee) => employee.employeeId)
    ),
    name: data.name,
    phase: data.projectPhase,
    countryId: data.countryId,
    startDate: dayjs(data.startDate).format(dateFormat),
    endDate: dayjs(data.endDate).format(dateFormat),
    stageGateDate: data.stageGateDate
      ? dayjs(data.stageGateDate).format(dateFormat)
      : null,
    jointVenturePartner: data.jointVenturePartner,
    technologies: data.technologies,
    projectType: data.projectType,
  };
}

/**
 * onChange method for the selection_renderer
 * sets the whole object if the field contains an object
 * or just the string value if not
 *
 * the "anys" can be removed when the selection_renderer is fully migrated to TypeScript
 * but with the new designes we will not need the function anymore
 * because inline editing will be removed
 *
 * @param {string} value string value from selection_renderer
 * @param {ICellRendererParams} params from the selection_renderer
 * @param {any} selectedObject Ant Design selection object from selection renderer
 * @param {any} selectedDataObject the whole object selected in the selection_renderer. can be any value of any property of a Project
 */
export function onChange(
  value: string,
  params: ICellRendererParams,
  selectedObject: any,
  selectedDataObject: any
): void {
  if (selectedDataObject) {
    params.node.setDataValue(params.colDef.field, selectedDataObject);
  } else {
    params.setValue(value);
  }
}

/**
 * AG Grid onCellValueChanged function
 * handles the saving of initial data for the cancel functionality
 *
 * @param {CellValueChangedEvent} event from AG Grid
 */
export function onCellValueChanged(event: CellValueChangedEvent): void {
  const data: Project & { initialData?: Partial<Project> } = event.data;
  const currentInitialDataValue: any = data.initialData?.[event.colDef.field]; //can be any value of any property of a Project

  if (!currentInitialDataValue) {
    if (!data.initialData) data.initialData = {};

    data.initialData[event.colDef.field] = event.oldValue;
    event.node.setData(data);
  } else if (currentInitialDataValue == event.newValue) {
    delete data.initialData?.[event.colDef.field];
    event.node.setData(data);
  }
}
