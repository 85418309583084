import React, { useMemo, useRef } from "react";

import { SelectionRenderer } from "@src/custom_renderer";
import ActionsRenderer from "@src/custom_renderer/actions_renderer/actions_renderer";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import {
  useGetCountriesQuery,
  useUpdateCountryMutation,
} from "@src/services/slices/countriesApi";
import { AgGridReact } from "ag-grid-react";
import { App } from "antd";
import _ from "lodash";

import { getGridOptions } from "../../utils/region_assignment_management_grid_options";
import { onChange } from "../../utils/region_assignment_utils";
import "./region_assignment_management.less";
import { RegionAssignmentFilter } from "../region_assignment_filter";

const RegionAssignmentManagement = ({ regionsData }) => {
  const { data: countriesData, isLoading } = useGetCountriesQuery();
  const gridApi = useRef(null);

  const { message } = App.useApp();

  const [updateCountry] = useUpdateCountryMutation();

  const context = useMemo(
    () => ({
      regions: regionsData,
      countries: countriesData,
    }),
    [regionsData, countriesData]
  );

  /**
   * onClick function of the edit button
   * @param {*} props - ag-grid props
   */
  const onEdit = (props) => {
    props.data.isEditActive = true;
    props.api.refreshCells({ rowNodes: [props.node], force: true });
  };

  /**
   * onClick function of the cancel button
   * @param {*} props  - ag-grid props
   */
  const onCancel = (props) => {
    props.data.isEditActive = false;
    props.api.redrawRows({ rowNodes: [props.node] });
  };

  /**
   * onClick function of the save button
   * @param {*} props - ag-grid props
   */
  const handleSave = async (props) => {
    await editCountry(props);
  };

  const editCountry = async (props) => {
    let foundCountry = _.cloneDeep(props.data);

    if (props?.data?.updatedData) {
      foundCountry = { ...foundCountry, ...props.data.updatedData };
    }
    updateCountry(foundCountry)
      .unwrap()
      .then(() => {
        updateNodeData(props);
        message.success(`Successfully updated region`);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  const updateNodeData = (props) => {
    if (props?.data?.updatedData) {
      Object.keys(props.data.updatedData).forEach((key) => {
        props.node.setDataValue(key, _.cloneDeep(props.data.updatedData[key]));
      });
      delete props.data.updatedData;
    }
    delete props.data.isEditActive;

    props.api.refreshCells({ rowNodes: [props.node], force: true });
  };

  //----------------------
  // AG-GRID FUNCTIONS
  //----------------------

  const frameworkComponents = {
    selectionRenderer: (props) => {
      return (
        <SelectionRenderer
          props={props}
          allowClear
          dataToExtractOptions={props.context.regions}
          propertyForOptionLabels="regionName"
          propertyForOptionValues="regionName"
          defaultValue={props?.data?.newRow ? null : props?.data?.regionName}
          onChangeCallback={(category) => onChange(category, props)}
          placeholder="Select Region"
          disabled={!props?.data.isEditActive}
        />
      );
    },

    actionsRenderer: (props) => {
      return (
        <ActionsRenderer
          props={props}
          onSave={handleSave}
          onEdit={onEdit}
          onCancel={onCancel}
        />
      );
    },
  };

  const onGridReady = (params) => {
    gridApi.current = params.api;
    gridApi.current.sizeColumnsToFit();
  };

  return (
    <div className="region-assignment-management">
      <div className="top-section" data-testid="top-section">
        <RegionAssignmentFilter
          gridApi={gridApi.current}
          regions={mapToTreeData(context?.regions, "regionName")}
          countries={mapToTreeData(context?.countries, "countryName")}
          disabled={isLoading}
        />
      </div>
      <div
        className="ag-theme-alpine header-white"
        data-testid="region-settings-table"
      >
        <AgGridReact
          rowData={_.cloneDeep(countriesData)}
          components={frameworkComponents}
          gridOptions={getGridOptions()}
          onGridReady={onGridReady}
          context={context}
          onGridSizeChanged={(props) => props.api.sizeColumnsToFit()}
        />
      </div>
    </div>
  );
};

export default RegionAssignmentManagement;
