import { columnTypes, paginationGridOptions } from "@src/utils/aggrid_utils";

import { getOptionsForSpecificationDropdown } from "./update_standard_role_modal_utils";

const columns = [
  {
    headerName: "No.",
    field: "rowNumber",
    maxWidth: 70,
  },
  {
    headerName: "Given Role from File",
    field: "standardRole",
    suppressSizeToFit: true,
    width: 300,
    cellClass: "provided-role",
  },
  {
    headerName: "Basic Role",
    field: "basicRole",
    width: 250,
    cellRenderer: "basicRoleRenderer",
  },
  {
    headerName: "Specification",
    field: "specification",
    width: 250,
    cellRenderer: "roleSpecificationRenderer",
    valueGetter: (props) => {
      return getOptionsForSpecificationDropdown(
        props.context?.basicRoles,
        props.data.basicRole
      );
    },
  },
];

export const gridOptions = {
  ...paginationGridOptions,
  columnDefs: [...columns],
  suppressContextMenu: true,
  domLayout: "autoHeight",
  rowHeight: 60,
  context: {
    basicRoles: [],
  },
  defaultColDef: {
    ...columnTypes.default,
  },
};
