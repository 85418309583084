// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-progress-modal__footer-buttons {
  display: flex;
  justify-content: space-between;
}
.step-progress-modal__footer-buttons .ant-btn:nth-child(2) {
  margin-left: auto;
}
.step-progress-modal__footer-buttons .ant-btn:nth-child(2) {
  margin-left: auto;
}
.step-progress-modal__footer-buttons .ant-btn:nth-last-child(1) {
  margin-left: 16px;
}
.step-progress-modal__steps-component {
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/step_progress_modal/step_progress_modal.less"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,8BAAA;AAAJ;AAFE;EAKI,iBAAA;AAAN;AAIM;EACE,iBAAA;AAFR;AAKM;EACE,iBAAA;AAHR;AAQE;EACE,mBAAA;AANJ","sourcesContent":[".step-progress-modal {\n  &__footer-buttons {\n    display: flex;\n    justify-content: space-between;\n\n    .ant-btn:nth-child(2) {\n      margin-left: auto;\n    }\n\n    .ant-btn {\n      &:nth-child(2) {\n        margin-left: auto;\n      }\n\n      &:nth-last-child(1) {\n        margin-left: 16px;\n      }\n    }\n  }\n\n  &__steps-component {\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
