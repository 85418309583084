import React from "react";

import "./region_country_renderer.less";
import { ICellRendererParams } from "ag-grid-community";

interface RegionCountryRendererParams {
  params: ICellRendererParams;
}

const RegionCountryRenderer: React.FC<RegionCountryRendererParams> = ({
  params,
}) => {
  const region = params.data.region;
  const country = params.data.country;

  return (
    <div className="region-country-col">
      <div className="region-country-col-text">{region}</div>
      <div className="region-country-col-subtext">{country}</div>
    </div>
  );
};

export default RegionCountryRenderer;
