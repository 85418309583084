// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-row .ant-col {
  max-width: 85% !important;
}
.employee-selection-section {
  margin-top: 30px;
}
.employee-selection-section .employee-selection {
  width: 100%;
}
.employee-selection-section .pl-select {
  margin-bottom: 30px;
}
.warning-title {
  margin-bottom: 20px;
}
.warning-msg {
  color: red;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/edit_project_confirmation_modal/edit_project_confirmation_modal.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAEA;EACE,gBAAA;AAAF;AADA;EAII,WAAA;AAAJ;AAJA;EAQI,mBAAA;AADJ;AAKA;EACE,mBAAA;AAHF;AAMA;EACE,UAAA;EACA,iBAAA;AAJF","sourcesContent":[".warning-row .ant-col {\n  max-width: 85% !important;\n}\n\n.employee-selection-section {\n  margin-top: 30px;\n\n  .employee-selection {\n    width: 100%;\n  }\n\n  .pl-select {\n    margin-bottom: 30px;\n  }\n}\n\n.warning-title {\n  margin-bottom: 20px;\n}\n\n.warning-msg {\n  color: red;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
