import React, { useState } from "react";

import { StepProgressModal } from "@src/components/modals/step_progress_modal";
import {
  useGetStandardRolesQuery,
  useSplitRoleMutation,
} from "@src/services/slices/standardRolesApi";
import {
  removeAssignmentFromSplit,
  resetSearch,
  resetSplitStore,
} from "@src/services/splitRoleSlice";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import {
  RoleSpecification,
  SpecificationSplits,
  RequestsToSplit,
  StandardRole,
} from "@src/types";
import { App, Form } from "antd";
import _ from "lodash";

import { SplitRequestAssignment } from "../split_request_assignment";
import { SplitSelection } from "../split_selection";

interface SplitModalProps {
  selectedStandardRole: StandardRole;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

const SplitRoleModal: React.FC<SplitModalProps> = ({
  selectedStandardRole,
  showModal,
  setShowModal,
}) => {
  const dispatch = useAppDispatch();

  const [specificationForm] = Form.useForm<SpecificationSplits>();

  const { message } = App.useApp();

  const { currentAssignedRequestsToSplit } = useAppSelector(
    (state: RootState) => state.splitRoleSlice
  );

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [newSelectedSpecifications, setNewSelectedSpecifications] = useState<
    RoleSpecification[]
  >([]);

  const { data: standardRoles } = useGetStandardRolesQuery(null, {
    selectFromResult: ({ data }) => {
      const filteredData: StandardRole[] = data?.filter(
        (standardRole: StandardRole) =>
          standardRole.basicRole.basicRoleId ===
          selectedStandardRole.basicRole.basicRoleId
      );

      return { data: filteredData };
    },
  });

  const [splitRole] = useSplitRoleMutation();

  /**
   * Will be triggered by the selection form when there are no errors left
   *
   * @param specificationSplits
   */
  function onSplitFinish(specificationSplits: SpecificationSplits): void {
    const selectedRoleSpecifications: RoleSpecification[] = [];

    for (const split of specificationSplits.splits) {
      const newSelectedStandardRole: StandardRole = standardRoles.find(
        (standardRole: StandardRole) =>
          standardRole.roleSpecification?.roleSpecificationId === split
      );

      selectedRoleSpecifications.push(
        newSelectedStandardRole.roleSpecification
      );
    }

    // check if one split was removed in the split selection section
    const assignedKeys: number[] = Object.keys(
      currentAssignedRequestsToSplit
    ).map((specificRoleId: string) => parseInt(specificRoleId));

    // get deleted splits
    const deletedSplitIds: string[] = _.difference(
      assignedKeys,
      specificationSplits.splits
    );
    dispatch(removeAssignmentFromSplit(deletedSplitIds));

    setNewSelectedSpecifications(selectedRoleSpecifications);
    setCurrentStepIndex((index: number) => index + 1);
  }

  /**
   * When the user clicks the cancel button then all states should be reset to default.
   *
   */
  function onSplitCancel(): void {
    setShowModal(false);
    setCurrentStepIndex(0);
    specificationForm.resetFields();
    dispatch(resetSplitStore());
  }

  /**
   * After user clicks next the form should trigger submit to have the error validation. This is necessary to prevent
   * the user from adding a split selection without selecting a split. When this will happen then an error message will
   * be shown below the selection where no split was selected.
   */
  function onSplitNext(): void {
    specificationForm.submit();
  }

  /**
   * Saves the assigned requests after the user finished the assignment to the selected split.
   *
   */
  function onSplitSave(): void {
    splitRole(getSplitRolePayload())
      .unwrap()
      .then(async () => {
        onSplitCancel();
        message.success("Role successfully split");
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  /**
   * When the user clicks back, then the active search by the user in the transfer component should be reset and
   * the stepIndex set to first page (the split selection)
   */
  function onSplitBack(): void {
    dispatch(resetSearch());
    setCurrentStepIndex((index: number) => index - 1);
  }

  /**
   * The pay load for the split role endpoint.
   */
  function getSplitRolePayload(): RequestsToSplit[] {
    const specificationIds: string[] = Object.keys(
      currentAssignedRequestsToSplit
    );

    const splitRoles: RequestsToSplit[] = [];

    for (const specificationId of specificationIds) {
      const newSelectedStandardRole: StandardRole = standardRoles
        .filter(
          (standardRole: StandardRole) =>
            standardRole.basicRole.basicRoleId ===
            selectedStandardRole.basicRole.basicRoleId
        )
        .find(
          (standardRole: StandardRole) =>
            standardRole.roleSpecification?.roleSpecificationId ===
            parseInt(specificationId)
        );

      const splitRole: RequestsToSplit = {
        standardRoleId: newSelectedStandardRole.standardRoleId,
        requestIdsToReassign: currentAssignedRequestsToSplit[specificationId],
      };

      splitRoles.push(splitRole);
    }

    return splitRoles;
  }

  return (
    <>
      <StepProgressModal
        modalVisible={showModal}
        onCancel={onSplitCancel}
        onNext={onSplitNext}
        onSave={onSplitSave}
        onBack={onSplitBack}
        title={`Split Role "${selectedStandardRole.standardRoleName}"`}
        stepTitles={["Split", "Reassign"]}
        currentStepIndex={currentStepIndex}
      >
        <SplitSelection
          specificationForm={specificationForm}
          onFinish={onSplitFinish}
          newSelectedSpecifications={newSelectedSpecifications}
          basicRoleId={selectedStandardRole.basicRole.basicRoleId}
        />
        {newSelectedSpecifications.length > 0 && (
          <SplitRequestAssignment
            selectedStandardRoleToSplit={selectedStandardRole}
            newSelectedSpecifications={newSelectedSpecifications}
          />
        )}
      </StepProgressModal>
    </>
  );
};

export default SplitRoleModal;
