import React from "react";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useGetAreasQuery } from "@src/services/slices/areasApi";
import { useGetBasicRolesQuery } from "@src/services/slices/basicRolesApi";
import { useGetN1DepartmentsQuery } from "@src/services/slices/departmentsSlice";
import { Area, BasicRole, Department } from "@src/types";
import { Form, Input, Modal, Select } from "antd";
import _ from "lodash";

import { getValidatorRulesForInput } from "../../utils/role_assignment_helper";

export interface BasicRoleModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onSaveCallback?: (basicRole: BasicRole) => void;
  defaultBasicRole?: BasicRole;
}

const BasicRoleModal: React.FC<BasicRoleModalProps> = ({
  showModal,
  setShowModal,
  onSaveCallback,
  defaultBasicRole,
}) => {
  const [form] = Form.useForm();

  /* rtk queries and mutations */
  const { data: basicRoles } = useGetBasicRolesQuery();
  const { data: departments } = useGetN1DepartmentsQuery();
  const { data: areas } = useGetAreasQuery();

  const initialValues = defaultBasicRole && {
    basicRoleName: defaultBasicRole.basicRoleName,
    area: defaultBasicRole.area?.areaId,
    n1Department: defaultBasicRole.department?.departmentId,
  };

  /* -------- click events --------  */

  /**
   * click function of the save button. submits the form
   */
  function onSave() {
    form.submit();
  }

  /**
   * click function of the cancel button. make the modal disappear
   */
  function onCancel() {
    setShowModal(false);
  }

  /* -------- form events -------- */

  /**
   * onFinish function of the form.
   * will map the payload and call the onSaveCallback function
   */
  function onFinish() {
    const payload: Partial<BasicRole> = getPayload();
    onSaveCallback(payload as BasicRole);
  }

  /* -------- helper functions -------- */

  /**
   * maps the FormItem values to the payload
   * @returns the payload
   */
  function getPayload(): Partial<BasicRole> {
    return {
      basicRoleId: defaultBasicRole?.basicRoleId,
      basicRoleName: form.getFieldValue("basicRoleName"),
      area: areas.find(
        (area: Area) => area.areaId === form.getFieldValue("area")
      ),
      department: departments.find(
        (department: Department) =>
          department.departmentId === form.getFieldValue("n1Department")
      ),
    };
  }

  /**
   * needed for the duplicate validation.
   * checks if a basic role with the entered name already exists
   *
   * @param {string} newBasicRoleName entered basic role name
   * @returns true if it already exists, false if not
   */
  function checkIfRoleExists(newBasicRoleName: string): boolean {
    const newNameTrimmed = newBasicRoleName.trim();
    const initialValue = defaultBasicRole?.basicRoleName;

    if (newNameTrimmed === initialValue) {
      return false;
    }

    return basicRoles.some(
      (basicRole: BasicRole) => basicRole.basicRoleName === newNameTrimmed
    );
  }

  return (
    <Modal
      open={showModal}
      closable={false}
      title={defaultBasicRole ? "Edit Basic Role" : "Add Basic Role"}
      okText={defaultBasicRole ? "Save" : "Add Role"}
      okButtonProps={{ icon: <CheckOutlined />, size: "large" }}
      onOk={onSave}
      cancelButtonProps={{ icon: <CloseOutlined />, size: "large" }}
      onCancel={onCancel}
      width="470px"
      className="update-basic-role-modal"
      maskClosable={false}
    >
      <Form
        layout="vertical"
        initialValues={initialValues}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          name="basicRoleName"
          label="Basic Role"
          rules={getValidatorRulesForInput(checkIfRoleExists)}
        >
          <Input placeholder="Type basic role" />
        </Form.Item>

        <Form.Item name="area" label="Area">
          <Select
            placeholder="Select area"
            allowClear
            showSearch
            optionFilterProp="label"
            options={areas?.map((area: Area) => ({
              value: area.areaId,
              label: area.areaName,
            }))}
          />
        </Form.Item>

        <Form.Item name="n1Department" label="N-1 Department">
          <Select
            placeholder="Select department"
            allowClear
            showSearch
            optionFilterProp="label"
            options={_.sortBy(
              departments?.map((department: Department) => ({
                value: department.departmentId,
                label: `${department.orgName} (${department.orgUnit})`,
              })),
              ["label"]
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BasicRoleModal;
