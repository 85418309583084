import filterIcon from "@src/assets/icons/filter.svg";
import { Button, Tooltip } from "antd";

export interface FilterButtonProps {
  className?: string;
  disabled?: boolean;
  setOpenFilterDrawer?: (open: boolean) => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({
  className,
  disabled = false,
  setOpenFilterDrawer,
}) => {
  return (
    <Tooltip mouseEnterDelay={0.5} placement="top" title={"Filter"}>
      <Button
        className={className}
        htmlType="submit"
        icon={<img src={filterIcon} alt={"filter icon"} />}
        disabled={disabled}
        size="large"
        data-testid="filter-drawer-button"
        onClick={() => setOpenFilterDrawer(true)}
      />
    </Tooltip>
  );
};

export default FilterButton;
