import React, { useEffect, useRef, useState } from "react";

import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import WarningFilled from "@ant-design/icons/lib/icons/WarningFilled";
import { LoadingModal } from "@src/components/modals/loading_modal";
import { NoRowsOverlay } from "@src/components/overlays/no_rows_overlay";
import * as constants from "@src/constants";
import "@src/features/resource_plan/components/resource_plan/resource_plan.less";
import { FilterOpenRequests } from "@src/features/resource_plan/components/filter_open_requests/filter_open_requests";
import { SelectStandardRoleModal } from "@src/features/resource_plan/components/select_standard_role_modal";
import {
  GenericRoleRenderer,
  JointVentureRenderer,
  SimpleEditor,
} from "@src/features/resource_plan/custom_renderer";
import { useOpenRequestStateChangeHandler } from "@src/features/resource_plan/hooks/use_open_requests_state_change_handler";
import { getCommonColumnDefs } from "@src/features/resource_plan/utils/common_column_defs";
import { getGridOptions } from "@src/features/resource_plan/utils/open_requests_utils";
import {
  isNonZeroAllocations,
  updateFTEData,
  validateMonthlyAllocation,
} from "@src/features/resource_plan/utils/resource_planner_utils";
import ActiveFilterBar from "@src/features/table_filtering/components/active_filters_bar/active_filters_bar";
import FiltersBar from "@src/features/table_filtering/components/filters_bar/filters_bar";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import {
  setOpenRequestsColumns,
  setOpenRequestsQuickFilter,
} from "@src/services/resourcePlanSlice";
import { useSaveResourcePlanDataMutation } from "@src/services/slices/adminApi";
import {
  useDeleteRequestMutation,
  useSaveRequestMutation,
  useSendRequestsMutation,
} from "@src/services/slices/projectsSlice";
import { useAppDispatch, useAppSelector } from "@src/setupStore";
import {
  DeleteRequest,
  Project,
  ProjectRoleRequest,
  RequestSelection,
  SaveRequestPayload,
  SendRequestPayload,
  TableSettingsConfig,
} from "@src/types";
import { handleParallelScrollEvent } from "@src/utils/handle_scroll_utils";
import { getErrorMessage } from "@src/utils/helper";
import { getMonthlyAllocationCells } from "@src/utils/month_cell_renderer_utils";
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, Col, message, Modal, Row } from "antd";
import _ from "lodash";

export const statusRenderer = (params) => {
  return <>{<p>{params.value}</p>}</>;
};

interface OpenRequestProps {
  projectId: string;
  startDate: Date;
  endDate: Date;
  requests: ProjectRoleRequest[];
  callBack: (a: boolean, b: boolean) => void;
  selectedTable: boolean;
  isJointVenture: boolean;
  isAdminUpload?: boolean;
  gridApiRefCallback: (a: GridApi) => void;
  project: Project;
}

const OpenRequests: React.FC<OpenRequestProps> = ({
  projectId,
  startDate,
  endDate,
  requests,
  callBack,
  selectedTable,
  isJointVenture,
  isAdminUpload,
  gridApiRefCallback,
  project,
}) => {
  const gridApi = useRef(null);

  const [resourcePlan, setResourcePlan] = useState({
    openRequests: [],
    searchText: "",
    searchedColumn: "",
  });

  const [selectedRequests, setSelectedRequests] = useState<RequestSelection[]>(
    []
  );
  const [showModel, setShowModel] = useState<boolean>(false);
  const [showSelectStandardRoleModal, setShowSelectStandardRoleModal] =
    useState<boolean>(false);
  const [genericRoleNameFilterList, setGenericRoleNameFilterList] = useState(
    []
  );
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [context, setContext] = useState({});

  const [sendRequests] = useSendRequestsMutation();
  const [deleteRequests] = useDeleteRequestMutation();
  const [saveRequest] = useSaveRequestMutation();
  const [saveResourcePlan] = useSaveResourcePlanDataMutation();

  const roleRequestPayload = {
    requestID: -1,
    genericRoleId: null,
    columnName: null,
    value: "",
    requestedYear: null,
    requestedMonth: null,
    allocation: null,
    isAllocationRequest: false,
    jointVenture: false,
  };

  const fieldMap = {
    genericRoleName: "generic_role_id",
    jointVenture: "is_joint_venture",
    specificRole: "specific_role",
    projectIdentifier: "project_identifier",
    scope: "scope",
  };

  getMonthlyAllocationCells(startDate, endDate);

  const frameworkComponents = {
    genericRoleRenderer: GenericRoleRenderer,
    simpleEditorRenderer: SimpleEditor,
    statusRenderer: statusRenderer,
    jointVentureRenderer: JointVentureRenderer,
  };

  const columnDefs: ColDef[] = getCommonColumnDefs(
    "open",
    startDate,
    endDate
  ) as ColDef[];

  const dispatch = useAppDispatch();
  const {
    openRequestTableState: { quickFilter, columns },
  } = useAppSelector((state) => state.resourcePlanSlice);

  const { activeFilters, resetActiveFilters, setTableRendered } =
    useOpenRequestStateChangeHandler({
      gridApi,
      columnDefs,
    });

  useEffect(() => {
    if (resourcePlan) {
      const filterOptions = mapToTreeData(
        resourcePlan.openRequests,
        "genericRoleName"
      );
      setGenericRoleNameFilterList(filterOptions);
    }
  }, [resourcePlan]);

  useEffect(() => {
    setResourcePlan((r) => ({ ...r, openRequests: requests }));
  }, [requests]);

  useEffect(() => {
    if (gridApi.current && selectedTable != null && selectedTable === false) {
      const selectedNodes = gridApi.current.getCellRanges();
      if (selectedNodes.length > 0) {
        gridApi.current.clearRangeSelection();
      }
    }
  }, [selectedTable]);

  function changeSelectedRowError(requestId, isError) {
    const currentRow = selectedRequests.find(
      (selectedReq) => selectedReq.roleRequestId === requestId
    );
    currentRow.isError = isError;
    setSelectedRequests([...selectedRequests]);
  }

  async function sendSaveAllocationDetailsApi(
    request,
    updatedvalue,
    year,
    month,
    params
  ) {
    request.requestedMonth = month;
    request.requestedYear = year;
    request.allocation = updatedvalue;
    request.isAllocationRequest = true;
    await saveRoleRequestDetails(request, params.data);
  }

  function removeAnyErrorsOnCell(field, params) {
    if (parseInt(field) in params.data) {
      if (params.node.error) {
        if (Object.hasOwn.call(params.node.error, field)) {
          delete params.node.error[field];
        }
        // Check if there are any errors left in the node error object
        if (Object.keys(params.node.error).length === 0) {
          params.node.selectable = true;
        }
      }
    }
  }

  async function changeCellValue(params, year, month, updatedValue) {
    const updatedvalue = params.value !== "" ? updatedValue : "0";
    params.data.yearlyAllocations[year][month] = updatedvalue;

    if (updatedvalue === 0 || updatedValue === "0" || updatedValue === "") {
      delete params.data.yearlyAllocations[year][month];
      if (Object.keys(params.data.yearlyAllocations[year]).length === 0) {
        delete params.data.yearlyAllocations[year];
        if (Object.keys(params.data.yearlyAllocations).length === 0) {
          params.node.error = params.node.error ? params.node.error : {};
          params.node.error["yearlyAllocations"] = true;
        }
      }
    }
    params.node.yearlyAllocations = params.data.yearlyAllocations;
    params.node.data.yearlyAllocations = params.data.yearlyAllocations;

    // mark selected row as error to disable the send selected request button
    if (
      params.node.error &&
      Object.keys(params.node.error).length &&
      params.node.selected
    ) {
      changeSelectedRowError(params.data.projectRoleRequestId, true);
    }
  }

  function saveAdminRequestDetails(
    originalRequestId,
    updatedRequestId,
    updatedRowData
  ) {
    updatedRowData.projectRoleRequestId = updatedRequestId;
    const record = selectedRequests.find(
      (x) => x.roleRequestId === originalRequestId
    );

    if (record) {
      record.roleRequestId = originalRequestId;
      setSelectedRequests([...selectedRequests]);
    }
    const updatedResourcePlan = [...resourcePlan.openRequests];
    const index = updatedResourcePlan.findIndex(
      (request) => request.projectRoleRequestId === originalRequestId
    );

    updatedResourcePlan[index] = updatedRowData;
    setResourcePlan({ ...resourcePlan, openRequests: updatedResourcePlan });
  }

  function updateTableAfterSave(res, updatedRowData, originalRequestId) {
    updatedRowData.projectRoleRequestId = _.cloneDeep(res);

    const record = selectedRequests.find(
      (x) => x.roleRequestId === originalRequestId
    );
    if (record) {
      record.roleRequestId = _.cloneDeep(res);
      setSelectedRequests([...selectedRequests]);
    }
  }

  /**
   * calls the api for saving the request
   *
   * @param updatedRequest prepared payload
   * @param updatedRowData current params.data
   */
  async function saveRoleRequestDetails(updatedRequest, updatedRowData) {
    const originalRequestId = updatedRequest.requestID;

    if (isAdminUpload) {
      saveAdminRequestDetails(
        originalRequestId,
        updatedRequest.requestID,
        updatedRowData
      );
    } else {
      updatedRequest.requestID = Math.max(updatedRequest.requestID, -1);
      const saveRequestPayload: SaveRequestPayload = {
        projectId: parseInt(projectId),
        updatedRequest: updatedRequest,
      };

      saveRequest(saveRequestPayload)
        .unwrap()
        .then((res) => {
          updateTableAfterSave(res, updatedRowData, originalRequestId);
        })
        .catch(console.error);
    }
  }

  // select role modal functions
  async function onFinishSelectStandardRole(fieldsValue) {
    const values = {
      ...fieldsValue,
    };

    const genericRoleName = values.roleSpecification
      ? values.basicRole + " " + values.roleSpecification
      : values.basicRole;

    const updatedResourcePlan = [...resourcePlan.openRequests];

    const rowNode: IRowNode = gridApi.current.getRowNode(
      updatedResourcePlan[0].projectRoleRequestId
    );

    rowNode.setDataValue("genericRoleName", genericRoleName);
    rowNode.data.genericRoleId = values.genericRoleId;
    setResourcePlan({ ...resourcePlan, openRequests: updatedResourcePlan });
  }

  function addRole() {
    let maxKey = 0;
    if (resourcePlan.openRequests?.length > 0) {
      maxKey = Math.max(
        ...resourcePlan.openRequests.map((request) => request.key)
      );
    }

    const request = {
      key: maxKey + 1,
      status: constants.REQUEST_STATUS.DRAFT,
      jointVenture: false,
      projectRoleRequestId: -maxKey - 1,
      yearlyAllocations: {},
    };

    const updatedResourcePlan = [...resourcePlan.openRequests];
    updatedResourcePlan.splice(0, 0, request);

    setResourcePlan({ ...resourcePlan, openRequests: updatedResourcePlan });
    setSelectedRequests([]);
    setShowSelectStandardRoleModal(true);
  }

  function onCancelModal() {
    const updatedResourcePlan = [...resourcePlan.openRequests];
    updatedResourcePlan.shift();
    setResourcePlan({ ...resourcePlan, openRequests: updatedResourcePlan });
    setSelectedRequests([]);
    setShowSelectStandardRoleModal(false);
  }

  async function handleAdminUpload() {
    setIsFileUploading(true);
    setLoadingMessage({
      text: "loading ...",
    });
    const payload = [];

    selectedRequests.forEach((selectedRequest) => {
      const requestToBeSent = resourcePlan.openRequests.find(
        (openRequest) =>
          openRequest.projectRoleRequestId === selectedRequest.roleRequestId
      );
      payload.push(requestToBeSent);
    });

    saveResourcePlan({ projectId: projectId, payload: payload })
      .unwrap()
      .then(() => {
        const updatedResourcePlan = [...resourcePlan.openRequests];

        payload.forEach((sentRequest) => {
          const index = updatedResourcePlan.findIndex(
            (request) =>
              request.projectRoleRequestId === sentRequest.projectRoleRequestId
          );
          updatedResourcePlan.splice(index, 1);
        });

        setResourcePlan({ ...resourcePlan, openRequests: updatedResourcePlan });
        gridApi.current.redrawRows();
      })
      .catch(() =>
        console.log("Something went wrong. Requests could not be uploaded")
      );

    setIsFileUploading(false);
  }

  // send button function
  async function sendSelectedRequest() {
    if (isAdminUpload) {
      await handleAdminUpload();
    } else {
      const convertToPayload = (
        requests: RequestSelection[]
      ): SendRequestPayload[] => {
        return requests.map((request) => ({
          roleRequestId: request.roleRequestId,
          genericRoleId: request.genericRoleId,
        }));
      };
      sendRequests(convertToPayload(selectedRequests))
        .unwrap()
        .then(() => {
          setSelectedRequests([]);
          message.success("Request(s) sent successfully");
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    }
  }

  function updateTableAfterDelete() {
    const updatedResourcePlan = [...resourcePlan.openRequests];

    for (const selectedRequest of selectedRequests) {
      const index = updatedResourcePlan.indexOf(
        updatedResourcePlan.find(
          (x) => x.projectRoleRequestId === selectedRequest.roleRequestId
        )
      );
      updatedResourcePlan.splice(index, 1);
    }

    setResourcePlan({ ...resourcePlan, openRequests: updatedResourcePlan });
    setSelectedRequests([]);
  }

  // delete button functions
  async function deleteSelectedRequest() {
    if (isAdminUpload) {
      updateTableAfterDelete();
    } else {
      const deleteOnServerIds: DeleteRequest[] = selectedRequests
        .filter((request) => request.roleRequestId >= 0)
        .map((request) => ({ requestID: request.roleRequestId }));

      if (deleteOnServerIds.length) {
        deleteRequests(deleteOnServerIds)
          .unwrap()
          .then(() => {
            updateTableAfterDelete();
          })
          .catch(console.error);
      }
    }
    setShowModel(false);
  }

  async function confirmDeleteSelectedRequest() {
    const anyNonZeroEntries = Math.max(
      ...selectedRequests.map((req) => (req.roleRequestId >= 0 ? 1 : 0))
    );

    if (anyNonZeroEntries) {
      setShowModel(true);
    } else {
      await deleteSelectedRequest();
    }
  }

  // ag grid functions
  function onGridReady(params: GridReadyEvent) {
    gridApi.current = params.api;
    gridApiRefCallback?.(gridApi.current);

    if (isJointVenture && !isAdminUpload) {
      params.api.setColumnsVisible(["jointVenture"], true);
    }

    handleParallelScrollEvent(
      ".open-requests .ag-horizontal-left-spacer",
      ".open-requests .ag-pinned-left-header"
    );

    handleParallelScrollEvent(
      ".open-requests .ag-horizontal-left-spacer",
      ".open-requests .ag-pinned-left-cols-container"
    );

    handleParallelScrollEvent(
      ".sent-requests .ag-body-horizontal-scroll-viewport",
      ".open-requests .ag-body-horizontal-scroll-viewport"
    );

    const scrollContainer = document.querySelector(
      ".open-requests .ag-horizontal-left-spacer"
    );
    if (scrollContainer) {
      const node = document.createElement("div");
      node.className = "scrollContent";
      scrollContainer.appendChild(node);
    }
  }

  function updateAllocations(params, request) {
    const splitColId = params.colDef.colId.split("_");
    const year = +splitColId[0];
    const month = +splitColId[1];
    const field = params.colDef.field;
    const yearlyAllocation = params.data.yearlyAllocations[year];
    const currentValue = yearlyAllocation ? yearlyAllocation[month] : "0";

    if (params.value === currentValue?.toString()) return;

    const validatedResult = validateMonthlyAllocation(params, year, month);
    params.data.yearlyAllocations[year] = yearlyAllocation || {};
    const updatedValue = validatedResult.updatedValue;

    if (validatedResult.isValid) {
      removeAnyErrorsOnCell(field, params);
      sendSaveAllocationDetailsApi(request, updatedValue, year, month, params);
    }

    if (params.node.selected) {
      const anyNonZeroEntries = isNonZeroAllocations(
        params.node.data.yearlyAllocations
      );
      if (params?.node.error["yearlyAllocations"] && anyNonZeroEntries) {
        delete params.node.error["yearlyAllocations"];
        params.api.redrawRows();
      }
    }
    changeCellValue(params, year, month, updatedValue);
    params.api.applyTransaction({ update: [params.node] });
  }

  function updateGenericRoleName(params, payload) {
    if (!params.data.genericRoleName) return;

    if (params.node.error && params.colDef.field in params.node.error) {
      delete params.node.error[params.colDef.field];
    }
    if (params.node.error && Object.keys(params.node.error).length === 0) {
      params.node.selectable = true;
    }
    payload.columnName = fieldMap[params.colDef.field];
    payload.genericRoleId = params.data.genericRoleId;
    saveRoleRequestDetails(payload, params.data);
    params.api.refreshCells({ force: true });
  }

  function updateField(params, request) {
    request.columnName = fieldMap[params.colDef.field];
    request.value = params.value;
    params.node.error && delete params.node.error[params.colDef.field];
    saveRoleRequestDetails(request, params.data);
  }

  function onCellValueChanged(params) {
    params.value = params.data[params.colDef.field];
    const request = { ...roleRequestPayload };
    request.requestID = params.data.projectRoleRequestId;
    if (
      params.colDef?.colId?.match(/^\d{4}_\d{1,2}$/) // matches "yyyy_mm" or "yyyy_m"
    ) {
      updateAllocations(params, request);
    } else if (params.colDef?.field === "genericRoleName") {
      updateGenericRoleName(params, request);
    } else {
      updateField(params, request);
    }

    if (!params.node.error || Object.keys(params.node.error).length === 0) {
      const record = selectedRequests.find(
        (x) => x.roleRequestId === request.requestID
      );
      if (record) {
        delete record.isError;
        setSelectedRequests([...selectedRequests]);
      }
    }
    params.api.refreshCells(params);
  }

  function checkAnyNonZeroEntries(params, node) {
    const anyNonZeroEntries = isNonZeroAllocations(
      JSON.parse(JSON.stringify(node.data.yearlyAllocations))
    );

    if (!node.data["genericRoleName"] || !anyNonZeroEntries) {
      node.error = {};

      let errorMsg = "Missing Fields: please enter a ";
      if (!anyNonZeroEntries) {
        node.error["yearlyAllocations"] = true;
        errorMsg = errorMsg + " allocation ";
      }

      if (!node.data["genericRoleName"]) {
        node.error["genericRoleName"] = true;

        if (!anyNonZeroEntries) {
          errorMsg = errorMsg + " and standard role ";
        } else {
          errorMsg = errorMsg + " standard role ";
        }
      }

      getErrorMessage(errorMsg);
      params.api.redrawRows();
    } else if (node.error) {
      delete node.error.yearlyAllocations;
    }
  }

  function onSelectionChanged(params: SelectionChangedEvent) {
    const selectedNodes = gridApi.current.getSelectedNodes();
    const requests: RequestSelection[] = [];

    selectedNodes.forEach((node) => {
      if (node.data.status !== constants.REQUEST_STATUS.DRAFT) {
        node.selectable = false;
        node.selected = false;
        params.api.redrawRows({ rowNodes: [node] });
      }

      checkAnyNonZeroEntries(params, node);

      if (node.selected) {
        requests.push({
          roleRequestId: node.data.projectRoleRequestId,
          genericRoleId: node.data.genericRoleId,
          isError: node.error && Object.keys(node.error).length !== 0,
        });
      }
    });

    setSelectedRequests(requests);
  }

  function onRangeSelectionChanged() {
    const selectedNodes = gridApi.current.getCellRanges();
    if (selectedNodes.length > 0) {
      callBack(true, null);
    }
  }

  function getTableSettings(): TableSettingsConfig {
    return {
      columns: columnDefs,
      onChange: (columns: string[]) => {
        dispatch(setOpenRequestsColumns(columns));
      },
      selectedColumns: columns,
      columnsToHide: project?.jointVenturePartner ? [] : ["JV"],
    };
  }

  function getExtraButtons() {
    return (
      <>
        <Button
          type="primary"
          className="delete-requests"
          danger
          size="large"
          icon={<DeleteOutlined />}
          disabled={selectedRequests.length === 0}
          onClick={confirmDeleteSelectedRequest}
        >
          Delete row
        </Button>

        <SelectStandardRoleModal
          setModalVisible={setShowSelectStandardRoleModal}
          modalVisible={showSelectStandardRoleModal}
          onFinishForm={onFinishSelectStandardRole}
          onCancelModal={onCancelModal}
          project={project}
        />

        <Button
          size="large"
          icon={<MailOutlined />}
          type="primary"
          disabled={
            selectedRequests.length === 0 ||
            !!selectedRequests.filter((s) => s.isError).length
          }
          onClick={sendSelectedRequest}
        >
          Send selected requests
        </Button>
      </>
    );
  }

  function getPrimaryActionButton() {
    return (
      <Button
        icon={<PlusOutlined />}
        type="primary"
        id="add-role-button"
        data-testid="add-role-button"
        size="large"
        onClick={addRole}
      >
        Add role
      </Button>
    );
  }

  function onQuickFilterChange(value: string) {
    dispatch(setOpenRequestsQuickFilter(value));
  }

  function getFilters() {
    return (
      <FilterOpenRequests disabled={false} roles={genericRoleNameFilterList} />
    );
  }

  return (
    <div>
      <div style={{ marginTop: "20px" }}>
        <FiltersBar
          drawerTitle="Filter draft role requests"
          extraButtons={getExtraButtons()}
          tableSettings={getTableSettings()}
          quickFilterDefaultValue={quickFilter}
          onQuickFilterChange={onQuickFilterChange}
          filters={getFilters()}
          primaryActionButton={getPrimaryActionButton()}
        />
        <ActiveFilterBar
          activeFilters={activeFilters}
          onRemove={(key: string, value: string) =>
            resetActiveFilters(key, value)
          }
          onRemoveAll={() => resetActiveFilters()}
        />
      </div>
      <div
        id={"openRequests"}
        className={`open-requests ag-theme-alpine header-white ${
          resourcePlan.openRequests && resourcePlan.openRequests.length === 0
            ? ""
            : "scroll-default"
        }`}
      >
        <AgGridReact
          key={resourcePlan.openRequests?.length}
          gridOptions={getGridOptions(columnDefs) as GridOptions}
          rowData={resourcePlan.openRequests}
          onGridReady={onGridReady}
          components={frameworkComponents}
          onSelectionChanged={onSelectionChanged}
          onCellValueChanged={onCellValueChanged}
          onRangeSelectionChanged={onRangeSelectionChanged}
          pinnedTopRowData={
            resourcePlan.openRequests?.length === 0 ? undefined : [{}]
          }
          noRowsOverlayComponent={NoRowsOverlay}
          noRowsOverlayComponentParams={{
            text: "No requests found",
            customStyle: { marginTop: "97px" },
          }}
          onRowDataUpdated={(params) => updateFTEData(params.api, setContext)}
          onFilterChanged={(params) => updateFTEData(params.api, setContext)}
          onFirstDataRendered={setTableRendered}
          context={context}
        />
      </div>
      <Modal
        width="30%"
        open={showModel}
        centered
        closable={false}
        bodyStyle={{ width: "100%" }}
        footer={[
          <Button
            data-testid="cancel"
            key="back"
            onClick={() => setShowModel(false)}
          >
            Cancel
          </Button>,

          <Button
            danger
            data-testid="delete-button"
            key="delete"
            onClick={() => {
              deleteSelectedRequest();
            }}
          >
            Delete
          </Button>,
        ]}
      >
        <Row>
          <Col>
            <WarningFilled className="warningIcon" />
          </Col>
          <Col>
            <div className={"warning-title"}>
              Are you sure you want to delete all selected rows?
            </div>
            <p>Data cannot be restored.</p>
          </Col>
        </Row>
      </Modal>
      {isAdminUpload && isFileUploading && (
        <LoadingModal
          modalVisible={isFileUploading}
          loadingMessage={loadingMessage}
        />
      )}
    </div>
  );
};

export default OpenRequests;
