import { SortSpec } from "@src/types";

export function getSortParameters<U extends SortSpec<any>>(
  sortSpec: U[]
): object {
  if (!sortSpec) return undefined;

  return sortSpec
    .filter((spec) => spec.field)
    .reduce(
      (acc, spec) => ({
        ...acc,
        sort: `${spec.field},${spec.direction}`,
      }),
      {}
    );
}
