// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.split-role-transfer-tree-left__no-requests {
  display: flex;
  padding: 16px;
}
.split-role-transfer-tree-left__no-requests svg {
  font-size: 25px;
  margin-right: 10px;
  color: #5ab88f;
}
`, "",{"version":3,"sources":["webpack://./src/features/role_split/components/requests_to_select_tree/requests_to_select_tree.less"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,aAAA;AAAJ;AAFE;EAKI,eAAA;EACA,kBAAA;EACA,cAAA;AAAN","sourcesContent":[".split-role-transfer-tree-left {\n  &__no-requests {\n    display: flex;\n    padding: 16px;\n\n    svg {\n      font-size: 25px;\n      margin-right: 10px;\n      color: #5ab88f;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
