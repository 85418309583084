import React from "react";

import { AllocationDetailsTable } from "@src/components/allocation_details_table";
import { NoteHistory } from "@src/components/note/note_history";
import { REQUEST_STATUS } from "@src/constants";
import { RequestIdTransformation } from "@src/utils/helper";
import { Col, Row } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import moment from "moment";

import { RequestRepliesActionRenderer } from "..";

const FullWidthCellRenderer = (params, gridApi) => {
  const {
    node: { data: requestData },
  } = params;

  return (
    <div data-testid="moreInfo" className={"request-info"}>
      <Paragraph className={" details-top highlight details-left "}>
        {requestData["isOutdated"] && (
          <label>This request is outdated. Please update or delete it</label>
        )}
        {requestData["isProposalWithdrawn"] && (
          <label>The initially proposed team member has been taken back</label>
        )}
        {(requestData.status === REQUEST_STATUS.PROPOSED ||
          requestData.status === REQUEST_STATUS.DECLINED) &&
          requestData["isProposalWithdrawn"] && (
            <label> Please review this new proposal</label>
          )}
        {requestData["isEmployeeDeleted"] && (
          <label>
            {" "}
            The proposed employee has been deleted and the request status set
            back to requested{" "}
          </label>
        )}
      </Paragraph>
      <Row>
        <Col sm={8} className={"details details-left details-top"}>
          <Paragraph data-testid="requestIdLabel">
            <label>Request ID:</label>{" "}
            {RequestIdTransformation(
              requestData.parentRequestId.toString(),
              requestData.projectName
            )}
          </Paragraph>
          <Paragraph data-testid="requestSendLabel">
            <label>Request sent:</label>{" "}
            {moment(requestData.requestSentDate).format("Do MMMM YYYY")}
          </Paragraph>
          <Paragraph data-testid="initiallySentToLabel">
            <label>Initially sent to:</label> {requestData.teamLead}
          </Paragraph>
          {requestData.forwardedTeamlead && (
            <Paragraph data-testid="initiallySentToLabel">
              <label>Forwarded to:</label> {requestData.forwardedTeamlead}
            </Paragraph>
          )}
        </Col>

        <Col sm={8} className={"details"}>
          {(requestData.status === REQUEST_STATUS.CONFIRMED ||
            requestData.status === REQUEST_STATUS.PROPOSED) &&
          requestData.assignedTeamMember ? (
            <div>
              <Paragraph data-testid="confirmedOrProposedPersonLabel">
                {requestData.status === REQUEST_STATUS.CONFIRMED ? (
                  <label>Confirmed person</label>
                ) : (
                  <label>Proposed person</label>
                )}{" "}
                <label>:</label> {requestData.assignedTeamMember}
              </Paragraph>
              <Paragraph data-testid="standardRoleLabel">
                <label>Standard role:</label> {requestData.genericRoleName}
              </Paragraph>
              <Paragraph>
                <label>Located in:</label> {requestData.country}
              </Paragraph>
              <Paragraph>
                <label> Contract type:</label> {requestData.contractType}
              </Paragraph>
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row>
        <Col span={16} className={"details details-left details-top"}>
          <Paragraph>
            <label>Comments</label>
          </Paragraph>
          <NoteHistory requestId={requestData.parentRequestId} />
        </Col>
        <br />
      </Row>
      {(requestData.status === REQUEST_STATUS.PROPOSED ||
        requestData.status === REQUEST_STATUS.DECLINED) &&
        requestData.proposedYearlyAllocations &&
        Object.keys(requestData.proposedYearlyAllocations).length > 0 &&
        !requestData.isOutdated && (
          <div className={"allocations"}>
            <div className={"tl-suggested-msg-with-action"}>
              <Paragraph className={"highlight"}>
                The team lead suggested a different workload:
              </Paragraph>
            </div>
            <AllocationDetailsTable requestData={requestData} />
          </div>
        )}

      <Row>
        <Col span={16} className={"details details-left details-top"}>
          <RequestRepliesActionRenderer params={params} gridApi={gridApi} />
        </Col>
      </Row>
    </div>
  );
};

export default FullWidthCellRenderer;
