import { Modal } from "antd";
import { ProjectForm } from "@src/features/projects/components/project_form";
import React from "react";
import Cookies from "universal-cookie";
import { navigate } from "@reach/router";
import { PROJECT_FORM_VARIANT, ROUTES } from "@src/constants";

const cookies = new Cookies();

const EditProjectModal = ({ project, setModalVisible, modalVisible }) => {
  const empObj = cookies.get("loggedInuser");

  const onSave = (projectManagerName, editorNames) => {
    if (
      empObj.fullName === projectManagerName ||
      editorNames.includes(empObj.fullName)
    ) {
      navigate(0);
    } else {
      navigate(ROUTES.PROJECT_LEAD.PROJECTS);
    }
  };

  const onCancel = () => {
    setModalVisible(false);
  };

  if (modalVisible) {
    return (
      <Modal
        width="60%"
        open={modalVisible}
        title="Project details"
        centered
        closable={false}
        bodyStyle={{ width: "100%" }}
        footer={null}
      >
        <ProjectForm
          project={project}
          onProjectSaved={onSave}
          onCancel={onCancel}
          variant={PROJECT_FORM_VARIANT.EDIT}
        />
      </Modal>
    );
  } else {
    return null;
  }
};

export default EditProjectModal;
