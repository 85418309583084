import React, { ReactNode, useEffect, useState } from "react";

import WarningFilled from "@ant-design/icons/lib/icons/WarningFilled";
import "./delete_project_confirmation_modal.less";
import { REQUEST_STATUS } from "@src/constants";
import {
  useDeleteProjectMutation,
  useGetRequestsRepliesQuery,
} from "@src/services/slices/projectsSlice";
import { App, Col, Modal, Radio, Row } from "antd";

const projectDeletionWarning =
  "You are about to delete this project. All requests will be deleted. Are you sure?";

const inRecruitmentProjectDeletionWarning = (
  <>
    <p>Attention!</p>
    <p>
      You are about to delete this project which includes at least one request
      with the status <label>"in recruitment"</label>.
    </p>
    <p>Does the Team lead know about the deletion of this project?</p>
  </>
);

interface DeleteProjectConfirmationModalProps {
  setModalVisible: (modalVisible: boolean) => void;
  modalVisible: boolean;
  projectId: number;
  onDeleteCallback?: () => void;
}

const DeleteProjectConfirmationModal: React.FC<
  DeleteProjectConfirmationModalProps
> = ({ setModalVisible, modalVisible, projectId, onDeleteCallback }) => {
  const { message } = App.useApp();

  const [acceptDelete, setAcceptDelete] = useState<boolean>(false);
  const [hasInRecruitmentStatus, setHasInRecruitmentStatus] =
    useState<boolean>(false);
  const [content, setContent] = useState<ReactNode>(projectDeletionWarning);

  const [deleteProject] = useDeleteProjectMutation();
  const { data: requestReplies, isSuccess: isSuccessRequests } =
    useGetRequestsRepliesQuery(projectId.toString());

  useEffect(() => {
    if (isSuccessRequests) {
      const hasInRecruitmentStatus = requestReplies.some(
        (request) => request.status === REQUEST_STATUS.IN_RECRUITMENT
      );
      setHasInRecruitmentStatus(hasInRecruitmentStatus);
      if (hasInRecruitmentStatus) {
        setContent(inRecruitmentProjectDeletionWarning);
      }
    }
  }, [isSuccessRequests]);

  async function onDelete() {
    try {
      await deleteProject(projectId).unwrap();
      message.success("Project successfully deleted");
      onDeleteCallback?.();
      setModalVisible(false);
    } catch (error) {
      console.log(`not able to delete project with id: ${projectId}`);
    }
  }

  return (
    <Modal
      open={modalVisible}
      centered
      closable={false}
      okButtonProps={
        !acceptDelete && hasInRecruitmentStatus
          ? { type: "text" }
          : { type: "primary", danger: true }
      }
      okText={
        !acceptDelete && hasInRecruitmentStatus ? (
          <Radio value={true}>Yes, I double checked it.</Radio>
        ) : (
          "Delete"
        )
      }
      onOk={
        !acceptDelete && hasInRecruitmentStatus
          ? () => setAcceptDelete(true)
          : onDelete
      }
      onCancel={() => setModalVisible(false)}
    >
      <Row className="warning-row">
        <Col>
          <WarningFilled className="warningIcon" />
        </Col>
        <Col>
          <div className="warning-title">Delete project</div>
          <div>{content}</div>
        </Col>
      </Row>
    </Modal>
  );
};

export default DeleteProjectConfirmationModal;
