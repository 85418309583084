import { EndpointBuilder } from "@reduxjs/toolkit/src/query/endpointDefinitions";
import { baseApiSlice } from "@src/services/slices/baseApiSlice";
import { NotificationsCount } from "@src/types";
import { message } from "antd";

export const layoutApi = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    /**
     * Load notifications
     */
    getNotificationsCount: builder.query<NotificationsCount[], void>({
      query: () => "/layout/getTabNotificationCount",
      transformErrorResponse: async () => {
        message.error("Failed to load notifications", 5);
      },
      providesTags: ["Notifications"],
    }),
  };
}

export const {
  /*GET endpoints */
  useGetNotificationsCountQuery,
} = layoutApi;
