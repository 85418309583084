import React from "react";

import { COLORS, REQUEST_STATUS } from "@src/constants";
import { Avatar, Badge } from "antd";
import "./name_circle_renderer.less";

function NameCircleRenderer({ record, colorsMembers, value, index }) {
  if (value?.status === REQUEST_STATUS?.PROPOSED) {
    return (
      <span
        className="notificationCount tag-proposed"
        data-testid="proposed-tag"
      >
        proposed
      </span>
    );
  }

  // expanded time allocation circle renderer.
  // When the tl expands the team allocation than it should not show a cirlce
  if (!value?.fullName) {
    return null;
  }

  //name circle for vacancies
  if (!record?.employeeId) {
    return (
      <Avatar
        style={{ backgroundColor: COLORS.AVATAR_VACANCY }}
        shape="circle"
        data-testid={"vac-avatar"}
      >
        {"#"}
      </Avatar>
    );
  }

  return (
    <Badge
      count={value?.numberOfProposedProjects}
      className="badge"
      data-testid="badge"
      color={COLORS.LIGHT_ORANGE}
      size="small"
    >
      <Avatar
        style={{ backgroundColor: colorsMembers[index % 5] }}
        shape="circle"
        data-testid={"avatar"}
      >
        {value?.fullName.substring(0, 1)}
      </Avatar>
    </Badge>
  );
}

export default NameCircleRenderer;
