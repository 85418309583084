import React from "react";

import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import { months } from "@src/constants";
import {
  useAddTimeBlockerMutation,
  useUpdateTimeBlockerMutation,
} from "@src/services/slices/teamLeadsApi";
import {
  Allocation,
  TimeBlockerFormValue,
  TimeBlocker,
  TimeBlockerRequestPayload,
} from "@src/types";
import { Button } from "antd";

interface InEditTimeBlockerActionsProps {
  resetTimeBlocker: (record: TimeBlocker) => void;
  record: TimeBlocker;
  formValues: TimeBlockerFormValue[];
  resetTimeBlockerInEdit: (record: TimeBlocker) => void;
}

const InEditTimeBlockerActions: React.FC<InEditTimeBlockerActionsProps> = ({
  resetTimeBlocker,
  record,
  formValues,
  resetTimeBlockerInEdit,
}) => {
  const row: TimeBlockerFormValue[] = formValues;
  const timeBlockerDescription: string = row["Team member"]?.isFieldTouched
    ? row["Team member"].value
    : record.specificRole;

  const [addTimeBlocker] = useAddTimeBlockerMutation();
  const [updateTimeBlocker] = useUpdateTimeBlockerMutation();

  function anyNumericValuesInTimeBlocker(): boolean {
    for (const key in formValues) {
      const value: number = formValues[key].value;

      if (value > 0 && key !== "Team member") {
        return true;
      }
    }
  }

  function addTimeBlockerElement(
    timeBlockerPayload: TimeBlockerRequestPayload
  ): void {
    addTimeBlocker({
      teamMemberId: record.teamMemberId,
      timeBlockerPayload: timeBlockerPayload,
    })
      .unwrap()
      .then((projectRoleRequestId: number) => {
        delete record.isNewTimeBlocker;
        record.projectRoleRequestId = projectRoleRequestId;
        record.projectName = "Time Blocker";
        record.allocations = timeBlockerPayload.timeBlockers;
        record.specificRole = timeBlockerPayload.timeBlockerDescription;
        resetTimeBlockerInEdit(record);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  function updateTimeBlockerElement(
    timeBlockerPayload: TimeBlockerRequestPayload
  ): void {
    updateTimeBlocker({
      projectRoleRequestId: record.projectRoleRequestId,
      timeBlockerPayload: timeBlockerPayload,
    })
      .unwrap()
      .then(() => {
        record.specificRole = timeBlockerPayload.timeBlockerDescription;
        timeBlockerPayload.timeBlockers.forEach((allocation: Allocation) => {
          const currentMonthAllocation: Allocation[] =
            record.allocations.filter(
              (prevAllocs: Allocation) =>
                prevAllocs.year === allocation.year &&
                prevAllocs.month === allocation.month
            );
          if (currentMonthAllocation[0]) {
            currentMonthAllocation[0].requiredPercentage =
              allocation.requiredPercentage;
          } else {
            record.allocations.push(allocation);
          }
        });
        resetTimeBlockerInEdit(record);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  function parseAllocations(
    row: TimeBlockerFormValue[],
    allocationsToSend: Allocation[]
  ): void {
    for (const key in row) {
      if (row[key].isFieldTouched) {
        const yearMonthsArray = key.split("_");
        allocationsToSend.push({
          year: parseInt(yearMonthsArray[0]),
          month: months.indexOf(yearMonthsArray[1]) + 1,
          requiredPercentage: row[key].value,
        });
      }
    }
  }

  async function saveTimeBlocker(): Promise<void> {
    delete row["Team member"];

    const allocationsToSend: Allocation[] = [];

    parseAllocations(row, allocationsToSend);
    const requestPayload: TimeBlockerRequestPayload = {
      timeBlockers: allocationsToSend,
      timeBlockerDescription: timeBlockerDescription,
    };
    if (record.isNewTimeBlocker) {
      addTimeBlockerElement(requestPayload);
    } else {
      updateTimeBlockerElement(requestPayload);
    }
  }

  return (
    <div className="set-reset-button">
      <Button
        data-testid="closeBtn"
        onClick={() => resetTimeBlocker(record)}
        icon={<CloseOutlined />}
      />
      <Button
        data-testid="save-time-blocker-button"
        onClick={saveTimeBlocker}
        type="primary"
        className="submit"
        disabled={!anyNumericValuesInTimeBlocker()}
        icon={<CheckOutlined />}
      />
    </div>
  );
};

export default InEditTimeBlockerActions;
