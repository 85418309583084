import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Retrieve toggled features from localStorage if they exist
const storedToggledFeatures = localStorage.getItem("toggledFeatures");
const initialState: any = {
  featureToggleState: {
    toggledFeatures: storedToggledFeatures
      ? JSON.parse(storedToggledFeatures)
      : {}, // Initialize from localStorage if available
  },
};

const featureToggleSlice = createSlice({
  name: "featureToggle",
  initialState: initialState,
  reducers: {
    /**
     * Sets the toggled features so that the admin can toggle on or off specific in-development features.
     *
     * @param state           the state of the toggled features.
     * @param toggledFeatures the toggledFeatures which are toggled on or off.
     */
    setToggledFeatures: (state: any, action: PayloadAction<any>) => {
      // Ensure immutability by spreading state
      state.featureToggleState = {
        ...state.featureToggleState,
        toggledFeatures: action.payload,
      };
      // Save the toggled features to localStorage whenever they are updated
      localStorage.setItem("toggledFeatures", JSON.stringify(action.payload));
    },
  },
});

export const { setToggledFeatures } = featureToggleSlice.actions;

export default featureToggleSlice;
