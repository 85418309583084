// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.status-section Button {
  margin-right: 10px;
}
.status-section .orange {
  background: orange;
  color: white;
}
.status-section .orange:hover,
.status-section .orange:focus,
.status-section .orange:active {
  background: rgba(255, 165, 0, 0.8);
  color: white;
}
.status-section .black {
  background: black;
  color: white;
}
.status-section .black:hover,
.status-section .black:focus,
.status-section .black:active {
  background: rgba(0, 0, 0, 0.8);
  color: white;
}
.status-section .grey {
  background: grey;
  color: white;
}
.status-section .grey:hover,
.status-section .grey:focus,
.status-section .grey:active {
  background: rgba(0, 0, 0, 0.8);
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/features/sap_upload/components/upload_result/upload_result.less"],"names":[],"mappings":"AAAA;EAaE,aAAA;EACA,8BAAA;EACA,mBAAA;AAXF;AAJA;EAkBI,kBAAA;AAXJ;AAPA;EAEI,kBAAA;EACA,YAAA;AAQJ;AANI;;;EAGE,kCAAA;EACA,YAAA;AAQN;AAjBA;EAEI,iBAAA;EACA,YAAA;AAkBJ;AAhBI;;;EAGE,8BAAA;EACA,YAAA;AAkBN;AA3BA;EAEI,gBAAA;EACA,YAAA;AA4BJ;AA1BI;;;EAGE,8BAAA;EACA,YAAA;AA4BN","sourcesContent":[".status-section {\n  .main-button-props(@background, @background-color) {\n    background: @background;\n    color: white;\n\n    &:hover,\n    &:focus,\n    &:active {\n      background: @background-color;\n      color: white;\n    }\n  }\n\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 20px;\n\n  Button {\n    margin-right: 10px;\n  }\n\n  .orange {\n    .main-button-props(orange, rgba(255, 165, 0, 0.8));\n  }\n\n  .black {\n    .main-button-props(black, rgba(0, 0, 0, 0.8));\n  }\n\n  .grey {\n    .main-button-props(grey, rgba(0, 0, 0, 0.8));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
