import React, { useState } from "react";

import WarningFilled from "@ant-design/icons/lib/icons/WarningFilled";
import "./confirmation_modal.less";
import { Button, Col, Modal, Radio, Row } from "antd";

function ConfirmationModal({
  action,
  setModalVisible,
  modalVisible,
  modalMessage,
}) {
  const [acceptDelete, setAcceptDelete] = useState(false);

  const handleOk = () => {
    if (modalMessage.selectedRowToDelete) {
      action(modalMessage.selectedRowToDelete);
    } else {
      action();
    }

    setModalVisible(false);
  };

  const button =
    modalMessage?.btnType === "danger" ? (
      <Button type="primary" danger onClick={handleOk}>
        {" "}
        {modalMessage.button}
      </Button>
    ) : (
      <Button type="primary" onClick={handleOk}>
        {" "}
        {modalMessage.button}
      </Button>
    );

  return (
    <>
      <Modal
        data-testid="confirmation-modal"
        width="30%"
        open={modalVisible}
        centered
        closable={false}
        bodyStyle={{ width: "100%" }}
        footer={
          <>
            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
            {!acceptDelete && modalMessage.hasInRecruitmentStatus ? (
              <Radio
                data-testid="doubleCheckRB"
                onClick={() => setAcceptDelete(true)}
                value={true}
              >
                Yes, I double checked it.
              </Radio>
            ) : (
              button
            )}
          </>
        }
      >
        <Row className="warning-row">
          <Col>
            <WarningFilled className="warningIcon" />
          </Col>
          <Col>
            <div className="warning-title">{modalMessage.title}</div>
            <div className="warning-msg">{modalMessage.warning}</div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
