import React, { useEffect, useState } from "react";

import * as constants from "@src/constants";
import { ResourcePlanDownloadButton } from "@src/features/resource_plan/components/resource_plan_download_button";
import { clearFilters } from "@src/services/resourcePlanSlice";
import {
  useGetRequestsQuery,
  useGetRequestsRepliesQuery,
} from "@src/services/slices/projectsSlice";
import { useAppDispatch } from "@src/setupStore";
import {
  ParentChildIdMap,
  Project,
  ProjectRoleRequest,
  RequestReplies,
  SentRequest,
} from "@src/types";
import { GridApi } from "ag-grid-community";
import { Card } from "antd";
import _ from "lodash";

import { OpenRequests } from "../open_requests";
import { SentRequests } from "../sent_requests";

import "./resource_plan.less";

interface ResourcePlanProps {
  id: string;
  startDate: Date;
  endDate: Date;
  project: Project;
}

const ResourcePlan: React.FC<ResourcePlanProps> = ({
  id,
  startDate,
  endDate,
  project,
}) => {
  const [openRequestsGridApi, setOpenRequestsGridApi] = useState(null);
  const [sendRequestsGridApi, setSendRequestsGridApi] = useState(null);

  const [resourcePlan, setResourcePlan] = useState<{
    sentRequests: SentRequest[];
    openRequests: ProjectRoleRequest[];
  }>({
    sentRequests: [],
    openRequests: [],
  });

  const [selectOpenRequests, setSelectOpenRequests] = useState<boolean>(false);
  const [selectSentRequests, setSelectSentRequests] = useState<boolean>(false);

  const { data: requestsAndReplies, isSuccess: requestAndRepliesSuccess } =
    useGetRequestsRepliesQuery(id);

  const { data: requestData, isSuccess: requestDataSuccess } =
    useGetRequestsQuery(id);

  useEffect(() => {
    if (requestData && requestDataSuccess) {
      // open requests from requests data
      const openRequests: ProjectRoleRequest[] = _.cloneDeep(
        requestData
      ).filter(
        (req: ProjectRoleRequest) =>
          req.status === constants.REQUEST_STATUS.DRAFT
      );

      // sent requests from requests data
      // includes requests that are parents and no draft, are children and parents when child was deleted but assignment is still there (kind of a fallback)
      const sentRequests: SentRequest[] = _.cloneDeep(requestData).filter(
        (req: ProjectRoleRequest) =>
          (req.status !== constants.REQUEST_STATUS.DRAFT && !req.childId) ||
          req.projectRoleRequestId === req.childId
      );

      // filter out to show only current requests
      const sentRequestsAndReplies: RequestReplies[] = filterSentRequests(
        _.cloneDeep(requestsAndReplies)
      );
      for (const sentRequestsElement of sentRequests) {
        // because the parent id is needed, we have to search for the id in the requests and replies data
        const foundRequestsAndRepliesElement = sentRequestsAndReplies.find(
          (r) =>
            r.projectRoleRequestId === sentRequestsElement.projectRoleRequestId
        );

        sentRequestsElement.parentRequestId = foundRequestsAndRepliesElement
          ? foundRequestsAndRepliesElement.parentRequestId
          : sentRequestsElement.projectRoleRequestId;
      }

      const resourcePlan = {
        sentRequests: sentRequests,
        openRequests: openRequests,
      };

      setResourcePlan(resourcePlan);
    }
  }, [
    id,
    requestsAndReplies,
    requestAndRepliesSuccess,
    requestData,
    requestDataSuccess,
  ]);

  const getParentChildRequestsMap = (
    data: RequestReplies[]
  ): ParentChildIdMap => {
    const parentChildIdMap: ParentChildIdMap = {};

    data.forEach((req) => {
      if (req.childId && req.projectRoleRequestId !== req.childId) {
        parentChildIdMap[req.childId] = {
          projectRoleRequestId: req.projectRoleRequestId,
        };
      }
    });

    return parentChildIdMap;
  };

  /**
   * Prepare the requests and replies data. If the request has a child, the parent id is added to the request.
   * And only the child request will be added to the list.
   */
  const filterSentRequests = (data: RequestReplies[]): RequestReplies[] => {
    const sentRequests = [];

    const parentChildIdMap = getParentChildRequestsMap(data);

    data.forEach((req) => {
      if (!req.childId || req.projectRoleRequestId === req.childId) {
        req.parentRequestId = req.projectRoleRequestId;

        if (req.childId && parentChildIdMap[req.childId]) {
          req.parentRequestId =
            parentChildIdMap[req.childId].projectRoleRequestId;
        }

        sentRequests.push(req);
      }
    });

    return sentRequests;
  };

  resourcePlan.sentRequests.sort(
    (a, b) => Number(b.outdated) - Number(a.outdated)
  );

  /**
   * Check if the open requests or sent requests table is clicked.
   * If one of them is clicked, the selection from the other table is removed.
   */
  const currentClickedTable = (
    openRequestsClicked: boolean,
    sentRequestsClicked: boolean
  ) => {
    if (openRequestsClicked != null) {
      //open requests table is clicked
      setSelectOpenRequests(true);
      //remove selection from sent requests table
      setSelectSentRequests(false);
    } else if (sentRequestsClicked != null) {
      //sent requests table is clicked
      setSelectSentRequests(true);
      //remove selection from open requests table
      setSelectOpenRequests(false);
    }
  };

  const gridApiRefCallbackOpenRequests = (gridApi: GridApi) => {
    setOpenRequestsGridApi(gridApi);
  };

  const gridApiRefCallbackSendRequests = (gridApi: GridApi) => {
    setSendRequestsGridApi(gridApi);
  };

  return (
    <Card className="pageSection">
      <div className="download-button-resource-plan">
        <ResourcePlanDownloadButton
          sendRequestsGridApi={sendRequestsGridApi}
          openRequestsGridApi={openRequestsGridApi}
          project={project}
        />
      </div>

      <div className="resource-plan">
        <div>
          <div className="requested-roles-title">Draft role requests</div>
          <OpenRequests
            projectId={id}
            startDate={startDate}
            endDate={endDate}
            requests={resourcePlan.openRequests}
            callBack={currentClickedTable}
            selectedTable={selectOpenRequests}
            isJointVenture={!!project.jointVenturePartner}
            gridApiRefCallback={gridApiRefCallbackOpenRequests}
            project={project}
          />
        </div>
        <div>
          <div className="requested-roles-title">Already requested roles</div>
          <SentRequests
            id={id}
            sentRequests={resourcePlan.sentRequests}
            startDate={startDate}
            endDate={endDate}
            project={project}
            callBack={currentClickedTable}
            selectedTable={selectSentRequests}
            gridApiRefCallback={gridApiRefCallbackSendRequests}
          />
        </div>
      </div>
    </Card>
  );
};

export default ResourcePlan;
