import { COLORS, months } from "@src/constants";
import { MonthlyTotalFte } from "@src/types";
import { ChartData, ChartOptions } from "chart.js";

/**
 * Maps the data for the total fte line chart
 * @param {MonthlyTotalFte[]} monthlyTotalFteData - Monthly total fte from backend
 * @returns {ChartData<"line">} The chart data
 */
export function mapChartData(
  monthlyTotalFteData: MonthlyTotalFte[]
): ChartData<"line"> {
  return {
    labels: getMonthLabels(monthlyTotalFteData),
    datasets: [
      {
        label: "total FTE",
        data: monthlyTotalFteData.map((data) => data.totalFte),
        fill: true,
        borderColor: COLORS.BRAND_100,
        backgroundColor: COLORS.BRAND_100_OPACITY_33,
        pointBackgroundColor: COLORS.BRAND_100,
        pointRadius: 5,
      },
    ],
  };
}

/**
 * Gets the options for the total fte line chart
 * @returns {ChartOptions<"line">} The chart options
 */
export function getChartOptions(): ChartOptions<"line"> {
  return {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },

    scales: {
      x: {
        ticks: {
          font: {
            family: "RWESansWeb-Regular",
            size: 11,
          },
        },
      },
      y: {
        suggestedMax: 100,
        min: 0,
        ticks: {
          callback: getFteLabels,
          font: {
            family: "RWESansWeb-Regular",
            size: 11,
          },
        },
      },
    },
  };
}

/**
 * gets the month names for the x axes in the line chart
 *
 * @param {MonthlyTotalFte[]} monthlyTotalFteData monthly total fte from backend
 *
 * @returns {string[]} a list of month names
 */
function getMonthLabels(monthlyTotalFteData: MonthlyTotalFte[]): string[] {
  const monthsToDisplay: string[] = [];

  monthlyTotalFteData.forEach((monthlyTotalFte: MonthlyTotalFte) => {
    const month = months[monthlyTotalFte.month];
    const year = monthlyTotalFte.year.toString().slice(2, 4);

    monthsToDisplay.push(`${month} '${year}`);
  });

  return monthsToDisplay;
}

/**
 * Gets the labels for the y axes.
 * Callback function for the charts ticks. it should just show every 20th label
 * @param {number} value - label of the y axes
 * @returns {number | null} the label or null
 */
function getFteLabels(value: number): number | null {
  return value !== 0 && value % 20 === 0 ? value : null;
}
