import { useEffect, useState } from "react";

import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Headline } from "@src/components/headline";
import { ROLES } from "@src/constants";
import { EmployeeForm } from "@src/features/admin_role_assignments/components/employee_form";
import { RoleAssignmentModal } from "@src/features/role_assignment_old/components/role_assignment_modal";
import { DownloadButton } from "@src/features/table_filtering/components/download_button";
import {
  useGetEmployeeApplicationRolesQuery,
  useLazySearchEmployeesQuery,
} from "@src/services/slices/employeesSlice";
import { ApplicationRole, Employee, EmployeeSearchRequest } from "@src/types";
import { Button, Empty, Input, Space, Spin, Table } from "antd";
import {
  ColumnFilterItem,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import useDeepCompareEffect from "use-deep-compare-effect";
import "./admin_role_assignment_page.less";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

/**
 * This is the old admin role assignment page and will be removed after the new one is finished and the role assignment
 * is finished.
 *
 * @constructor
 */
const AdminRoleAssignmentPage = () => {
  const { data: applicationRoles } = useGetEmployeeApplicationRolesQuery();
  const [searchEmployeesData, { data: employeeDataPage, isFetching }] =
    useLazySearchEmployeesQuery();

  const [employeesData, setEmployeesData] = useState([]);
  const [hasNoData, setHasNoData] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [record, setRecord] = useState<Employee>();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [type, setType] = useState<"add" | "edit">();
  const [roleAssignmentModalVisible, setRoleAssignmentModalVisible] =
    useState<boolean>();

  useDeepCompareEffect(() => {
    // call the api when the table params change
    fetchData();
  }, [tableParams]);

  useEffect(() => {
    if (employeeDataPage) {
      mapEmployeeData(employeeDataPage.content);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: employeeDataPage.totalElements,
        },
      });
    }
  }, [employeeDataPage]);

  async function fetchData() {
    const request: EmployeeSearchRequest = {
      pageRequestParams: {
        page: tableParams.pagination.current - 1,
        size: tableParams.pagination.pageSize,
      },
      filterParams: {
        fullName: tableParams.filters?.fullName?.[0] as string,
        uiNumber: tableParams.filters?.uiNumber?.[0] as string,
        positionId: tableParams.filters?.deskId?.[0] as string,
        standardRoles: tableParams.filters?.genericRole as string[],
        roles: tableParams.filters?.applicationRoles as string[],
        orgCodes: tableParams.filters?.departmentCode as string[],
      },
    };
    await searchEmployeesData(request).unwrap();
  }

  function mapEmployeeData(data: Employee[]): void {
    if (data?.length) {
      setEmployeesData(data);
    } else {
      setEmployeesData([]);
      setHasNoData(true);
    }
  }

  function filter(): ColumnFilterItem[] {
    const filters: ColumnFilterItem[] = [];

    applicationRoles?.forEach((applicationRole: ApplicationRole) =>
      filters.push({
        text: applicationRole.applicationRoleName,
        value: applicationRole.applicationRoleName,
      })
    );

    return filters;
  }

  function filterSapData(): ColumnFilterItem[] {
    return [
      { text: "yes", value: "yes" },
      { text: "no", value: "no" },
    ];
  }

  const getColumnSearchProps = (dataIndex: string, placeholder: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${placeholder}`}
          data-testid="searchText"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            data-testid="resetSearch"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            onClick={() => confirm()}
            data-testid="searchItems"
            size="small"
            style={{ width: 90 }}
            type="primary"
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: string) => (
      <SearchOutlined
        data-testid="filter"
        style={{ color: filtered ? "#3ED8C3" : "#1D4477" }}
      />
    ),
  });

  function getUnique(values: string[]): string[] {
    const unique: string[] = [];

    values.forEach((item: string) => {
      if (!unique.includes(item)) {
        unique.push(item);
      }
    });

    return unique;
  }

  const columns: any = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      ...getColumnSearchProps("fullName", "name"),
    },
    {
      title: "UI number",
      dataIndex: "uiNumber",
      key: "uiNumber",
      width: "150px",
      ...getColumnSearchProps("uiNumber", "ui number"),
    },
    {
      title: "Position ID",
      dataIndex: "deskId",
      key: "deskId",
      width: "140px",
      ...getColumnSearchProps("deskId", "position id"),
    },
    {
      title: "ORG-unit",
      dataIndex: "departmentCode",
      key: "departmentCode",
      width: "160px",
      ...getColumnSearchProps("departmentCode", "ORG-unit"),
    },
    {
      title: "Standard role",
      dataIndex: "genericRole",
      key: "genericRole",
      ...getColumnSearchProps("genericRole", "Standard role"),
      render: (genericRole: string, record: Employee) => {
        function isTeamLead() {
          return (
            record.applicationRoles.filter((role) => role === ROLES.TEAM_LEAD)
              .length > 0
          );
        }

        if (genericRole && isTeamLead()) {
          genericRole += ", " + ROLES.TEAM_LEAD;
        } else {
          genericRole = isTeamLead() ? ROLES.TEAM_LEAD : "";
        }
        return genericRole;
      },
    },
    {
      title: "Account role",
      dataIndex: "applicationRoles",
      key: "applicationRoles",
      filters: filter(),
      onFilter: (value: string, record: Employee) =>
        record.applicationRoles.indexOf(value) !== -1,
      render: (role: string[]) => {
        return role.join(", ");
      },
    },
    {
      title: "SAP data",
      dataIndex: "sapData",
      key: "sapData",
      width: "140px",
      filters: filterSapData(),
      render: (text: string) => {
        return text ? "yes" : "no";
      },
      onFilter: (value: string, record: Employee) => {
        const isSapData = value === "yes";
        return record["sapData"] === isSapData;
      },
    },
    {
      key: "assignRole",
      width: "170px",
      render: (_, record: Employee) => (
        <Button onClick={() => showAssignRoleModal(record)} type="link">
          Assign roles
        </Button>
      ),
    },
    {
      key: "editEmployee",
      width: "170px",
      render: (text: string, record: Employee) => (
        <Button onClick={() => showModal(record, "edit")} type="link">
          Edit employee
        </Button>
      ),
    },
  ];

  function showAssignRoleModal(record: Employee): void {
    setRoleAssignmentModalVisible(true);
    setRecord(record);
  }

  function showModal(record: Employee, type: "add" | "edit"): void {
    setType(type);
    setRecord(record);
    setModalVisible(true);
  }

  /**
   * This function is called when the table is changed.
   * It will update the tableParams object with the new values.
   *
   * @param pagination the pagination object
   * @param filters the filters object
   * @param sorter the sorter object
   */
  function handleTableChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<Employee>
  ): void {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  }

  return (
    <>
      {record && roleAssignmentModalVisible && (
        <RoleAssignmentModal
          user={record}
          employeesData={employeesData}
          setRoleAssignmentModalVisible={setRoleAssignmentModalVisible}
          roleAssignmentModalVisible={roleAssignmentModalVisible}
        />
      )}

      <div className="header-section">
        <Headline title="Employee overview" />
        <form method="GET">
          <Button
            type="primary"
            onClick={() => showModal(null, "add")}
            icon={<PlusOutlined />}
            size="large"
          >
            Add Employee
          </Button>
          {modalVisible === true && (
            <EmployeeForm
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              record={record}
              type={type}
            />
          )}
        </form>
        <DownloadButton path="/reports/export-rpp-report" />
      </div>

      <Table
        className="rpp-admin"
        locale={{
          emptyText: hasNoData ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <div style={{ height: "100px" }} />
          ),
        }}
        loading={isFetching && { indicator: <Spin tip="Loading..." /> }}
        rowKey={(record) => record.employeeId}
        dataSource={employeesData}
        pagination={tableParams.pagination}
        columns={columns} // remove any
        scroll={{ y: 400 }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default AdminRoleAssignmentPage;
