// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-name-proposed {
  color: #ffa500;
}
.project-name {
  color: #3b3d42;
}
.project-name-section {
  display: flex;
  justify-content: flex-end;
  font-family: "RWESansWeb-Medium", sans-serif;
}
.hyphen {
  margin-right: 10px;
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/features/teams/components/teammember_project_renderer/teammember_project_renderer.less"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAIA;EACE,cAAA;AAFF;AAKA;EACE,aAAA;EACA,yBAAA;EACA,4CAAA;AAHF;AAMA;EACE,kBAAA;EACA,iBAAA;AAJF","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.project-name-proposed {\n  color: @light-orange;\n}\n\n.project-name {\n  color: @font-00;\n}\n\n.project-name-section {\n  display: flex;\n  justify-content: flex-end;\n  font-family: \"RWESansWeb-Medium\", sans-serif;\n}\n\n.hyphen {\n  margin-right: 10px;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
