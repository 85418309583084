import _ from "lodash";

export const isRegionValid = (props, message) => {
  const isRegionAlreadyAvailable = !!props.context.regions.find(
    (region) => region.regionName === props.data.updatedData.regionName
  );

  //prevents user from adding a duplicated region
  if (isRegionAlreadyAvailable) {
    message.error(`Region is already available`);
    return false;
  }

  //prevents user from adding an empty region name
  if (
    (!props.data.updatedData.regionName && !props.data.regionName) ||
    props.data.updatedData.regionName === ""
  ) {
    message.error(`Region name should not be empty`);
    return false;
  }

  //prevents user from adding a region with less than 2 chars
  if (props.data.updatedData.regionName?.length <= 2) {
    message.error(`Region name should contain more than 2 chars`);
    return false;
  }

  //do not allow special character
  const regEx = /^[a-zA-Z 0-9 ()&/\\ -]*$/g;
  if (!regEx.test(props.data.updatedData.regionName)) {
    message.error(
      "Only alphanumeric values and the special chars: & ( ) / - are allowed"
    );
    return false;
  }

  //prevents user from adding a region name containing only numbers
  if (!isNaN(props.data.updatedData.regionName)) {
    message.error("Only numeric values are not allowed");
    return false;
  }

  return true;
};

export const updateChangedDataObject = (value, props) => {
  if (props.data.updatedData === undefined) {
    props.data.updatedData = {};
  }

  if (value === props.data[props.colDef.field]) {
    // deletes changes from diff object when current changed value is same like the original value or when the value is empty
    delete props.data.updatedData[props.colDef.field];
  } else {
    // adds changes to diff object when current changed value is different from the original value
    props.data.updatedData[props.colDef.field] = _.cloneDeep(value);
  }
};

export const onChange = (value, props) => {
  if (props.data.updatedData === undefined) {
    props.data.updatedData = {};
  }

  if (value === props.data[props.colDef.field]) {
    // deletes changes from diff object when current changed value is same like the original value or when the value is empty
    delete props.data.updatedData[props.colDef.field];
  } else {
    // adds changes to diff object when current changed value is different from the original value
    props.data.updatedData[props.colDef.field] = value;
  }
  props.api.refreshCells({ rowNodes: [props.node], force: true });
};
