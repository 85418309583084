import App from "@src/App";
import { connectTableSelectors } from "@src/utils/activate_table_select_utils";
import { LicenseManager } from "ag-grid-enterprise";
import ReactDOM from "react-dom";

LicenseManager.setLicenseKey(
  `Using_this_AG_Grid_Enterprise_key_( AG-042127 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( RWE Supply & Trading GmbH )_is_granted_a_( Single Application )_Developer_License_for_the_application_( DUALIS )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( DUALIS )_need_to_be_licensed___( DUALIS )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 29 November 2024 )____[v2]_${process.env.REACT_APP_AG_GRID_KEY}`
);

ReactDOM.render(
  <App />,
  document.getElementById("root"),
  connectTableSelectors
);
