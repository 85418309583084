import { paginationGridOptions } from "@src/utils/aggrid_utils";

import { TooltipRenderer } from "../../../custom_renderer/tooltip_renderer";

export const gridOptions = {
  ...paginationGridOptions,
  paginationPageSize: 5,
  suppressContextMenu: true,
  domLayout: "autoHeight",
  defaultColDef: {
    autoHeight: true,
  },
};

export const columnDefs = [
  {
    headerName: "Assigned teammember",
    field: "assignedTeammemberSAPDto",
    width: 60,
    valueGetter: (props) => {
      return props.data.assignedTeammemberSAPDto.fullName;
    },
    cellRenderer: (props) => {
      return TooltipRenderer(props.data.assignedTeammemberSAPDto.fullName);
    },
  },
  {
    headerName: "Standardrole",
    field: "assignedTeammemberStandardRole",
    width: 60,
    valueGetter: (props) => {
      if (props.data.assignedTeammemberStandardRole !== null) {
        return props.data.assignedTeammemberStandardRole;
      } else {
        return "No role assigned";
      }
    },
    cellRenderer: (props) => {
      if (props.data.assignedTeammemberStandardRole !== null) {
        return TooltipRenderer(props.data.assignedTeammemberStandardRole);
      } else {
        return "No role assigned";
      }
    },
  },
  {
    headerName: "Requests (confirmed or proposed)",
    field: "totalAssignedRequestsByStandardrole",
    cellRenderer: "requestsRenderer",
    width: "100%",
    resizable: false,
  },
];
