import React, { useState } from "react";

import { EditOutlined } from "@ant-design/icons";

import { REQUEST_STATUS } from "../../../../constants";
import { SelectStandardRoleModal } from "../../components/select_standard_role_modal";

const GenericRoleRenderer = (params) => {
  const [showSelectStandardRoleModal, setShowSelectStandardRoleModal] =
    useState(false);

  const onFinishSelectStandardRole = (fieldsValue) => {
    const values = {
      ...fieldsValue,
    };
    let field = params.colDef.field;

    const genericRoleName = values.roleSpecification
      ? `${values.basicRole} ${values.roleSpecification}`
      : values.basicRole;

    params.node.setDataValue(field, genericRoleName);
    params.node.data.genericRoleId = values.genericRoleId;
  };

  const onClickEdit = () => {
    setShowSelectStandardRoleModal(true);
  };

  const disableEdit =
    params.node.data.status === REQUEST_STATUS.CONFIRMED ||
    params.node.data.status === REQUEST_STATUS.IN_RECRUITMENT ||
    params.node.data.status === REQUEST_STATUS.PROPOSED
      ? "disabled"
      : "";

  return (
    <div className="standard-role-col">
      {!disableEdit ? (
        <EditOutlined
          onClick={() => onClickEdit()}
          data-testid="editoutlined"
        />
      ) : (
        ""
      )}
      <span className={`standard-role-name ${disableEdit}`}>
        {params.node.data.genericRoleName}
      </span>
      <SelectStandardRoleModal
        setModalVisible={setShowSelectStandardRoleModal}
        modalVisible={showSelectStandardRoleModal}
        onFinishForm={onFinishSelectStandardRole}
        onCancelModal={() => setShowSelectStandardRoleModal(false)}
      />
    </div>
  );
};

export default GenericRoleRenderer;
