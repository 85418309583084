import { RequestReplies } from "@src/types";
import { TransferItem } from "antd/es/transfer";

export function mapTreeDataToTransfer(
  projectRoleRequests: RequestReplies[]
): TransferItem[] {
  // should map the requests to the specific transfer item
  return projectRoleRequests.map((projectRoleRequest: RequestReplies) => {
    return {
      key: projectRoleRequest.projectRoleRequestId.toString(),
      data: {
        projectRoleRequestId: projectRoleRequest.projectRoleRequestId,
        projectName: projectRoleRequest.projectDTO.name,
        projectPhase: projectRoleRequest.projectDTO.projectPhase,
        status: projectRoleRequest.status,
        country: projectRoleRequest.projectDTO.country,
      },
    };
  });
}
