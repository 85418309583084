import React from "react";

import { Card, Progress, Button } from "antd";
import "./project_overview_card.less";
import { Link } from "@reach/router";
import moment from "moment";
import { Project } from "@src/types";

interface ProjectOverviewCardProps {
  project: Project;
  index: number;
}

const ProjectOverviewCard: React.FC<ProjectOverviewCardProps> = ({
  project,
  index,
}) => {
  return (
    <Card
      title={project.name}
      extra={
        <Link to={`/projects/${project.id}`} data-testid="view-details-link">
          <Button type="link">View details</Button>
        </Link>
      }
      data-testid="project-overview-card"
      className={`project-card ${index % 2 === 0 ? "left-card" : "right-card"}`}
    >
      <div className="new-messages" data-testid="new-messages-section">
        {project.actionNeededTotal ? (
          <>
            New messages:{" "}
            <span className="notificationCount">
              {project.actionNeededTotal}
            </span>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="project-attributes">
        <p data-testid="project-id">
          <label>Project ID:</label> {project.id}
          <br />
        </p>
        <p data-testid="phase">
          <label>Project phase:</label> {project.projectPhase}
          <br />
        </p>
        <p data-testid="project-start-label">
          <label>Project start:</label>{" "}
          {moment(project.startDate).format("Do MMMM YYYY")}
          <br />
        </p>
        <p data-testid="project-end-label">
          <label>Project end:</label>{" "}
          {moment(project.endDate).format("Do MMMM YYYY")}
        </p>
        <p data-testid="project-type-label">
          <label>Project Type:</label> {project.projectType}
        </p>
      </div>
      <div className="staffing-overview">
        <span>Staffing overview</span>
        <div>
          <div className="chart">
            <Progress
              type="circle"
              data-testid="progress-bar"
              percent={
                project.sendRequestsTotal
                  ? Math.floor(
                      (project.confirmedRequestsTotal /
                        project.sendRequestsTotal) *
                        100
                    )
                  : 0
              }
            />
          </div>
          <div
            className="staffing-overview-attributes"
            data-testid="request-total"
          >
            <p data-testid="send-requests-total">
              <label>Requested roles:</label> {project.sendRequestsTotal}
            </p>
            <p data-testid="open-requests-total">
              <label>Open roles:</label> {project.openRequestsTotal}
            </p>
            <p data-testid="staffed-requests-total">
              <label>Staffed roles:</label> {project.confirmedRequestsTotal}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProjectOverviewCard;
