import { Form, Select } from "antd";
import { DownloadButton } from "@src/features/table_filtering/components/download_button";
import React, { useState } from "react";
import "./download_resource_plan_by_region.less";
import { useGetRegionsQuery } from "@src/services/slices/regionsSlice";
import { Region } from "@src/types";

interface DownloadResourcePlanByRegionProps {
  disabled: boolean;
}

const DownloadResourcePlanByRegion: React.FC<
  DownloadResourcePlanByRegionProps
> = ({ disabled }) => {
  const [selectedRegionId, setSelectedRegionId] = useState(0);
  const [btnDisabledStatus, setBtnDisabledStatus] = useState(true);
  const { data: regions } = useGetRegionsQuery();

  function onChange(selectedRegionId: number) {
    if (selectedRegionId === 0) {
      setSelectedRegionId(0);
    } else {
      const regionObject: Region = regions.find(
        (region: Region) => region.regionId === selectedRegionId
      );

      if (regionObject) {
        setSelectedRegionId(regionObject.regionId);
      }
    }

    setBtnDisabledStatus(false);
  }

  return (
    <Form layout="vertical" size="large">
      <div className="form-inputs">
        <Form.Item>
          <Select
            className="region-selection"
            placeholder="please select a region"
            data-testid="region-selection"
            showSearch
            onChange={onChange}
            optionFilterProp="children"
            disabled={disabled}
          >
            <Select.Option value={0} key={0}>
              {"no region selected"}
            </Select.Option>
            {regions?.map((region: Region) => {
              return (
                <Select.Option value={region.regionId} key={region.regionId}>
                  {region.regionName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <DownloadButton
          className="download-button"
          path={
            "/reports/export-admin-project-overview?regionId=" +
            selectedRegionId
          }
          disabled={btnDisabledStatus || disabled}
        />
      </div>
    </Form>
  );
};

export default DownloadResourcePlanByRegion;
