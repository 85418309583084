import { EndpointBuilder } from "@reduxjs/toolkit/src/query/endpointDefinitions";
import { HTTP_METHODS } from "@src/constants";
import {
  StandardRole,
  TeamMemberAllocationDetails,
  TimeBlockerRequestPayload,
  SuggestWorkloadPayload,
  TeamLeadRequestOverviewItem,
  TeamMemberCapacity,
  Employee,
  RequestOverviewDetails,
} from "@src/types";
import { message } from "antd";

import { baseApiSlice } from "./baseApiSlice";

export const teamLeadsApi = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    /* GET endpoints */
    ...getEndpoints(builder),

    /* POST endpoints */
    ...postEndpoints(builder),

    /* DELETE endpoints */
    ...deleteEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    /**
     * Get team capacities
     */
    getTeamCapacities: builder.query<
      TeamMemberCapacity[],
      { roleRequestId: number }
    >({
      query: ({ roleRequestId }) => {
        return {
          url: `/teamlead/getTeamCapacities`,
          params: {
            roleRequestId: roleRequestId,
          },
        };
      },
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to get TeamMembers list", 5);
      },
    }),

    /**
     * Get team lead request details
     */
    getTeamLeadRequestDetails: builder.query<RequestOverviewDetails, number>({
      query: (id) => ({
        url: `/teamlead/getTeamLeadRequestDetails`,
        method: HTTP_METHODS.GET,
        params: { roleRequestId: id },
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to get TeamLeadRequestDetails list", 5);
      },
    }),

    /**
     * Get all team members
     */
    getTeamMembers: builder.query<Employee[], void>({
      query: () => "teamlead/getTeamMembers",
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to get all team members", 5);
      },
      providesTags: ["Employee"],
    }),
    /**
     * Get all requests
     */
    getStaffingRequests: builder.query<TeamLeadRequestOverviewItem[], void>({
      query: () => "teamlead/requests?startIndex=0&pageSize=9999",
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to get team members overview", 5);
      },
      providesTags: ["Requests"],
    }),

    /**
     * Get team allocations
     */
    getTeamAllocations: builder.query<TeamMemberAllocationDetails[], void>({
      query: () => "teamlead/getTeamAllocations",
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to get team allocations", 5);
      },
    }),

    /**
     * Get standard roles by given team lead id
     */
    getTeamLeadsStandardRoles: builder.query<StandardRole[], number>({
      query: (teamLeadId): string => `teamlead/${teamLeadId}/standard-roles`,
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to get standard roles", 5);
      },
      providesTags: ["StandardRoles"],
    }),
  };
}

function postEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.POST;

  return {
    /**
     * revoke updated request
     */
    revokeUpdatedRequest: builder.mutation<void, any>({
      query: (body) => ({
        url: `/teamlead/updateToRequested`,
        method: baseMethod,
        body: body,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to revoke update", 5);
      },
      invalidatesTags: ["Requests"],
    }),
    /**
     * accept updated request
     */
    acceptUpdatedRequest: builder.mutation<void, any>({
      query: (body) => ({
        url: `/teamlead/acceptUpdatedRequest`,
        method: baseMethod,
        body: body,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to accept updated request", 5);
      },
      invalidatesTags: ["Requests"],
    }),
    /**
     * forward request
     */
    forwardRequest: builder.mutation<
      void,
      { roleRequestId: number; teamLeadId: number }
    >({
      query: (body) => ({
        url: `/teamlead/forwradRequest2Teamlead`,
        method: baseMethod,
        body: body,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to forward request", 5);
      },
      invalidatesTags: ["Requests"],
    }),

    /**
     * Update in recruitment
     */
    updateInRecruitment: builder.mutation<void, any>({
      query: (body) => ({
        url: `/teamlead/updateRequestInRecruitmentStatus`,
        method: baseMethod,
        body: body,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to update in recruitment", 5);
      },
      invalidatesTags: ["Requests"],
    }),
    /**
     * Take back proposal
     */
    takeBackProposal: builder.mutation<void, any>({
      query: (body) => ({
        url: `/teamlead/takeBackProposal`,
        method: baseMethod,
        body: body,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to take back proposal", 5);
      },
      invalidatesTags: ["Requests"],
    }),
    /**
     * Propose team member
     */
    proposeTeamMember: builder.mutation<void, any>({
      query: (body) => ({
        url: `/teamlead/proposeTeamMember`,
        method: baseMethod,
        body: body,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to propose team member", 5);
      },
      invalidatesTags: ["Requests"],
    }),
    /**
     * Suggest different workload
     */
    suggestDifferentWorkload: builder.mutation<void, SuggestWorkloadPayload>({
      query: ({ id, selectedMemberId, requestAllocationPayload }) => ({
        url: `teamlead/roleRequest/${id}/teamMember/${selectedMemberId}/suggestDifferentWorkloads`,
        method: baseMethod,
        body: requestAllocationPayload,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to save allocations", 5);
      },
      invalidatesTags: ["Requests"],
    }),

    /**
     * Decline Request
     */
    teamLeadDeclineRequest: builder.mutation<
      void,
      { payLoad: SuggestWorkloadPayload; params: any }
    >({
      query: ({ payLoad, params }) => ({
        url: `teamlead/role-request/${payLoad.id}/decline`,
        method: baseMethod,
        params: params,
        body: payLoad.requestAllocationPayload,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to save allocations", 5);
      },
      invalidatesTags: ["Requests"],
    }),

    /**
     * Create a time blocker
     */
    addTimeBlocker: builder.mutation<
      number,
      { teamMemberId: number; timeBlockerPayload: TimeBlockerRequestPayload }
    >({
      query: ({ teamMemberId, timeBlockerPayload }) => ({
        url: `/teamlead/teamMember/${teamMemberId}/addTimeBlocker`,
        method: baseMethod,
        body: timeBlockerPayload,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to post time blocker", 5);
      },
      invalidatesTags: ["Requests", "AdminStandardRoles"],
    }),

    /**
     * Update a time blocker
     */
    updateTimeBlocker: builder.mutation<
      number,
      {
        projectRoleRequestId: number;
        timeBlockerPayload: TimeBlockerRequestPayload;
      }
    >({
      query: ({ projectRoleRequestId, timeBlockerPayload }) => ({
        url: `/teamlead/teamMember/${projectRoleRequestId}/updateTimeBlockerRoleAllocations`,
        method: baseMethod,
        body: timeBlockerPayload,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Failed to save edited time allocations", 5);
      },
      invalidatesTags: ["Requests"],
    }),
  };
}

function deleteEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.DELETE;

  return {
    /**
     * Delete time blocker
     */
    deleteTimeBlocker: builder.mutation<void, number>({
      query: (projectRoleRequestId: number) => ({
        url: `teamlead/teamMember/${projectRoleRequestId}/deleteByRequestId`,
        method: baseMethod,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Error deleting time blocker", 5);
      },
      invalidatesTags: ["Requests", "AdminStandardRoles"],
    }),
  };
}

export const {
  /* GET endpoints */
  useGetTeamCapacitiesQuery,
  useGetStaffingRequestsQuery,
  useLazyGetTeamLeadsStandardRolesQuery,
  useGetTeamLeadsStandardRolesQuery,
  useLazyGetTeamMembersQuery,
  useLazyGetTeamLeadRequestDetailsQuery,

  /* POST endpoints */
  useSuggestDifferentWorkloadMutation,
  useTeamLeadDeclineRequestMutation,
  useAddTimeBlockerMutation,
  useUpdateTimeBlockerMutation,
  useProposeTeamMemberMutation,
  useTakeBackProposalMutation,
  useUpdateInRecruitmentMutation,
  useForwardRequestMutation,
  useAcceptUpdatedRequestMutation,
  useRevokeUpdatedRequestMutation,

  /* DELETE endpoints */
  useDeleteTimeBlockerMutation,

  //lazy endpoints
  useLazyGetTeamAllocationsQuery,
} = teamLeadsApi;
