// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-filter-bar {
  min-height: 40px;
  border: 1px solid #e8e8e4;
  margin-bottom: 16px;
  line-height: 30px;
  padding: 5px 15px;
  font-size: 14px;
  font-family: "RWESansWeb-Medium", sans-serif;
  color: #52555c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.active-filter-bar__tags .ant-tag {
  border-radius: 50px;
  background-color: transparent;
  font-size: 12px;
  color: #3b3d42;
  font-family: "RWESansWeb-Medium", sans-serif;
  padding: 1px 7px;
}
.active-filter-bar__tags .ant-tag .ant-tag-close-icon {
  color: #ffffff;
  background-color: #3b3d42;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  padding: 2px;
  margin-left: 6px;
}
.active-filter-bar__tags .ant-tag:first-child {
  margin-left: 8px;
}
.active-filter-bar__delete-button {
  font-size: 12px !important;
  color: #778fad !important;
  padding: 0;
  height: auto;
  margin-bottom: auto;
  line-height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/features/table_filtering/components/active_filters_bar/active_filters_bar.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,4CAAA;EACA,cAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAGE;EAEI,mBAAA;EACA,6BAAA;EACA,eAAA;EACA,cAAA;EACA,4CAAA;EACA,gBAAA;AAFN;AALE;EAUM,cAAA;EACA,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;AAFR;AAdE;EAqBI,gBAAA;AAJN;AAQE;EACE,0BAAA;EACA,yBAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;AANJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.active-filter-bar {\n  min-height: 40px;\n  border: 1px solid @sand;\n  margin-bottom: 16px;\n  line-height: 30px;\n  padding: 5px 15px;\n  font-size: 14px;\n  font-family: \"RWESansWeb-Medium\", sans-serif;\n  color: @font-01;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  &__tags {\n    .ant-tag {\n      border-radius: 50px;\n      background-color: transparent;\n      font-size: 12px;\n      color: @innogy-text-color;\n      font-family: \"RWESansWeb-Medium\", sans-serif;\n      padding: 1px 7px;\n\n      .ant-tag-close-icon {\n        color: @white;\n        background-color: @innogy-text-color;\n        border-radius: 50%;\n        width: 12px;\n        height: 12px;\n        padding: 2px;\n        margin-left: 6px;\n      }\n    }\n\n    .ant-tag:first-child {\n      margin-left: 8px;\n    }\n  }\n\n  &__delete-button {\n    font-size: 12px !important;\n    color: @brand-60 !important;\n    padding: 0;\n    height: auto;\n    margin-bottom: auto;\n    line-height: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
