import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "universal-cookie";

/**
 * when every api (slice) stops using create api and uses injectEndpoints instead,
 * we can remove this file and use baseApiSlice.js instead
 */

const cookies = new Cookies();
const { REACT_APP_SSO_ENABLE } = process.env;
const baseUrl =
  window.location.hostname === "localhost"
    ? `http://localhost:8081/api`
    : `${window.location.origin}/api`;

export const createFetchBaseQuery = (basePath) =>
  fetchBaseQuery({
    baseUrl: `${baseUrl}${basePath}`,
    prepareHeaders: (headers) => prepareHeaders(headers),
  });

const prepareHeaders = (headers) => {
  return JSON.parse(REACT_APP_SSO_ENABLE) === true
    ? enabledSSO(headers)
    : disabledSSO(headers);
};

const disabledSSO = (headers) => {
  let impersonationUser = cookies.get("ImpersonationUser", { path: "/" });

  if (impersonationUser) {
    headers.set("HTTP2_HEADER_ACCESS_CONTROL_ALLOW_ORIGIN", "*");
    headers.set("ImpersonationUser", impersonationUser);
  }

  headers.set("Authorization", null);
  return headers;
};

const enabledSSO = (headers) => {
  const token = localStorage.getItem("token");

  if (token) {
    headers.set("Authorization", "Bearer " + token);
    headers.set("HTTP2_HEADER_ACCESS_CONTROL_ALLOW_ORIGIN", "*");

    let impersonationUser = cookies.get("ImpersonationUser", {
      path: "/",
    });

    if (impersonationUser) {
      headers.set("ImpersonationUser", impersonationUser);
    } else {
      const empObj = cookies.get("loggedInuser");
      if (empObj) {
        cookies.set("ImpersonationUser", empObj.employeeId, {
          path: "/",
        });
        headers.set("ImpersonationUser", impersonationUser);
      }
    }
  }
};
