import { SelectionRenderer } from "@src/custom_renderer";
import { Employee } from "@src/types";
import {
  columnTypes,
  paginationGridOptions,
  preventPinnedColumnJumpingGridOption,
} from "@src/utils/aggrid_utils";
import { ColDef, GridOptions, ICellRendererParams } from "ag-grid-community";

import { onChange } from "./admin_project_overview_utils";
import { ActionsRenderer } from "../custom_renderer";

interface AdminProjectOverviewGridComponents {
  projectManagerRenderer: (params: ICellRendererParams) => React.ReactNode;
  projectEditorRenderer: (params: ICellRendererParams) => React.ReactNode;
  actionsRenderer: (params: ICellRendererParams) => React.ReactNode;
}

const columns: ColDef[] = [
  {
    headerName: "Project Name",
    field: "name",
    cellClass: "text-cell",
    filter: "agSetColumnFilter",
    flex: 1,
  },
  {
    headerName: "Country",
    field: "country",
    cellClass: "text-cell",
    filter: "agSetColumnFilter",
    minWidth: 100,
    maxWidth: 300,
    flex: 1,
  },
  {
    headerName: "Project Manager",
    field: "projectManager",
    cellRenderer: "projectManagerRenderer",
    maxWidth: 300,
    flex: 1,
  },
  {
    headerName: "Project Editor",
    field: "projectEditors",
    cellRenderer: "projectEditorRenderer",
    cellClass: "project-editor-cell ag-grid-cell no-border",
    autoHeight: true,
    minWidth: 400,
    flex: 1,
  },
  {
    headerName: "Actions",
    field: "actions",
    cellRenderer: "actionsRenderer",
    cellClass: "actions-cell ag-grid-cell no-border",
    width: 400,
    wrapText: true,
    suppressSizeToFit: true,
    pinned: "right",
    sortable: false,
    resizable: false,
  },
];

export const gridComponents: AdminProjectOverviewGridComponents = {
  projectManagerRenderer: (props: ICellRendererParams) => {
    return (
      <SelectionRenderer
        props={props}
        dataToExtractOptions={props.context.projectLeads}
        propertyForOptionValues="employeeId"
        propertyForOptionLabels="fullName"
        defaultValue={props?.data.projectManager.fullName}
        onChangeCallback={onChange}
        placeholder="Select Project Manager"
        disabled={!props?.data.isEditActive}
        style={{ width: "100%" }}
      />
    );
  },
  projectEditorRenderer: (props: ICellRendererParams) => {
    return (
      <SelectionRenderer
        props={props}
        dataToExtractOptions={props.context.projectLeads}
        propertyForOptionValues="employeeId"
        propertyForOptionLabels="fullName"
        defaultValue={props?.data.projectEditors?.map(
          (editor: Employee) => editor.employeeId
        )}
        placeholder="Select Project Editors"
        isMultiple
        onChangeCallback={onChange}
        disabled={!props?.data.isEditActive}
        style={{ width: "100%" }}
      />
    );
  },

  actionsRenderer: (props: ICellRendererParams) => (
    <ActionsRenderer props={props} />
  ),
};

export function getGridOptions(): GridOptions {
  return {
    ...paginationGridOptions,
    ...preventPinnedColumnJumpingGridOption,
    suppressContextMenu: true,
    rowHeight: 150,
    domLayout: "autoHeight",
    components: gridComponents,
    columnDefs: [...columns],
    defaultColDef: {
      ...columnTypes.default,
    },
  };
}
