import React, { ReactElement, useEffect, useState } from "react";

import "./split_request_assignment.less";

import { useGetProjectRoleRequestsOfStandardRoleQuery } from "@src/services/slices/standardRolesApi";
import {
  setCurrentAssignedRequestsToSplit,
  setIsSaveDisabled,
  setUnassignedRequests,
  setLastStepStateInitialized,
  setTargetKeys,
} from "@src/services/splitRoleSlice";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import { RoleSpecification, StandardRole } from "@src/types";
import { TransferItem } from "@src/types/antd_types";
import {
  Collapse,
  Radio,
  RadioChangeEvent,
  Spin,
  Transfer,
  TransferProps,
  TreeDataNode,
} from "antd";
import { TransferKey } from "antd/es/transfer/interface";
import { TransferDirection } from "antd/lib/transfer";

import { mapTreeDataToTransfer } from "../../utils/split_request_assignment_utils";
import { RequestsToSelectTree } from "../requests_to_select_tree";
import { SplitRequestsTree } from "../split_requests_tree";
import { SplitRoleTransferTreeNode } from "../split_role_transfer_tree_node";

const { Panel } = Collapse;

interface SplitRequestAssignmentProps {
  newSelectedSpecifications?: RoleSpecification[];
  selectedStandardRoleToSplit: StandardRole;
}

export const SplitRequestAssignment: React.FC<SplitRequestAssignmentProps> = ({
  newSelectedSpecifications,
  selectedStandardRoleToSplit,
}) => {
  const dispatch = useAppDispatch();

  /**use states **/
  const [allRequestsToReassign, setAllRequestsToReassign] = useState<
    TransferItem[]
  >([]);
  const [isUserSearching, setIsUserSearching] = useState<boolean>(false);

  // preselecting the first specification to auto select one element in the split selection (right section)
  const [selectedSpecificationId, setSelectedSpecificationId] =
    useState<number>(newSelectedSpecifications[0].roleSpecificationId);

  /** rtk queries **/
  const {
    unassignedRequests,
    currentAssignedRequestsToSplit,
    isLastStepStateInitialized,
    targetKeys,
  } = useAppSelector((state: RootState) => state.splitRoleSlice);

  const { data: projectRoleRequests, isLoading } =
    useGetProjectRoleRequestsOfStandardRoleQuery({
      standardRoleId: selectedStandardRoleToSplit.standardRoleId,
      showChildRequest: false,
      showTimeBlocker: false,
    });

  useEffect(() => {
    // standard role has no requests and can be split without assigning requests
    if (!projectRoleRequests) {
      dispatch(setIsSaveDisabled(false));
      return;
    }

    // should map the requests to the specific transfer item
    const mappedTreeDataRequest: TransferItem[] =
      mapTreeDataToTransfer(projectRoleRequests);

    // necessary to check if last step was once initialized because when the user will assign all requests
    // so that no requests are left and when the user will go back and go again to the next page then
    // it should not reinitialize again with all request data
    if (unassignedRequests.length === 0 && !isLastStepStateInitialized) {
      dispatch(setUnassignedRequests([...mappedTreeDataRequest]));
      dispatch(setLastStepStateInitialized(true));
      dispatch(setIsSaveDisabled(true));
    }
    setAllRequestsToReassign([...mappedTreeDataRequest]);
  }, [projectRoleRequests]);

  /**
   * Generates the selection list view with the request information
   *
   * @param {TransferItem[]} treeNodes   Transferitem to generate tree.
   */
  function getTreeNodes(treeNodes: TransferItem[] = []): TreeDataNode[] {
    return treeNodes.map((transferItem: TransferItem) => {
      return {
        title: <SplitRoleTransferTreeNode transferItem={transferItem} />,
        key: transferItem.key as React.Key,
      };
    });
  }

  /**
   * The onchange function will be triggered when one request will be reassigned to a new role.
   * When the user selects a role and click the button with the right-arrow, then the right direction from the transfer
   * component will be triggered and the selected id will be moved to requestIdsToReassign (right side).
   *
   * @param allAssignedRequestIds {string[]}    This are all request ids which are already reassigned to the new specification.
   * @param direction {TransferDirection}       Right = assign to a role | Left: remove from a role back to the old one.
   * @param requestIdsToReassign {string[]}     This are all ids selected for reassigning to a new specification.
   */
  function onChanges(
    allAssignedRequestIds: TransferKey[],
    direction: TransferDirection,
    requestIdsToReassign: TransferKey[]
  ): void {
    const targetKeys = updateTargetKeys(
      direction,
      requestIdsToReassign,
      allAssignedRequestIds
    );

    updateDataFromRequestSelection(
      allAssignedRequestIds,
      requestIdsToReassign,
      direction,
      targetKeys
    );
    updateDataFromSplits(requestIdsToReassign, direction);
  }

  function updateTargetKeys(
    direction: TransferDirection,
    requestIdsToReassign: TransferKey[],
    allAssignedRequestIds: TransferKey[]
  ): any {
    if (direction === "left") {
      const updatedArray: TransferProps["targetKeys"] = targetKeys.filter(
        (targetKey: React.Key) => !requestIdsToReassign.includes(targetKey)
      );
      dispatch(setTargetKeys(updatedArray));

      return updatedArray;
    } else {
      dispatch(setTargetKeys([...targetKeys, ...allAssignedRequestIds]));
      return [...targetKeys, ...allAssignedRequestIds];
    }
  }

  /**
   * All already assigned requests to a new split will be managed here. If the direction is right then a new
   * request id will be assigned to a selected split. If the direction is left then the selected assigned request
   * will be removed from the split.
   *
   * @param requestIdsToReassign {TransferKey[]}      This are all ids selected for reassigning to a new specification.
   * @param direction {TransferDirection}        Right = assign to a role | Left: remove from a role back to the old one.
   */
  function updateDataFromSplits(
    requestIdsToReassign: TransferKey[],
    direction: TransferDirection
  ): void {
    if (direction === "right") {
      //assign request to a new split
      const currentAssignedRequestIds: string[] =
        currentAssignedRequestsToSplit?.[selectedSpecificationId] || [];

      const currentAssignedRequestsCopy: Record<string, string[]> = {
        ...currentAssignedRequestsToSplit,
        [selectedSpecificationId]: [
          ...currentAssignedRequestIds,
          ...requestIdsToReassign,
        ],
      };

      dispatch(setCurrentAssignedRequestsToSplit(currentAssignedRequestsCopy));
    } else {
      //remove request from the split
      const currentAssignedRequestsCopy: Record<string, string[]> = {
        ...currentAssignedRequestsToSplit,
      };

      for (const key of Object.keys(currentAssignedRequestsToSplit)) {
        currentAssignedRequestsCopy[key] = currentAssignedRequestsCopy[
          key
        ].filter(
          (requestId: React.Key) => !requestIdsToReassign.includes(requestId)
        );
      }

      dispatch(setCurrentAssignedRequestsToSplit(currentAssignedRequestsCopy));
    }
  }

  /**
   * Request selection is the left section. It will be updated when the user clicks right then the requests will be moved
   * to the splits section. And when the user will click left then it will be removed from the splits and assigned back to the
   * requests section.
   *
   * @param allAssignedRequestIds {TransferKey[]}                  This are all request ids which are already reassigned to the new specification.
   * @param requestIdsToReassign {TransferKey[]}                   This are all ids selected for reassigning to a new specification.
   * @param direction {TransferDirection}                          Right = assign to a role | Left: remove from a role back to the old one.
   * @param targetKeys {TransferProps["targetKeys"]}               The target keys which are already assigned to the new splits.
   */
  function updateDataFromRequestSelection(
    allAssignedRequestIds: TransferKey[],
    requestIdsToReassign: TransferKey[],
    direction: TransferDirection,
    targetKeys: TransferProps["targetKeys"]
  ): void {
    let updatedTransferDataRequests: TransferItem[];
    const copyTransferredDataRequestsStore: TransferItem[] = [
      ...unassignedRequests,
    ];

    if (direction === "right") {
      updatedTransferDataRequests = copyTransferredDataRequestsStore.filter(
        (transferDataRequest: TransferItem) =>
          !allAssignedRequestIds.includes(transferDataRequest.key)
      );
    } else {
      updatedTransferDataRequests = [
        ...copyTransferredDataRequestsStore,
        ...allRequestsToReassign.filter((transferItem: TransferItem) =>
          requestIdsToReassign.includes(transferItem.key)
        ),
      ];
    }

    // compares ids of the assigned requests with the overall of all requests to check if the save button
    // can be enabled or not. Should disable the save button when there are not all requests assigned.
    const areAllRequestsAssigned: boolean =
      targetKeys.length !== allRequestsToReassign.length;

    dispatch(setIsSaveDisabled(areAllRequestsAssigned));
    dispatch(setUnassignedRequests(updatedTransferDataRequests));
  }

  /**
   * Creates the role specifications for which the requests should be assigned for
   *
   * @param roleSpecificationId {number}     The selected role specification for which the requests will be reassigned
   * @param onItemSelect                     Callback function to update the select state of the current selected item.
   */
  function getRightSideTree(
    roleSpecificationId: number,
    onItemSelect: (key: React.Key, check: boolean) => void
  ): ReactElement {
    // selected request ids for the selected roleSpecificationId
    const selectedRequestIds: string[] =
      currentAssignedRequestsToSplit?.[roleSpecificationId];

    const treeRequestData: TreeDataNode[] = [];

    selectedRequestIds?.map((requestId: string) => {
      const filteredTreeNode: TransferItem[] = allRequestsToReassign.filter(
        (transferItem: TransferItem) => requestId === transferItem.key
      );

      treeRequestData.push(...getTreeNodes(filteredTreeNode));
    });

    return (
      <SplitRequestsTree
        treeRequestData={treeRequestData}
        onItemSelect={onItemSelect}
      />
    );
  }

  /**
   * Filters the overall data found by the given search value.
   *
   * @param {string} searchedValue   The value the user is searching for
   */
  //Todo: needs to be refactored to not to use the store to filter because this may lead to some issues
  function onSearch(searchedValue: string): void {
    if (searchedValue) {
      // user is searching for a specific request
      const lowerCaseSearchValue: string = searchedValue.toLowerCase();

      const filteredTreeData: TransferItem[] = unassignedRequests.filter(
        (transferDataRequest: TransferItem) => {
          return Object.values(transferDataRequest.data).some((value: any) =>
            value?.toString().toLowerCase().includes(lowerCaseSearchValue)
          );
        }
      );

      dispatch(setUnassignedRequests(filteredTreeData));
      setIsUserSearching(true);
    } else {
      // user has cleared the search input field
      const filteredTransferDataRequests: TransferItem[] =
        allRequestsToReassign.filter(
          (transferDataRequest: TransferItem) =>
            !targetKeys.includes(transferDataRequest.key)
        );

      dispatch(setUnassignedRequests(filteredTransferDataRequests));
      setIsUserSearching(false);
    }
  }

  /**
   * Generates the collapse header with the selected specification name and the radio button.
   *
   * @param {RoleSpecification} selectedSpecification     The selected specification object.
   */
  function createCollapseHeader(
    selectedSpecification: RoleSpecification
  ): React.ReactElement {
    const totalAssignedRequests: number =
      currentAssignedRequestsToSplit?.[
        selectedSpecification.roleSpecificationId
      ]?.length || 0;

    const collapseLabel = `${selectedSpecification.roleSpecificationName} (${totalAssignedRequests})`;

    return (
      <>
        <Radio
          onChange={(radioChangeEvent: RadioChangeEvent) =>
            setSelectedSpecificationId(radioChangeEvent.target.value)
          }
          value={selectedSpecification.roleSpecificationId}
          checked={
            selectedSpecificationId ===
            selectedSpecification.roleSpecificationId
          }
        />
        {collapseLabel}
      </>
    );
  }

  return (
    <div className="split-request-assignment">
      {!isLoading && allRequestsToReassign ? (
        <>
          <p data-testid="description">
            Select the requests from “
            {selectedStandardRoleToSplit.standardRoleName}” and assign them to
            the new splits.
          </p>
          <Transfer
            onChange={onChanges}
            selectAllLabels={[
              selectedStandardRoleToSplit.standardRoleName,
              "Splits",
            ]}
            dataSource={unassignedRequests}
            onSearch={(_, searchItem: string) => onSearch(searchItem)}
            filterOption={() => true}
            showSearch
            locale={{ searchPlaceholder: "Search requests" }}
          >
            {({ direction, onItemSelect, selectedKeys }) => {
              if (direction === "left") {
                const checkedKeys: React.Key[] = [
                  ...selectedKeys,
                  ...targetKeys,
                ];
                return (
                  <RequestsToSelectTree
                    treeRequestData={getTreeNodes(unassignedRequests)}
                    onItemSelect={onItemSelect}
                    checkedKeys={checkedKeys}
                    isUserSearching={isUserSearching}
                  />
                );
              } else {
                return (
                  <>
                    <Collapse
                      className="collapse"
                      expandIconPosition="end"
                      collapsible="icon"
                    >
                      {newSelectedSpecifications?.map(
                        (selectedSpecification: RoleSpecification) => {
                          return (
                            <Panel
                              header={createCollapseHeader(
                                selectedSpecification
                              )}
                              key={selectedSpecification.roleSpecificationId}
                            >
                              {getRightSideTree(
                                selectedSpecification.roleSpecificationId,
                                onItemSelect
                              )}
                            </Panel>
                          );
                        }
                      )}
                    </Collapse>
                  </>
                );
              }
            }}
          </Transfer>
        </>
      ) : (
        <div className="requests-loading-message">
          <p>Requests are loading please wait...</p>{" "}
          <Spin data-testid="loading-spinner" />{" "}
        </div>
      )}
    </div>
  );
};

export default SplitRequestAssignment;
