const scrollBothTables = (table1, table2) => {
  if (table2) {
    table2.scrollTo(table1.scrollLeft, 0);
  }
};

export const handleParallelScrollEvent = (table1Class, table2Class) => {
  let bottomTable = null;
  let topTable = null;

  topTable = window.document.querySelector(table1Class);
  /* istanbul ignore else*/

  setTimeout(() => {
    bottomTable = window.document.querySelector(table2Class);
    if (bottomTable != null) {
      bottomTable.addEventListener("scroll", () =>
        scrollBothTables(bottomTable, topTable)
      );
    }
  }, 1000);

  if (topTable != null) {
    topTable.addEventListener("scroll", () =>
      scrollBothTables(topTable, bottomTable)
    );
  }
};

export const handleInfiniteScroll = (selector, callback) => {
  const handleScroll = () => {
    /* istanbul ignore else*/
    if (
      listElm.scrollTop + listElm.clientHeight >=
      listElm.scrollHeight * 0.9
    ) {
      /* istanbul ignore else*/
      callback();
    }
  };
  const listElm = window.document.querySelector(selector);
  /* istanbul ignore else*/
  if (listElm != null) {
    listElm.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      listElm.removeEventListener("scroll", handleScroll);
    };
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return () => {};
};
