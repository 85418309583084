// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-settings-modal .table-settings-headline {
  margin: 12px 0;
}
.table-cols-checkbox-list .ant-tree-switcher {
  margin-left: -24px;
}
.table-cols-checkbox-list .ant-tree-switcher-noop {
  display: none !important;
}
.compact-switch-wrapper {
  padding-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/features/table_filtering/components/table_settings_button/table_settings_button.less"],"names":[],"mappings":"AAEA;EAEI,cAAA;AAFJ;AAQI;EACE,kBAAA;AANN;AAQM;EACE,wBAAA;AANR;AAYA;EACE,oBAAA;AAVF","sourcesContent":["@import \"@src/stylings/variables\";\n\n.table-settings-modal {\n  .table-settings-headline {\n    margin: 12px 0;\n  }\n}\n\n.table-cols-checkbox-list {\n  .ant-tree {\n    &-switcher {\n      margin-left: -24px;\n\n      &-noop {\n        display: none !important;\n      }\n    }\n  }\n}\n\n.compact-switch-wrapper {\n  padding-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
