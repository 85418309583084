import React, { MouseEventHandler } from "react";

import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";

interface ResetFilterButtonProps {
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLElement>;
}

const ResetFilterButton: React.FC<ResetFilterButtonProps> = ({
  disabled = false,
  onClick,
}) => {
  return (
    <Button
      size="large"
      disabled={disabled}
      icon={<DeleteOutlined />}
      onClick={onClick}
      data-testid="filter-reset-button"
    >
      Reset Filter
    </Button>
  );
};

export default ResetFilterButton;
