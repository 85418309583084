export const getConfirmationModalMessages = () => {
  return {
    title: "Project end date updated",
    warning:
      "You have reduced the project lifetime. Any request after the new finish date will be deleted.\n Do you want to save the changes?",
    button: "Ok",
  };
};

export const getConfirmationModalMessagesCopyProject = () => {
  return {
    title:
      "All requests of the original project will be copied and defined as draft for your new project.\n" +
      "Are you sure you want to create an additional project?",
    button: "Ok",
  };
};

export const getConfirmationModalMessagesCopyProjectWarning = () => {
  return {
    title:
      "All requests of the original project will be copied and defined as draft for your new project.",
    warning:
      "You didn't change any project info. Are you sure you want to continue?",
    button: "Ok",
  };
};
