import { message } from "antd";
import { createFetchBaseQuery } from "./baseQuery";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Project } from "@src/types";

const getEndpoints = (builder: EndpointBuilder<any, any, any>) => {
  return {
    /**
     * Get projects of a given project lead id
     */
    getProjectsByProjectLeadId: builder.query<Project[], number>({
      query: (projectLeadId) => `/${projectLeadId}/projects`,
      transformResponse(response) {
        return response.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      },
      transformErrorResponse: async () => {
        message.error("Unable to load projects", 5);
      },
      providesTags: ["Projects"],
    }),
  };
};

export const projectLeadsSlice = createApi({
  reducerPath: "projectLeadsSlice",
  baseQuery: createFetchBaseQuery("/project-leads"),
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),
  }),
});

export const {
  /* GET endpoints */

  //lazy endpoints
  useLazyGetProjectsByProjectLeadIdQuery,
} = projectLeadsSlice;
