import { Rule } from "antd/lib/form";
import { MessageInstance } from "antd/lib/message/interface";
import _ from "lodash";

import {
  ROLE_ASSIGNMENT_ERROR_MESSAGES,
  RoleTypes,
} from "./role_assignment_constants";

/**
 * helper method to show success messages
 * @param {`created` | `updated` | `deleted`} manipulationType the kind of data manipulation that will be shown in the message
 * @param {MessageInstance} message api of the messageContextHolder
 * @param {RoleTypes} roleType type of the role to be shown in the message
 */
export function showSuccessMessage(
  manipulationType: `created` | `updated` | `deleted`,
  message: MessageInstance,
  roleType: RoleTypes
): void {
  message.success(`Successfully ${manipulationType} ${_.lowerCase(roleType)}`);
}

/**
 * gets the validator rules for the input fields of basic role and role specification forms
 *
 * @param {(value: string) => boolean} checkIfRoleExists function that checks the duplication
 * @returns the rules for input field
 */
export function getValidatorRulesForInput(
  checkIfRoleExists: (value: string) => boolean
): Rule[] {
  return [
    {
      whitespace: true,
      required: true,
      message: ROLE_ASSIGNMENT_ERROR_MESSAGES.EMPTY,
    },
    {
      min: 2,
      message: ROLE_ASSIGNMENT_ERROR_MESSAGES.LESS_THAN_2_CHARS,
    },
    {
      pattern: /^[a-zA-Z 0-9 ()&/\\ -]*$/,
      message: ROLE_ASSIGNMENT_ERROR_MESSAGES.SPECIAL_CHARS,
    },

    {
      validator(_, value) {
        // check for only numbers
        if (value.trim() && value.length > 1 && !isNaN(Number(value))) {
          return Promise.reject(
            new Error(ROLE_ASSIGNMENT_ERROR_MESSAGES.ONLY_NUMERIC)
          );
        }
        // check if role already exists
        if (checkIfRoleExists(value)) {
          return Promise.reject(
            new Error(ROLE_ASSIGNMENT_ERROR_MESSAGES.DUPLICATE)
          );
        }
        return Promise.resolve();
      },
    },
  ];
}
