import React from "react";

import { OverlayView } from "@react-google-maps/api";
import "./google_maps_marker.less";
import { Tooltip } from "antd";

export interface GoogleMapsMarkerProps {
  position: google.maps.LatLng;
  countryName: string;
  totalRequests: number;
}

const GoogleMapsMarker: React.FC<GoogleMapsMarkerProps> = ({
  position,
  countryName,
  totalRequests,
}) => {
  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <Tooltip placement="top" title={countryName}>
        <div className="marker">{totalRequests}</div>
      </Tooltip>
    </OverlayView>
  );
};

export default GoogleMapsMarker;
