import React from "react";

import InfoCircleFilled from "@ant-design/icons/lib/icons/InfoCircleFilled";
import { Popover } from "antd";
import "./info_label.less";

interface RoleAssignmentModalInfoLabelProps {
  isDisabled: boolean;
  showPopOver: boolean;
  infoLabelContent: string;
  popOverTitle?: string;
  popOverContent?: any;
}

const InfoLabel: React.FC<RoleAssignmentModalInfoLabelProps> = ({
  isDisabled,
  showPopOver,
  infoLabelContent,
  popOverTitle,
  popOverContent,
}) => {
  return (
    <div className="info-label">
      <span className={isDisabled ? "label-disabled" : ""}>
        {infoLabelContent}
        {showPopOver && (
          <Popover
            content={popOverContent}
            title={popOverTitle}
            trigger="click"
            placement="topRight"
            overlayClassName="info-label__popover"
          >
            <InfoCircleFilled
              className={isDisabled ? "label-disabled" : ""}
              data-testid="info-circle-icon"
            />
          </Popover>
        )}
      </span>
    </div>
  );
};

export default InfoLabel;
