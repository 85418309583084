import { message } from "antd";
import { createFetchBaseQuery } from "./baseQuery";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { Region } from "@src/types";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { HTTP_METHODS } from "@src/constants";

const getEndpoints = (builder: EndpointBuilder<any, any, any>) => {
  return {
    /**
     * Get all regions
     */
    getRegions: builder.query<Region[], void>({
      query: () => "",
      transformErrorResponse: async () => {
        message.error("Unable to load regions", 5);
      },
      providesTags: ["Regions"],
    }),
  };
};

const deleteEndpoints = (builder: EndpointBuilder<any, any, any>) => {
  const baseMethod = HTTP_METHODS.DELETE;

  return {
    /**
     * Delete a single region by a given id
     */
    deleteRegion: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}`,
        method: baseMethod,
      }),
      transformErrorResponse: async () => {
        message.error("Error deleting region", 5);
      },
      invalidatesTags: ["Regions"],
    }),
  };
};

const postEndpoints = (builder: EndpointBuilder<any, any, any>) => {
  const baseMethod = HTTP_METHODS.POST;

  return {
    /**
     * Create a new region with the given data
     */
    createRegion: builder.mutation<void, Region>({
      query: (regions) => ({
        url: "",
        method: baseMethod,
        body: regions,
      }),
      transformErrorResponse: async () => {
        message.error("Error creating new region", 5);
      },
      invalidatesTags: ["Regions"],
    }),
  };
};

const putEndpoints = (builder: EndpointBuilder<any, any, any>) => {
  const baseMethod = HTTP_METHODS.PUT;

  return {
    /**
     * Update a region with the given data
     */
    updateRegion: builder.mutation<void, Region>({
      query: (region) => ({
        url: `/${region.regionId}`,
        method: baseMethod,
        body: region,
      }),
      transformErrorResponse: async () => {
        message.error("Error updating region", 5);
      },
      invalidatesTags: ["Regions"],
    }),
  };
};

export const regionsSlice = createApi({
  reducerPath: "regionsSlice",
  baseQuery: createFetchBaseQuery("/regions"),

  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),

    /* DELETE endpoints */
    ...deleteEndpoints(builder),

    /* POST endpoints */
    ...postEndpoints(builder),

    /* PUT endpoints */
    ...putEndpoints(builder),
  }),
});

export const {
  /* GET endpoints */
  useGetRegionsQuery,
  /* DELETE endpoints */
  useDeleteRegionMutation,
  /* POST endpoints */
  useCreateRegionMutation,
  /* PUT endpoints */
  useUpdateRegionMutation,
} = regionsSlice;
