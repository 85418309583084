import { Button, message, Tooltip } from "antd";
import React, { useState } from "react";
import { SelectionRenderer } from "../../../../custom_renderer";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import { useUpdateEmployeesStandardRoleMutation } from "../../../../services/slices/employeesSlice";

function TeamMemberRenderer({ value, teamLeadStandardRoles, setTeamMembers }) {
  const [employeeIdInEdit, setEmployeeIdInEdit] = useState([]);
  const [assignTeamMemberStandardRole] =
    useUpdateEmployeesStandardRoleMutation();

  const hasTeamLeadRoles = teamLeadStandardRoles?.length !== 0;
  const showEditRoleIcon =
    !value?.vacancy &&
    !employeeIdInEdit?.includes(value.employeeId) &&
    teamLeadStandardRoles?.length !== 0;

  function startEdit(employeeId) {
    setEmployeeIdInEdit([...employeeIdInEdit, employeeId]);
  }

  function stopEdit() {
    setEmployeeIdInEdit([]);
  }

  function standardRoleSelected(selectedStandardRoleId) {
    const updatedStandardRoleObject = {
      tmGenericRole: selectedStandardRoleId,
    };

    assignTeamMemberStandardRole({
      employeeId: value.employeeId,
      assignedRoleObject: updatedStandardRoleObject,
    })
      .unwrap()
      .then(() => {
        const selectedStandardRole = teamLeadStandardRoles.find(
          (role) => role.standardRoleId === selectedStandardRoleId
        );

        setTeamMembers((members) => {
          const member = members.find(
            (member) => member.employeeId === value.employeeId
          );
          member.genericRoleName = selectedStandardRole.standardRoleName;
          member.genericRoleId = selectedStandardRole.standardRoleId;

          return [...members];
        });

        stopEdit();
        message.success("Successfully assigned role.", 5);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  const checkGenericRoleName = value?.genericRoleName
    ? value?.genericRoleName
    : "Please select a role";

  return (
    <>
      <span className="member-name" data-testid="member-name">
        {value?.fullName}&nbsp;
        <span hidden={!value?.contractType}>
          ({value?.contractType?.toLowerCase()})
        </span>
      </span>

      <div className="standard-role-section">
        {employeeIdInEdit?.includes(value.employeeId) ? (
          <span data-testid="standard-role-selection">
            <SelectionRenderer
              dataToExtractOptions={teamLeadStandardRoles}
              propertyForOptionValues="standardRoleId"
              propertyForOptionLabels="standardRoleName"
              defaultValue={
                value?.genericRoleName &&
                teamLeadStandardRoles.find(
                  (role) => role.standardRoleId === value?.genericRoleId
                )
                  ? value?.genericRoleId
                  : "Please select a role"
              }
              style={{ width: "230px", height: "25px" }}
              onChangeCallback={(value) => standardRoleSelected(value)}
              hideWhenFocusLostCallback={() => stopEdit()}
            />
          </span>
        ) : (
          <span className="role-text" data-testid="role-text">
            {value?.vacancy ? value?.positionText : checkGenericRoleName}
          </span>
        )}

        {hasTeamLeadRoles ? (
          showEditRoleIcon && (
            <Button
              data-testid="update-role-button"
              type="link"
              className="edit-role-button"
              onClick={() => startEdit(value.employeeId)}
              icon={<EditOutlined />}
            />
          )
        ) : (
          <Tooltip
            placement="topLeft"
            title="Role not editable because The team lead has no roles assigned"
            data-testid="update-role-button-tooltip"
          >
            <Button
              type="link"
              data-testid="update-role-button"
              disabled
              icon={<EditOutlined />}
            />
          </Tooltip>
        )}
      </div>
    </>
  );
}

export default TeamMemberRenderer;
