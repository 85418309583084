// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-blocker-add {
  padding-left: 20px;
}
.time-blocker-add Button {
  background: #ffffff;
  color: #595959;
  border: 1px dashed #d9d9d9;
}
.time-blocker-add Button .ant-btn:hover {
  background: #ffffff !important;
  color: #00a19f !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/teams/components/add_time_blocker/add_time_blocker.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAFA;EAII,mBAAA;EACA,cAAA;EACA,0BAAA;AACJ;AACM;EACE,8BAAA;EACA,yBAAA;AACR","sourcesContent":[".time-blocker-add {\n  padding-left: 20px;\n\n  Button {\n    background: #ffffff;\n    color: #595959;\n    border: 1px dashed #d9d9d9;\n    .ant-btn {\n      &:hover {\n        background: #ffffff !important;\n        color: #00a19f !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
