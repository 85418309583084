import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BasicRole } from "@src/types";
import { message } from "antd";

import { baseApiSlice } from "./baseApiSlice";

export const basicRolesApi = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),
    /* POST endpoints */
    ...postEndpoints(builder),
    /* PUT endpoints */
    ...putEndpoints(builder),
    /* DELETE endpoints */
    ...deleteEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    /**
     * Get all basic roles
     */
    getBasicRoles: builder.query<BasicRole[], void>({
      query: () => "basic-roles",
      transformErrorResponse: async (): Promise<void> => {
        message.error("Unable to load basic roles", 5);
      },
      providesTags: ["BasicRoles"],
    }),
  };
}

function postEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = "POST";

  return {
    /**
     * Create a new basic role with the given data
     */
    addBasicRole: builder.mutation<void, BasicRole>({
      query: (basicRole) => ({
        url: "basic-roles",
        method: baseMethod,
        body: basicRole,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Error creating new basic role", 5);
      },
      invalidatesTags: ["BasicRoles", "StandardRoles"],
    }),
  };
}

function putEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = "PUT";
  return {
    /**
     * Update a basic role with the given data
     */
    updateBasicRole: builder.mutation<void, BasicRole>({
      query: (basicRole) => ({
        url: `basic-roles/${basicRole.basicRoleId}`,
        method: baseMethod,
        body: basicRole,
      }),
      transformErrorResponse: async (): Promise<void> => {
        message.error("Error updating basic role", 5);
      },
      invalidatesTags: ["BasicRoles", "StandardRoles"],
    }),
  };
}

function deleteEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = ["DELETE"];

  return {
    /**
     * Delete a single basic role by a given id
     */
    deleteBasicRole: builder.mutation<void, number>({
      query: (id) => ({
        url: `basic-roles/${id}`,
        method: baseMethod,
      }),
      transformErrorResponse: async () => {
        message.error("Error deleting basic role", 5);
      },
      invalidatesTags: ["BasicRoles", "StandardRoles"],
    }),
  };
}

export const {
  /* GET endpoints */
  useGetBasicRolesQuery,
  /* POST endpoints */
  useAddBasicRoleMutation,
  /* PUT endpoints */
  useUpdateBasicRoleMutation,
  /* DELETE endpoints */
  useDeleteBasicRoleMutation,
} = basicRolesApi;
