// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend__item {
  display: flex;
  justify-content: space-between;
  padding: 5px 30px;
}
.legend__item:hover {
  cursor: pointer;
}
.legend__item__text {
  display: flex;
  align-items: center;
  font-family: "RWESansWeb-Medium";
  user-select: none;
  color: #3b3d42;
}
.legend__item__circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.legend__item__count {
  font-family: "RWESansWeb-Small", sans-serif;
  color: #3b3d42;
}
`, "",{"version":3,"sources":["webpack://./src/features/dashboard/components/doughnut_chart_legend_item/doughnut_chart_legend_item.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;AADF;AAGE;EACE,eAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,gCAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAKE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AAHJ;AAME;EACE,2CAAA;EACA,cAAA;AAJJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.legend__item {\n  display: flex;\n  justify-content: space-between;\n  padding: 5px 30px;\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  &__text {\n    display: flex;\n    align-items: center;\n    font-family: \"RWESansWeb-Medium\";\n    user-select: none;\n    color: @font-00;\n  }\n\n  &__circle {\n    border-radius: 50%;\n    width: 15px;\n    height: 15px;\n    margin-right: 8px;\n  }\n\n  &__count {\n    font-family: \"RWESansWeb-Small\", sans-serif;\n    color: @font-00;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
