import { Tooltip } from "antd";
import React from "react";
import "./teammember_project_renderer.less";
import * as constants from "../../../../constants";
import { ROUTES } from "../../../../constants";

function TeammemberProjectRenderer({ value }) {
  let proposedClass =
    value.status === constants.REQUEST_STATUS.PROPOSED
      ? "project-name-proposed"
      : "project-name";

  const projectNameRenderer = (projectName) => {
    if (projectName.length > 50) {
      return projectName.substring(0, 50) + "...";
    }

    return projectName;
  };

  return (
    <Tooltip
      title={`${value.projectName} ${
        value.specificRole ? ", " + value.specificRole : ""
      }`}
      data-testid="tooltip"
    >
      <div className="project-name-section">
        {value.projectName !== "Time Blocker" ? (
          <a
            href={`${ROUTES.TEAM_LEAD.STAFFING_REQUESTS}/suggestTeamMember/${value.projectRoleRequestId}`}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="link-to-project"
          >
            <span className={`${proposedClass}`} data-testid="project-name">
              {projectNameRenderer(value.projectName)}
            </span>
          </a>
        ) : (
          <span className="member-name" data-testid="time-blocker">
            {value.projectName}
          </span>
        )}

        {value.specificRole && (
          <>
            <span className="hyphen" data-testid="hyphen">
              -
            </span>
            <span className="role-text" data-testid="specific-role">
              {value.specificRole}
            </span>
          </>
        )}
      </div>
    </Tooltip>
  );
}

export default TeammemberProjectRenderer;
