import { getErrorMessage } from "@src/utils/helper";

function setErrors(params, field) {
  if (!params.node.error) {
    params.node.error = {};
  }
  params.node.error[field || params.colDef.field] = true;
}

export const isNonZeroAllocations = (allocations) => {
  let result = false;
  if (Object.values(allocations).length === 0) {
    result = false;
  }
  Object.values(allocations).forEach((eachYear) => {
    if (Object.values(eachYear).length > 0) {
      Object.values(eachYear).forEach((eachMonthValue) => {
        if (Number(eachMonthValue) && parseInt(eachMonthValue) > 0) {
          result = true;
        }
      });
    }
  });
  return result;
};

export const validateMonthlyAllocation = (params, year, month) => {
  let yearlyAllocations = { ...params.node.data.yearlyAllocations };
  let updatedValue = params.value;

  if (!yearlyAllocations[year]) {
    yearlyAllocations[year] = {};
  }
  if (Number.isInteger(parseInt(params.value))) {
    yearlyAllocations[year][month] = parseInt(params.value);
  } else {
    delete yearlyAllocations[year][month];
  }

  let nonZeroAllocation = isNonZeroAllocations(yearlyAllocations);

  let isValid = true;

  if (
    params.value &&
    params.value !== "" &&
    isNaN(params.value) &&
    params.value.match(/[^0-9\\-]/g)
  ) {
    isValid = false;
    setErrors(params);

    getErrorMessage(
      "You have entered invalid values. Correct examples: 0%, 79%, 100%"
    );
  } else if (parseInt(params.value) > 100 || parseInt(params.value) < 0) {
    isValid = false;
    updatedValue = parseInt(params.value);
    setErrors(params);

    getErrorMessage("Invalid values: Please insert values between 0% and 100%");
  } else if (params.value === "") {
    updatedValue = "";
  } else {
    updatedValue = parseInt(params.value);

    if (params.node.error) {
      delete params.node.error[`${year}_${month}`];
    }
  }

  if (!nonZeroAllocation) {
    if (params.node.selected) {
      setErrors(params, "yearlyAllocations");
      params.api.redrawRows();
      getErrorMessage("Missing Fields: please enter a allocation  ");
    }
  } else if (params.node.error) {
    delete params.node.error.yearlyAllocations;
  }

  return {
    isValid,
    updatedValue,
  };
};

export function updateFTEData(api, setContext) {
  if (api.getRenderedNodes().length === 0) return;

  const node = api.getRenderedNodes()[0];
  setContext(node?.parent?.aggData);
}

/* 
These guarantees that the totalFTE headline is always shown even when some columns are hiddden.
(!) To make this work it's important to NOT set any colSpan, valueFormatter or cellRenderer in all the left pinned columns.
Use cellRendererSelector instead and make it return undefined when params.node.rowPinned is true. 

This might be a little bit ugly but the PL page will be redesigned soon and the totalFTE will then be displayed differently.
*/
export const defaultFTEColDefs = {
  valueFormatter: (params) => {
    if (params.node.rowPinned && params.colDef.pinned === "left")
      return "Total FTE";
  },
  colSpan: (params) =>
    params.node.rowPinned && params.colDef.pinned === "left"
      ? params.api.getAllDisplayedColumns()?.length - 1
      : 1,
};
