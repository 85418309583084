import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { HTTP_METHODS } from "@src/constants";
import {
  ApplicationRole,
  AssignRolesPayload,
  AssignTeamMemberStandardRolesPayload,
  Employee,
  EmployeeDeleteStatus,
  ReassignTeamLeadPayload,
  EmployeeSearchRequest,
  Page,
} from "@src/types";
import { getSortParameters } from "@src/utils/query_utils";
import { message } from "antd";

import { baseApiSlice } from "./baseApiSlice";

export const employeesSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),

    /* POST endpoints */
    ...postEndpoints(builder),

    /* PUT endpoints */
    ...putEndpoints(builder),

    /* DELETE endpoints */
    ...deleteEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    /**
     * Get all project leads
     */
    getProjectLeads: builder.query<Employee[], void>({
      query: () => "employee/project-leads",
      transformErrorResponse: async () => {
        message.error("Unable to load project leads", 5);
      },
      providesTags: ["Employees"],
    }),

    getTeamLeads: builder.query<Employee[], void>({
      query: () => "employee/team-leads",
      transformErrorResponse: async () => {
        message.error("Unable to load team leads", 5);
      },
      providesTags: ["Employees"],
    }),

    getEmployeeById: builder.query<Employee, number>({
      query: (id) => `employee/id/${id}`,
      transformErrorResponse: async () => {
        message.error("Unable to load employee", 5);
      },
      providesTags: ["Employees"],
    }),
    getEmployeeByUiNumber: builder.query<Employee, string>({
      query: (uiNumber) => `employee/${uiNumber}`,
      transformErrorResponse: async () => {
        message.error("Unable to load employee", 5);
      },
      providesTags: ["Employees"],
    }),
    getEmployees: builder.query<Page<Employee>, EmployeeSearchRequest>({
      query: (request: EmployeeSearchRequest) => ({
        url: "/employees",
        method: HTTP_METHODS.GET,
        params: {
          ...(request?.pageRequestParams || { page: 0, size: 5000 }),
          ...request.filterParams,
          ...getSortParameters(request.sortRequestParams),
        },
      }),
      transformErrorResponse: async () => {
        message.error("Failed to search for employees", 5);
      },
      providesTags: ["Employees"],
    }),
    getEmployeeImpersonations: builder.query<Employee[], void>({
      query: () => "/employees/impersonations",
      transformErrorResponse: async () => {
        message.error("Failed to get employee impersonations", 5);
      },
      providesTags: ["Employees"],
    }),
    getEmployeeApplicationRoles: builder.query<ApplicationRole[], void>({
      query: () => "/employee/getApplicationRoles",
      transformErrorResponse: async () => {
        message.error("Failed to get application role list", 5);
      },
    }),
  };
}

function postEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.POST;

  return {
    saveEmployee: builder.mutation<void, Employee>({
      query: (selectedEmployee) => ({
        url: `employees`,
        method: baseMethod,
        body: selectedEmployee,
      }),
      transformErrorResponse: async () => {
        message.error("Failed to save the employee", 5);
      },
      invalidatesTags: ["Employees"],
    }),

    checkEmployeeStatus: builder.mutation<EmployeeDeleteStatus[], Employee[]>({
      query: (selectedItems) => ({
        url: "/employee/check-employee-delete-status",
        method: baseMethod,
        body: selectedItems,
      }),
      transformErrorResponse: async () => {
        message.error("Failed to get delete status", 5);
      },
    }),

    assignRoles: builder.mutation<void, AssignRolesPayload>({
      query: (assignRolesParams) => ({
        url: "/assign-roles",
        method: baseMethod,
        body: assignRolesParams,
      }),
      transformErrorResponse: async () => {
        message.error("Failed to assign roles", 5);
      },
      invalidatesTags: [
        "Employees",
        "AdminStandardRoles",
        "DeputyStandardRoles",
        "Notifications",
      ],
    }),

    assignRolesV2: builder.mutation<void, AssignRolesPayload>({
      query: (assignRolesParams) => ({
        url: "v2/assign-roles",
        method: baseMethod,
        body: assignRolesParams,
      }),
      transformErrorResponse: async () => {
        message.error("Failed to assign roles", 5);
      },
      invalidatesTags: [
        "Employees",
        "AdminStandardRoles",
        "DeputyStandardRoles",
        "Notifications",
      ],
    }),

    searchEmployees: builder.query<Page<Employee>, EmployeeSearchRequest>({
      query: (request: EmployeeSearchRequest) => ({
        url: "/employees/search",
        method: baseMethod,
        body: request.filterParams,
        params: {
          ...(request?.pageRequestParams || { page: 0, size: 5000 }),
          ...getSortParameters(request.sortRequestParams),
        },
      }),
      transformErrorResponse: async () => {
        message.error("Failed to search for employees", 5);
      },
      providesTags: ["Employees"],
    }),
  };
}

function putEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.PUT;

  return {
    /**
     * Update employees standard role
     */
    updateEmployeesStandardRole: builder.mutation<
      void,
      AssignTeamMemberStandardRolesPayload
    >({
      query: ({ employeeId, assignedRoleObject }) => ({
        url: `employees/${employeeId}/standard-roles`,
        method: baseMethod,
        body: assignedRoleObject,
      }),
      transformErrorResponse: async () => {
        message.error("Error assigning role", 5);
      },
      invalidatesTags: ["Employees"],
    }),

    updateEmployee: builder.mutation<Employee, Employee>({
      query: (selectedEmployee) => ({
        url: `employees/${selectedEmployee.employeeId}`,
        method: baseMethod,
        body: selectedEmployee,
      }),
      transformErrorResponse: async () => {
        message.error("Failed to save the employee", 5);
      },
      invalidatesTags: ["Employees"],
    }),

    /**
     * Update team leads response
     */
    updateTeamLeadsResponse: builder.mutation<void, ReassignTeamLeadPayload>({
      query: ({ oldLeadId, newLeadId }) => ({
        url: `employee/reassign-team-lead?oldTeamLeadId=${oldLeadId}&newTeamLeadId=${newLeadId}`,
        method: baseMethod,
        body: {},
      }),
      transformErrorResponse: async () => {
        message.error("Failed to update team leads", 5);
      },
      invalidatesTags: ["Employees"],
    }),
  };
}

function deleteEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.DELETE;

  return {
    deleteResponse: builder.mutation<void, number>({
      query: (employeeId) => ({
        url: `/employee/${employeeId}`,
        method: baseMethod,
      }),
      transformErrorResponse: async () => {
        message.error("Failed to delete employee", 5);
      },
      invalidatesTags: ["Employees"],
    }),
  };
}

export const {
  /* GET endpoints */
  useGetProjectLeadsQuery,
  useLazyGetProjectLeadsQuery,
  useGetTeamLeadsQuery,
  useLazyGetTeamLeadsQuery,
  useLazyGetEmployeeByIdQuery,
  useLazyGetEmployeeByUiNumberQuery,
  useGetEmployeesQuery,
  useGetEmployeeImpersonationsQuery,
  useLazyGetEmployeeImpersonationsQuery,
  useLazySearchEmployeesQuery,
  useGetEmployeeApplicationRolesQuery,
  useLazyGetEmployeeApplicationRolesQuery,

  /* POST endpoints */
  useSaveEmployeeMutation,
  useCheckEmployeeStatusMutation,
  useAssignRolesMutation,
  useAssignRolesV2Mutation,

  /* PUT endpoints */
  useUpdateEmployeeMutation,
  useUpdateEmployeesStandardRoleMutation,
  useUpdateTeamLeadsResponseMutation,

  /* DELETE endpoints */
  useDeleteResponseMutation,
} = employeesSlice;
