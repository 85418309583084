// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-assignment-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1 1 65%;
}
.role-assignment-filter .filter-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.role-assignment-filter__team-leads {
  align-self: center;
  width: 200px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/features/role_assignment/components/role_assignment_filter/role_assignment_filter.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,eAAA;EACA,8BAAA;EACA,aAAA;AADF;AAHA;EAOI,aAAA;EACA,eAAA;EACA,8BAAA;AADJ;AAIE;EACE,kBAAA;EACA,YAAA;EACA,mBAAA;AAFJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.role-assignment-filter {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  flex: 1 1 65%;\n\n  .filter-group {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n  }\n\n  &__team-leads {\n    align-self: center;\n    width: 200px;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
