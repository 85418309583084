// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request-replied-chart {
  min-width: 20%;
}
.request-replied-chart .ant-card-body {
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/features/dashboard/components/request_replied_chart/request_replied_chart.less"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAFA;EAII,aAAA;EACA,uBAAA;AACJ","sourcesContent":[".request-replied-chart {\n  min-width: 20%;\n\n  .ant-card-body {\n    display: flex;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
