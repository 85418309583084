import React from "react";
import { CommentOutlined } from "@ant-design/icons";

const commentRenderer = (param) => {
  if (param?.data["isCommentAvailable"] && !param?.data["isReadByEmployee"]) {
    return (
      <span className="comment-badge" data-testid="comment-badge">
        <CommentOutlined />
      </span>
    );
  }

  return "";
};

export default commentRenderer;
