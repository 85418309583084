import React from "react";
import { Checkbox } from "antd";

const JointVentureRenderer = (params) => {
  const onChange = (e) => {
    let field = params.colDef.field;
    params.node.setDataValue(field, e.target.checked);
  };
  return <Checkbox checked={params.value} onChange={onChange} />;
};

export default JointVentureRenderer;
