// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-range-picker {
  width: 100%;
  height: 40px;
  background-color: #fafafa !important;
  border-color: #d2dae4 !important;
}
.date-range-picker .ant-picker-input > input {
  color: #1d4477 !important;
  font-family: "RWESansWeb-Medium", sans-serif !important;
  font-size: 13px !important;
}
.date-range-picker .ant-picker-active-bar {
  background-color: #1d4477 !important;
}
.date-range-picker__suffix-icon > img {
  margin: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/table_filtering/components/date_range_filter/date_range_filter.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,oCAAA;EACA,gCAAA;AADF;AAHA;EAQM,yBAAA;EACA,uDAAA;EACA,0BAAA;AAFN;AARA;EAeI,oCAAA;AAJJ;AAOE;EAEI,oBAAA;AANN","sourcesContent":["@import \"@src/stylings/variables\";\n\n.date-range-picker {\n  width: 100%;\n  height: 40px;\n  background-color: @sand-20 !important;\n  border-color: @brand-20 !important;\n\n  .ant-picker-input {\n    > input {\n      color: @brand !important;\n      font-family: \"RWESansWeb-Medium\", sans-serif !important;\n      font-size: 13px !important;\n    }\n  }\n\n  .ant-picker-active-bar {\n    background-color: @brand !important;\n  }\n\n  &__suffix-icon {\n    > img {\n      margin: 0 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
