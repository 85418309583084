import React, { useEffect } from "react";

import { navigate, RouteComponentProps } from "@reach/router";
import { PROJECT_FORM_VARIANT, ROUTES } from "@src/constants";
import ProjectForm from "@src/features/projects/components/project_form/project_form";
import { useLazyGetProjectQuery } from "@src/services/slices/projectsSlice";

export interface ICreateProjectPage extends RouteComponentProps {
  id?: string;
}

const CreateProjectPage = ({ id }: ICreateProjectPage) => {
  const [getProject, { data: project, isError: errorDuplicatingProject }] =
    useLazyGetProjectQuery();

  const variant =
    id === "-1" ? PROJECT_FORM_VARIANT.CREATE : PROJECT_FORM_VARIANT.DUPLICATE;

  useEffect(() => {
    if (variant === PROJECT_FORM_VARIANT.DUPLICATE) {
      getProject(id);
    }
  }, [id]);

  useEffect(() => {
    if (errorDuplicatingProject) {
      navigate("/");
    }
  }, [errorDuplicatingProject]);

  const onSave = () => {
    if (variant === PROJECT_FORM_VARIANT.DUPLICATE) {
      navigate(ROUTES.PROJECT_LEAD.PROJECTS);
    } else {
      navigate(-1);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <ProjectForm
      project={project}
      onProjectSaved={onSave}
      onCancel={onCancel}
      variant={variant}
    />
  );
};

export default CreateProjectPage;
