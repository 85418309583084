import { message } from "antd";
import { HTTP_METHODS } from "@src/constants";
import { AdminUploadRequest, Employee, SapCsvData } from "@src/types";
import { EndpointBuilder } from "@reduxjs/toolkit/src/query/endpointDefinitions";
import { baseApiSlice } from "@src/services/slices/baseApiSlice";

export const adminApi = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* POST endpoints */
    ...postEndpoints(builder),
  }),
});

function postEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.POST;
  return {
    /**
     * Check SAP data
     */
    saveResourcePlanData: builder.mutation<
      void,
      { projectId: string; payload: any }
    >({
      query: ({ projectId, payload }) => ({
        url: `/admin/${projectId}/save-resource-plan-data`,
        method: baseMethod,
        body: payload,
      }),
      transformErrorResponse: async () => {
        message.error("Error saving resource plan", 5);
      },
      invalidatesTags: ["Requests"],
    }),

    checkDeletedSapData: builder.mutation<SapCsvData[], string[]>({
      query: (sapData) => ({
        url: "/admin/check-deleted-employees",
        method: baseMethod,
        body: sapData,
      }),
      transformErrorResponse: async () => {
        message.error("Check deleted employees", 5);
      },
    }),
    checkSapData: builder.mutation<SapCsvData[], SapCsvData[]>({
      query: (sapData) => ({
        url: "/admin/check-sap-data",
        method: baseMethod,
        body: sapData,
      }),
      transformErrorResponse: async () => {
        message.error("Check sap data", 5);
      },
    }),

    saveSAPData: builder.mutation<Employee, SapCsvData>({
      query: (sapDataToSave) => ({
        url: "/admin/save-sap-data",
        method: baseMethod,
        body: sapDataToSave,
      }),
    }),

    /**
     * Check resource plan data
     */
    checkResourcePlanData: builder.mutation<
      AdminUploadRequest[],
      AdminUploadRequest[]
    >({
      query: (ResourcePlan) => ({
        url: "/admin/check-resource-plan-data",
        method: baseMethod,
        body: ResourcePlan,
      }),
      transformErrorResponse: async () => {
        message.error("Failed to sent resource plan", 5);
      },
    }),
  };
}

export const {
  /* POST endpoints */
  useCheckSapDataMutation,
  useSaveSAPDataMutation,
  useCheckDeletedSapDataMutation,
  useCheckResourcePlanDataMutation,
  useSaveResourcePlanDataMutation,
} = adminApi;
