import React, { useCallback, useRef, useState } from "react";

import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { createGridOptions } from "@src/features/role_assignment/utils/standard_role_assignment_grid_options";
import SplitRoleModal from "@src/features/role_split/components/split_role_modal/split_role_modal";
import { useGetBasicRolesQuery } from "@src/services/slices/basicRolesApi";
import { useGetRoleSpecificationsQuery } from "@src/services/slices/roleSpecificationsApi";
import {
  useAddStandardRoleMutation,
  useDeleteStandardRoleMutation,
  useGetStandardRolesQuery,
  useUpdateStandardRoleMutation,
} from "@src/services/slices/standardRolesApi";
import { StandardRole, RoleAssignmentFilterData } from "@src/types";
import { columnTypes } from "@src/utils/aggrid_utils";
import {
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, App } from "antd";
import _ from "lodash";

import { RoleTypes } from "../../utils/role_assignment_constants";
import { showSuccessMessage } from "../../utils/role_assignment_helper";
import { RoleAssignmentFilter } from "../role_assignment_filter";
import { StandardRoleModal } from "../standard_role_modal";

import "./standard_role_assignment.less";

const StandardRoleAssignment: React.FC = () => {
  const gridApi = useRef<GridApi>(null);

  const { message } = App.useApp();

  /* use states */
  useState<StandardRole>(null);
  const [selectedStandardRoleToSplit, setSelectedStandardRoleToSplit] =
    useState<StandardRole>(null);
  const [showRoleAssignmentSplitModal, setShowRoleAssignmentSplitModal] =
    useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);
  const [showUpdateRoleModal, setShowUpdateRoleModal] =
    useState<boolean>(false);

  /* rtk queries and mutations */
  const { data: standardRoles, isFetching: isFetchingStandardRoles } =
    useGetStandardRolesQuery();
  const [addStandardRole] = useAddStandardRoleMutation();
  const [updateStandardRole] = useUpdateStandardRoleMutation();
  const [deleteStandardRole] = useDeleteStandardRoleMutation();

  const { data: basicRoles, isFetching: isFecthingBasicRoles } =
    useGetBasicRolesQuery();

  const { data: roleSpecifications, isFetching: isFecthingSpecifications } =
    useGetRoleSpecificationsQuery();

  /**
   * AG Grid onGridReady function
   * assigns the GridApi to a ref and resizes the columns so that the table is displayed correctly
   * also enables the filter bar and add button when table is ready
   *
   * @param {GridReadyEvent} event AG Grid event
   */
  const onGridReady = useCallback((event: GridReadyEvent) => {
    gridApi.current = event.api;
    event.api.sizeColumnsToFit();
    setIsTableLoading(false);
  }, []);

  /**
   * opens a modal where the user can add a new role
   */
  function onAddNewRow(): void {
    setShowUpdateRoleModal(true);
  }

  /**
   * opens a modal where the user can add a new role
   */
  function onDownloadRoles(): void {
    gridApi.current.exportDataAsExcel({
      columnKeys: [
        "basicRole",
        "roleSpecification",
        "onlyVisibleForAdmin",
        "teamLead",
        "teamLeadDepartment",
      ],
    });
  }

  /**
   * makes a post request to create a new standard role
   *
   * @param {StandardRole} newStandardRole the standard role to create
   */
  function onSaveRoleModal(newStandardRole: StandardRole) {
    addStandardRole(newStandardRole)
      .unwrap()
      .then(() => {
        showSuccessMessage("created", message, RoleTypes.STANDARD_ROLE);
        setShowUpdateRoleModal(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  /**
   * OnSplit will be activated after the user clicks the split button. This will lead in opening the modal and set the
   * state with necessary data.
   *
   * @param {ICellRendererParams} params     Ag grid current selected row parameters.
   */
  function onSplit(params: ICellRendererParams): void {
    setSelectedStandardRoleToSplit(params.data);
    setShowRoleAssignmentSplitModal(true);
  }

  /**
   * maps the data to a format that fits for the filter
   *
   * @returns the filterData
   */
  function getFilterContext(): RoleAssignmentFilterData {
    return {
      standardRoles: standardRoles,
      numberOfErrors: standardRoles?.filter(
        (role: StandardRole) => !role.teamLead?.teamLeadId
      ).length,
      basicRoles: basicRoles,
      roleSpecifications: roleSpecifications,
    };
  }

  return (
    <div className="standard-role-assignment">
      {showRoleAssignmentSplitModal && (
        <SplitRoleModal
          selectedStandardRole={selectedStandardRoleToSplit}
          showModal={showRoleAssignmentSplitModal}
          setShowModal={setShowRoleAssignmentSplitModal}
        />
      )}

      {showUpdateRoleModal && (
        <StandardRoleModal
          showModal={showUpdateRoleModal}
          setShowModal={setShowUpdateRoleModal}
          onSaveCallback={onSaveRoleModal}
        />
      )}

      <div className="top-section" data-testid="top-section">
        <RoleAssignmentFilter
          gridApi={gridApi.current}
          filterData={getFilterContext()}
          disabled={
            isFetchingStandardRoles ||
            isFecthingBasicRoles ||
            isFecthingSpecifications ||
            isTableLoading
          }
        />
        <Button
          onClick={onAddNewRow}
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          disabled={isFetchingStandardRoles || isTableLoading}
          data-testid="add-button"
        >
          Add Role
        </Button>
        <Button
          onClick={onDownloadRoles}
          size="large"
          icon={<DownloadOutlined />}
          disabled={isFetchingStandardRoles || isTableLoading}
          data-testid="download-button"
        >
          Download Roles
        </Button>
      </div>
      <div
        className="ag-theme-alpine header-white"
        data-testid="roles-management-table"
      >
        <AgGridReact
          rowData={_.cloneDeep(standardRoles)} //without clone deep props.data are not extensible with isEditActive or isNewRow
          gridOptions={createGridOptions(
            updateStandardRole,
            deleteStandardRole,
            onSplit
          )}
          columnTypes={columnTypes}
          onGridReady={onGridReady}
          onGridSizeChanged={(event: GridSizeChangedEvent) =>
            event.api.sizeColumnsToFit()
          }
        />
      </div>
    </div>
  );
};

export default StandardRoleAssignment;
