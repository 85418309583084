// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-staffing-requests {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.filter-staffing-requests .ant-picker-range {
  max-width: 255px;
  min-width: 170px;
  height: 40px;
}
.filter-staffing-requests .filter-select {
  min-width: 120px;
  max-width: 180px;
}
.filter-staffing-requests .ant-input-search {
  min-width: 150px;
}
.filter-staffing-requests .action-required-switch {
  text-align: center;
  min-width: 80px;
}
.filter-staffing-requests .action-required-switch .ant-switch {
  margin-left: 5px;
}
.filter-staffing-requests .filter-columns {
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_requests/components/tabs_staffing_requests/tabs_staffing_requests.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2BAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;AACF;AANA;EAQI,gBAAA;EACA,gBAAA;EACA,YAAA;AACJ;AAXA;EAcI,gBAAA;EACA,gBAAA;AAAJ;AAfA;EAmBI,gBAAA;AADJ;AAlBA;EAuBI,kBAAA;EACA,eAAA;AAFJ;AAtBA;EA2BM,gBAAA;AAFN;AAzBA;EAgCI,iBAAA;AAJJ","sourcesContent":[".filter-staffing-requests {\n  display: flex;\n  justify-content: flex-start;\n  gap: 15px;\n  margin-bottom: 10px;\n  flex-wrap: wrap;\n\n  .ant-picker-range {\n    max-width: 255px;\n    min-width: 170px;\n    height: 40px;\n  }\n\n  .filter-select {\n    min-width: 120px;\n    max-width: 180px;\n  }\n\n  .ant-input-search {\n    min-width: 150px;\n  }\n\n  .action-required-switch {\n    text-align: center;\n    min-width: 80px;\n\n    .ant-switch {\n      margin-left: 5px;\n    }\n  }\n\n  .filter-columns {\n    margin-left: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
