import React from "react";

import "./requested_role_renderer.less";
import { navigate } from "@reach/router";
import comment from "@src/assets/icons/comment.svg";
import warning from "@src/assets/icons/warning.svg";
import { ICellRendererParams } from "ag-grid-community";
import { Button, Tooltip } from "antd";

interface RequestedRoleRendererParams {
  params: ICellRendererParams;
}

const RequestedRoleRenderer: React.FC<RequestedRoleRendererParams> = ({
  params,
}) => {
  const requestedRoleId = params.data.projectRoleRequestId;
  const basicRoleName = params.data.basicRoleName;
  const roleSpecificationName = params.data.roleSpecificationName;
  const actionRequired = params.data.actionRequired;
  const commentAvailable =
    params.data.commentAvailable && !params.data.commentReadByEmployee;

  return (
    <div className="requested-role-col">
      <div className="requested-role-col-title-wrapper">
        <div className="requested-role-col-icon-wrapper">
          {actionRequired ? (
            <Tooltip
              mouseEnterDelay={0.5}
              placement="top"
              title={"Action required"}
            >
              <img
                src={warning}
                alt={"warning"}
                className={"requested-role-col-icon"}
              />
            </Tooltip>
          ) : (
            <div className="requested-role-col-icon empty" />
          )}
          <Button
            type="link"
            className={"requested-role-col-button"}
            onClick={() =>
              navigate(`staffingRequests/suggestTeamMember/${requestedRoleId}`)
            }
          >
            <span className="requested-role-col-button-text">
              {requestedRoleId}&nbsp;{basicRoleName}
            </span>
          </Button>
        </div>
        <div className="requested-role-col-subtext">
          {roleSpecificationName}
        </div>
      </div>
      {commentAvailable && (
        <Tooltip
          mouseEnterDelay={0.5}
          placement="top"
          title={"A comment is available"}
        >
          <img
            src={comment}
            alt={"comment"}
            className={"requested-role-col-icon"}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default RequestedRoleRenderer;
