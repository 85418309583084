import {
  columnTypes,
  paginationGridOptions,
  preventPinnedColumnJumpingGridOption,
} from "@src/utils/aggrid_utils";

const columnDefs = [
  {
    headerName: "Region",
    field: "regionName",
    cellRenderer: "inputRenderer",
    filter: "agSetColumnFilter",
  },
  {
    headerName: "Actions",
    field: "actions",
    suppressSizeToFit: true,
    width: 350,
    pinned: "right",
    cellRenderer: "actionsRenderer",
    sortable: false,
    resizable: false,
  },
];

export const getGridOptions = () => ({
  ...paginationGridOptions,
  ...preventPinnedColumnJumpingGridOption,
  domLayout: "autoHeight",
  suppressContextMenu: true,
  suppressClickEdit: true,
  animateRows: true,
  rowHeight: 55,
  columnDefs: columnDefs,
  defaultColDef: {
    ...columnTypes.default,
    ...columnTypes.cellWrapText,
  },
  context: {
    region: [],
  },
});
