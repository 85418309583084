import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  OpenRequestTableFilters,
  ResourcePlanState,
  ResourcePlanTableFilters,
  SentRequestTableFilters,
} from "@src/types";

const initialState: ResourcePlanState = {
  projectId: "",
  requestRepliesTableState: {
    quickFilter: "",
    filterResult: [],
    dateFilter: null,
    customTableFilters: {
      genericRoleName: [],
    },
    columns: [],
    currentTabConfig: null,
    isFilterApplied: false,
  },
  openRequestTableState: {
    quickFilter: "",
    filterResult: [],
    dateFilter: null,
    customTableFilters: {
      genericRoleName: [],
    },
    columns: [],
    currentTabConfig: null,
    isFilterApplied: false,
  },
  sentRequestsTableState: {
    quickFilter: "",
    filterResult: [],
    dateFilter: null,
    customTableFilters: {
      genericRoleName: [],
    },
    columns: [],
    currentTabConfig: null,
    isFilterApplied: false,
  },
};

/**
 * This function is used to check if the filter is applied
 * @param state the state of the table
 * @returns true if the filter is applied, false otherwise
 */
function checkIfFilterApplied(
  state: SentRequestTableFilters | OpenRequestTableFilters
) {
  const { customTableFilters } = state;
  return customTableFilters.genericRoleName?.length !== 0;
}

const resourcePlanSlice = createSlice({
  name: "resourcePlanSlice",
  initialState,
  reducers: {
    /**
     * Sets the project id state to the new state.
     * @param state the request overview filter state with all values.
     * @param projectId the project id string.
     */
    setProjectId: (
      state: ResourcePlanState,
      projectId: PayloadAction<string>
    ) => {
      state.projectId = projectId.payload;
    },
    /*Request & Replies*/
    /**
     * Sets the quick filter state to the new state.
     *
     * @param state       the request overview filter state with all values.
     * @param quickSearch the quick search string.
     */
    setRequestRepliesQuickFilter: (
      state: ResourcePlanState,
      quickSearch: PayloadAction<string>
    ) => {
      state.requestRepliesTableState.quickFilter = quickSearch.payload;
      state.requestRepliesTableState.isFilterApplied = checkIfFilterApplied(
        state.requestRepliesTableState
      );
    },
    /**
     * Sets the custom table filter state to the new state.
     *
     * @param state       the request overview filter state with all values.
     * @param customTableFilters the custom table filters object with all filters.
     */
    setRequestRepliesCustomTableFilter: (
      state: ResourcePlanState,
      customTableFilters: PayloadAction<ResourcePlanTableFilters>
    ) => {
      state.requestRepliesTableState.customTableFilters =
        customTableFilters.payload;
      state.requestRepliesTableState.isFilterApplied = checkIfFilterApplied(
        state.requestRepliesTableState
      );
    },
    /**
     * Sets the columns to the new state.
     *
     * @param state the request overview filter state with all values.
     * @param columns the columns to be set.
     */
    setRequestRepliesColumns: (
      state: ResourcePlanState,
      columns: PayloadAction<string[]>
    ) => {
      state.requestRepliesTableState.columns = columns.payload;
    },
    /**
     * Clears a specific property from the custom table filter for sent requests.
     *
     * @param state The current filter state.
     * @param action Contains the key and value to be cleared.
     * @returns Updated state with the specified filter value removed.
     */
    clearRequestRepliesCustomTableFilterProperty: (
      state: ResourcePlanState,
      action: { payload: { key: string; value: string } }
    ): ResourcePlanState => {
      const { key, value } = action.payload;
      return {
        ...state,
        requestRepliesTableState: {
          ...state.requestRepliesTableState,
          customTableFilters: {
            ...state.requestRepliesTableState.customTableFilters,
            [key]: state.requestRepliesTableState.customTableFilters[
              key
            ]?.filter((item: string) => item !== value),
          },
        },
      };
    },
    /**
     * Clears the filter state to the initial state.
     *
     */
    clearRequestRepliesFilter: (state: ResourcePlanState) => {
      return {
        ...state,
        openRequestTableState: {
          ...state.openRequestTableState,
        },
        sentRequestsTableState: {
          ...state.sentRequestsTableState,
        },
        requestRepliesTableState: {
          ...initialState.requestRepliesTableState,
          columns: state.requestRepliesTableState.columns, // preserve the columns
          quickFilter: state.requestRepliesTableState.quickFilter, // preserve the quick filter
        },
      };
    },

    /*Open Request*/
    /**
     * Sets the quick filter state to the new state.
     *
     * @param state       the request overview filter state with all values.
     * @param quickSearch the quick search string.
     */
    setOpenRequestsQuickFilter: (
      state: ResourcePlanState,
      quickSearch: PayloadAction<string>
    ) => {
      state.openRequestTableState.quickFilter = quickSearch.payload;
      state.openRequestTableState.isFilterApplied = checkIfFilterApplied(
        state.openRequestTableState
      );
    },
    /**
     * Sets the custom table filter state to the new state.
     *
     * @param state       the request overview filter state with all values.
     * @param customTableFilters the custom table filters object with all filters.
     */
    setOpenRequestsCustomTableFilter: (
      state: ResourcePlanState,
      customTableFilters: PayloadAction<ResourcePlanTableFilters>
    ) => {
      state.openRequestTableState.customTableFilters =
        customTableFilters.payload;
      state.openRequestTableState.isFilterApplied = checkIfFilterApplied(
        state.openRequestTableState
      );
    },
    /**
     * Sets the columns to the new state.
     *
     * @param state the request overview filter state with all values.
     * @param columns the columns to be set.
     */
    setOpenRequestsColumns: (
      state: ResourcePlanState,
      columns: PayloadAction<string[]>
    ) => {
      state.openRequestTableState.columns = columns.payload;
    },
    /**
     * Clears a specific property from the custom table filter for sent requests.
     *
     * @param state The current filter state.
     * @param action Contains the key and value to be cleared.
     * @returns Updated state with the specified filter value removed.
     */
    clearOpenRequestsCustomTableFilterProperty: (
      state: ResourcePlanState,
      action: { payload: { key: string; value: string } }
    ): ResourcePlanState => {
      const { key, value } = action.payload;
      return {
        ...state,
        openRequestTableState: {
          ...state.openRequestTableState,
          customTableFilters: {
            ...state.openRequestTableState.customTableFilters,
            [key]: state.openRequestTableState.customTableFilters[key]?.filter(
              (item: string) => item !== value
            ),
          },
        },
      };
    },
    /**
     * Clears the filter state to the initial state.
     *
     */
    clearOpenRequestsFilter: (state: ResourcePlanState) => {
      return {
        ...state,
        sentRequestsTableState: {
          ...state.sentRequestsTableState,
        },
        requestRepliesTableState: {
          ...state.requestRepliesTableState,
        },
        openRequestTableState: {
          ...initialState.openRequestTableState,
          columns: state.openRequestTableState.columns, // preserve the columns
          quickFilter: state.openRequestTableState.quickFilter, // preserve the quick filter
        },
      };
    },

    /*Sent Request*/
    /**
     * Sets the quick filter state to the new state.
     *
     * @param state       the request overview filter state with all values.
     * @param quickSearch the quick search string.
     */
    setSentRequestsQuickFilter: (
      state: ResourcePlanState,
      quickSearch: PayloadAction<string>
    ) => {
      state.sentRequestsTableState.quickFilter = quickSearch.payload;
      state.sentRequestsTableState.isFilterApplied = checkIfFilterApplied(
        state.sentRequestsTableState
      );
    },
    /**
     * Sets the custom table filter state to the new state.
     *
     * @param state       the request overview filter state with all values.
     * @param customTableFilters the custom table filters object with all filters.
     */
    setSentRequestsCustomTableFilter: (
      state: ResourcePlanState,
      customTableFilters: PayloadAction<ResourcePlanTableFilters>
    ) => {
      state.sentRequestsTableState.customTableFilters =
        customTableFilters.payload;
      state.sentRequestsTableState.isFilterApplied = checkIfFilterApplied(
        state.sentRequestsTableState
      );
    },
    /**
     * Sets the columns to the new state.
     *
     * @param state the request overview filter state with all values.
     * @param columns the columns to be set.
     */
    setSentRequestsColumns: (
      state: ResourcePlanState,
      columns: PayloadAction<string[]>
    ) => {
      state.sentRequestsTableState.columns = columns.payload;
    },
    /**
     * Clears a specific property from the custom table filter for sent requests.
     *
     * @param state The current filter state.
     * @param action Contains the key and value to be cleared.
     * @returns Updated state with the specified filter value removed.
     */
    clearSentRequestsCustomTableFilterProperty: (
      state: ResourcePlanState,
      action: { payload: { key: string; value: string } }
    ): ResourcePlanState => {
      const { key, value } = action.payload;
      return {
        ...state,
        sentRequestsTableState: {
          ...state.sentRequestsTableState,
          customTableFilters: {
            ...state.sentRequestsTableState.customTableFilters,
            [key]: state.sentRequestsTableState.customTableFilters[key]?.filter(
              (item: string) => item !== value
            ),
          },
        },
      };
    },
    /**
     * Clears the filter state to the initial state.
     *
     */
    clearSentRequestsFilter: (state: ResourcePlanState) => {
      return {
        ...state,
        openRequestTableState: {
          ...state.openRequestTableState,
        },
        requestRepliesTableState: {
          ...state.requestRepliesTableState,
        },
        sentRequestsTableState: {
          ...initialState.sentRequestsTableState,
          columns: state.sentRequestsTableState.columns, // preserve the columns
          quickFilter: state.sentRequestsTableState.quickFilter, // preserve the quick filter
        },
      };
    },
    clearFilters: (state: ResourcePlanState) => {
      return {
        ...initialState,
        projectId: state.projectId, // preserve the project id
        requestRepliesTableState: {
          ...initialState.requestRepliesTableState,
        },
        sentRequestsTableState: {
          ...initialState.sentRequestsTableState,
        },
        openRequestTableState: {
          ...initialState.openRequestTableState,
        },
      };
    },
  },
});

export const {
  setProjectId,
  //request & replies
  setRequestRepliesColumns,
  clearRequestRepliesFilter,
  setRequestRepliesQuickFilter,
  setRequestRepliesCustomTableFilter,
  clearRequestRepliesCustomTableFilterProperty,
  //open requests
  setOpenRequestsColumns,
  clearOpenRequestsFilter,
  setOpenRequestsQuickFilter,
  setOpenRequestsCustomTableFilter,
  clearOpenRequestsCustomTableFilterProperty,
  //sent requests
  setSentRequestsColumns,
  clearSentRequestsFilter,
  setSentRequestsQuickFilter,
  setSentRequestsCustomTableFilter,
  clearSentRequestsCustomTableFilterProperty,
  //general
  clearFilters,
} = resourcePlanSlice.actions;

export default resourcePlanSlice;
