// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.region-assignment-management .ant-select {
  width: 250px;
}
`, "",{"version":3,"sources":["webpack://./src/features/region_assignment/components/region_assignment_management/region_assignment_management.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;AAAJ","sourcesContent":[".region-assignment-management {\n  .ant-select {\n    width: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
