import React, { ReactNode } from "react";

import "./step_progress_modal.less";
import {
  CheckOutlined,
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { RootState, useAppSelector } from "@src/setupStore";
import { Button, ButtonProps, Modal, StepProps, Steps } from "antd";

interface StepProgressModalProps {
  modalVisible: boolean;
  children: ReactNode[];
  stepTitles: string[];
  currentStepIndex: number;
  title: string;
  onCancel: () => void;
  onBack: () => void;
  onNext: () => void;
  onSave: () => void;
}

const StepProgressModal: React.FC<StepProgressModalProps> = ({
  modalVisible,
  children,
  stepTitles,
  currentStepIndex,
  title,
  onCancel,
  onBack,
  onNext,
  onSave,
}) => {
  const isLastStep: boolean = currentStepIndex === children.length - 1;

  const { isSaveDisabled } = useAppSelector(
    (state: RootState) => state.splitRoleSlice
  );

  const okButtonProperties: ButtonProps = isLastStep
    ? {
        icon: <CheckOutlined />,
        disabled: isSaveDisabled,
      }
    : { icon: <RightOutlined />, iconPosition: "end" };

  /**
   * Generates the step items by the given stepTitles.
   */
  function getStepItems(): StepProps[] {
    return stepTitles.map((title: string, index: number) => ({
      title: title,
      key: index,
    }));
  }

  return (
    <Modal
      className="step-progress-modal"
      bodyStyle={{ height: 450 }}
      width="1050px"
      centered
      title={title}
      closable={false}
      open={modalVisible}
      okText={isLastStep ? "Save" : "Next"}
      okButtonProps={{ ...okButtonProperties, size: "large" }}
      onOk={isLastStep ? onSave : onNext}
      cancelButtonProps={{ icon: <CloseOutlined />, size: "large" }}
      onCancel={onCancel}
      footer={(_, { OkBtn, CancelBtn }) => (
        <div className="step-progress-modal__footer-buttons">
          <Button
            disabled={currentStepIndex === 0}
            type="primary"
            size="large"
            onClick={onBack}
            icon={<LeftOutlined />}
          >
            Back
          </Button>
          <CancelBtn />
          <OkBtn />
        </div>
      )}
    >
      <Steps
        current={currentStepIndex}
        items={getStepItems()}
        size="small"
        className="step-progress-modal__steps-component"
      />
      {children[currentStepIndex]}
    </Modal>
  );
};

export default StepProgressModal;
