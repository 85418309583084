import { COLORS } from "@src/constants";
import { ChartData, ChartOptions } from "chart.js";

/**
 * Get chart options for a bar chart.
 * @returns {ChartOptions<"bar">} The chart options.
 */
export function getChartOptions(): ChartOptions<"bar"> {
  return {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        position: "right" as const,
        ticks: {
          font: {
            family: "RWESansWeb-Medium",
            size: 14,
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "RWESansWeb-Medium",
            size: 10,
          },
        },
      },
    },
  };
}

/**
 * Get chart data for a bar chart.
 * @param {number} requested - The number of requests with the status requested.
 * @param {number} replied - The number of replies (all requests without requested).
 * @returns {ChartData<"bar">} The chart data.
 */
export function getChartData(
  requested: number,
  replied: number
): ChartData<"bar"> {
  return {
    labels: ["Request", "Replied"],
    datasets: [
      {
        data: [requested, replied],
        backgroundColor: [COLORS.BRAND_60, COLORS.GRADIENT_GREEN_30],
        yAxisID: "y",
        barPercentage: 0.9,
      },
    ],
  };
}
