import { message } from "antd";
import { createFetchBaseQuery } from "./baseQuery";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Employee } from "@src/types";
import { response } from "msw";

const getEndpoints = (builder: EndpointBuilder<any, any, any>) => {
  return {
    /**
     * Get loggedIn user
     */
    getLoggedInUser: builder.query<Employee, void>({
      query: () => "",
      transformErrorResponse: async (): Promise<void> => {
        message.error("Unable to load logged in user", 5);
      },
    }),

    /**
     * Get photo of loggedIn user
     */
    getPhotoLoggedInUser: builder.query<string, void>({
      query(args) {
        return {
          url: `https://graph.microsoft.com/v1.0/me/photo/$value`,
          responseHandler: async (response) => {
            const url = window.URL || window.webkitURL;
            return url.createObjectURL(await response.blob());
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
      },
    }),
  };
};

export const usersSlice = createApi({
  reducerPath: "userSlice",
  baseQuery: createFetchBaseQuery("/user"),
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),
  }),
});

export const {
  /*GET endpoints */
  useLazyGetLoggedInUserQuery,
  useGetPhotoLoggedInUserQuery,
} = usersSlice;
