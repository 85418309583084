import React, { useEffect, useState } from "react";

import { navigate } from "@reach/router";
import { ROLES } from "@src/constants";
import { Events } from "@src/events";
import { clearAll } from "@src/services/requestOverviewSlice";
import { useGetEmployeeImpersonationsQuery } from "@src/services/slices/employeesSlice";
import { Employee } from "@src/types";
import { Select } from "antd";
import PubSub from "pubsub-js";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

const cookies = new Cookies();

interface ImpersonateUserProps {
  setActiveRoute: (activeRoute: string) => void;
}

const ImpersionateUser: React.FC<ImpersonateUserProps> = ({
  setActiveRoute,
}) => {
  const [empList, setEmpList] = useState<Employee[]>(null);
  const { data: employeeData, isFetching } =
    useGetEmployeeImpersonationsQuery();

  const useAppDispatch = useDispatch();

  useEffect(() => {
    if (employeeData) {
      setEmpList(
        employeeData.filter(
          (employee) =>
            !(
              employee.applicationRoles.length === 1 &&
              employee.applicationRoles[0] === ROLES.TEAM_MEMBER
            )
        )
      );
    }
  }, [employeeData]);

  const onSelectedMemberChange = (employeeId: number) => {
    useAppDispatch(clearAll()); // clear team lead filter after impersonating to another user
    PubSub.publish(Events.TL_REQUEST_STATUS_UPDATED);
    cookies.set("ImpersonationUser", employeeId, { path: "/" });
    cookies.remove("loggedInuser", { path: "/" });
    setActiveRoute(null);
    navigate(`/`);
  };

  return (
    empList?.length > 0 &&
    !isFetching && (
      <Select
        showSearch
        optionFilterProp="children"
        data-testid="teamMember"
        placeholder="Select login to impersonate"
        onChange={onSelectedMemberChange}
      >
        {empList.map((member) => {
          return (
            <Select.Option
              data-testid={member.employeeId}
              key={member.employeeId}
              value={member.employeeId}
            >
              {member.fullName} |{" "}
              {member.applicationRoles.length > 1 &&
              member.applicationRoles[0] === ROLES.TEAM_MEMBER
                ? member.applicationRoles[1]
                : member.applicationRoles[0]}
            </Select.Option>
          );
        })}
      </Select>
    )
  );
};

export default ImpersionateUser;
