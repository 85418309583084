// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marker {
  background: #78e4d5;
  width: 28px;
  padding: 0 6px;
  text-align: center;
  font-family: "RWESansWeb-Medium";
  font-size: 11px;
  color: #3b3d42;
  box-shadow: 0 4px 4px 0 #00000025;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-60%, -60%);
  border-radius: 64px;
}
`, "",{"version":3,"sources":["webpack://./src/features/dashboard/components/google_maps_marker.tsx/google_maps_marker.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;EACA,gCAAA;EACA,eAAA;EACA,cAAA;EACA,iCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gCAAA;EACA,mBAAA;AADF","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.marker {\n  background: @energy-green-70;\n  width: 28px;\n  padding: 0 6px;\n  text-align: center;\n  font-family: \"RWESansWeb-Medium\";\n  font-size: 11px;\n  color: @font-00;\n  box-shadow: 0 4px 4px 0 #00000025;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transform: translate(-60%, -60%);\n  border-radius: 64px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
