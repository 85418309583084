import React from "react";

import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import "./tree_selection_with_tooltip.less";

interface TreeSelectionWithTooltipProps {
  title: string;
  tooltip: string;
}

const TreeSelectionWithTooltip: React.FC<TreeSelectionWithTooltipProps> = ({
  title,
  tooltip,
}) => {
  return (
    <div key={title} className="tree-selection-with-tooltip">
      <label>{title}</label>
      <Tooltip title={tooltip}>
        <InfoCircleOutlined data-testid="info-icon" />
      </Tooltip>
    </div>
  );
};

export default TreeSelectionWithTooltip;
