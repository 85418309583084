// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-label .label-disabled {
  color: #8c8c8c;
}
.info-label .label-disabled .anticon-info-circle > svg {
  color: #8c8c8c;
}
.info-label__popover {
  width: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/features/role_assignment_old/components/info-label/info_label.less"],"names":[],"mappings":"AAAA;EAKI,cAAA;AAHJ;AAFA;EAGM,cAAA;AAEN;AAGE;EACE,YAAA;AADJ","sourcesContent":[".info-label {\n  .label-disabled {\n    .anticon-info-circle > svg {\n      color: #8c8c8c;\n    }\n    color: #8c8c8c;\n  }\n\n  &__popover {\n    width: 400px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
