// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  height: 20px;
  margin: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/app_layout/components/logo/logo.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;AACF","sourcesContent":[".logo {\n  height: 20px;\n  margin: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
