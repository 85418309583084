import { camelize } from "../../../utils/csv_parser_helper";

/**
 * maps names of the headers in the csv file to names for the backend request
 * @param {string} header
 * @returns {string} mapped header
 */
export const mapResourcePlanHeader = (header) => {
  if (header === "No.") {
    return "rowNumber";
  }
  if (header.includes("-")) {
    let day = "01";
    let month = getMonthAsNumber(header.substring(0, 3));
    let year = "20" + header.substring(4);
    let date = day + "/" + month + "/" + year;
    return date;
  }
  return camelize(header);
};

/**
 * converts month into number
 * @param {string} month
 * @returns {number} of the respective month or -1 if param is not a month
 */
export const getMonthAsNumber = (month) => {
  switch (month) {
    case "Jan":
      return 1;
    case "Feb":
      return 2;
    case "Mar":
      return 3;
    case "Apr":
      return 4;
    case "May":
      return 5;
    case "Jun":
      return 6;
    case "Jul":
      return 7;
    case "Aug":
      return 8;
    case "Sep":
      return 9;
    case "Oct":
      return 10;
    case "Nov":
      return 11;
    case "Dec":
      return 12;
    default:
      return -1;
  }
};

/**
 * reads the data in the cells of the csv file
 * index of the array is the index of the line
 * @param {String[]} data
 * @returns {String[]} list of the values
 */
export const readResourcePlanHeader = (csvData) => {
  const headers = csvData[0].map(mapResourcePlanHeader);
  const resultData = [];
  for (let i = 1; i < csvData.length; i++) {
    const currentRow = csvData[i];
    if (headers && currentRow.length === headers.length) {
      readRow(headers, currentRow, resultData);
    }
  }
  return resultData;
};

const readRow = (headers, currentRow, resultData) => {
  const rowData = {};
  rowData.allocations = [];
  let isRowEmpty = true;
  for (let j = 0; j < headers.length; j++) {
    let currentCell = currentRow[j].trim();
    const isAllocationCell = headers[j].includes("/");

    // check if non allocation cell has value
    if (
      headers[j] !== "rowNumber" &&
      !isAllocationCell &&
      currentCell.length > 0
    ) {
      isRowEmpty = false;
    }
    addCellValueToData(headers[j], isAllocationCell, currentCell, rowData);
  }

  if (!isRowEmpty) {
    resultData.push(rowData);
  }
};

const addCellValueToData = (header, isAllocationCell, currentCell, rowData) => {
  if (isAllocationCell && currentCell.length > 0) {
    const allocation = {
      date: header,
      allocation: currentCell,
    };
    rowData.allocations.push(allocation);
  } else {
    rowData[header] = currentCell;
  }
};

/**
 * maps the requestDTOs coming from backend to fit into the open requests table
 * @param {Object[]} requestDTOs
 * @returns {Object[]}
 */
export const mapRequestsForTable = (requestDTOs) => {
  return requestDTOs.map((requestDTO, index) => {
    return {
      childId: null,
      genericRoleId: requestDTO.genericRoleId,
      genericRoleName: requestDTO.standardRole,
      jointVenture: false,
      key: index,
      outdated: false,
      projectIdentifier: requestDTO.projectIdentifier,
      projectRoleRequestId: index,
      roleSpecificationName: requestDTO.specification,
      scope: requestDTO.scope,
      specificRole: requestDTO.specificRole,
      status: "Draft",
      yearlyAllocations: mapYearlyAllocations(requestDTO.allocations),
    };
  });
};

/**
 * helper method for mapping the requests coming from backend to fit into the open requests table
 * @param {Object[]} allocationDTOs
 * @returns {Object[]}
 */
export const mapYearlyAllocations = (allocationDTOs) => {
  let result = {};
  allocationDTOs.forEach((allocationDTO) => {
    if (allocationDTO) {
      const dateArray = allocationDTO.date.split("/");
      const month = dateArray[1];
      const year = dateArray[2];
      if (!result[year]) {
        result[year] = {};
      }

      result[year][month] = isNaN(allocationDTO.allocation)
        ? allocationDTO.allocation
        : Number(allocationDTO.allocation);
    }
  });
  return result;
};
