// Function to check if two route segments match
import { ROUTES } from "@src/constants";
function segmentsMatch(allowedSegment: string, inputSegment: string) {
  return allowedSegment === inputSegment;
}

// Function to check if the given route matches the allowed route pattern
function isRouteMatch(allowedRoute: string, inputRoute: string) {
  const allowedSegments: string[] = allowedRoute.split("/");
  const inputSegments: string[] = inputRoute.split("/");

  // Remove the last segment if a number is present
  if (inputSegments[inputSegments.length - 1].match(/^\d+$/)) {
    inputSegments.pop();
  }

  // Check if the number of segments match
  if (allowedSegments.length !== inputSegments.length) {
    return false;
  }

  return allowedSegments.every((segment, index) =>
    segmentsMatch(segment, inputSegments[index])
  );
}

// Function to check if a given route is valid
export function isValidRoute(route: string) {
  // Remove trailing slash
  const routeWithoutTrailingSlash = route.replace(/\/$/, "");

  // Normalizing the ROUTES object into an array of regular expressions
  const flatRoutes: string[] = Object.values(ROUTES).flatMap(Object.values);
  return flatRoutes.some((allowedRoute) =>
    isRouteMatch(allowedRoute, routeWithoutTrailingSlash)
  );
}
