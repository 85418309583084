// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request-status-statistics-chart__doughnut-chart {
  width: 60%;
  min-width: 200px;
}
.request-status-statistics-chart .ant-card-body {
  display: flex;
}
.request-status-statistics-chart .legend {
  width: 40%;
  min-width: 200px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.request-status-statistics-chart .legend__item {
  min-width: 200px;
}
.request-status-statistics-chart .legend__item_total {
  border-top: 1px solid #d2dae4;
}
.request-status-statistics-chart .legend__item_total:hover {
  cursor: default;
}
.request-status-statistics-chart .legend__item_total .legend__item__circle {
  display: none;
}
.request-status-statistics-chart .legend__item_total .legend__item__count {
  font-family: "RWESansWeb-Medium", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/features/dashboard/components/request_status_statistics_chart/request_status_statistics_chart.less"],"names":[],"mappings":"AAGE;EACE,UAAA;EACA,gBAAA;AAFJ;AADA;EAOI,aAAA;AAHJ;AAJA;EAWI,UAAA;EACA,gBAAA;EACA,YAAA;EAEA,aAAA;EACA,sBAAA;AALJ;AAOI;EACE,gBAAA;AALN;AAOM;EACE,6BAAA;AALR;AAMQ;EACE,eAAA;AAJV;AAQU;EACE,aAAA;AANZ;AASU;EACE,4CAAA;AAPZ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.request-status-statistics-chart {\n  &__doughnut-chart {\n    width: 60%;\n    min-width: 200px;\n  }\n\n  .ant-card-body {\n    display: flex;\n  }\n\n  .legend {\n    width: 40%;\n    min-width: 200px;\n    margin: auto;\n\n    display: flex;\n    flex-direction: column;\n\n    &__item {\n      min-width: 200px;\n\n      &_total {\n        border-top: 1px solid @brand-20;\n        &:hover {\n          cursor: default;\n        }\n\n        .legend__item {\n          &__circle {\n            display: none;\n          }\n\n          &__count {\n            font-family: \"RWESansWeb-Medium\", sans-serif;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
