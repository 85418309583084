// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.split-role-transfer-tree-node__title {
  color: #3b3d42;
  font-family: "RWESansWeb-Medium", sans-serif;
  font-size: 12px;
}
.split-role-transfer-tree-node__text {
  color: black;
  font-family: "RWESansWeb-Regular", sans-serif;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/features/role_split/components/split_role_transfer_tree_node/split_role_transfer_tree_node.less"],"names":[],"mappings":"AAGE;EACE,cAAA;EACA,4CAAA;EACA,eAAA;AAFJ;AAKE;EACE,YAAA;EACA,6CAAA;EACA,eAAA;AAHJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.split-role-transfer-tree-node {\n  &__title {\n    color: @innogy-text-color;\n    font-family: \"RWESansWeb-Medium\", sans-serif;\n    font-size: 12px;\n  }\n\n  &__text {\n    color: black;\n    font-family: \"RWESansWeb-Regular\", sans-serif;\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
