// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}
.expandIcon {
  margin-top: 3px;
}
.expandFeature {
  font-size: 16px;
}
.team-capacity .ant-table-content {
  overflow-y: scroll;
}
.fixedWidth {
  min-width: 70px !important;
}
.filterResetInfo {
  color: red;
  margin: 10px;
}
.year-column-1 {
  width: 70px;
}
.year-column-2 {
  width: 140px;
}
.year-column-3 {
  width: 210px;
}
.year-column-4 {
  width: 280px;
}
.year-column-5 {
  width: 350px;
}
.year-column-6 {
  width: 420px;
}
.year-column-7 {
  width: 490px;
}
.year-column-8 {
  width: 560px;
}
.year-column-9 {
  width: 630px;
}
.year-column-10 {
  width: 700px;
}
.year-column-11 {
  width: 770px;
}
.year-column-12 {
  width: 840px;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_requests/components/team_member_capacities/team_member_capacities.less"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AAEA;EACE,eAAA;AAAF;AAGA;EACE,eAAA;AADF;AAIA;EACE,kBAAA;AAFF;AAKA;EACE,0BAAA;AAHF;AAMA;EACE,UAAA;EACA,YAAA;AAJF;AASK;EACC,WAAA;AAPN;AAMK;EACC,YAAA;AAJN;AAGK;EACC,YAAA;AADN;AAAK;EACC,YAAA;AAEN;AAHK;EACC,YAAA;AAKN;AANK;EACC,YAAA;AAQN;AATK;EACC,YAAA;AAWN;AAZK;EACC,YAAA;AAcN;AAfK;EACC,YAAA;AAiBN;AAlBK;EACC,YAAA;AAoBN;AArBK;EACC,YAAA;AAuBN;AAxBK;EACC,YAAA;AA0BN","sourcesContent":[".ant-collapse-content > .ant-collapse-content-box {\n  padding: 0px !important;\n}\n\n.expandIcon {\n  margin-top: 3px;\n}\n\n.expandFeature {\n  font-size: 16px;\n}\n\n.team-capacity .ant-table-content {\n  overflow-y: scroll;\n}\n\n.fixedWidth {\n  min-width: 70px !important;\n}\n\n.filterResetInfo {\n  color: red;\n  margin: 10px;\n}\n\n.year-column {\n  each(range(70px, 840px, 70), {\n    &-@{index} {\n      width: @value;\n    }\n  });\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
