import { DeleteOutlined, FileDoneOutlined } from "@ant-design/icons";
import { ProgressLoadingMessage } from "@src/types";
import { Button } from "antd";
import { useCSVReader, formatFileSize } from "react-papaparse";

import "./csv_parser.less";
import { Headline } from "../headline";

export interface ICSVParser {
  setIsFileUploading?: (isFileUploading: boolean) => void;
  isFileUploading?: boolean;
  setLoadingMessage?: (
    loadingMessage: (loadingMessage: ProgressLoadingMessage) => {
      currentRowStatus: number;
      text: string;
      totalRows: number;
      type: string;
      percent: number;
    }
  ) => void;
  sendData: (payload: any) => void;
  onCancel?: () => void;
  title: string;
  textArray: string[];
  readData: (data: any) => any;
}

const CSVParser = ({
  setIsFileUploading,
  isFileUploading,
  setLoadingMessage,
  sendData,
  onCancel,
  title,
  textArray,
  readData,
}: ICSVParser) => {
  const { CSVReader } = useCSVReader();

  const onUploadAccepted = (results) => {
    setLoadingMessage((loadingMessage: ProgressLoadingMessage) => ({
      ...loadingMessage,
      text: "Parsing data ...",
    }));
    setIsFileUploading(true);
    sendData(readData(results.data));
  };

  return (
    <>
      <Headline title={title} />

      <div>
        <CSVReader
          onUploadAccepted={onUploadAccepted}
          onDragOver={(event) => {
            event.preventDefault();
          }}
          onDragLeave={(event) => {
            event.preventDefault();
          }}
          noClick={isFileUploading}
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
          }) => (
            <>
              <div {...getRootProps()} className="input-field">
                {acceptedFile ? (
                  <>
                    <div className="file">
                      <div className="info">
                        <span className="size">
                          {formatFileSize(acceptedFile.size)}
                        </span>
                        <span className="name">{acceptedFile.name}</span>
                      </div>
                      <div className="progressbar">
                        <ProgressBar />
                      </div>
                      <div {...getRemoveFileProps()} className="remove">
                        <Button
                          {...getRemoveFileProps()}
                          type="primary"
                          danger
                          disabled={isFileUploading}
                          shape="circle"
                          icon={<DeleteOutlined />}
                          size="20px"
                          onClick={() => {
                            onCancel();
                          }}
                          className="csv-cancel-btn"
                          id="csv-cancel-btn"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <FileDoneOutlined className="document-icon" />
                    {textArray.map((text) => {
                      return <div key={text}>{text}</div>;
                    })}
                  </>
                )}
              </div>
            </>
          )}
        </CSVReader>
      </div>
    </>
  );
};

export default CSVParser;
