// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sent-requests {
  margin: 16px 0;
}
.standard-role-tooltip {
  line-height: 10px;
}
.standard-role-tooltip .requestId {
  color: darkgrey;
  display: flex;
  font-size: 14px;
  font-family: "RWESansWeb-Regular", sans-serif !important;
  font-weight: normal !important;
}
.button-section {
  display: flex;
  justify-content: space-between;
}
.button-section .edit-status-msg {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.button-section Button {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/features/resource_plan/components/sent_requests/sent_requests.less"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAEA;EACE,iBAAA;AAAF;AADA;EAII,eAAA;EACA,aAAA;EACA,eAAA;EACA,wDAAA;EACA,8BAAA;AAAJ;AAIA;EACE,aAAA;EACA,8BAAA;AAFF;AAAA;EAKI,aAAA;EACA,yBAAA;EACA,qBAAA;AAFJ;AALA;EAWI,iBAAA;AAHJ","sourcesContent":[".sent-requests {\n  margin: 16px 0;\n}\n\n.standard-role-tooltip {\n  line-height: 10px;\n\n  .requestId {\n    color: darkgrey;\n    display: flex;\n    font-size: 14px;\n    font-family: \"RWESansWeb-Regular\", sans-serif !important;\n    font-weight: normal !important;\n  }\n}\n\n.button-section {\n  display: flex;\n  justify-content: space-between;\n\n  .edit-status-msg {\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-end;\n  }\n\n  Button {\n    margin-left: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
