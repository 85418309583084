import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  ReactNode,
} from "react";

import "./team_member_capacities.less";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { months } from "@src/constants";
import { useGetTeamCapacitiesQuery } from "@src/services/slices/teamLeadsApi";
import { AntdTableFilter, FreeCapacity, TeamMemberCapacity } from "@src/types";
import { PanelProps } from "@src/types/antd_types";
import { handleParallelScrollEvent } from "@src/utils/handle_scroll_utils";
import {
  CalculateTableHeader,
  CreateStartMonthAndEndMonth,
} from "@src/utils/helper";
import { Collapse, Table } from "antd";
import { ColumnsType } from "antd/es/table";

const { Panel } = Collapse;

interface TeamMemberCapacitiesProps {
  roleRequestId: string;
  genericRole: string;
  childRequest: any;
}

export interface TeamMemberCapacitiesRef {
  getProposedMemberCapacity: (id: string) => TeamMemberCapacity | undefined;
}

const TeamMemberCapacities = forwardRef<
  TeamMemberCapacitiesRef,
  TeamMemberCapacitiesProps
>(({ roleRequestId, genericRole, childRequest }, ref) => {
  const [yearColumns, setYearColumns] = useState<
    ColumnsType<TeamMemberCapacity>
  >([]);
  const [genericRoles, setGenericRoles] = useState<Set<string>>(new Set());
  const { data: teamMembers } = useGetTeamCapacitiesQuery({
    roleRequestId: parseInt(roleRequestId),
  });

  const columns: ColumnsType<TeamMemberCapacity> = [
    {
      title: "Employee",
      key: "employeeName",
      dataIndex: "employeeName",
      width: 200,
      sorter: (
        teamMemberCapacityA: TeamMemberCapacity,
        teamMemberCapacityB: TeamMemberCapacity
      ) => {
        return (
          teamMemberCapacityA.lastName.length -
          teamMemberCapacityB.lastName.length
        );
      },
      fixed: "left",
    },
    {
      title: "Standard Role",
      key: "genericRoleName",
      dataIndex: "genericRoleName",
      fixed: "left",
      filters: filter(),
      onFilter: (standardRoleName: string, record: TeamMemberCapacity) => {
        return record.genericRoleName?.includes(standardRoleName);
      },
      width: 200,
      defaultFilteredValue: [genericRole],
    },
    ...yearColumns,
  ];

  useEffect(() => {
    if (!teamMembers || teamMembers.length === 0) return;

    const genericRolesSet = new Set<string>();
    teamMembers?.forEach((teamMemberCapacity: TeamMemberCapacity) =>
      genericRolesSet.add(teamMemberCapacity.genericRoleName)
    );
    setGenericRoles(genericRolesSet);

    const yearMonthColumns: ColumnsType<TeamMemberCapacity> =
      prepareYearMonthColumns(teamMembers[0].freeCapacitiesDTOS);

    setYearColumns(yearMonthColumns);
  }, [teamMembers]);

  function prepareYearMonthColumns(
    allocationDetails: FreeCapacity[] = []
  ): ColumnsType<TeamMemberCapacity> {
    const yearColumns = {};

    if (allocationDetails.length > 0) {
      const lastYearIndex: number = allocationDetails.length;
      const allocationDetailsLast: FreeCapacity =
        allocationDetails[lastYearIndex - 1];

      const dates = CalculateTableHeader(childRequest, allocationDetailsLast);

      for (let y = dates.startYear; y <= dates.endYear; y++) {
        const monthRanges = CreateStartMonthAndEndMonth(dates, y);

        for (
          let m = monthRanges.currentStartMonth;
          m <= monthRanges.currentEndMonth;
          m++
        ) {
          if (!yearColumns[y]) {
            yearColumns[y] = { children: [] };
          }

          yearColumns[y].children.push({
            title: months[m - 1],
            dataIndex: "freeCapacitiesDTOS",
            key: `${y}_${months[m - 1]}`,
            className: "fixedWidth",
            render: (freeCapacities: FreeCapacity[]) => {
              const currentMonth: FreeCapacity = freeCapacities.find(
                (freeCapacity: FreeCapacity) =>
                  freeCapacity.year === y && freeCapacity.month === m
              );

              if (!currentMonth?.capacity) return;

              return currentMonth.capacity + "%";
            },
          });
        }
      }
    }

    return Object.keys(yearColumns).map((year: string) => {
      return {
        title: year,
        className: "year-column-" + yearColumns[year].children.length,
        children: yearColumns[year].children,
      };
    });
  }

  function filter(): AntdTableFilter[] {
    const filters: AntdTableFilter[] = [];

    genericRoles.forEach((genericRoleName: string) => {
      genericRoleName &&
        filters.push({ text: genericRoleName, value: genericRoleName });
    });

    return filters;
  }

  useImperativeHandle(ref, () => ({
    getProposedMemberCapacity(id: string) {
      return teamMembers.find(
        (teamMemberCapacity: TeamMemberCapacity) =>
          teamMemberCapacity.employeeId === parseInt(id)
      );
    },
  }));

  function expandIcon(panelProps: PanelProps): ReactNode {
    if (panelProps.isActive) {
      handleParallelScrollEvent(
        ".requestedTime .ant-table-body",
        ".team-capacity .ant-table-body"
      );
      return (
        <div className={"expandIcon"}>
          <MinusSquareOutlined
            className="expandFeature"
            data-testid="minusIcon"
          />
        </div>
      );
    }

    return (
      <div className={"expandIcon"}>
        <PlusSquareOutlined className="expandFeature" data-testid="plusIcon" />
      </div>
    );
  }

  return (
    <Collapse
      accordion
      expandIcon={expandIcon}
      data-testid="teamAvailabilitySection"
    >
      <Panel header="Team availability" key="1">
        <Table
          className="team-capacity"
          bordered
          dataSource={teamMembers}
          columns={columns}
          pagination={false}
          size="small"
          scroll={{ x: "max-content", y: "300px" }}
        />
      </Panel>
    </Collapse>
  );
});

export default TeamMemberCapacities;
