import React from "react";

import "./filters_drawer.less";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";

interface FiltersDrawerProps {
  isDrawerOpen?: boolean;
  setIsDrawerOpen?: (isDrawerOpen: boolean) => void;
  drawerTitle?: string;
  filters: React.ReactNode;
  loading?: boolean;
}

const FiltersDrawer: React.FC<FiltersDrawerProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  drawerTitle = "Filter",
  filters,
}) => {
  return (
    <Drawer
      className="filter-drawer"
      title={drawerTitle}
      placement={"right"}
      width={344}
      closable={false}
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      extra={
        <Button
          icon={<CloseOutlined />}
          type="text"
          onClick={() => setIsDrawerOpen(false)}
          data-testid="close-filter-drawer"
        />
      }
    >
      <div className="filter-group">{filters}</div>
    </Drawer>
  );
};

export default FiltersDrawer;
