import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "./propose_workload.less";
import _ from "lodash";
import { onCellValueChanged } from "../../../../utils/update_monthly_allocation_utils";
import {
  gridOptions,
  frameworkComponents,
  childrenGridOption,
  leftPanelSettings,
} from "../../utils/propose_workload_table_utils";
import { message } from "antd";

const ProposeWorkload = ({
  allocationDetailsData,
  selectedMemberCapacity,
  setValidateSuggestWorkload,
  setIsActionButtonDisabled,
  variant,
}) => {
  const [cols, setCols] = useState([]);
  const [data, setData] = useState([]);
  const [areOnlyNullValuesModalShown, setAreOnlyNullValuesModalShown] =
    useState(false);
  const gridApi = useRef(null);
  const allocationsKey = "allocations";
  const titleKey = "title";
  const [yearlyAllocations, setYearlyAllocations] = useState({});

  useEffect(() => {
    const columnSettings = [leftPanelSettings];
    const proposalOfTeamLead = [];

    createYearlyAllocations(allocationDetailsData.originalAllocations);

    allocationDetailsData.originalAllocations.forEach((allocation) => {
      const momentDate = moment().set("month", allocation.month - 1);
      const monthName = moment(momentDate).format("MMM");
      const yearMonthKey = `${allocation.year}_${allocation.month}`;

      let yearAllocations = columnSettings.find(
        (c) => c.headerName === allocation.year
      );

      if (!yearAllocations) {
        yearAllocations = {
          headerName: allocation.year,
          field: allocation.year,
          children: [],
        };
        columnSettings.push(yearAllocations);
      }

      yearAllocations.children.push(
        childrenGridOption(
          allocation,
          monthName,
          yearMonthKey,
          allocationsKey,
          yearlyAllocations
        )
      );

      generateProposalOfTeamleadRow(
        proposalOfTeamLead,
        yearMonthKey,
        allocation
      );
    });

    setCols(columnSettings);

    const requestedAllocation =
      generateRequestedAllocationRow(proposalOfTeamLead);

    let tableData = [];

    if (selectedMemberCapacity) {
      selectedMemberCapacity.freeCapacitiesDTOS =
        selectedMemberCapacity.freeCapacitiesDTOS.filter((fcdto) => {
          const isCurrentYearOrFutureYear =
            fcdto.year >= new Date().getFullYear();
          const isCurrentMonthOrFutureMonth =
            fcdto.month >= new Date().getMonth() + 1;

          return (
            (isCurrentYearOrFutureYear && isCurrentMonthOrFutureMonth) ||
            fcdto.year > new Date().getFullYear()
          );
        });

      const teamMemberCapacity = addTeamMemberCapacity();
      tableData = [
        ...requestedAllocation,
        ...teamMemberCapacity,
        ...proposalOfTeamLead,
      ];
    } else {
      tableData = [...requestedAllocation, ...proposalOfTeamLead];
    }

    setData(tableData);
  }, [allocationDetailsData]);

  const generateProposalOfTeamleadRow = (
    proposalOfTeamLead,
    yearMonthKey,
    allocation
  ) => {
    if (proposalOfTeamLead.length === 0) {
      proposalOfTeamLead.push({
        [titleKey]: "your Proposal: ",
        [allocationsKey]: { [yearMonthKey]: allocation.requiredPercentage },
      });
    } else {
      proposalOfTeamLead[0][allocationsKey][yearMonthKey] =
        allocation.requiredPercentage;
    }
  };

  const generateRequestedAllocationRow = (proposalOfTeamLead) => {
    const requestedAllocation = _.cloneDeep(proposalOfTeamLead);
    if (requestedAllocation.length > 0) {
      requestedAllocation[0][titleKey] = "Requested: ";
    }
    return requestedAllocation;
  };

  const onGridReady = useCallback((params) => {
    gridApi.current = params.api;
    gridApi.current.sizeColumnsToFit();
  }, []);

  const addTeamMemberCapacity = () => {
    const deffinedCapa = [];
    for (const capa of selectedMemberCapacity.freeCapacitiesDTOS) {
      const yearMonthKey = capa.year + "_" + capa.month;
      if (deffinedCapa.length === 0) {
        deffinedCapa.push({
          [titleKey]: "Availability of: ",
          teammember: selectedMemberCapacity.employeeName,
          [allocationsKey]: {
            [yearMonthKey]: capa.capacity,
          },
        });
      } else {
        deffinedCapa[0][allocationsKey][yearMonthKey] = capa.capacity;
      }
    }

    return deffinedCapa;
  };

  const createYearlyAllocations = (allocations) => {
    const yearlyAllocation = {};
    for (const allocation of allocations) {
      if (yearlyAllocation[allocation.year]) {
        yearlyAllocation[allocation.year][allocation.month] =
          allocation.requiredPercentage;
      } else {
        yearlyAllocation[allocation.year] = {};
        yearlyAllocation[allocation.year][allocation.month] =
          allocation.requiredPercentage;
      }
    }

    setYearlyAllocations(yearlyAllocation);
  };

  const initData = (params) => {
    params.node.data.yearlyAllocations = _.cloneDeep(yearlyAllocations);

    if (!params.node.initialData) {
      params.node.suggestWorkload = true;
      params.node.initialData = _.cloneDeep(params.node.data);
      params.node.initialData.yearlyAllocations =
        _.cloneDeep(yearlyAllocations);
      params.data.yearlyAllocations = _.cloneDeep(yearlyAllocations);
      params.node.diff = {};
      params.node.error = new Set();
    }
  };

  const onChange = (params) => {
    initData(params);
    onCellValueChanged(params);

    const updatedNodes = gridApi.current
      .getRenderedNodes()
      .find((node) => node.data.title === "your Proposal: ");

    if (!updatedNodes) return;

    const { diff } = updatedNodes;
    const { error, data } = params.node;
    const { allocations } = data;

    const hasDiff = Object.values(diff).length !== 0;
    const noErrors = error.size === 0;

    if (hasDiff && noErrors) {
      const hasOnlyZeroValues = !Object.values(allocations).some(
        (allocation) => parseInt(allocation) !== 0
      );

      setValidateSuggestWorkload({
        hasSuggested: true,
        payload: Object.values(diff),
        allAllocations: allocations,
      });

      setAreOnlyNullValuesModalShown(hasOnlyZeroValues);

      if (hasOnlyZeroValues) {
        if (!areOnlyNullValuesModalShown) {
          message.error("only zero values are not allowed");
        }
        setIsActionButtonDisabled(true);
      } else {
        setIsActionButtonDisabled(false);
      }
    } else {
      setValidateSuggestWorkload({
        hasSuggested: false,
        payload: {},
        allAllocations: allocations,
      });

      setIsActionButtonDisabled(true);
    }
  };

  const customTableHeader = () => {
    if (variant === "decline") {
      return "decline-table";
    } else if (variant === "propose") {
      return "propose-table";
    }
  };

  return (
    <div className="main-table-container">
      <div className={`ag-theme-alpine ${customTableHeader()} header-white`}>
        <AgGridReact
          rowData={data}
          columnDefs={cols}
          gridOptions={gridOptions}
          components={frameworkComponents}
          onCellValueChanged={onChange}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
};

export default ProposeWorkload;
