import React from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";

interface SearchInputProps {
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  disabled,
  defaultValue,
  onChange,
}) => {
  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange && onChange(e.target.value);
  }

  return (
    <Input
      prefix={<SearchOutlined />}
      placeholder="Search"
      size="large"
      onChange={handleInputChange}
      disabled={disabled}
      value={defaultValue}
      data-testid="filter-group-input"
      className="search-input"
    />
  );
};

export default SearchInput;
