import React from "react";

import {
  getChartOptions,
  mapChartData,
} from "@src/features/dashboard/utils/total_fte_utils";
import { MonthlyTotalFte } from "@src/types";
import { Card } from "antd";
import {
  Chart,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ChartData,
  Tooltip,
  Filler,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "./total_fte_chart.less";

Chart.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Filler
);

export interface TotalFteChartProps {
  data: MonthlyTotalFte[];
  isLoading?: boolean;
}

const TotalFteChart: React.FC<TotalFteChartProps> = ({
  data = [],
  isLoading,
}) => {
  const chartData: ChartData<"line"> = mapChartData(data);
  const chartOptions: ChartOptions<"line"> = getChartOptions();

  return (
    <Card title="Total FTE" className="total-fte-chart" loading={isLoading}>
      <div className="total-fte-chart__line-chart">
        <Line
          data={chartData}
          options={chartOptions}
          height="300px"
          data-testid="total-fte-line-chart"
        />
      </div>
    </Card>
  );
};

export default TotalFteChart;
