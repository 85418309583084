// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-filters-wrapper {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}
.table-filters-wrapper .filters-group-pinned-left {
  display: flex;
  gap: 20px;
}
.table-filters-wrapper .filters-group-pinned-right {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
}
.table-filters-wrapper .filters-group-pinned-right .toolbar-button {
  border: 1px solid #efefec !important;
  background-color: #f6f6f4 !important;
  color: #1d4477 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}
.table-filters-wrapper .filters-group-pinned-right .toolbar-button:hover {
  background-color: #eaeae7 !important;
}
.table-filters-wrapper .filters-group-pinned-right .filters-inner-group {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}
.table-filters-wrapper .filters-group-pinned-right .filters-inner-group:not(:first-child) {
  margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/features/table_filtering/components/filters_bar/filters_bar.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AADF;AAHA;EAOI,aAAA;EACA,SAAA;AADJ;AAPA;EAYI,aAAA;EACA,8BAAA;EACA,iBAAA;AAFJ;AAZA;EAiBM,oCAAA;EACA,oCAAA;EACA,yBAAA;EACA,4BAAA;EACA,2BAAA;AAFN;AAnBA;EAyBM,oCAAA;AAHN;AAtBA;EA6BM,aAAA;EACA,yBAAA;EACA,QAAA;AAJN;AA3BA;EAmCM,iBAAA;AALN","sourcesContent":["@import \"@src/stylings/variables\";\n\n.table-filters-wrapper {\n  width: 100%;\n  display: flex;\n  margin-bottom: 20px;\n  justify-content: space-between;\n\n  .filters-group-pinned-left {\n    display: flex;\n    gap: 20px;\n  }\n\n  .filters-group-pinned-right {\n    display: flex;\n    justify-content: space-between;\n    margin-left: auto;\n\n    .toolbar-button {\n      border: 1px solid @sand-70 !important;\n      background-color: @sand-40 !important;\n      color: @brand !important;\n      text-shadow: none !important;\n      box-shadow: none !important;\n    }\n\n    .toolbar-button:hover {\n      background-color: @sand-90 !important;\n    }\n\n    .filters-inner-group {\n      display: flex;\n      justify-content: flex-end;\n      gap: 5px;\n    }\n\n    .filters-inner-group:not(:first-child) {\n      margin-left: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
