// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.employee-form .modal-form {
  width: 80%;
  margin: auto;
}
.employee-form .modal-form .ant-col {
  margin-bottom: 5px;
}
.employee-form .ant-input[disabled] {
  font-weight: 500;
  cursor: not-allowed;
}
.employee-form .ant-select-arrow {
  color: #3ed8c3;
}
.employee-form .ant-select-selection-placeholder {
  color: #8c8c8c;
  opacity: 1;
}
.employee-form .ant-modal {
  min-width: 600px;
}
.employee-form .button-section-main {
  display: flex;
  justify-content: flex-end;
}
.employee-form .button-section-main-edit {
  justify-content: flex-end;
}
@media only screen and (max-width: 1500px) {
  .employee-form .modal-form {
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/admin_role_assignments/components/employee_form/employee_form.less"],"names":[],"mappings":"AAAA;EAEI,UAAA;EACA,YAAA;AAAJ;AAHA;EAMM,kBAAA;AAAN;AAKI;EACE,gBAAA;EACA,mBAAA;AAHN;AAMI;EACE,cAAA;AAJN;AAOI;EACE,cAAA;EACA,UAAA;AALN;AAQI;EACE,gBAAA;AANN;AApBA;EA+BI,aAAA;EACA,yBAAA;AARJ;AAUI;EACE,yBAAA;AARN;AAYE;EAAA;IAEI,UAAA;EAVJ;AACF","sourcesContent":[".employee-form {\n  .modal-form {\n    width: 80%;\n    margin: auto;\n\n    .ant-col {\n      margin-bottom: 5px;\n    }\n  }\n\n  .ant {\n    &-input[disabled] {\n      font-weight: 500;\n      cursor: not-allowed;\n    }\n\n    &-select-arrow {\n      color: #3ed8c3;\n    }\n\n    &-select-selection-placeholder {\n      color: #8c8c8c;\n      opacity: 1;\n    }\n\n    &-modal {\n      min-width: 600px;\n    }\n  }\n\n  .button-section-main {\n    display: flex;\n    justify-content: flex-end;\n\n    &-edit {\n      justify-content: flex-end;\n    }\n  }\n\n  @media only screen and (max-width: 1500px) {\n    .modal-form {\n      width: 80%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
