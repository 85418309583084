// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-assignment-actions {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.role-assignment-actions-tooltip {
  width: 32px;
}
.role-assignment-actions-tooltip .anticon-info-circle > svg {
  margin-left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/features/role_assignment/custom_renderer/role_management_actions_renderer/role_assignment_actions_renderer.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,6BAAA;EACA,WAAA;AACF;AACE;EACE,WAAA;AACJ;AAFE;EAII,cAAA;AACN","sourcesContent":[".role-assignment-actions {\n  display: flex;\n  justify-content: space-evenly;\n  width: 100%;\n\n  &-tooltip {\n    width: 32px;\n\n    .anticon-info-circle > svg {\n      margin-left: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
