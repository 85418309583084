// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allocationTable {
  width: 100%;
}
.allocationTable .ant-table-cell {
  font-family: "RWESansWeb-Medium", sans-serif !important;
}
.allocationTable .highlight {
  color: #b61f34;
}
.allocationTable .fixed-cell {
  position: sticky !important;
  left: 10px;
}
.allocationTable .ant-table-row-level-0:nth-child(2n) {
  background-color: #f7f9fc;
}
`, "",{"version":3,"sources":["webpack://./src/components/allocation_details_table/allocation_details_table.less"],"names":[],"mappings":"AAAA;EAKE,WAAA;AAHF;AAFA;EAEI,uDAAA;AAGJ;AALA;EAQI,cAAA;AAAJ;AARA;EAYI,2BAAA;EACA,UAAA;AADJ;AAZA;EAiBI,yBAAA;AAFJ","sourcesContent":[".allocationTable {\n  .ant-table-cell {\n    font-family: \"RWESansWeb-Medium\", sans-serif !important;\n  }\n\n  width: 100%;\n\n  .highlight {\n    color: #b61f34;\n  }\n\n  .fixed-cell {\n    position: sticky !important;\n    left: 10px;\n  }\n\n  .ant-table-row-level-0:nth-child(2n) {\n    background-color: #f7f9fc;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
