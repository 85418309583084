import React from "react";

import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import { FilterPanel } from "@src/types";
import { Collapse, Tree } from "antd";

import "./filter_collapse.less";

interface FilterCollapseProps {
  filterPanels: FilterPanel[];
  disabled?: boolean;
  onFilter: (filterName: string, values: string[]) => void;
}

const FilterCollapse: React.FC<FilterCollapseProps> = ({
  filterPanels,
  disabled = false,
  onFilter,
}) => {
  return (
    filterPanels?.length > 0 && (
      <Collapse
        defaultActiveKey={filterPanels.map(
          (filterPanel: FilterPanel) => filterPanel.key
        )}
        expandIconPosition="end"
        className="filter-collapse"
      >
        {filterPanels.map((filterPanel: FilterPanel) => (
          <Collapse.Panel
            key={filterPanel.key}
            header={filterPanel.header}
            data-testid="filter-panel"
          >
            <div className="filter-panel__border"></div>
            {filterPanel.labels.length > 0 ? (
              <Tree
                checkable
                disabled={disabled}
                onCheck={(values: string[]) =>
                  onFilter(filterPanel.filterType, values)
                }
                treeData={mapToTreeData<string>(filterPanel.labels)}
                checkedKeys={filterPanel.checkedLabels || []}
                selectable={false}
                data-testid="filter-tree"
              />
            ) : (
              <label>No data to filter</label>
            )}
          </Collapse.Panel>
        ))}
      </Collapse>
    )
  );
};

export default FilterCollapse;
