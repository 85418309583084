// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-renderer-button {
  margin-right: 10px;
  min-width: 80px;
}
.hidden {
  visibility: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/custom_renderer/actions_renderer/actions_renderer.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;AACF;AAEA;EACE,kBAAA;AAAF","sourcesContent":[".action-renderer-button {\n  margin-right: 10px;\n  min-width: 80px;\n}\n\n.hidden {\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
