import React, { useEffect } from "react";

import "./projects_overview_page.less";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "@reach/router";
import { Headline } from "@src/components/headline";
import ProjectOverviewCard from "@src/features/projects/components/project_overview_card/project_overview_card";
import CheckUserRoleService from "@src/services/checkUserRole";
import { useLazyGetProjectsByProjectLeadIdQuery } from "@src/services/slices/projectLeadsSlice";
import { useLazyGetLoggedInUserQuery } from "@src/services/slices/usersSlice";
import { Employee, Project } from "@src/types";
import { Button, Empty } from "antd";
import Cookies from "universal-cookie";

const ProjectsOverviewPage: React.FC = () => {
  const cookies = new Cookies();
  const [fetchProjects, { data: allProjects, isLoading }] =
    useLazyGetProjectsByProjectLeadIdQuery();
  const [fetchUser] = useLazyGetLoggedInUserQuery();

  useEffect(() => {
    const isRequestedPathMatchingRole: boolean =
      CheckUserRoleService.checkRole();

    if (isRequestedPathMatchingRole) {
      const loggedInEmployee: Employee = cookies.get("loggedInuser");

      if (!loggedInEmployee) {
        fetchProjects(loggedInEmployee.employeeId);
      } else {
        fetchUser()
          .unwrap()
          .then((employee: Employee) => {
            fetchProjects(employee.employeeId);
          })
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {});
      }
    }
  }, []);

  return (
    <>
      <div className="header-section">
        <Headline title="Project overview" />
        <Link to="/projects/create/-1">
          <Button
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            data-testid="create-project-btn"
          >
            Create project
          </Button>
        </Link>
      </div>

      {isLoading && <div>Loading...</div>}

      <div className="projects site-card-wrapper">
        {allProjects?.length === 0 && (
          <Empty
            description="No projects created yet !"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
        {allProjects?.map((project: Project, index: number) => {
          return (
            <ProjectOverviewCard
              project={project}
              index={index}
              key={project.id}
            />
          );
        })}
      </div>
    </>
  );
};

export default ProjectsOverviewPage;
