import React from "react";

import { COLORS, REQUEST_STATUS } from "@src/constants";
import { RequestStatusStatistics } from "@src/types";
import { ChartData, ChartOptions, LegendItem, Chart, Plugin } from "chart.js";
import _ from "lodash";

import { DoughnutChartLegendItem } from "../components/doughnut_chart_legend_item";

const COLOR_LIST: string[] = [
  COLORS.BRAND_60,
  COLORS.ENERGY_BLUE_40,
  COLORS.SUCCESS,
  COLORS.YELLOW_30,
  COLORS.DARK_RED_50,
];

const LABELS: string[] = [
  REQUEST_STATUS.REQUESTED,
  REQUEST_STATUS.PROPOSED,
  REQUEST_STATUS.CONFIRMED,
  _.startCase(REQUEST_STATUS.IN_RECRUITMENT),
  REQUEST_STATUS.DECLINED,
];

/**
 * Maps the data for the request status statistics doughnut chart
 * @param {RequestStatusStatistics} requestStatusStatisticsData - Request status statistics data from backend
 * @returns {ChartData<"doughnut">} The chart data
 */
export function mapChartData(
  requestStatusStatisticsData: RequestStatusStatistics
): ChartData<"doughnut"> {
  return {
    labels: LABELS,
    datasets: [
      {
        label: "Requests",
        data: [
          requestStatusStatisticsData?.requested,
          requestStatusStatisticsData?.proposed,
          requestStatusStatisticsData?.confirmed,
          requestStatusStatisticsData?.inRecruitment,
          requestStatusStatisticsData?.declinedStatus,
        ],
        backgroundColor: COLOR_LIST,
        borderColor: COLOR_LIST,
      },
    ],
  };
}

/**
 * Gets the options for the request status statistics doughnut chart
 * @returns {ChartOptions<"doughnut">} The chart options
 */
export function getChartOptions(): ChartOptions<"doughnut"> {
  return {
    maintainAspectRatio: false,
    cutout: "65%",
    plugins: {
      legend: {
        display: false,
      },
    },
  };
}

/**
 * Gets the plugin for centering the label in the request status statistics doughnut chart
 * @returns {Plugin<"doughnut">} The doughnut chart Plugin centerLabel
 */
export function getCenterLabelPlugin(): Plugin<"doughnut"> {
  return {
    id: "centerLabel",
    afterDatasetsDraw(chart: Chart<"doughnut">) {
      const { ctx } = chart;

      const totals: number = calculateTotals(chart);

      const centerX: number =
        (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY: number =
        (chart.chartArea.top + chart.chartArea.bottom) / 2;

      const fontSize = 28;
      ctx.font = `${fontSize}px RWESansWeb-Medium`;
      ctx.fillStyle = COLORS.BRAND_100;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(`${totals}`, centerX, centerY - fontSize / 2);
      ctx.fillText(`Requests`, centerX, centerY + fontSize / 2);
    },
  };
}

/**
 * Calculates the total requests displayed in the chart
 * @param {Chart<"doughnut">} chart - The doughnut chart object
 * @returns {number} Amount of total requests
 */
function calculateTotals(chart: Chart<"doughnut">): number {
  let totals = 0;
  const { data } = chart;

  const visibleData: number[] = data.datasets[0].data.filter(
    (element: number, index: number) => chart.getDataVisibility(index)
  );

  if (visibleData.length > 0) {
    totals = visibleData.reduce(
      (accumulator, currentValue) => accumulator + currentValue
    );
  }

  return totals;
}

/**
 * Gets the plugin for the custom legend of the request status statistics doughnut chart
 * @param {Function} setLegend - Setter for the legend
 * @returns {Plugin<"doughnut">} The doughnut chart Plugin customLegend
 */
export function getCustomLegendPlugin(
  setLegend: (list: React.ReactNode) => void
): Plugin<"doughnut"> {
  return {
    id: "customLegend",
    afterUpdate(chart: Chart<"doughnut">) {
      const items = chart.options.plugins.legend.labels.generateLabels(
        chart as Chart
      );
      const data: number[] = chart.data.datasets[0].data;
      const list: React.ReactNode = (
        <>
          {items.map((item: LegendItem) => (
            <DoughnutChartLegendItem
              text={item.text}
              count={data[item.index]}
              backgroundColor={item.fillStyle as string}
              action={() => {
                chart.toggleDataVisibility(item.index);
                chart.update();
              }}
              key={item.text}
            />
          ))}
        </>
      );
      setLegend(list);
    },
  };
}
