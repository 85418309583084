// these types are also used for getting strings of the enum.
// when changing values here, the enums below need to be changed as well
export const DASHBOARD_FILTER_TYPE = {
  REGION: "Region",
  COUNTRY: "Country",
  PROJECT: "Project",
  DEPARTMENT_CODE: "DepartmentCode",
  STANDARD_ROLE: "StandardRole",
};

// order for displaying filters
export const FILTER_TYPES_DISPLAY_ORDER = [
  DASHBOARD_FILTER_TYPE.REGION,
  DASHBOARD_FILTER_TYPE.COUNTRY,
  DASHBOARD_FILTER_TYPE.PROJECT,
  DASHBOARD_FILTER_TYPE.DEPARTMENT_CODE,
  DASHBOARD_FILTER_TYPE.STANDARD_ROLE,
];

// keys of the query param that the backend expects
export enum DashboardFilterQueryParam {
  Region = "regions",
  Country = "countries",
  Project = "projects",
  DepartmentCode = "departmentCodes",
  StandardRole = "standardRoles",
}

// property of the dashboard requests that is considered when filtering and getting labels of the filter
export enum DashboardRequestProperty {
  Region = "region",
  Country = "country",
  Project = "projectName",
  DepartmentCode = "departmentCode",
  StandardRole = "requestedRoleName",
}

// header name displayed in the filter section
export enum DashboardFilterCollapseHeader {
  Region = "Region",
  Country = "Country",
  Project = "Project",
  DepartmentCode = "Organisation Unit",
  StandardRole = "Standard Role",
}
