// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-section {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.button-and-selected {
  display: flex;
  justify-content: space-between;
}
.value-changed {
  background: rgba(62, 216, 195, 0.4);
}
.filter-download-section {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/employee_data_page/employee_data_page.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AACF;AAEA;EACE,aAAA;EACA,8BAAA;AAAF;AAGA;EACE,mCAAA;AADF;AAIA;EACE,mBAAA;EACA,aAAA;EACA,8BAAA;AAFF","sourcesContent":[".button-section {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 10px;\n}\n\n.button-and-selected {\n  display: flex;\n  justify-content: space-between;\n}\n\n.value-changed {\n  background: rgba(62, 216, 195, 0.4);\n}\n\n.filter-download-section {\n  margin-bottom: 20px;\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
