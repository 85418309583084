// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-standard-role-modal .ant-select {
  width: 90%;
}
.update-standard-role-modal .ant-select-option .ant-select-item-option-content {
  overflow: visible;
  white-space: normal;
}
.provided-role {
  line-height: 20px !important;
  white-space: normal;
}
`, "",{"version":3,"sources":["webpack://./src/features/import_resource_plan/components/update_standard_role_modal/update_standard_role_modal.less"],"names":[],"mappings":"AAAA;EAEI,UAAA;AAAJ;AAEI;EAEI,iBAAA;EACA,mBAAA;AADR;AAOA;EACE,4BAAA;EACA,mBAAA;AALF","sourcesContent":[".update-standard-role-modal {\n  .ant-select {\n    width: 90%;\n\n    &-option {\n      .ant-select-item-option-content {\n        overflow: visible;\n        white-space: normal;\n      }\n    }\n  }\n}\n\n.provided-role {\n  line-height: 20px !important;\n  white-space: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
