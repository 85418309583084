// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-area {
  margin-bottom: 20px;
}
.filter-area .filter {
  display: flex;
  width: 100%;
  padding-right: 15px;
}
.filter-area .formItem {
  width: 50%;
}
.filter-area Select:nth-child(1) {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/features/teams/components/teammember_filter/teammember_filter.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAFA;EAII,aAAA;EACA,WAAA;EACA,mBAAA;AACJ;AAPA;EAUI,UAAA;AAAJ;AAII;EACE,kBAAA;AAFN","sourcesContent":[".filter-area {\n  margin-bottom: 20px;\n\n  .filter {\n    display: flex;\n    width: 100%;\n    padding-right: 15px;\n  }\n\n  .formItem {\n    width: 50%;\n  }\n\n  Select {\n    &:nth-child(1) {\n      margin-right: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
