import React from "react";

import { FilterDropdown } from "@src/components/filter_dropdown";
import { FilterGroup } from "@src/components/filter_group";
import { TreeNode } from "@src/types/antd_types";
import { GridApi } from "ag-grid-community";

interface FilterProjectsProps {
  gridApi: GridApi;
  projects: TreeNode[];
  countries: TreeNode[];
  disabled: boolean;
}

const FilterProjects: React.FC<FilterProjectsProps> = ({
  gridApi,
  projects,
  countries,
  disabled,
}) => {
  let clearFilterProjects: () => void;
  let clearFilterCountries: () => void;

  function assignResetStateProjects(filterResetFunction: () => void): void {
    clearFilterProjects = filterResetFunction;
  }

  function assignResetStateCountries(filterResetFunction: () => void): void {
    clearFilterCountries = filterResetFunction;
  }

  function onChangeQuickFilter(searchText: string): void {
    gridApi.setGridOption("quickFilterText", searchText);
  }

  function onFilterChange(field: string, values: string[]): void {
    gridApi.setColumnFilterModel(field, { values: values });
    gridApi.onFilterChanged();
  }

  function onClickResetFilter(): void {
    gridApi.setFilterModel(null);
    gridApi.setGridOption("quickFilterText", "");
    clearFilterProjects();
    clearFilterCountries();
  }

  return (
    <FilterGroup
      onFilterQuickSearch={onChangeQuickFilter}
      onReset={onClickResetFilter}
      disabled={disabled}
    >
      <FilterDropdown
        placeholderDropdown="Project Filter"
        placeholderSearch="Enter Project"
        treeData={projects}
        onFilterChange={(values: string[]) => onFilterChange("name", values)}
        assignResetFunction={assignResetStateProjects}
        disabled={disabled}
      />
      <FilterDropdown
        placeholderDropdown="Country Filter"
        placeholderSearch="Enter Country"
        treeData={countries}
        onFilterChange={(values: string[]) => onFilterChange("country", values)}
        assignResetFunction={assignResetStateCountries}
        disabled={disabled}
      />
    </FilterGroup>
  );
};

export default FilterProjects;
