import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import _ from "lodash";
import "./teammember_filter.less";

function TeamMemberFilter({
  allTeamMembers,
  setTeamMembers,
  teamMembersWithoutVacancies,
  standardRoles,
}) {
  const [form] = Form.useForm();
  const [standardRoleFiltered, setStandardRoleFiltered] = useState([]);

  useEffect(() => {
    setStandardRoleFiltered(standardRoles);
  }, [standardRoles]);

  const handleStandardRoleFilter = (selectedStandardRoles) => {
    let filteredTeamMembers;

    if (selectedStandardRoles.length !== 0) {
      filteredTeamMembers = teamMembersWithoutVacancies.filter(
        (teamMember) =>
          teamMember.genericRoleId !== null &&
          selectedStandardRoles.includes(teamMember.genericRoleId)
      );

      form.setFieldsValue({
        teamMember: filteredTeamMembers.map((member) => member.employeeId),
      });
    } else {
      filteredTeamMembers = allTeamMembers;
      form.setFieldsValue({ teamMember: [] });
      setStandardRoleFiltered(standardRoles);
    }

    setTeamMembers(filteredTeamMembers);
  };

  const handleUserNameFilter = (filteredTeamMembersIds) => {
    let filteredTeamMembers;

    if (filteredTeamMembersIds.length !== 0) {
      filteredTeamMembers = teamMembersWithoutVacancies.filter((teamMember) =>
        filteredTeamMembersIds.includes(teamMember.employeeId)
      );

      const filteredStandardRoles = _.uniq(
        filteredTeamMembers
          .filter((teamMember) => teamMember.genericRoleId !== null)
          .map((teamMember) => teamMember.genericRoleId)
      );

      form.setFieldsValue({ standardRole: filteredStandardRoles });

      if (
        form.getFieldValue("standardRole") &&
        form.getFieldValue("standardRole").length !== 0
      ) {
        //employee has standard role
        setStandardRoleFiltered(standardRoles);
      } else {
        //employee has no standard role
        setStandardRoleFiltered(filteredStandardRoles);
      }
    } else {
      //all team members were removed from filter then reset standard role filter
      form.setFieldsValue({ standardRole: [] });
      filteredTeamMembers = allTeamMembers;
      setStandardRoleFiltered(standardRoles);
    }

    setTeamMembers(filteredTeamMembers);
  };

  return (
    <div className="filter-area">
      <Form form={form} layout="inline" size="large">
        <div className="filter">
          <Form.Item name="standardRole" className="formItem">
            <Select
              placeholder="Filter by standard role"
              showSearch
              mode="multiple"
              data-testid="standard-role-select"
              onChange={handleStandardRoleFilter}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {standardRoleFiltered
                .sort((a, b) =>
                  a.genericRoleName > b.genericRoleName ? 1 : -1
                )
                .map((standardRole) => {
                  return (
                    <Select.Option
                      key={standardRole?.genericRoleId}
                      value={standardRole?.genericRoleId}
                    >
                      {standardRole?.genericRoleName}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item name="teamMember" className="formItem">
            <Select
              placeholder="Filter by name"
              showSearch
              mode="multiple"
              data-testid="team-member-select"
              optionFilterProp="children"
              onChange={handleUserNameFilter}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {teamMembersWithoutVacancies.map((member) => {
                return (
                  <Select.Option
                    key={member?.employeeId}
                    value={member?.employeeId}
                  >
                    {member.fullName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default TeamMemberFilter;
