// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-input-error {
  border-color: #ff4d4f;
}
.ant-tooltip-inner {
  max-height: 150px;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/features/resource_plan/custom_renderer/simple_editor/simple_editor.css"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AACA;EACE,iBAAA;EACA,gBAAA;AACF","sourcesContent":[".ag-input-error {\n  border-color: #ff4d4f;\n}\n.ant-tooltip-inner {\n  max-height: 150px;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
