import React, { useState, ReactElement } from "react";

import { ForkOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { BasicRole, RoleSpecification, StandardRole } from "@src/types";
import { ICellRendererParams } from "ag-grid-community";
import { App, Button, Tooltip } from "antd";

import "./role_assignment_actions_renderer.less";
import { BasicRoleModal } from "../../components/basic_role_modal";
import { RoleSpecificationModal } from "../../components/role_specification_modal";
import { StandardRoleModal } from "../../components/standard_role_modal";
import {
  RoleTypes,
  StandardRoleAssignments,
} from "../../utils/role_assignment_constants";
import { showSuccessMessage } from "../../utils/role_assignment_helper";

interface RoleAssignmentActionsRendererProps {
  params: ICellRendererParams;
  updateRoleRtkFunction?: MutationTrigger<any>;
  deleteRoleRtkFunction?: MutationTrigger<any>;
  onSplitCallback?: (params: ICellRendererParams) => void;
}

const RoleAssignmentActionsRenderer: React.FC<
  RoleAssignmentActionsRendererProps
> = ({
  params,
  updateRoleRtkFunction,
  deleteRoleRtkFunction,
  onSplitCallback,
}) => {
  const { modal, message } = App.useApp();

  /* use states */
  const [showUpdateStandardRoleModal, setShowUpdateStandardRoleModal] =
    useState<boolean>(false);
  const [showUpdateBasicRoleModal, setShowUpdateBasicRoleModal] =
    useState<boolean>(false);
  const [showUpdateSpecificationModal, setShowUpdateSpecificationModal] =
    useState<boolean>(false);

  const rolesAssigned: boolean =
    params.data.isAssignedToStandardRoles ||
    params.data.assignments?.length > 0;

  /**
   * onClick function of the edit button
   * opens a modal regarding the role type
   */
  function onEdit(): void {
    if (params.context.roleType === RoleTypes.STANDARD_ROLE)
      setShowUpdateStandardRoleModal(true);
    else if (params.context.roleType === RoleTypes.BASIC_ROLE)
      setShowUpdateBasicRoleModal(true);
    else if (params.context.roleType === RoleTypes.ROLE_SPECIFICATION)
      setShowUpdateSpecificationModal(true);
  }

  /**
   * onClick function of the delete button
   * opens a modal that needs to be accepted by the user
   */
  function onDelete(): void {
    modal.confirm({
      autoFocusButton: null,
      title: "Delete Role",
      content: "Are you sure you want to delete the following role?",
      okText: "Delete Role",
      okButtonProps: {
        icon: <DeleteOutlined />,
        danger: true,
        size: "large",
      },
      cancelButtonProps: {
        type: "default",
        size: "large",
      },
      icon: null,
      onOk: () => deleteRole(),
    });
  }

  /**
   * onClick function of the delete button in the delete modal
   * calls the endpoint to delete a role
   *
   */
  function deleteRole(): void {
    deleteRoleRtkFunction(params.data[`${params.context.roleType}Id`])
      .unwrap()
      .then(() => {
        params.api.applyTransaction({ remove: [params.data] });
        showSuccessMessage("deleted", message, params.context.roleType);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  /**
   * onClick function of the save button in the role modal
   * calls the endpoint to update a role regarding the role type
   *
   * @param {StandardRole | BasicRole | RoleSpecification} newRole
   */
  function onSaveRoleModal(
    newRole: StandardRole | BasicRole | RoleSpecification
  ) {
    updateRoleRtkFunction(newRole)
      .unwrap()
      .then(() => {
        showSuccessMessage("updated", message, params.context.roleType);
        params.api.applyTransaction({ update: [params.data] });
        showUpdateBasicRoleModal && setShowUpdateBasicRoleModal(false),
          showUpdateSpecificationModal &&
            setShowUpdateSpecificationModal(false),
          showUpdateStandardRoleModal && setShowUpdateStandardRoleModal(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  /**
   * maps the right tooltip message for the delete button regarding the specific data
   *
   * @returns the tooltip react element for the delete button
   */
  function getTooltipMessage(): ReactElement {
    if (params.data.isAssignedToStandardRoles) {
      return <>This role is assigned to a Standard Role.</>;
    }
    return (
      <>
        <p>You can't delete roles on which assignments exist.</p>
        <div>Role still assigned to: </div>
        {params.data.assignments?.map((assignment: string) => {
          return <div>- {StandardRoleAssignments[assignment]}</div>;
        })}
      </>
    );
  }

  return (
    <div className="role-assignment-actions">
      {showUpdateStandardRoleModal && (
        <StandardRoleModal
          showModal={showUpdateStandardRoleModal}
          setShowModal={setShowUpdateStandardRoleModal}
          onSaveCallback={onSaveRoleModal}
          defaultStandardRole={params.data}
        />
      )}

      {showUpdateBasicRoleModal && (
        <BasicRoleModal
          showModal={showUpdateBasicRoleModal}
          setShowModal={setShowUpdateBasicRoleModal}
          onSaveCallback={onSaveRoleModal}
          defaultBasicRole={params.data}
        />
      )}

      {showUpdateSpecificationModal && (
        <RoleSpecificationModal
          showModal={showUpdateSpecificationModal}
          setShowModal={setShowUpdateSpecificationModal}
          onSaveCallback={onSaveRoleModal}
          defaultRoleSpecification={params.data}
        />
      )}

      <Button
        onClick={() => onEdit()}
        data-testid="edit-button"
        icon={<EditOutlined />}
        shape="circle"
        className="action-button"
      />

      <Tooltip
        placement="topRight"
        title={rolesAssigned && getTooltipMessage()}
      >
        <Button
          danger
          onClick={onDelete}
          disabled={rolesAssigned}
          data-testid="delete-button"
          icon={<DeleteOutlined />}
          shape="circle"
          className="action-button"
        />
      </Tooltip>

      {params.context.roleType === RoleTypes.STANDARD_ROLE &&
        onSplitCallback && (
          <Button
            onClick={() => onSplitCallback(params)}
            disabled={
              !params.data.assignments?.includes(
                StandardRoleAssignments.REQUESTS.toUpperCase()
              )
            }
            data-testid="split-button"
            shape="circle"
            icon={<ForkOutlined />}
            className="action-button"
          />
        )}
    </div>
  );
};

export default RoleAssignmentActionsRenderer;
