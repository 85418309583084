import { COLORS } from "@src/constants";

export const defaultTheme = {
  token: {
    borderRadius: 2,
    fontFamily: "RWESansWeb-Regular",
    fontSize: 14,
    colorPrimary: COLORS.BRAND_100,
    colorLink: COLORS.BRAND_100,
    colorTextBase: COLORS.FONT_00,
    colorSuccess: COLORS.SUCCESS,
    colorWarning: COLORS.WARNING,
    colorError: COLORS.ALERT,
    colorTextPlaceholder: COLORS.NAVIGATION_GREY,
  },
  components: {
    Layout: {
      bodyBg: COLORS.WHITE,
      siderBg: COLORS.SAND_20,
    },
    Button: {
      fontFamily: "RWESansWeb-Medium",
      contentFontSize: 16,
      //primary
      primaryShadow: "0",
      colorPrimary: COLORS.GRADIENT_GREEN,
      colorPrimaryHover: COLORS.SEMANTIC_PRIMARY_HOVER,
      colorPrimaryActive: COLORS.SEMANTIC_PRIMARY_ACTIVE,
      // default
      defaultColor: COLORS.BRAND_100,
      defaultBorderColor: COLORS.BRAND_100,
      defaultHoverColor: COLORS.BRAND_100,
      defaultHoverBorderColor: COLORS.BRAND_100,
      defaultHoverBg: COLORS.BRAND_10,
      defaultActiveColor: COLORS.BRAND_100,
      defaultActiveBorderColor: COLORS.BRAND_100,
      defaultActiveBg: COLORS.BRAND_20,
      // danger
      colorErrorHover: COLORS.ALERT_DARK,
      // link
      colorLinkHover: COLORS.BRAND_DARK,
      colorLinkActive: COLORS.BRAND_DARK,
    },
    Card: {
      headerHeight: 48,
      headerBg: COLORS.SAND_20,
      headerFontSize: 18,
      colorTextHeading: COLORS.BRAND_100,
    },
    Checkbox: {
      fontFamily: "RWESansWeb-Medium",
      colorPrimary: COLORS.BRAND_100,
      colorText: COLORS.FONT_00,
    },
    Radio: {
      colorPrimary: COLORS.FONT_00,
      radioSize: 16,
    },
    Tree: {
      colorPrimary: COLORS.BRAND_100,
      colorText: COLORS.FONT_01,
      nodeHoverBg: COLORS.TRANSPARENT,
      nodeSelectedBg: COLORS.TRANSPARENT,
      fontFamily: "RWESansWeb-Medium",
    },
    Input: {
      fontSize: 14,
      colorText: COLORS.FONT_00,
    },
    Transfer: {
      headerHeight: 42,
    },
    Collapse: {
      colorTextHeading: COLORS.FONT_00,
      contentBg: COLORS.WHITE,
      headerBg: COLORS.WHITE,
      contentPadding: 10,
    },
    Progress: {
      defaultColor: COLORS.ENERGY_GREEN,
    },
    Modal: {
      titleFontSize: 24,
      titleColor: COLORS.BRAND_100,
      marginXS: 16,
    },
    Tabs: {
      itemColor: COLORS.FONT_00,
      itemSelectedColor: COLORS.FONT_00,
      titleFontSizeLG: 18,
    },
    Tooltip: {
      colorBgSpotlight: COLORS.DARK,
    },
    Popover: {
      colorTextHeading: COLORS.DARK,
    },
    Select: {
      optionFontSize: 16,
      optionSelectedBg: COLORS.TRANSPARENT,
    },
    Form: {
      labelColor: COLORS.FONT_00,
    },
    DatePicker: {
      cellActiveWithRangeBg: COLORS.BRAND_20,
    },
    Steps: {
      fontSize: 16,
      colorPrimary: COLORS.GRADIENT_GREEN,
      colorText: COLORS.BRAND_100,
    },
    Table: {
      headerBg: COLORS.ANTD_HEADER_BG,
    },
  },
};
