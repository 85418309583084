// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-collapse-wrapper {
  width: 100%;
}
.filter-collapse-wrapper.ant-collapse {
  border: 0;
}
.filter-collapse-wrapper .ant-collapse-header {
  border-bottom: 1px solid #e8ecf1;
  padding-left: 0px !important;
}
.filter-collapse-wrapper .ant-collapse-header * {
  color: #1d4477 !important;
}
.filter-collapse-wrapper .ant-collapse-item {
  border-bottom: 0;
}
.filter-collapse-wrapper .ant-collapse-item-active .ant-collapse-arrow > svg {
  transform: rotate(-90deg) !important;
}
.filter-collapse-wrapper .ant-collapse-content {
  max-height: 152px !important;
  overflow: scroll;
  border: 0;
}
.filter-collapse-wrapper .ant-collapse-content .filter-panel__border {
  padding-bottom: 10px;
}
.filter-collapse-wrapper .ant-tree-treenode {
  margin-bottom: 12px;
}
.filter-collapse-wrapper .ant-tree-switcher {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/features/table_filtering/components/filter_collapse_wrapper/filter_collapse_wrapper.less"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;AAGE;EACE,SAAA;AADJ;AAMM;EACE,gCAAA;EACA,4BAAA;AAJR;AAEM;EAKI,yBAAA;AAJV;AAQM;EACE,gBAAA;AANR;AAQQ;EAEI,oCAAA;AAPZ;AAYM;EACE,4BAAA;EACA,gBAAA;EACA,SAAA;AAVR;AAaU;EACE,oBAAA;AAXZ;AAkBM;EACE,mBAAA;AAhBR;AAmBM;EACE,aAAA;AAjBR","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.filter-collapse-wrapper {\n  width: 100%;\n\n  &.ant-collapse {\n    border: 0;\n  }\n\n  .ant {\n    &-collapse {\n      &-header {\n        border-bottom: 1px solid @brand-10;\n        padding-left: 0px !important;\n\n        * {\n          color: @brand !important;\n        }\n      }\n\n      &-item {\n        border-bottom: 0;\n\n        &-active .ant-collapse-arrow {\n          > svg {\n            transform: rotate(-90deg) !important;\n          }\n        }\n      }\n\n      &-content {\n        max-height: 152px !important;\n        overflow: scroll;\n        border: 0;\n\n        .filter-panel {\n          &__border {\n            padding-bottom: 10px;\n          }\n        }\n      }\n    }\n\n    &-tree {\n      &-treenode {\n        margin-bottom: 12px;\n      }\n\n      &-switcher {\n        display: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
