// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.standard-role-modal__description textarea.ant-input {
  height: 71px;
}
`, "",{"version":3,"sources":["webpack://./src/features/role_assignment/components/standard_role_modal/standard_role_modal.less"],"names":[],"mappings":"AAGE;EAEI,YAAA;AAHN","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.standard-role-modal {\n  &__description {\n    textarea.ant-input {\n      height: 71px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
