import "./filter_collapse_wrapper.less";
import React from "react";

import { Collapse } from "antd";

export interface FilterCollapseWrapperProps {
  title: string;
  children: React.ReactNode;
}

const FilterCollapseWrapper: React.FC<FilterCollapseWrapperProps> = ({
  title,
  children,
}) => {
  return (
    <Collapse
      defaultActiveKey={title}
      expandIconPosition="end"
      className="filter-collapse-wrapper"
    >
      <Collapse.Panel
        key={title}
        header={title}
        data-testid="filter-collapse-wrapper"
      >
        <div className="filter-panel__border"></div>
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

export default FilterCollapseWrapper;
