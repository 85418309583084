// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-form,
.project-form .project-creation {
  margin: 20px !important;
}
.project-form h3 {
  margin-top: 32px;
  margin-bottom: 16px;
}
.project-form .joint-venture .ant-form-item-control-input-content {
  display: flex;
  align-items: end;
}
.project-form .joint-venture .ant-row {
  margin-bottom: 0;
}
@media only screen and (max-width: 1500px) {
  .project-form .project-creation {
    width: 80%;
  }
}
.project-form .save-project-button {
  margin-left: 20px;
}
.project-form .ant-form-item:not(:has(.joint-venture-label)) {
  margin-bottom: 10px;
}
.project-form .project-range-picker {
  max-width: 300px;
}
.project-form button[disabled] {
  cursor: not-allowed !important;
}
.project-form .ant-picker {
  width: 100%;
}
.project-form .joint-venture-partner {
  width: 100%;
}
.project-form .placeholder-input {
  background-color: #fafafa;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  height: 36px;
  color: rgba(0, 0, 0, 0.25);
  font-family: "RWESansWeb-Regular", sans-serif;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/features/projects/components/project_form/project_form.less"],"names":[],"mappings":"AACE;;EAEE,uBAAA;AAAJ;AAHA;EAOI,gBAAA;EACA,mBAAA;AADJ;AAMM;EACE,aAAA;EACA,gBAAA;AAJR;AAOM;EACE,gBAAA;AALR;AAUE;EAAA;IAEI,UAAA;EARJ;AACF;AAnBA;EA+BI,iBAAA;AATJ;AAtBA;EAmCI,mBAAA;AAVJ;AAzBA;EAuCI,gBAAA;AAXJ;AA5BA;EA2CI,8BAAA;AAZJ;AA/BA;EA+CI,WAAA;AAbJ;AAlCA;EAmDI,WAAA;AAdJ;AArCA;EAuDI,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;EACA,YAAA;EACA,0BAAA;EACA,6CAAA;EACA,eAAA;AAfJ","sourcesContent":[".project-form {\n  &,\n  .project-creation {\n    margin: 20px !important;\n  }\n\n  h3 {\n    margin-top: 32px;\n    margin-bottom: 16px;\n  }\n\n  .joint-venture {\n    .ant {\n      &-form-item-control-input-content {\n        display: flex;\n        align-items: end;\n      }\n\n      &-row {\n        margin-bottom: 0;\n      }\n    }\n  }\n\n  @media only screen and (max-width: 1500px) {\n    .project-creation {\n      width: 80%;\n    }\n  }\n\n  .save-project-button {\n    margin-left: 20px;\n  }\n\n  .ant-form-item:not(:has(.joint-venture-label)) {\n    margin-bottom: 10px;\n  }\n\n  .project-range-picker {\n    max-width: 300px;\n  }\n\n  button[disabled] {\n    cursor: not-allowed !important;\n  }\n\n  .ant-picker {\n    width: 100%;\n  }\n\n  .joint-venture-partner {\n    width: 100%;\n  }\n\n  .placeholder-input {\n    background-color: #fafafa;\n    border-radius: 4px;\n    border: 1px solid #d9d9d9;\n    padding: 4px 11px;\n    height: 36px;\n    color: rgba(0, 0, 0, 0.25);\n    font-family: \"RWESansWeb-Regular\", sans-serif;\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
