// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-row .ant-col {
  max-width: 85% !important;
}
.modal-content {
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/delete_project_confirmation_modal/delete_project_confirmation_modal.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAEA;EACE,aAAA;EACA,uBAAA;AAAF","sourcesContent":[".warning-row .ant-col {\n  max-width: 85% !important;\n}\n\n.modal-content {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
