// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role_assignment_page .top-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  gap: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/role_assignment_page/role_assignment_page.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,eAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AAAJ","sourcesContent":[".role_assignment_page {\n  .top-section {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-bottom: 10px;\n    align-items: center;\n    gap: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
