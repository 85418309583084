// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-info {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
.project-info-tooltip > p {
  margin: 0;
}
.project-info-tooltip .ant-tooltip-inner {
  white-space: pre-line;
  text-align: start !important;
  width: max-content;
  max-width: 450px;
  max-height: fit-content;
}
.project-info-technology-icon {
  height: 32px;
  width: 32px;
}
.project-info-hydrogen-icon {
  height: 24px;
  width: 24px;
  margin: 0 !important;
}
.project-info-profile-icon {
  height: 12px;
  width: 12px;
  margin: 0 !important;
}
.project-info-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.project-info-name-label {
  font-size: 14px !important;
  font-family: "RWESansWeb-Medium", sans-serif !important;
}
.project-info-subtext {
  display: flex;
  gap: 3px;
  align-items: center;
  font-family: "RWESansWeb-Regular";
  font-size: 12px !important;
  color: #3b3d42;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_requests/custom_renderer/project_information_renderer/project_information_renderer.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,8BAAA;AADF;AAGE;EAEI,SAAA;AAFN;AAAE;EAMI,qBAAA;EACA,4BAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;AAHN;AAOE;EACE,YAAA;EACA,WAAA;AALJ;AAQE;EACE,YAAA;EACA,WAAA;EACA,oBAAA;AANJ;AASE;EACE,YAAA;EACA,WAAA;EACA,oBAAA;AAPJ;AAUE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AARJ;AAWE;EACE,0BAAA;EACA,uDAAA;AATJ;AAYE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,iCAAA;EACA,0BAAA;EACA,cAAA;AAVJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.project-info {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  justify-content: space-between;\n\n  &-tooltip {\n    > p {\n      margin: 0;\n    }\n\n    .ant-tooltip-inner {\n      white-space: pre-line;\n      text-align: start !important;\n      width: max-content;\n      max-width: 450px;\n      max-height: fit-content;\n    }\n  }\n\n  &-technology-icon {\n    height: 32px;\n    width: 32px;\n  }\n\n  &-hydrogen-icon {\n    height: 24px;\n    width: 24px;\n    margin: 0 !important;\n  }\n\n  &-profile-icon {\n    height: 12px;\n    width: 12px;\n    margin: 0 !important;\n  }\n\n  &-wrapper {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n  }\n\n  &-name-label {\n    font-size: 14px !important;\n    font-family: \"RWESansWeb-Medium\", sans-serif !important;\n  }\n\n  &-subtext {\n    display: flex;\n    gap: 3px;\n    align-items: center;\n    font-family: \"RWESansWeb-Regular\";\n    font-size: 12px !important;\n    color: @font-00;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
