import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { ColDef, GridOptions, ValueFormatterParams } from "ag-grid-community";

import { getCommonGridOptions } from "./common_grid_options";
import { RoleTypes } from "./role_assignment_constants";

const columnDefs: ColDef[] = [
  {
    headerName: "Basic Role",
    field: "basicRoleName",
    colId: "basicRole", // needed for getFilterInstance in role_assignment_filter
    filter: "agSetColumnFilter",
  },
  {
    headerName: "Area",
    field: "area.areaName",
    filter: "agSetColumnFilter",
  },
  {
    headerName: "N-1 Department",
    field: "department",
    filter: "agSetColumnFilter",
    valueFormatter: (params: ValueFormatterParams) =>
      params.value ? `${params.value.orgName} (${params.value.orgUnit})` : "",
  },
];

/**
 * sets the grid options for the BasicRoleManagement.
 * combining basic role specific grid options with common ones
 *
 * @param updateRoleRtkFunction function to reach the update endpoint
 * @param deleteRoleRtkFunction function to reach the delete endpoint
 * @returns the grid options
 */
export function createGridOptions(
  updateRoleRtkFunction: MutationTrigger<any>,
  deleteRoleRtkFunction: MutationTrigger<any>
): GridOptions {
  const gridOptions = getCommonGridOptions(
    RoleTypes.BASIC_ROLE,
    columnDefs,
    updateRoleRtkFunction,
    deleteRoleRtkFunction
  );
  return gridOptions;
}
