import React from "react";

import "./loading_modal.less";
import { Progress, Spin, Modal } from "antd";

function LoadingModal({ modalVisible, loadingMessage }) {
  return (
    <Modal
      className="loading-modal"
      width="10%"
      open={modalVisible}
      centered
      closable={false}
      footer={null}
      data-testid="loading-modal"
    >
      {loadingMessage?.type === "progress" ? (
        <div data-testid="loading-message-progress">
          <div className="loading-modal__progress">
            <Progress type="circle" percent={loadingMessage.percent} />
          </div>
          <span data-testid="status-message">
            Status: <b>{loadingMessage.currentRowStatus}</b> of{" "}
            <b>{loadingMessage.totalRows}</b>
          </span>
        </div>
      ) : (
        <Spin data-testid="loading-message-spin" tip={loadingMessage.text}>
          <div className="loading-modal__spinn-tip" />
        </Spin>
      )}
    </Modal>
  );
}

export default LoadingModal;
