import { RequestIdTransformation } from "@src/utils/helper";
import { getMonthlyAllocationCells } from "@src/utils/month_cell_renderer_utils";

export const getCommonColumnDefs = (
  type,
  startDate,
  endDate,
  requestInEdit
) => {
  const monthlyAllocationCols = getMonthlyAllocationCells(startDate, endDate);

  const cellClassRules = {
    "month-allocation-valid-changed-value": (params) => {
      const isProjectIdentifierUpdated =
        params.colDef.field === "projectIdentifier" &&
        params.node.isProjectIdentifierUpdated;
      const isScopeUpdate =
        params.colDef.field === "scope" && params.node.isScopeUpdated;
      const isSpecificRoleUpdated =
        params.colDef.field === "specificRole" &&
        params.node.isSpecificRoleUpdated;

      return (
        isProjectIdentifierUpdated || isScopeUpdate || isSpecificRoleUpdated
      );
    },
    "no-border": (params) => {
      if (type === "open") {
        if (params.colDef.field !== "genericRoleName") {
          return true;
        } else if (
          params.colDef.field === "genericRoleName" &&
          params.data.genericRoleName
        ) {
          return true;
        } else if (
          !params.node.selected &&
          params.colDef.field === "genericRoleName" &&
          !params.data.genericRoleName
        ) {
          return true;
        }
      } else if (type === "sent") {
        return (
          params.colDef.field === "genericRoleName" &&
          !params.node.isGenericRoleUpdated
        );
      }
    },
    "error-field-open-request": (params) => {
      if (
        params.node.selected &&
        params.colDef.field === "genericRoleName" &&
        !params.data.genericRoleName
      ) {
        return true;
      }
    },
    "generic-role-changed": (params) => {
      if (
        type === "sent" &&
        params.colDef.field === "genericRoleName" &&
        params.node.isGenericRoleUpdated
      ) {
        return true;
      }
    },
  };

  /** col definitions */
  const requestIdCol = {
    headerName: "Request id",
    field: "parentRequestId",
    width: 180,
    minWidth: 140,
    pinned: "left",
    cellClassRules: cellClassRules,
    cellClass: "ag-grid-cell",
    suppressFillHandle: true,
    filterParams: {
      newRowsAction: "keep",
    },
    valueGetter: (param) =>
      !param.node.rowPinned &&
      RequestIdTransformation(`${param.data.parentRequestId}`, null),
  };

  const standardRoleNameCol = {
    headerName: "Standard role",
    field: "genericRoleName",
    cellClassRules: cellClassRules,
    width: 260,
    minWidth: 260,
    pinned: "left",
    filter: "agSetColumnFilter",
    filterParams: {
      newRowsAction: "keep",
    },
    cellRendererSelector: (props) =>
      !props.node.rowPinned &&
      type !== "replies" && { component: "genericRoleRenderer" },
    suppressFillHandle: true,
    cellClass: "ag-grid-cell no-border wrap-text",
    editable: false,
    suppressMenu: true,
    sortable: true,
    unSortIcon: true,
  };

  const jointVentureCol = {
    headerName: "JV",
    field: "jointVenture",
    width: 80,
    pinned: "left",
    suppressFillHandle: true,
    cellClassRules: cellClassRules,
    cellClass: "ag-grid-cell",
    filterParams: {
      newRowsAction: "keep",
    },
    cellRendererSelector: (props) =>
      !props.node.rowPinned && { component: "jointVentureRenderer" },
  };

  const projectIdentifierCol = {
    headerName: "Project identifier",
    field: "projectIdentifier",
    width: 220,
    minWidth: 220,
    pinned: "left",
    cellClassRules: cellClassRules,
    cellRendererSelector: (props) =>
      !props.node.rowPinned &&
      type !== "replies" && { component: "simpleEditorRenderer" },
    editable: type === "sent",
    suppressFillHandle: true,
    cellRendererParams: { requestInEdit },
    cellClass: "ag-grid-cell",
    filterParams: {
      newRowsAction: "keep",
    },
  };

  const specificRoleCol = {
    headerName: "Specific role",
    field: "specificRole",
    width: 220,
    minWidth: 220,
    pinned: "left",
    cellClassRules: cellClassRules,
    cellRendererSelector: (props) =>
      !props.node.rowPinned &&
      type !== "replies" && { component: "simpleEditorRenderer" },
    editable: type === "sent",
    cellRendererParams: { requestInEdit },
    cellClass: "ag-grid-cell",
    suppressFillHandle: true,
    filterParams: {
      newRowsAction: "keep",
    },
  };

  const scopeCol = {
    headerName: "Scope",
    field: "scope",
    width: 220,
    minWidth: 220,
    pinned: "left",
    suppressFillHandle: true,
    editable: type === "sent",
    cellRendererSelector: (props) =>
      !props.node.rowPinned &&
      type !== "replies" && { component: "simpleEditorRenderer" },
    cellRendererParams: { requestInEdit },
    cellClassRules: cellClassRules,
    cellClass: "ag-grid-cell",
    filterParams: {
      newRowsAction: "keep",
    },
  };

  const statusCol = {
    headerName: "Status",
    field: "status",
    width: 150,
    minWidth: 150,
    pinned: "left",
    cellRendererSelector: (props) =>
      !props.node.rowPinned && { component: "statusRenderer" },
    suppressFillHandle: true,
    cellClass: "ag-grid-cell no-border",
    filterParams: {
      newRowsAction: "keep",
    },
  };

  /**
   * Hidden columns only used for Excel export
   *
   */
  const teamMemberCol = {
    headerName: "Team member",
    pinned: "left",
    field: "teamMemberFullName",
    hide: true,
  };

  const contractTypeCol = {
    headerName: "Contract type",
    pinned: "left",
    field: "contractType",
    hide: true,
  };

  const technologyCol = {
    headerName: "Technology",
    field: "technologies",
    pinned: "left",
    hide: true,
  };

  const contractAvailabilityCol = {
    headerName: "Contract availability",
    field: "totalAvailability",
    pinned: "left",
    hide: true,
  };

  const rcdTeamMemberCol = {
    headerName: "RCD Team member",
    pinned: "left",
    field: "rcdTeamMember",
    hide: true,
  };

  const uiTeamMemberCol = {
    headerName: "UI Team member",
    pinned: "left",
    field: "uiTeamMember",
    hide: true,
  };

  const teamMemberOrgCodeCol = {
    headerName: "Team member Org-Code",
    pinned: "left",
    field: "teamMemberOrgCode",
    hide: true,
  };

  const teamLeadCol = {
    headerName: "Team lead",
    pinned: "left",
    field: "teamLeadFullName",
    hide: true,
  };

  const rcdTeamLeadCol = {
    headerName: "RCD Team lead",
    pinned: "left",
    field: "teamLeadRcdNumber",
    hide: true,
  };

  const uiTeamLeadCol = {
    headerName: "UI Team lead",
    pinned: "left",
    field: "teamLeadUiNumber",
    hide: true,
  };

  const teamLeadOrgCode = {
    headerName: "Team lead Org-Code",
    pinned: "left",
    field: "teamLeadOrgCode",
    hide: true,
  };

  const costCenterCol = {
    headerName: "Cost center",
    pinned: "left",
    field: "costCenter",
    hide: true,
  };

  const comment = {
    colKey: "expand",
    width: 60,
    pinned: "left",
    cellRenderer: "commentRenderer",
    suppressFillHandle: true,
    cellClass: "ag-grid-cell no-border",
    filterParams: {
      newRowsAction: "keep",
    },
    sortable: false,
  };

  const checkboxCol = {
    width: 100,
    pinned: "right",
    colKey: "checkbox",
    checkboxSelection: true,
    suppressFillHandle: true,
    cellClass: "ag-grid-cell no-border",
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    sortable: false,
  };

  const repliesExpandCol = {
    pinned: "left",
    align: "center",
    colKey: "expand",
    width: 60,
    cellRenderer: "agGroupCellRenderer",
    lockPosition: true,
    sortable: false,
  };

  const assignedTeamMemberName = {
    headerName: "Name",
    field: "assignedTeamMember",
    pinned: "right",
    align: "center",
    cellRendererSelector: (props) =>
      !props.node.rowPinned && { component: "tooltipRenderer" },
    sortable: true,
    unSortIcon: true,
  };

  // without technology -> this needs to be included separatley
  const hiddenExcelExportColumns = [
    teamMemberCol,
    contractTypeCol,
    contractAvailabilityCol,
    rcdTeamMemberCol,
    uiTeamMemberCol,
    teamMemberOrgCodeCol,
    teamLeadCol,
    rcdTeamLeadCol,
    uiTeamLeadCol,
    teamLeadOrgCode,
    costCenterCol,
  ];

  /** col assignments */

  let columns = [];

  if (type === "open") {
    columns.push(
      standardRoleNameCol,
      jointVentureCol,
      projectIdentifierCol,
      specificRoleCol,
      scopeCol,
      statusCol,
      ...monthlyAllocationCols,
      checkboxCol
    );
  }

  if (type === "sent") {
    columns.push(
      requestIdCol,
      technologyCol,
      standardRoleNameCol,
      jointVentureCol,
      projectIdentifierCol,
      specificRoleCol,
      scopeCol,
      statusCol,
      ...hiddenExcelExportColumns,
      ...monthlyAllocationCols,
      checkboxCol
    );
  }

  if (type === "replies") {
    columns.push(
      repliesExpandCol,
      requestIdCol,
      standardRoleNameCol,
      projectIdentifierCol,
      specificRoleCol,
      scopeCol,
      comment,
      statusCol,
      ...monthlyAllocationCols,
      assignedTeamMemberName
    );
  }
  return columns;
};
