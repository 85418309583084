import React from "react";

import "./phase_renderer.less";
import ProjectPhaseChip from "@src/components/project_phase_chip/project_phase_chip";

interface PhaseRendererProps {
  status: string;
}

const PhaseRenderer: React.FC<PhaseRendererProps> = ({ status }) => {
  return (
    <div className="phase-renderer">
      <div>{status}</div>
      <ProjectPhaseChip projectPhase={status} />
    </div>
  );
};

export default PhaseRenderer;
