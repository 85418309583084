// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request-country-statistics-chart .ant-card-body {
  display: flex;
  justify-content: center;
}
.request-country-statistics-chart__google-maps-chart {
  width: 510px;
  height: 335px;
}
`, "",{"version":3,"sources":["webpack://./src/features/dashboard/components/request_country_statistics_chart/request_country_statistics_chart.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,uBAAA;AAAJ;AAGE;EACE,YAAA;EACA,aAAA;AADJ","sourcesContent":[".request-country-statistics-chart {\n  .ant-card-body {\n    display: flex;\n    justify-content: center;\n  }\n\n  &__google-maps-chart {\n    width: 510px;\n    height: 335px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
