import {
  FreeTextRenderer,
  GenericRoleRenderer,
  JointVentureRenderer,
  SimpleEditor,
  StatusRenderer,
} from "@src/features/resource_plan/custom_renderer";
import {
  columnTypes,
  paginationGridOptions,
  preventPinnedColumnJumpingGridOption,
} from "@src/utils/aggrid_utils";

import { defaultFTEColDefs } from "./resource_planner_utils";

export const frameworkComponents = {
  genericRoleRenderer: GenericRoleRenderer,
  specificRoleRenderer: FreeTextRenderer,
  statusRenderer: (params) => StatusRenderer({ params: params }),
  simpleEditorRenderer: SimpleEditor,
  jointVentureRenderer: JointVentureRenderer,
};

const excelBorders = {
  color: "#5d5c5c",
  lineStyle: "Continuous",
  weight: 1,
};

export const excelStyles = [
  {
    id: "header",
    alignment: {
      vertical: "Center",
    },
    interior: {
      color: "#e5e5e5",
      pattern: "Solid",
      patternColor: undefined,
    },
    font: {
      bold: true,
      size: 13,
    },
    borders: {
      borderBottom: excelBorders,
      borderRight: excelBorders,
    },
  },
];

export const updatableColumnProps = [
  // isColumnUpdated, columnName, attributeName
  ["isScopeUpdated", "scope", "scope"],
  ["isProjectIdentifierUpdated", "project_identifier", "projectIdentifier"],
  ["isSpecificRoleUpdated", "specific_role", "specificRole"],
  ["isJointVentureUpdated", "is_joint_venture", "jointVenture"],
  ["isGenericRoleUpdated", "generic_role_id", "generic_role_id"],
];

export const getGridOptions = (columnsDefs) => {
  return {
    ...paginationGridOptions,
    ...preventPinnedColumnJumpingGridOption,
    enableRangeSelection: true,
    enableFillHandle: true,
    suppressCellSelection: false,
    suppressContextMenu: true,
    rowSelection: "multiple",
    animateRows: true,
    rowHeight: 60,
    excelStyles: excelStyles,
    columnDefs: [...columnsDefs],
    suppressRowClickSelection: true,
    domLayout: "autoHeight",
    alwaysAggregateAtRootLevel: true,
    defaultColDef: {
      ...columnTypes.default,
      ...defaultFTEColDefs,
      flex: 1,
      autoHeaderHeight: true,
      resizable: false,
    },
    getRowId: function (params) {
      return params.data.projectRoleRequestId;
    },
    fillOperation: function (params) {
      const isNonAllocationCell = isNaN(
        parseInt(params.column.getColId().substring(0, 4))
      );
      if (isNonAllocationCell && params.direction === "left") {
        return params.currentCellValue;
      }
      return params.values[params.values.length - 1];
    },
  };
};

export const compareNodesByProjectRoleRequestId = (nodeA, nodeB) => {
  return nodeA.projectRoleRequestId == nodeB.projectRoleRequestId;
};

export const getPayloadData = (node, columnName, attributeName) => {
  const payloadData = {
    isAllocationRequest: false,
    requestID: node.data.childId,
    originRequestId: node.data.parentRequestId,
    genericRoleId: null,
    oldGenericRoleId: null,
    requestedYear: null,
    requestedMonth: null,
    allocation: null,
    columnName: columnName,
  };

  if (attributeName === "generic_role_id") {
    payloadData.genericRoleId = node.data.genericRoleId;
    payloadData.oldGenericRoleId = node.initialData.genericRoleId;
    payloadData.value = node.data.genericRoleId.toString();
  } else {
    payloadData.value =
      node.data[attributeName] != null ? node.data[attributeName] : "";
  }

  payloadData.uniqueId = node.data.childId + `_${attributeName}`;
  return payloadData;
};

export const getStandardRoleUpdatedModalMessage = (
  oldGenericRoleName,
  newGenericRoleName,
  requestId,
  teamLeadFullName
) => (
  <>
    <p>
      You have changed the standard role from{" "}
      <label>{oldGenericRoleName}</label> to <label>{newGenericRoleName}</label>{" "}
      for the request <label>{requestId}</label>.
    </p>
    Now team lead <label> {teamLeadFullName} </label> will receive the request.
  </>
);
