import { COLORS } from "@src/constants";

/**
 * Returns the default position for the map.
 * @return {google.maps.LatLngLiteral} - The latitude and longitude object representing the map's center.
 */
export function getMapPosition(): google.maps.LatLngLiteral {
  return {
    lat: 45, // Center of the map (equator and prime meridian)
    lng: 10,
  };
}

/**
 * Returns the options for the Google Map instance.
 * @return {google.maps.MapOptions} - The configuration options for the map.
 */
export function getMapsOptions(): google.maps.MapOptions {
  return {
    restriction: {
      latLngBounds: {
        north: 85, // Maximum latitude
        south: -55, // Minimum latitude
        west: -180, // Minimum longitude
        east: 180, // Maximum longitude
      },
    },
    minZoom: 1,
    disableDefaultUI: true,
    styles: [...getGeometrySettings(), ...getLabelSettings()],
  };
}

/**
 * Returns the label settings for the map.
 * @return {google.maps.MapTypeStyle[]} - The style configuration for map labels.
 */
export function getLabelSettings(): google.maps.MapTypeStyle[] {
  return [
    {
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
  ];
}

/**
 * Returns the geometry settings for the map.
 * @return {google.maps.MapTypeStyle[]} - The style configuration for map geometries.
 */
export function getGeometrySettings(): google.maps.MapTypeStyle[] {
  return [
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: COLORS.WHITE }],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [{ color: COLORS.WHITE }],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [{ color: COLORS.BRAND_50 }],
    },
    {
      featureType: "administrative.province",
      elementType: "geometry.stroke",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ visibility: "off" }],
    },
  ];
}
