import React, { useState, useRef } from "react";
import "./change_team_lead_after_role_change_modal.less";
import {
  girdOptionInformations,
  columnDefs,
} from "./utils/change_team_lead_after_role_change_modal_utils";
import { AgGridReact } from "ag-grid-react";

function ChangeTeamLeadAfterRoleChangeModal({
  setSelectedRequestWithTeammemberToTransfer,
  currentUpdatedUser,
  teammembersAssigendToChangedRequest,
}) {
  const gridApi = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const onGridReady = (params) => {
    gridApi.current = params.api;
  };

  const onSelectionChanged = () => {
    setSelectedRows(gridApi.current.getSelectedRows());
    setSelectedRequestWithTeammemberToTransfer(
      gridApi.current.getSelectedRows()
    );
  };

  return (
    <>
      <div className="info-text" data-testid="infoText">
        <label>Please select employee for transfer.</label>
      </div>
      <div className="selected-info" data-testid="selectedEmployeeNumberInfo">
        You selected <b>{selectedRows.length}</b> employees
      </div>
      <div
        data-testid="agGridEmployeeToReassigneTable"
        className="ag-theme-alpine header-white"
        style={{ height: 300, width: 1150 }}
      >
        <AgGridReact
          onGridReady={onGridReady}
          rowData={teammembersAssigendToChangedRequest}
          onSelectionChanged={onSelectionChanged}
          gridOptions={girdOptionInformations}
          columnDefs={columnDefs(currentUpdatedUser)}
        />
      </div>
    </>
  );
}

export default ChangeTeamLeadAfterRoleChangeModal;
