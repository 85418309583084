// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-box {
  display: flex;
  height: 100%;
}
.main-box .user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-box .user-info .name-label {
  color: white;
}
.main-box .user-info .role-label {
  color: white;
  opacity: 0.5;
  font-size: 10pt;
}
.main-box .photo {
  margin: 5px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e8e8e8;
}
.main-box .photo img {
  height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/app_layout/components/user_area/user_area.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;AADF;AADA;EAKI,aAAA;EACA,sBAAA;EACA,uBAAA;AADJ;AANA;EAUM,YAAA;AADN;AATA;EAcM,YAAA;EACA,YAAA;EACA,eAAA;AAFN;AAdA;EAqBI,WAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AAJJ;AAvBA;EA8BM,YAAA;AAJN","sourcesContent":["@import \"../../../../stylings/variables\";\n\n.main-box {\n  display: flex;\n  height: 100%;\n\n  .user-info {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n\n    .name-label {\n      color: white;\n    }\n\n    .role-label {\n      color: white;\n      opacity: 0.5;\n      font-size: 10pt;\n    }\n  }\n\n  .photo {\n    margin: 5px;\n    height: 50px;\n    width: 50px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: @light-dark-grey;\n\n    img {\n      height: 50px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
