import { RouteComponentProps } from "@reach/router";
import { useAuthentication } from "@src/auth/useAuthentication";
import { useGetEmployeeImpersonationsQuery } from "@src/services/slices/employeesSlice";
import { Select } from "antd";
import Cookies from "universal-cookie";
import "./start_screen_page.less";

const cookies = new Cookies();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StartScreenPage = (props: RouteComponentProps) => {
  const { data: empList } = useGetEmployeeImpersonationsQuery();
  const { isAuthenticating } = useAuthentication();

  const onSelectedMemberChange = (val) => {
    cookies.set("ImpersonationUser", val, { path: "/" });
    cookies.remove("loggedInuser", { path: "/" });
    window.location.reload();
  };

  if (isAuthenticating) return <h1>Loading...</h1>;

  return (
    <div className="start-screen-page">
      {empList?.length > 0 ? (
        <Select
          showSearch
          optionFilterProp="children"
          onChange={onSelectedMemberChange}
          data-testid="teamMember"
          placeholder="Select login to impersonate"
        >
          {empList.map((member) => {
            return (
              <Select.Option
                data-testid={member.employeeId}
                key={member.employeeId}
                value={member.employeeId}
              >
                {member.fullName} | {member.applicationRoles[0]}
              </Select.Option>
            );
          })}
        </Select>
      ) : (
        <div>No team member found. Please enable sso login.</div>
      )}
      <hr />
    </div>
  );
};

export default StartScreenPage;
