import { navigate } from "@reach/router";
import Cookies from "universal-cookie";
import { ROLES, ROUTES } from "../constants";

const cookies = new Cookies();

const rolesPathAssignment = [
  {
    role: ROLES.PROJECT_LEAD,
    path: [ROUTES.PROJECT_LEAD.PROJECTS],
  },
  {
    role: ROLES.TEAM_LEAD,
    path: [ROUTES.TEAM_LEAD.STAFFING_REQUESTS, ROUTES.TEAM_LEAD.TEAM_OVERVIEW],
  },
  {
    role: ROLES.ADMIN,
    path: [ROUTES.ADMIN.ROLE_ASSIGNMENT],
  },
  {
    role: ROLES.MANAGER,
    path: [ROUTES.MANAGER.DASHBOARD],
  },
  {
    role: ROLES.DEPUTY,
    path: [ROUTES.TEAM_LEAD.STAFFING_REQUESTS, ROUTES.TEAM_LEAD.TEAM_OVERVIEW],
  },
];

const CheckUserRoleService = {
  checkRole: () => {
    let pathName = window.location.pathname;

    if (pathName != null) {
      pathName = window.location.pathname;

      const empObj = cookies.get("loggedInuser");

      if (empObj?.applicationRoles && rolesPathAssignment) {
        const roleOfRequestedPath = rolesPathAssignment.filter((rp) =>
          rp.path.find((path) => pathName.indexOf(path) !== -1)
        );

        if (roleOfRequestedPath) {
          const hasUserRoleToEnterTheGivenPath = !!empObj.applicationRoles.find(
            (ar) =>
              roleOfRequestedPath.find((roleRequest) => roleRequest.role === ar)
          );

          if (!hasUserRoleToEnterTheGivenPath) {
            navigate("/");
          }

          return hasUserRoleToEnterTheGivenPath;
        } else {
          return true;
        }
      } else {
        navigate("/");
      }
    }

    return true;
  },

  checkRoleAssignment: (res) => {
    if (!res || res.status !== 200) {
      navigate("/");
      return false;
    }

    return true;
  },
};

export default CheckUserRoleService;
