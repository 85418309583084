import { Form, Input } from "antd";
import React from "react";
import { AddTimeBlocker } from "../add_time_blocker";
import { TeamMemberRenderer } from "../teammember_renderer";
import { TeammemberProjectRenderer } from "../teammember_project_renderer";

function MemberRenderer({
  value,
  timeBlockerInEdit,
  addTimeBlocker,
  teamLeadStandardRoles,
  teamMembers,
  setTeamMembers,
}) {
  const addTimeBlockerButtonSection = () => {
    return (
      <AddTimeBlocker
        timeBlockerInEdit={timeBlockerInEdit}
        addTimeBlocker={addTimeBlocker}
        value={teamMembers?.find(
          (member) => member.employeeId === value.teamMemberId
        )}
      />
    );
  };

  const timeBlockerNameInputField = () => {
    return (
      <div>
        <Form.Item name="Team member">
          <Input
            data-testid="time-blocker-name"
            placeholder="Name of blocker"
          />
        </Form.Item>
      </div>
    );
  };

  const teamMemberAndTeamMemberProjectSection = () => {
    return (
      <div>
        {value?.fullName ? (
          <TeamMemberRenderer
            value={value}
            teamLeadStandardRoles={teamLeadStandardRoles}
            setTeamMembers={setTeamMembers}
          />
        ) : (
          <TeammemberProjectRenderer value={value} />
        )}
      </div>
    );
  };

  function TimeBlockerActionFieldsRenderer(value, timeBlockerInEdit) {
    if (value?.addTimeBlocker) {
      return addTimeBlockerButtonSection();
    }
    if (value?.projectRoleRequestId === timeBlockerInEdit) {
      return timeBlockerNameInputField();
    }
    return teamMemberAndTeamMemberProjectSection();
  }
  return TimeBlockerActionFieldsRenderer(value, timeBlockerInEdit);
}

export default MemberRenderer;
