// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request-overview .ag-row,
.request-overview .ag-header-cell-text {
  font-size: 14px !important;
}
.request-overview .ag-header-cell-text {
  line-height: 20px !important;
  white-space: normal;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_requests/components/request_overview/request_overview.less"],"names":[],"mappings":"AAAA;;EAGI,0BAAA;AAAJ;AAHA;EAOI,4BAAA;EACA,mBAAA;AADJ","sourcesContent":[".request-overview {\n  .ag-row,\n  .ag-header-cell-text {\n    font-size: 14px !important;\n  }\n\n  .ag-header-cell-text {\n    line-height: 20px !important;\n    white-space: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
