// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-icon {
  display: flex;
  color: white;
  align-items: center;
  margin-right: 10px;
  height: 60px;
}
.question-icon__popover {
  width: 17%;
}
.question-icon__popover-inner {
  width: 105%;
}
`, "",{"version":3,"sources":["webpack://./src/components/app_layout/components/help_info/help_info.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;AACF;AACE;EACE,UAAA;AACJ;AACI;EACE,WAAA;AACN","sourcesContent":[".question-icon {\n  display: flex;\n  color: white;\n  align-items: center;\n  margin-right: 10px;\n  height: 60px;\n\n  &__popover {\n    width: 17%;\n\n    &-inner {\n      width: 105%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
