// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.App-link {
  color: #704cb6;
}
@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.modalContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50vw;
  z-index: 10;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding: 50px;
}
.modalContainer .ant-alert {
  margin: 10px;
}
.app-container {
  position: relative;
}
.app-container .ant-alert {
  position: relative;
  animation: alertPushup 0.5s forwards;
}
@keyframes alertPushUp {
  0% {
    bottom: -20px;
  }
  100% {
    bottom: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAEA;EACE,cAAA;EACA,oBAAA;AAAF;AAGA;EACE;IACE,iDAAA;EADF;AACF;AAIA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;AAFF;AAKA;EACE,cAAA;AAHF;AAMA;EACE;IACE,wBAAA;EAJF;EAMA;IACE,2BAAA;EAJF;EAMA;IACE,0BAAA;EAJF;AACF;AAOA;EACE,kBAAA;EACA,OAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;EACA,yBAAA;EACA,aAAA;AALF;AAOA;EACE,YAAA;AALF;AAQA;EACE,kBAAA;AANF;AASA;EACE,kBAAA;EACA,oCAAA;AAPF;AAUA;EACE;IACE,aAAA;EARF;EAWA;IACE,WAAA;EATF;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-float infinite 3s ease-in-out;\n  }\n}\n\n.App-header {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n}\n\n.App-link {\n  color: rgb(112, 76, 182);\n}\n\n@keyframes App-logo-float {\n  0% {\n    transform: translateY(0);\n  }\n  50% {\n    transform: translateY(10px);\n  }\n  100% {\n    transform: translateY(0px);\n  }\n}\n\n.modalContainer {\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  width: 50vw;\n  z-index: 10;\n  display: flex;\n  flex-flow: column;\n  justify-content: flex-end;\n  padding: 50px;\n}\n.modalContainer .ant-alert {\n  margin: 10px;\n}\n\n.app-container {\n  position: relative;\n}\n\n.app-container .ant-alert {\n  position: relative;\n  animation: alertPushup 0.5s forwards;\n}\n\n@keyframes alertPushUp {\n  0% {\n    bottom: -20px;\n  }\n\n  100% {\n    bottom: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
