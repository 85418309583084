// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.standard-role-assignment .anticon-lock {
  margin-right: 5px;
}
.standard-role-assignment .description > span {
  line-height: 100px;
  white-space: nowrap;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: "block";
}
.description__tooltip .ant-tooltip-inner {
  white-space: pre-line;
  text-align: start !important;
  width: max-content;
  max-width: 450px;
  max-height: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/features/role_assignment/components/standard_role_assignment/standard_role_assignment.less"],"names":[],"mappings":"AAAA;EAEI,iBAAA;AAAJ;AAFA;EAMI,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;AADJ;AAKA;EAEI,qBAAA;EACA,4BAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;AAJJ","sourcesContent":[".standard-role-assignment {\n  .anticon-lock {\n    margin-right: 5px;\n  }\n\n  .description > span {\n    line-height: 100px;\n    white-space: nowrap;\n    align-items: center;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: \"block\";\n  }\n}\n\n.description__tooltip {\n  .ant-tooltip-inner {\n    white-space: pre-line;\n    text-align: start !important;\n    width: max-content;\n    max-width: 450px;\n    max-height: fit-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
