import { ApplicationRole } from "@src/types";
import { ICellRendererParams, StatusPanelDef } from "ag-grid-community";

export const updateApplicationRole = (
  selectedApplicationRoles: number[],
  params: ICellRendererParams
): void => {
  const updatedApplicationRoles = params.context.applicationRoles
    .filter(
      (role: ApplicationRole) =>
        !!selectedApplicationRoles.find(
          (roleId) => roleId === role.applicationRoleId
        )
    )
    .map((role: ApplicationRole) => role.applicationRoleName);

  params.node.setDataValue("applicationRoles", updatedApplicationRoles);
};

export const updateStandardRole = (
  selectedStandardRoleId: number,
  params: ICellRendererParams
): void => {
  params.node.setDataValue("genericRoleId", selectedStandardRoleId);
};

export const updateDepartment = (
  selectedDepartmentId: number,
  params: ICellRendererParams
): void => {
  params.node.setDataValue("departmentId", selectedDepartmentId);
};

export const statusBar = (): { statusPanels: StatusPanelDef[] } => {
  return {
    statusPanels: [
      { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
      { statusPanel: "agTotalRowCountComponent", align: "center" },
      { statusPanel: "agFilteredRowCountComponent" },
      { statusPanel: "agSelectedRowCountComponent" },
      { statusPanel: "agAggregationComponent" },
    ],
  };
};
