// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
  height: 40px;
  background-color: #f6f6f4;
  border: 1px solid #efefec;
  display: flex;
  align-items: center;
  border-radius: 4px;
}
.tabs .tab-label {
  display: flex;
  font-size: 15px !important;
  font-family: "RWESansWeb-Medium", sans-serif !important;
  align-items: center;
  gap: 5px;
}
.tabs .tab-label > img {
  height: 16px !important;
  width: 16px !important;
  margin: 0 !important;
}
.tabs .ant-radio-button-wrapper {
  border: none !important;
  background: #f6f6f4 !important;
  color: #617ca0 !important;
  height: 31px;
  font-size: 16px;
  line-height: 30px;
  margin-right: 4px;
  margin-left: 4px;
  border-radius: 2px;
}
.tabs .ant-radio-button-wrapper-checked {
  border-color: #8ea1bb !important;
  background: #ffffff !important;
  color: #1d4477 !important;
  box-shadow: 0px 2px 2px 0px #0000000d;
}
.tabs .ant-radio-button-wrapper-checked :focus {
  box-shadow: none !important;
  border-color: #8ea1bb !important;
}
.tabs .ant-radio-button-wrapper::before {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/features/table_filtering/components/filter_tabs/filter_tabs.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,yBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AADF;AALA;EAeI,aAAA;EACA,0BAAA;EACA,uDAAA;EACA,mBAAA;EACA,QAAA;AAPJ;AAZA;EAUM,uBAAA;EACA,sBAAA;EACA,oBAAA;AAKN;AAjBA;EAuBI,uBAAA;EACA,8BAAA;EACA,yBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AAHJ;AA5BA;EAmCI,gCAAA;EACA,8BAAA;EACA,yBAAA;EACA,qCAAA;AAJJ;AAlCA;EAyCM,2BAAA;EACA,gCAAA;AAJN;AAtCA;EA+CI,aAAA;AANJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.tabs {\n  height: 40px;\n  background-color: @sand-40;\n  border: 1px solid @sand-70;\n  display: flex;\n  align-items: center;\n  border-radius: 4px;\n\n  .tab-label {\n    > img {\n      height: 16px !important;\n      width: 16px !important;\n      margin: 0 !important;\n    }\n\n    display: flex;\n    font-size: 15px !important;\n    font-family: \"RWESansWeb-Medium\", sans-serif !important;\n    align-items: center;\n    gap: 5px;\n  }\n\n  .ant-radio-button-wrapper {\n    border: none !important;\n    background: @sand-40 !important;\n    color: @brand-70 !important;\n    height: 31px;\n    font-size: 16px;\n    line-height: 30px;\n    margin-right: 4px;\n    margin-left: 4px;\n    border-radius: 2px;\n  }\n\n  .ant-radio-button-wrapper-checked {\n    border-color: @brand-50 !important;\n    background: @white !important;\n    color: @brand !important;\n    box-shadow: 0px 2px 2px 0px #0000000d;\n\n    :focus {\n      box-shadow: none !important;\n      border-color: @brand-50 !important;\n    }\n  }\n\n  .ant-radio-button-wrapper::before {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
