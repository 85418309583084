// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.region-country-col-text {
  font-size: 14px !important;
  font-family: "RWESansWeb-Medium", sans-serif !important;
}
.region-country-col-subtext {
  font-family: "RWESansWeb-Regular";
  font-size: 12px !important;
  color: #3b3d42;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_requests/custom_renderer/region_country_renderer/region_country_renderer.less"],"names":[],"mappings":"AAGE;EACE,0BAAA;EACA,uDAAA;AAFJ;AAKE;EACE,iCAAA;EACA,0BAAA;EACA,cAAA;AAHJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.region-country-col {\n  &-text {\n    font-size: 14px !important;\n    font-family: \"RWESansWeb-Medium\", sans-serif !important;\n  }\n\n  &-subtext {\n    font-family: \"RWESansWeb-Regular\";\n    font-size: 12px !important;\n    color: @font-00;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
