// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.total-fte-chart .ant-card-body {
  padding: 40px;
}
.total-fte-chart__line-chart {
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/features/dashboard/components/total_fte_chart/total_fte_chart.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;AAAJ;AAGE;EACE,YAAA;EACA,WAAA;AADJ","sourcesContent":[".total-fte-chart {\n  .ant-card-body {\n    padding: 40px;\n  }\n\n  &__line-chart {\n    height: 100%;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
