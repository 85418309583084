import React, { useEffect, useRef, useState } from "react";

import "./update_project_lead_modal.less";
import { useUpdatedProjectsResponseMutation } from "@src/services/slices/projectsSlice";
import { Employee, Project } from "@src/types";
import {
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  RowNode,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Modal, message } from "antd";

import {
  columns,
  gridOptions,
  projectleadSelectionRenderer,
} from "../../utils/project_lead_selection_table_config";

interface UpdateProjectLeadModalProps {
  setModalVisible?: (modalVisible: boolean) => void;
  modalVisible: boolean;
  openedDetailsSapDTO: ICellRendererParams;
  projectleads: Employee[];
  mainGridApi: React.MutableRefObject<GridApi>;
}

const UpdateProjectLeadModal: React.FC<UpdateProjectLeadModalProps> = ({
  setModalVisible,
  modalVisible,
  openedDetailsSapDTO,
  projectleads,
  mainGridApi,
}) => {
  const gridApi = useRef<GridApi<string[]>>(null);
  const [rowData, setRowdata] = useState<string[]>([]);
  const [isSavebtnDisabled, setIsSavebtnDisabled] = useState<boolean>(true);
  const [changedProjectsWithProjectleads, setChangedProjectsWithProjectleads] =
    useState<Project[]>([]);

  const [updatedProjectsResponse] = useUpdatedProjectsResponseMutation();

  useEffect(() => {
    const projectList: string[] = [];
    for (const project of openedDetailsSapDTO.data.projectDtoList) {
      projectList.push(project);
    }
    setRowdata(projectList);
  }, []);

  const onGridReady = (params: GridReadyEvent): void => {
    gridApi.current = params.api;
  };

  const onChange = (
    selectedProjectLeadId: number,
    projectElement: ICellRendererParams
  ): void => {
    const updatedProjects: Project[] = changedProjectsWithProjectleads;

    const foundAlreadyUpdatedProject = updatedProjects.find(
      (project: Project) => project.id === projectElement.data.id
    );

    const foundProjectLead: Employee = projectleads?.find(
      (lead: Employee) => lead.employeeId === selectedProjectLeadId
    );

    if (foundAlreadyUpdatedProject) {
      foundAlreadyUpdatedProject.projectManager = foundProjectLead;
    } else {
      projectElement.data.projectManager = foundProjectLead;
      updatedProjects.push(projectElement.data);
    }

    setChangedProjectsWithProjectleads(updatedProjects);

    if (gridApi.current) {
      const isSaveBtnDisabled =
        gridApi.current.getDisplayedRowCount() !== updatedProjects.length;

      setIsSavebtnDisabled(isSaveBtnDisabled);
    }
  };

  const updateRowNode = (
    currentGridApi: GridApi,
    rowNodeToUpdate: RowNode,
    type: string
  ): void => {
    currentGridApi.applyTransaction({ [type]: [rowNodeToUpdate] });
    currentGridApi.flashCells({ rowNodes: [rowNodeToUpdate] });
  };

  const changeElements = (): void => {
    updatedProjectsResponse(changedProjectsWithProjectleads)
      .unwrap()
      .then(() => {
        message.success("Successfully updated projects", 5);
        openedDetailsSapDTO.data.projectDtoList = [];
        updateRowNode(mainGridApi.current, openedDetailsSapDTO.data, "update");
        setModalVisible(false);
      })
      .catch(() => message.error("Failed to change project."));
  };

  const frameworkComponents = {
    projectleadSelectionRenderer: (params: ICellRendererParams) =>
      projectleadSelectionRenderer(params, onChange, projectleads),
  };

  return (
    <Modal
      className="update-project-lead-modal"
      width="680px"
      open={modalVisible}
      centered
      title="Project lead selection"
      closable={false}
      maskClosable={false}
      okButtonProps={{ type: "primary", disabled: isSavebtnDisabled }}
      onOk={changeElements}
      onCancel={() => setModalVisible(false)}
    >
      <div data-testid="select-pl-info-msg">
        <span>Please select a new Project lead to replace </span>
        <b>
          {openedDetailsSapDTO.data.firstName}{" "}
          {openedDetailsSapDTO.data.lastName}
        </b>
      </div>
      <br />
      <div
        className="ag-theme-alpine header-white"
        style={{ height: 310, width: 640 }}
      >
        <AgGridReact
          rowData={rowData}
          components={frameworkComponents}
          columnDefs={columns}
          onGridReady={onGridReady}
          gridOptions={gridOptions}
        />
      </div>
    </Modal>
  );
};

export default UpdateProjectLeadModal;
