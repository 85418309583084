import React, { useMemo, useState } from "react";

import {
  getChartOptions,
  getCenterLabelPlugin,
  mapChartData,
  getCustomLegendPlugin,
} from "@src/features/dashboard/utils/request_status_statistics_chart_utils";
import { RequestStatusStatistics } from "@src/types";
import { Card } from "antd";
import "./request_status_statistics_chart.less";
import {
  ArcElement,
  Chart,
  ChartData,
  ChartOptions,
  Legend,
  Tooltip,
  Plugin,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { DoughnutChartLegendItem } from "../doughnut_chart_legend_item";

Chart.register(ArcElement, Legend, Tooltip);

export interface RequestStatusStatisticsChartProps {
  data?: RequestStatusStatistics;
  isLoading?: boolean;
}

const RequestStatusStatisticsChart: React.FC<
  RequestStatusStatisticsChartProps
> = ({ data, isLoading }) => {
  const [legend, setLegend] = useState<React.ReactNode>();

  const chartData = useMemo<ChartData<"doughnut">>(() => {
    return mapChartData(data);
  }, [data]);

  const chartOptions = useMemo<ChartOptions<"doughnut">>(() => {
    return getChartOptions();
  }, []);

  const centerLabel: Plugin<"doughnut"> = getCenterLabelPlugin();
  const customLegend: Plugin<"doughnut"> = getCustomLegendPlugin(setLegend);

  return (
    <Card
      title="Requests & their states"
      className="request-status-statistics-chart"
      data-testid="request-status-statistics-chart"
      loading={isLoading}
    >
      <div className="request-status-statistics-chart__doughnut-chart">
        <Doughnut
          data={chartData}
          options={chartOptions}
          plugins={[customLegend, centerLabel]}
          data-testid="doughnut-chart"
        />
      </div>
      <div
        className="request-status-statistics-chart__legend legend"
        data-testid="legend"
      >
        {legend}
        <DoughnutChartLegendItem
          className="legend__item_total"
          text="Total Requests"
          count={data?.total}
        />
      </div>
    </Card>
  );
};

export default RequestStatusStatisticsChart;
