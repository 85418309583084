// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-standard-role-form .ant-form-item {
  margin-bottom: 24px !important;
}
.role-information {
  color: grey;
  padding-top: 16px !important;
}
.role-information .col-description {
  padding-right: 0 !important;
}
.role-information .text-area-description {
  height: 80px;
  color: grey;
}
.role-information .ant-input {
  color: grey;
}
.role-information .ant-input-disabled {
  cursor: default !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/resource_plan/components/select_standard_role_modal/select_standard_role_modal.less"],"names":[],"mappings":"AAAA;EAEI,8BAAA;AAAJ;AAIA;EACE,WAAA;EACA,4BAAA;AAFF;AAAA;EAKI,2BAAA;AAFJ;AAHA;EASI,YAAA;EACA,WAAA;AAHJ;AAPA;EAcI,WAAA;AAJJ;AAVA;EAkBI,0BAAA;AALJ","sourcesContent":[".select-standard-role-form {\n  .ant-form-item {\n    margin-bottom: 24px !important;\n  }\n}\n\n.role-information {\n  color: grey;\n  padding-top: 16px !important;\n\n  .col-description {\n    padding-right: 0 !important;\n  }\n\n  .text-area-description {\n    height: 80px;\n    color: grey;\n  }\n\n  .ant-input {\n    color: grey;\n  }\n\n  .ant-input-disabled {\n    cursor: default !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
