import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { ColDef, GridOptions } from "ag-grid-community";

import { getCommonGridOptions } from "./common_grid_options";
import { RoleTypes } from "./role_assignment_constants";

export const columnDefs: ColDef[] = [
  {
    headerName: "Specification",
    field: "roleSpecificationName",
    colId: "roleSpecification", // needed for getFilterInstance in role_assignment_filter
    filter: "agSetColumnFilter",
  },
  {
    headerName: "Category",
    field: "roleSpecificationCategory",
    filter: "agSetColumnFilter",
  },
];

/**
 * sets the grid options for the RoleSpecificationManagement.
 * combining basic role specific grid options with common ones
 *
 * @param updateRoleRtkFunction function to reach the update endpoint
 * @param deleteRoleRtkFunction function to reach the delete endpoint
 * @returns the grid options
 */
export function createGridOptions(
  updateRoleRtkFunction: MutationTrigger<any>,
  deleteRoleRtkFunction: MutationTrigger<any>
): GridOptions {
  const gridOptions = getCommonGridOptions(
    RoleTypes.ROLE_SPECIFICATION,
    columnDefs,
    updateRoleRtkFunction,
    deleteRoleRtkFunction
  );
  return gridOptions;
}
