import React, { useState } from "react";

import { navigate } from "@reach/router";
import { DeleteProjectConfirmationModal } from "@src/components/modals/delete_project_confirmation_modal";
import { useUpdateProjectMutation } from "@src/services/slices/projectsSlice";
import { ProjectPayload } from "@src/types";
import { ICellRendererParams } from "ag-grid-community";
import { App, Button } from "antd";

import { getProjectPayload } from "../../utils/admin_project_overview_utils";

interface ActionsRendererProps {
  props: ICellRendererParams;
}

const ActionsRenderer: React.FC<ActionsRendererProps> = ({ props }) => {
  const { message } = App.useApp();

  const [showDeleteProjectModal, setShowDeleteProjectModal] =
    useState<boolean>(false);

  const [updateProject] = useUpdateProjectMutation();

  function onEdit(): void {
    props.data.isEditActive = true;
    props.api.redrawRows({ rowNodes: [props.node] });
  }

  function onCancel(): void {
    const resetData = {
      ...props.data,
      ...props.data.initialData,
    };
    delete resetData.initialData;
    delete resetData.isEditActive;
    props.node.setData(resetData);
    props.api.redrawRows({ rowNodes: [props.node] });
  }

  async function onSave(): Promise<void> {
    const payload: ProjectPayload = getProjectPayload(props.data);

    try {
      await updateProject(payload).unwrap();
      delete props.data.initialData;
      delete props.data.isEditActive;
      props.api.redrawRows({ rowNodes: [props.node] });
      message.success("Successfully updated project");
    } catch (error) {
      console.log(`error updating project with id: ${props.data.id}`);
    }
  }

  async function onDelete(): Promise<void> {
    setShowDeleteProjectModal(true);
  }

  function onDeleteCallback(): void {
    props.api.applyTransaction({ remove: [props.data] });
  }

  function onImport(): void {
    navigate(`/projectAssignments/${props.data.id}/import`);
  }

  return (
    <>
      <div className="actions-buttons">
        <Button
          disabled={props.data.isEditActive}
          type="primary"
          onClick={onEdit}
          data-testid="edit-button"
        >
          Edit
        </Button>
        <Button
          disabled={!props.data.isEditActive}
          type="primary"
          danger
          onClick={onDelete}
          data-testid="delete-button"
        >
          Delete Project
        </Button>
        <Button
          disabled={!props.data.isEditActive}
          onClick={onCancel}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
        <Button
          disabled={!props.data.isEditActive}
          type="primary"
          onClick={onSave}
          data-testid="save-button"
        >
          Save
        </Button>
        <Button
          disabled={!props.data.isEditActive}
          type="primary"
          onClick={onImport}
          data-testid="import-button"
        >
          Import Resource Plan
        </Button>
      </div>

      {showDeleteProjectModal && (
        <DeleteProjectConfirmationModal
          setModalVisible={setShowDeleteProjectModal}
          modalVisible={showDeleteProjectModal}
          projectId={props.data.id}
          onDeleteCallback={onDeleteCallback}
        />
      )}
    </>
  );
};

export default ActionsRenderer;
